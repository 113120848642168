/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

import ProjectManagementCommonHeader from "components/ProjectManagementComponents/Common/Header/ProjectManagementCommonHeader";

import ChallengesCommonHeader from "components/ChallengeComponents/Common/Header/ChallengesCommonHeader";
import ReportListViewHeader from "./Header/ReportListViewHeader";

//import { assetImages } from "constants";

import {
  createdAtToDateConverter,
  getDateFormatInSpanish,
} from "helper/Common/CommonHelper";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const ReportListViewBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);

  const [responseList, setResponseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //function for get response
  const getAllChallengeResponse = async () => {
    setRowSelection({});
    setSelectedResponseIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_RESPONSE +
        `?token=${token}`;

      console.log("response requestUrl", requestUrl);

      const response = await getData(requestUrl);

      console.log("response data >>> ", response);

      if (response.status) {
        setResponseList(response.data);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "responsecode",
        header: "Response Code",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <span
              className={`fw-semibold ${
                row.original.approvalstatus == "2"
                  ? "text-danger"
                  : row.original.approvalstatus == "1"
                  ? "text-success"
                  : "text-black"
              }`}
            >
              {row.original.responsecode}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "jobcode",
        header: "Job Code",
      },
      {
        accessorKey: "challengename",
        header: "Challenge",
        Cell: ({ row }) => (
          <div className="title border-bottom-0" style={{ width: "200px" }}>
            <a
              target="_blank"
              href={`${url.FRONTEND_BASE_URL}/jobs/details/${row.original.challengeid}`}
              className="d-flex flex-auto"
            >
              <span class="d-block material-symbols-outlined icon-md me-2">
                link
              </span>
              {row.original.challengename.slice(0, 50)}
            </a>
          </div>
        ),
      },
      {
        accessorKey: "leadname",
        header: "Lead",
      },
      {
        accessorKey: "applicantname",
        header: "Provider / Applicant",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.applicantname}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.applicantemail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "submittedbyname",
        header: "Submitted By",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.submittedbyname}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.submittedbyemail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "challengedate",
        header: "Challenge Date",
      },
      {
        accessorKey: "applieddate",
        header: "Applied On",
      },
      {
        accessorKey: "approvalresponsedate",
        header: "Responded on",
        Cell: ({ row }) => (
          <div
            className={`title border-bottom-0 ${
              row.original.approvalstatus == "2"
                ? "text-danger"
                : row.original.approvalstatus == "1"
                ? "text-success"
                : "text-black"
            }`}
          >
            {row.original.approvalresponsedate === ""
              ? "-"
              : row.original.approvalresponsedate}
          </div>
        ),
      },
      {
        accessorKey: "providerbudget",
        header: "P. Budget",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            {row.original.providerbudget ? row.original.providerbudget : "-"}{" "}
            &euro;
          </div>
        ),
      },
      {
        accessorKey: "customerbudget",
        header: "Cr Budget",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            {row.original.customerbudget ? row.original.customerbudget : "-"}{" "}
            &euro;
          </div>
        ),
      },
      {
        accessorKey: "candidateskillpoints",
        header: "skill / points (N/10) / exp. years",
        Cell: ({ row }) => (
          <div className="title border-bottom-0" style={{ width: "300px" }}>
            {row.original.candidateskillpoints.map((skillpoint, index) => {
              return (
                <p key={index}>
                  {skillpoint.skillname ? skillpoint.skillname : "--"} /{" "}
                  {skillpoint.points} pnts / {skillpoint.experienceyear} yrs
                </p>
              );
            })}
          </div>
        ),
      },
      {
        accessorKey: "isagency",
        header: "Agency",
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            {row.original.isagency ? row.original.submittedbyname : "-"}
          </div>
        ),
      },
      {
        accessorKey: "responseCount",
        header: "Response Count",
      },
    ],
    []
  );

  useEffect(() => {
    getAllChallengeResponse();
  }, []);

  return (
    <div id="content_wrapper">
      <section class="survey-wrapper bg-white pb-5">
        <ChallengesCommonHeader componentName="fullreport" />

        <div class="container-fluid px-lg-5">
          <ReportListViewHeader />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div class="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={responseList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                enableRowActions // show the action column
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem key="details">
                    <Link
                      className="dropdown-item"
                      target="_blank"
                      to={`/admin/challengeresponse/details/${row.original.challengeid}/${row.id}`}
                    >
                      View Details
                    </Link>
                  </MenuItem>,
                  <MenuItem key="feedback">
                    <Link
                      className="dropdown-item"
                      target="_blank"
                      to={`/admin/customerfeedback/${row.id}`}
                    >
                      Customer Feedback
                    </Link>
                  </MenuItem>,
                ]} // action columns menu items
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ReportListViewBody;
