/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import BlogHeader from "../Header/BlogHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
// import ViewBlogPopup from "../Popup/ViewBlogPopup";
import BlogFilterPopup from "../Popup/BlogFilterPopup";

//import images
import { assetImages } from "constants";

import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const BlogListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterBlogTitle, setFilterBlogTitle] = useState("");
  const [filterBlogCategory, setFilterBlogCategory] = useState([]);
  const [filterBlogLabels, setFilterBlogLabels] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedBlogIds, setSelectedBlogIds] = useState([]);

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedBlogList = [...blogList];
    const rowIndex = updatedBlogList.findIndex(
      (blog) => blog._id === row.original._id
    );
    if (rowIndex !== -1) {
      updatedBlogList[rowIndex].rank = value;
      setBlogList(updatedBlogList);
    }
  };

  //function for get all category
  const fetchAllCategories = async () => {
    try {
      const parentSlug = "blogpost";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setCategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all blogs
  const getAllBlogs = async () => {
    setRowSelection({});
    setSelectedBlogIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_BLOG + `?token=${token}`;

      //search by date
      if (filterDateRange != "") {
        requestUrl = requestUrl + `&blogdates=${filterDateRange}`;
      }

      //search by blog name
      if (filterBlogTitle != "") {
        requestUrl = requestUrl + `&blogtitle=${filterBlogTitle}`;
      }

      //search by category
      if (filterBlogCategory.length > 0) {
        requestUrl = requestUrl + `&category=${filterBlogCategory}`;
      }

      //search by tags
      if (filterBlogLabels.length > 0) {
        requestUrl = requestUrl + `&tags=${filterBlogLabels}`;
      }

      console.log("url of all blogs------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("blog list response------>", response);

      if (response.status) {
        setIsLoading(false);
        setBlogList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async (e) => {
    for (let selectedBlogId of selectedBlogIds) {
      try {
        let blogData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_BLOG +
          `/${selectedBlogId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, blogData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    getAllBlogs();
  };

  //function for update rank
  const updateRankHandler = async () => {
    let responseArr = [];

    //run loop checkbox wise
    for (let selectedBlogId of selectedBlogIds) {
      //check if checkbox was checked.If checked then proceed

      //run loop of updated bloglist
      for (let blog of blogList) {
        let blogData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (blog._id == selectedBlogId) {
          blogData.rank = blog.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_BLOG +
              `/${selectedBlogId}` +
              `?token=${token}`;
            const response = await putData(requestUrl, blogData);
            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    if (responseArr.length > 0) {
      getAllBlogs();
    }
  };

  //function for after tag modal close
  const afterTagModalClose = () => {
    fetchAllCategories();
    getAllTags();
    getAllBlogs();
  };

  //function for reload lead list by clicking refresh icon
  const reloadBlogList = () => {
    getAllBlogs();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange("");
    setFilterBlogTitle("");
    setFilterBlogCategory([]);
    setFilterBlogLabels([]);
  };

  //use effect for search filter popup
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterBlogTitle != "" ||
      filterBlogCategory.length > 0 ||
      filterBlogLabels.length > 0
    ) {
      getAllBlogs();
    }
  }, [filterDateRange, filterBlogTitle, filterBlogCategory, filterBlogLabels]);

  useEffect(() => {
    fetchAllCategories();
    getAllTags();
    getAllBlogs();
  }, []);

  /*------ Material React Table Column and States ---- */
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("Title"),
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100px",
            }}
          >
            <input
              // loading="lazy"
              type="number"
              name="rank"
              id="rank"
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
            />
          </Box>
        ),
      },
      {
        accessorKey: "categorystring",
        header: t("Category"),
      },
      {
        accessorKey: "authorname",
        header: t("Posted By"),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "200px",
            }}
          >
            <img
              height={30}
              src={url.SERVER_URL + row.original.authorimage}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "createddate",
        header: t("Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [blogList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedBlogIds(selectedIdsArray);
    } else {
      setSelectedBlogIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CMS")
  ) {
    return (
      <div id="content_wrapper">
        <section className="blog-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <BlogHeader
              reloadBlogList={reloadBlogList}
              updateRankHandler={updateRankHandler}
            />

            {/* ----- blog list table start ----- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                {/* ------ pagination section end ------ */}
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={blogList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/blog/save/${row.id}`}
                      >
                        {t("View Details")}
                      </Link>
                    </MenuItem>,
                    ,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ----- blog list table end ----- */}
          </div>
        </section>
        {/* ======== all modal and pop up render here ======= */}
        <AddLabelModal
          selectedIds={selectedBlogIds}
          moduleName="blog"
          afterTagModalClose={afterTagModalClose}
        />

        {/* <ViewBlogPopup /> */}

        {/* blog search popup */}
        <BlogFilterPopup
          categoryList={categoryList}
          tagList={tagList}
          setFilterDateRange={setFilterDateRange}
          setFilterBlogTitle={setFilterBlogTitle}
          setFilterBlogCategory={setFilterBlogCategory}
          setFilterBlogLabels={setFilterBlogLabels}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedBlogIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t("blog")}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default BlogListBody;
