import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AllLogListBody from "components/AllLogComponents/AllLogListBody/AllLogListBody";

const AllLogList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "All Logs";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="alllogs" />
      <AllLogListBody />
    </main>
  );
};

export default AllLogList;
