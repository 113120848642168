/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PortfolioModal = ({ userPortfolioLinks, setuserPortfolioLinks }) => {
  const linkTemplate = {
    linktext: "",
  };

  const [linkBlock, setlinkBlock] = useState([]);

  const addNewLinkBlock = () => {
    setlinkBlock([...linkBlock, linkTemplate]);
  };

  const deleteLinkBlock = (index) => {
    const deleteLinkArr = [...linkBlock];
    deleteLinkArr.splice(index, 1);
    setlinkBlock(deleteLinkArr);
  };

  //function for change survey select
  const linkInputHandler = (index, field, value) => {
    const updatedFormValues = linkBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setlinkBlock(updatedFormValues);
  };

  const updatedPortfolioBlock = (updatedLinks) => {
    setlinkBlock(updatedLinks);
  };

  const saveInfoHandler = () => {
    if (linkBlock.length > 0) {
      setuserPortfolioLinks(linkBlock);
    } else {
      setuserPortfolioLinks([]);
    }

    resetHandler();

    let loginModal = document.querySelector("#portfolio_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setlinkBlock([]);
  };

  useEffect(() => {
    if (userPortfolioLinks.length > 0) {
      updatedPortfolioBlock(userPortfolioLinks);
    } else {
      updatedPortfolioBlock([]);
    }
  }, [userPortfolioLinks]);

  return (
    <div
      className="modal fade"
      id="portfolio_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Portfolio Links</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                <div className="form-group mb-3">
                  <label className="form-label">Links</label>
                  {linkBlock.map((linkData, index) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-1 mb-2"
                        key={index}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Links"
                          value={linkData.linktext}
                          onChange={(e) => {
                            linkInputHandler(index, "linktext", e.target.value);
                          }}
                        />
                        <div className="adnew_btn">
                          <Link
                            onClick={() => {
                              deleteLinkBlock(index);
                            }}
                            to="#"
                            className="d-inline-flex align-items-center justify-content-center gap-1"
                          >
                            <i className="material-symbols-outlined">delete</i>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="form-group mb-3">
                  <div className="adnew_btn">
                    <Link
                      to="#"
                      className="d-inline-flex align-items-center justify-content-center gap-1"
                      onClick={addNewLinkBlock}
                    >
                      <i className="material-symbols-outlined">add </i>
                      <span>Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={saveInfoHandler}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioModal;
