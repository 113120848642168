import React, { useState, useEffect } from "react";

import DashboardOldHeader from "../Header/DashboardOldHeader";

import DashboardOldActivityTab from "../Tab/DashboardOldTabs/DashboardOldActivityTab";
import DashboardOldCustomerTab from "../Tab/DashboardOldTabs/DashboardOldCustomerTab";
import AverageOldSalesTab from "../Tab/DashboardOldTabs/AverageOldSalesTab";
import TicketsOldPerYear from "../Tab/DashboardOldTabs/TicketsOldPerYear";
import SalesByChannelOld from "../Tab/DashboardOldTabs/SalesByChannelOld";
import TicketsPerMonthOld from "../Tab/DashboardOldTabs/TicketsPerMonthOld";
import TicketsPerDaysOfWeekOld from "../Tab/DashboardOldTabs/TicketsPerDaysOfWeekOld";
import TicketsByGenderOld from "../Tab/DashboardOldTabs/TicketsByGenderOld";
import TicketsByPriceOld from "../Tab/DashboardOldTabs/TicketsByPriceOld";
import TicketsByPromotionOld from "../Tab/DashboardOldTabs/TicketsByPromotionOld";
import TicketsBySectorOld from "../Tab/DashboardOldTabs/TicketsBySectorOld";
import TicketsByBillOld from "../Tab/DashboardOldTabs/TicketsByBillOld";
import TicketsByWorkOld from "../Tab/DashboardOldTabs/TicketsByWorkOld";
import BestBuyerTabOld from "../Tab/DashboardOldTabs/BestBuyerTabOld";
import BuyerDistinctEventCountOld from "../Tab/DashboardOldTabs/BuyerDistinctEventCountOld";
import DashboardOldFilterPopup from "../Popup/DashboardOldFilterPopup";

const DashboardOldBody = () => {
  const [dashboardTab, setDashboardTab] = useState("activity");

  const [organizations, setOrganizations] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [events, setEvents] = useState([]);

  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);

  const [zipcodes, setZipcodes] = useState([]);

  const [operationType, setOperationType] = useState([]);

  const [refreshData, setRefreshData] = useState(false);

  const [applyFilter, setApplyFilter] = useState(false);

  const commonProps = {
    startDate,
    endDate,
    operationStartDate,
    operationEndDate,
    organizations,
    enclosures,
    sectors,
    eventTypes,
    events,
    channels,
    zones,
    zipcodes,
    operationType,

    //setApplyFilter,
    applyFilter,
    refreshData,
    //setRefreshData
  };

  useEffect(() => {
    //console.log('refreshData >>>>>', refreshData)

    if (refreshData) {
      setApplyFilter(false);
      setRefreshData(false);

      // reset states
      setStartDate("");
      setEndDate("");
      setOperationStartDate("");
      setOperationEndDate("");
      setOrganizations([]);
      setEnclosures([]);
      setSectors([]);
      setEventTypes([]);
      setEvents([]);
      setChannels([]);
      setZones([]);
      setZipcodes([]);
      setOperationType([]);
    }
  }, [refreshData]);

  return (
    <div id="content_wrapper">
      <section className="dashboard-wrapper">
        {/* <!-- ========== Start dashboard header component ========== --> */}
        <DashboardOldHeader
          setRefreshData={setRefreshData}
          setDashboardTab={setDashboardTab}
        />

        {/* <!-- ========== Start dashboard tab components ========== --> */}
        <div className="tab-container">
          <div className="container-fluid px-lg-5">
            <div className="tab-content" id="myTabContent">
              {/* activity  */}
              {dashboardTab === "activity" && (
                <DashboardOldActivityTab {...commonProps} />
              )}

              {/* customer */}
              {dashboardTab === "customer" && (
                <DashboardOldCustomerTab {...commonProps} />
              )}

              {/* sales per year per month */}

              {dashboardTab === "sales" && (
                <AverageOldSalesTab {...commonProps} />
              )}

              {/* sales per year per month */}

              {dashboardTab === "ticketsperyear" && (
                <TicketsOldPerYear {...commonProps} />
              )}

              {/* sales per channel */}

              {dashboardTab === "salesbychannel" && (
                <SalesByChannelOld {...commonProps} />
              )}

              {/* sales per month per year */}

              {dashboardTab === "ticketspermonth" && (
                <TicketsPerMonthOld {...commonProps} />
              )}

              {/* sales per day by week */}

              {dashboardTab === "ticketsperdayofweek" && (
                <TicketsPerDaysOfWeekOld {...commonProps} />
              )}

              {/* tickets by gender */}

              {dashboardTab === "ticketsbygender" && (
                <TicketsByGenderOld {...commonProps} />
              )}

              {/* tickets by price */}

              {dashboardTab === "ticketsbyprice" && (
                <TicketsByPriceOld {...commonProps} />
              )}

              {/* tickets by promotion */}

              {dashboardTab === "ticketsbypromotion" && (
                <TicketsByPromotionOld {...commonProps} />
              )}

              {/* tickets by sector */}

              {dashboardTab === "ticketsbysector" && (
                <TicketsBySectorOld {...commonProps} />
              )}

              {/* tickets by bill */}

              {dashboardTab === "ticketsbybill" && (
                <TicketsByBillOld {...commonProps} />
              )}

              {/* tickets by work */}

              {dashboardTab === "ticketsbywork" && (
                <TicketsByWorkOld {...commonProps} />
              )}

              {/* Best Buyer */}

              {dashboardTab === "bestbuyer" && (
                <BestBuyerTabOld {...commonProps} />
              )}

              {/* distinct event count by customer */}

              {dashboardTab === "buyerdistinctevent" && (
                <BuyerDistinctEventCountOld {...commonProps} />
              )}
            </div>
          </div>
        </div>
        {/* <!-- ========== End dashboard tab components ========== --> */}
      </section>

      {/* Filter popup for dashboard */}
      <DashboardOldFilterPopup
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOperationStartDate={setOperationStartDate}
        setOperationEndDate={setOperationEndDate}
        setOrganizations={setOrganizations}
        setEnclosures={setEnclosures}
        setSectors={setSectors}
        setEventTypes={setEventTypes}
        setEvents={setEvents}
        setZones={setZones}
        setZipcodes={setZipcodes}
        setOperationType={setOperationType}
        setChannels={setChannels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
        refreshData={refreshData}
      />
    </div>
  );
};

export default DashboardOldBody;
