/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";
import JobFeedbackLeftPanel from "./Panels/JobFeedbackLeftPanel";
import JobFeedbackRightPanel from "./Panels/JobFeedbackRightPanel";
import JobReviewFeedbackPopup from "./Popup/JobReviewFeedbackPopup";

const CustomerJobFeedbackBody = () => {
  const params = useParams();

  const [cvResponseData, setCvResponseData] = useState({});

  const [challengeResponseId, setChallengeResponseId] = useState("")



  //** function for get cv details of logged in user */
  const getCvDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GUEST_CV_DETAILS + `/${params.id}`;

      const response = await getData(requestURL);

      console.log("response in cv details", response);

      if (response.status) {
        if (response.data) {
          setCvResponseData(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      setChallengeResponseId(params.id);

      getCvDetails();
    }
  }, [params.id]);

  return (
    <div class="my_survey_outer cv_wrapper  d-flex">
      <JobFeedbackLeftPanel cvResponseData={cvResponseData} />

      <JobFeedbackRightPanel cvResponseData={cvResponseData} />

      <JobReviewFeedbackPopup 
        challengeResponseId={challengeResponseId}        
      />
    </div>
  );
};

export default CustomerJobFeedbackBody;
