/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";
import TicketsListHeader from "../TicketsListHeader/TicketsListHeader";
import { Link } from "react-router-dom";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import TicketsListFilterPopup from "../Popup/TicketsListFilterPopup";
import CSVUploadModal from "../Modals/CSVUploadModal";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import ImportModal from "../Modals/ImportModal";
//import AddToListModal from "components/Common/Modal/AddToListModal";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";

const TicketListBody = () => {

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTicketsIds, setSelectedTicketsIds] = useState([]);
  const [selectedTicketsEmails, setSelectedTicketsEmails] = useState([]);


  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // filter params states to be sent to API and as props to child components ------------
  const [applyFilter, setApplyFilter] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [reloadDefault, setReloadDefault] = useState(false); // reload the default view used on reset click

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] =  useState("00:01");
  const [endTime, setEndTime] = useState("23:59");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [events, setEvents] = useState([]);
  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);

  const [entrycode, setEntrycode] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerLocation, setCustomerLocation] = useState("");

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedTicketsIds([]);
    setSelectedTicketsEmails("");
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all customers
  const getTicketsList = async (isFilter = false) => {
    setRowSelection({});
    setSelectedTicketsIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKETS_CUSTOMER +
        `?token=${token}&userrolestring=${userInfo.role.slug}`;

      if (startDate !== "" && endDate !== "") {
        requestUrl += `&filterstartdate=${startDate}&filterenddate=${endDate}`;

        if (startTime !== "" && endTime !== "") {
          requestUrl += `&filterstarttime=${startTime}&filterendtime=${endTime}`;
        }
      }


      if (operationStartDate !== "" && operationEndDate !== "") {
        requestUrl += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
      }

      if (organizations && organizations.length > 0) {
        organizations.forEach((data) => {
          requestUrl += `&filterorganizations[]=${encodeURIComponent(data)}`;
        });
      }

      if (enclosures && enclosures.length > 0) {
        enclosures.forEach((data) => {
          requestUrl += `&filterenclosures[]=${encodeURIComponent(data)}`;
        });
      }

      if (sectors && sectors.length > 0) {
        sectors.forEach((data) => {
          requestUrl += `&filtersectors[]=${encodeURIComponent(data)}`;
        });
      }

      // if (eventTypes && eventTypes.length > 0) {
      //   eventTypes.forEach((data) => {
      //     requestUrl += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      //   });
      // }

      if (events && events.length > 0) {
        events.forEach((data) => {
          requestUrl += `&filterevent[]=${encodeURIComponent(data)}`;
        });
      }

      if (channels && channels.length > 0) {
        channels.forEach((data) => {
          requestUrl += `&filterchannel[]=${encodeURIComponent(data)}`;
        });
      }

      if (zones && zones.length > 0) {
        zones.forEach((data) => {
          requestUrl += `&filterzone[]=${encodeURIComponent(data)}`;
        });
      }

      if (zipcodes && zipcodes.length > 0) {
        zipcodes.forEach((data) => {
          requestUrl += `&filterzipcode[]=${encodeURIComponent(data)}`;
        });
      }

      if(entrycode !== ""){
        requestUrl += `&filterentrycode=${entrycode}`;
      }

      if(customerName !== ""){
        requestUrl += `&filtercustomername=${customerName}`;
      }

      if(customerEmail !== ""){
        requestUrl += `&filtercustomeremail=${customerEmail}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setTicketsList(response.data);
        //resetFilterData();
      }
      
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    return true;
  };


  //function for reset filter
  const resetFilterData = () => {
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
    setOperationStartDate("");
    setOperationEndDate("");
    setOrganizations([]);
    setEvents([]);
    setEnclosures([]);
    setZipcodes([]);
    setSectors([]);
    setChannels([]);
    setZones([]);
    setEntrycode("");
    setCustomerName("");
    setCustomerEmail("");

    setApplyFilter(false);

  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => [
    
    { accessorKey: "email", header: "Email cliente" },
    { accessorKey: "name", header: "Nombre" },
    { accessorKey: "surname", header: "Apellidos" },
    { accessorKey: "phone_client", header: "Teléfono cliente" },
    
    { accessorKey: "address_client", header: "Dirección cliente" },
    { accessorKey: "country_client", header: "País cliente" },

    { accessorKey: "gender_client", header: "Género cliente" },
    { accessorKey: "age", header: "Age" }, 
    { accessorKey: "language_client", header: "Idioma cliente" },
    { accessorKey: "dob_client_formatted", header: "DOB cliente"},
       
    { accessorKey: "operation_date_formated", header: "Last Purchase Date" },
    { accessorKey: "session_date_formated", header: "Last Session Date" },
    { accessorKey: "company_name", header: "Organizador" },
    { accessorKey: "event", header: "Evento" },

    
    { accessorKey: "authorize_communication", header: "Autoriza comunicaciones" },
    { accessorKey: "province", header: "Provincia" },
    { accessorKey: "zipcode", header: "Codigo postal" },
    { accessorKey: "population", header: "Población" },
    
    
  ]);

  // on row selection
  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    console.log('selectedIdsArray', selectedIdsArray);

    if (selectedIdsArray.length > 0) {

      const selectedEmailString = selectedIdsArray.join(",");

      setSelectedTicketsIds([]);
      setSelectedTicketsEmails(selectedEmailString)

    } else {
      setSelectedTicketsIds([]);
      setSelectedTicketsEmails("")
    }
  }, [rowSelection]);


  // default call when component loads
  useEffect(() => {
    getTicketsList(false);
    getAllTags();

  }, []);

  
  // when reload is triggred, reset Filter data
  useEffect(()=>{    

    console.log('refreshData', refreshData);

    if(refreshData){
      resetFilterData();

      setReloadDefault(true);
      setRefreshData(false);
    }  

  },[refreshData]);


  // reload default data, when reload is triggred
  useEffect(()=>{

    console.log('reload', reloadDefault);

    if(reloadDefault){
      getTicketsList(false);
      setReloadDefault(false);
    }
  
  },[reloadDefault]);



  // when filter is triggred
  useEffect(() => {
    if (
      applyFilter &&
      (
        (startDate !== "" && endDate !== "") ||
        (operationStartDate !== "" && operationEndDate !== "") ||
        events.length>0 ||
        organizations.length > 0 ||
        enclosures.length > 0 ||
        sectors.length > 0 ||
        channels.length > 0 ||
        zones.length > 0 ||
        zipcodes.length > 0 ||
        entrycode != "" ||
        customerName != "" ||
        customerEmail != "" 
      )) 
      {
      
        getTicketsList(true);

        // set applyfilter false to reset
        if (applyFilter) {
          setApplyFilter(false);
        }

      }
  }, [applyFilter]);



  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_TICKET")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <TicketsListHeader
              setRefreshData={setRefreshData}
              changeStatusHandler={changeStatusHandler}
            />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={ticketsList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) =>
                    `${row.email}`
                  } // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableColumnOrdering={true}
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedTicketsIds}
          moduleName="ticket"
          afterTagModalClose={getTicketsList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------ new mail popup render here ----- */}
        <ConversationNewMailPopup
          contactPersonEmail={selectedTicketsEmails}
          reloadList={afterEmailClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType} 
        />

        {/* ------- filter popup ------- */}
        <TicketsListFilterPopup
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStartTime={setStartTime}
          setEndTime={setEndTime}

          setOperationStartDate={setOperationStartDate}
          setOperationEndDate={setOperationEndDate}

          setOrganizations={setOrganizations}
          setEnclosures={setEnclosures}
          setSectors={setSectors}
          setEvents={setEvents}
          setChannels={setChannels}
          setZones={setZones}
          setZipcodes={setZipcodes}
          setEntrycode={setEntrycode}
          
          setCustomerName={setCustomerName}
          setCustomerEmail={setCustomerEmail}
          setCustomerLocation={setCustomerLocation}
          
          setApplyFilter={setApplyFilter}
          refreshData={refreshData}
        />

        {/* CVS upload modal */}
        <CSVUploadModal />

        <ImportModal
          afterModalClose={() => {
            console.log("Success");
          }}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default TicketListBody;
