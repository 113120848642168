/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

//import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { BarChart } from "@mui/x-charts/BarChart";

//** import components
import TicketDashboardFilterPopup from "components/Common/Popup/TicketDashboardFilterPopup";
import PavonActivityTab from "../Tab/TicketDashboardTabs/PavonActivityTab";
import PavonTicketsPerYearTab from "../Tab/TicketDashboardTabs/PavonTicketsPerYear";

const TicketDashboardBody = () => {
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [dashboardTab, setDashboardTab] = useState("activity");

  const [organizations, setOrganizations] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [events, setEvents] = useState([]);

  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);

  const [zipcodes, setZipcodes] = useState([]);

  const [operationType, setOperationType] = useState([]);

  const [refreshData, setRefreshData] = useState(false);

  const [applyFilter, setApplyFilter] = useState(false);

  const [selectedTab, setSelectedTab] = useState("activity");
  const [selectedTabLabel, setSelectedTabLabel] = useState("Activity");

  const commonProps = {
    startDate,
    endDate,
    operationStartDate,
    operationEndDate,
    organizations,
    enclosures,
    sectors,
    eventTypes,
    events,
    channels,
    zones,
    zipcodes,
    operationType,

    //setApplyFilter,
    applyFilter,
    refreshData,
    //setRefreshData
  };

  useEffect(() => {
    //console.log('refreshData >>>>>', refreshData)

    if (refreshData) {
      setApplyFilter(false);
      setRefreshData(false);

      // reset states
      setStartDate(""),
        setEndDate(""),
        setOperationStartDate(""),
        setOperationEndDate(""),
        setOrganizations([]),
        setEnclosures([]),
        setSectors([]),
        setEventTypes([]),
        setEvents([]),
        setChannels([]),
        setZones([]),
        setZipcodes([]);
      setOperationType([]);
    }
  }, [refreshData]);

  return (
    <div id="content_wrapper">
      <section className="dashboard_wrapper body-bg pb-5">
        <div className="filter-container py-3 px-4 bg-white shadow-sm mb-4 ">
          <div className="d-flex align-items-center justify-content-between px-1">
            <div className="filter_left">
              {/* <h3 className="mb-0">Activity</h3> */}

              <div className="dropdown">
                <h3
                  className="btn dropdown-toggle d-flex"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedTabLabel}
                </h3>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        setSelectedTab("activity");
                        setSelectedTabLabel("Activity");
                      }}
                    >
                      {" "}
                      Activity
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        setSelectedTab("tickets-per-year");
                        setSelectedTabLabel("Tickets Per Year");
                      }}
                    >
                      {" "}
                      Tickets Per Year
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="filter_right justify-content-end">
              <div className="report_slect_container d-flex justify-content-end w-100  gap-1 gap-sm-2">
                <Link
                  to="#"
                  onClick={() => {
                    setRefreshData(true);
                  }}
                  className="btn btn-gray d-flex align-items-center"
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    refresh
                  </span>
                </Link>
                <Link
                  to="#"
                  className="btn btn-gray d-flex align-items-center gap-1"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasDashboardFilter"
                  aria-controls="offcanvasDashboardFilter"
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    tune
                  </span>
                  <span className="d-block">Filter</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {selectedTab === "activity" ? (
          <PavonActivityTab {...commonProps} />
        ) : null}

        {selectedTab === "tickets-per-year" ? (
          <PavonTicketsPerYearTab {...commonProps} />
        ) : null}
      </section>

      {/* Filter popup for dashboard */}
      <TicketDashboardFilterPopup
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOperationStartDate={setOperationStartDate}
        setOperationEndDate={setOperationEndDate}
        setOrganizations={setOrganizations}
        setEnclosures={setEnclosures}
        setSectors={setSectors}
        setEventTypes={setEventTypes}
        setEvents={setEvents}
        setZones={setZones}
        setZipcodes={setZipcodes}
        setOperationType={setOperationType}
        setChannels={setChannels}
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
        refreshData={refreshData}
      />
    </div>
  );
};

export default TicketDashboardBody;
