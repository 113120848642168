/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import UploadInvoiceDocModal from "../Modal/UploadInvoiceDocModal";
import InvoiceDocHeader from "../Header/InvoiceDocHeader";

const InvoiceDocListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDocList, setInvoiceDocList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedInvoiceDocIds, setSelectedInvoiceDocIds] = useState([]);

  const [selectedInvoiceDocId, setSelectedInvoiceDocId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all invoice docs
  const getInvoiceDocList = async () => {
    setRowSelection({});
    setSelectedInvoiceDocIds([]);

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_INVOICE_DOCS + `?token=${token}`;

      const response = await getData(requestURL);

      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setInvoiceDocList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //bulk delete function
  const massiveDeleteInvoiceDocHandler = async () => {
    try {
      let invoiceDocData = {
        invoicedocids: selectedInvoiceDocIds,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_DELETE_BULK_INVOICE_DOCS + `?token=${token}`;

      const response = await postData(requestUrl, invoiceDocData);

      if (response.status) {
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);

      setTimeout(() => {
        getInvoiceDocList();
      }, 2500);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  /* Material React Table Column and States  */
  const columns = useMemo(
    () => [
      {
        accessorKey: "customer",
        header: t("Customer"),
        size: 250,
      },
      {
        accessorKey: "customerstreet",
        header: t("Customer Address"),
      },
      {
        accessorKey: "customercity",
        header: t("Customer City"),
      },
      {
        accessorKey: "provider",
        header: t("Provider"),
        size: 250,
      },

      {
        accessorKey: "providerstreet",
        header: t("Provider Address"),
      },
      {
        accessorKey: "providercity",
        header: t("Provider City"),
      },
      {
        accessorKey: "invoicenumber",
        header: t("Invoice Number"),
      },
      {
        accessorKey: "purchaseorder",
        header: t("Purchase Order"),
      },
      {
        accessorKey: "grossamount",
        header: t("Gross Amount"),
      },
      {
        accessorKey: "netamount",
        header: t("Net Amount"),
      },
      {
        accessorKey: "totalpayingamount",
        header: t("Total Payble"),
      },
      {
        accessorKey: "invoicedate",
        header: t("Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
        // Filter: globalFilterTextHandler,
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInvoiceDocIds(selectedIdsArray);
    } else {
      setSelectedInvoiceDocIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    getInvoiceDocList();
  }, []);

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          {/* -------- header area ---------- */}
          <InvoiceDocHeader reloadList={getInvoiceDocList} />

          {/* --------- table area --------- */}
          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={invoiceDocList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                enableRowActions // show the action column
                renderRowActionMenuItems={({ row }) => {
                  return [
                    <MenuItem key="edit">
                      <Link to={`/admin/invoicedoc/details/${row.id}`}>
                        {t("Details")}
                      </Link>
                    </MenuItem>,
                  ];
                }} // action columns menu items
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>

        <UploadInvoiceDocModal
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
          setMessageType={setMessageType}
          afterModalClose={getInvoiceDocList}
        />

        <AddLabelModal
          moduleName={"invoicedoc"}
          selectedIds={selectedInvoiceDocIds}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          afterTagModalClose={() => {
            setTimeout(() => {
              getInvoiceDocList();
            }, 1500);
          }}
        />

        <DeleteWarningModal
          moduleName={"invoicedoc"}
          onDelete={massiveDeleteInvoiceDocHandler}
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedInvoiceDocIds([]);
          }}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>

      {/* ---------- popups and modals area ------------- */}
    </div>
  );
};

export default InvoiceDocListBody;
