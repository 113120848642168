/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import TaskPriority from "data/Prod/TaskPriority.json";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

import { assetImages } from "constants";

//import dnds
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import SaveColumnModal from "components/Common/Modal/SaveColumnModal";
import SaveActivityProcessPopup from "components/Common/Popup/ActivityProcessPopup/SaveActivityProcessPopup";
import TaskManagementPopup from "components/Common/Popup/TaskManagementPopup/TaskManagementPopup";
import AddProcessFromTemplateModal from "components/Common/Modal/AddProcessFromTemplateModal";
import MyTaskKanbanHeader from "./Header/MyTaskKanbanHeader";
import ProjectTaskFilter from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectTaskComponents/Popup/ProjectTaskFilter";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";

import { getCurrentDateInString } from "helper/getcurrentdatestring";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { useTranslation } from "react-i18next";

const MyTaskKanbanBody = () => {
  const params = useParams();

  const { t } = useTranslation(); //for translation
  // custom style for select process
  const customProcessStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      color: "#666666",
      fontSize: "0.875rem",
      fontWeight: "normal",
      display: "block",
      minHeight: "1.2em",
      // whiteSpace: "nowrap",
    }),

    // Value style
    control: (styles, state) => ({
      ...styles,
      fontSize: "0.875rem",
      borderRadius: "0.5rem",
      minWidth: 296,
      border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "0.875rem",
      };
    },

    // Indicator style
    // dropdownIndicator: (base, state) => {
    //   let changes = { color: "#505050" };
    //   return Object.assign(base, changes);
    // },
  };

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const [isProjectModerator, setisProjectModerator] = useState(false);

  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedSectionValue, setSelectedSectionValue] = useState(null);
  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [selectedEditTaskId, setSelectedEditTaskId] = useState(null);

  const [title, settitle] = useState("");

  const [showEmptyProcessMessage, setShowEmptyProcessMessage] = useState(false);
  const [processListLoading, setprocessListLoading] = useState(false);
  const [processList, setProcessList] = useState([]);

  const [kanbanData, setKanbanData] = useState([]);

  const [processDetailsLoading, setProcessDetailsLoading] = useState(false);

  //selected process
  const [selectedProcessvalue, setSelectedProcessvalue] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");

  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get all process
  const getAllProcess = async () => {
    try {
      setprocessListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}&moduleslug=MOD_TASK`;

      const response = await getData(requestURL);

      setprocessListLoading(false);
      console.log("response process----->", response);

      if (response.status) {
        setMessageType("success");

        if (response.data.length === 0) {
          setShowEmptyProcessMessage(true);
        } else {
          setShowEmptyProcessMessage(false);
        }
        const processOptions = response.data.map((item) => ({
          label: item.title,
          value: item._id,
        }));

        if (response.lastsavedprocessid) {
          proecessSelectionHandler(
            processOptions.find(
              (item) => item.value === response.lastsavedprocessid
            )
          );
        } else {
          proecessSelectionHandler(processOptions[processOptions.length - 1]);
        }

        setProcessList(processOptions);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
      setShowAlert(true);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //select process
  const proecessSelectionHandler = (val) => {
    if (val) {
      setSelectedProcessvalue(val);
      getProcessDetails(val.value);
      setSelectedProcessId(val.value);
    } else {
      setSelectedProcessvalue(null);
      getProcessDetails(null);
      setSelectedProcessId(null);
    }
  };

  //get process details
  const getProcessDetails = async (selectedProcessId) => {
    if (selectedProcessId) {
      setProcessDetailsLoading(true);

      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_PROCESS_DETAILS +
          `/${selectedProcessId}?token=${token}&moduleslug=MOD_TASK`;

        if (filterTaskDateRangeType != "") {
          requestUrl =
            requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
        }

        if (filterTaskFromDate != "" || filterTaskToDate != "") {
          requestUrl =
            requestUrl +
            `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
        }

        if (filterTaskCustomIds.length > 0) {
          requestUrl =
            requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
        }

        if (filterTaskTitle != "") {
          requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
        }

        if (filterTaskLabels.length > 0) {
          requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
        }

        if (filterTaskStatus.length > 0) {
          requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
        }

        if (filterTaskPriorities.length > 0) {
          requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
        }

        if (filterTaskProjects.length > 0) {
          requestUrl = requestUrl + `&filterprojects=${filterTaskProjects}`;
        }

        if (filterTaskAssignedByUsers.length > 0) {
          requestUrl =
            requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
        }

        if (filterTaskAssignedToUsers.length > 0) {
          requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
        } else if (params.userid) {
          requestUrl = requestUrl + `&filterusers=${params.userid}`;
        }

        const response = await getData(requestUrl);

        setProcessDetailsLoading(false);

        // console.log("Process Details Response >> ", response);

        await saveProcessHistoryHandler(selectedProcessId);

        if (response.status && response.data) {
          setKanbanData(response.data.processlabels);
          setMessageType("success");
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    } else {
      setKanbanData([]);
    }
  };

  //save process history
  const saveProcessHistoryHandler = async (processId) => {
    try {
      let historyData = {
        activityprocessid: processId,
        moduleslug: moduleSlug,
      };
      let requestURL =
        url.API_BASE_URL + url.API_SAVE_PROCESS_HISTORY + `?token=${token}`;

      await postData(requestURL, historyData);
    } catch (error) {
      console.log(error.message);
    }
  };

  //after Add New  Column
  const afterAddNewColumn = () => {
    if (selectedProcessId) {
      getProcessDetails(selectedProcessId);
    }
  };

  //delete task from board
  const deleteTaskHandler = async (task, tagId) => {
    try {
      let taskData = {
        taskid: task._id,
        componentorderitemid: task.componentorderitemid,
        tagid: tagId,
      };

      let requestURL =
        url.API_BASE_URL + url.API_REMOVE_CARD_ITEM_KANBAN + `?token=${token}`;

      const response = await putData(requestURL, taskData);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        setTimeout(() => {
          getProcessDetails(selectedProcessId);
        }, 2200);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //drag and drop operation
  const onDragEnd = async (result) => {
    // console.log("onDragEnd", result);
    const { destination, source, draggableId, type } = result;

    //if no destination or no drag and drop return.
    if (!destination) {
      return;
    }

    //update kanban table
    let requestURL =
      url.API_BASE_URL +
      url.API_UPDATE_KANBAN +
      `?token=${token}&changetype=${type}`;

    //check the drag and drop type is column
    if (type === "column") {
      // Reordering columns
      const newColumnOrder = Array.from(kanbanData);
      newColumnOrder.splice(source.index, 1)[0]; // Remove from source index
      newColumnOrder.splice(
        destination.index,
        0,
        kanbanData.find((column) => column.componentid === draggableId)
      ); // Insert at destination index

      // Update componentorder field based on new positions
      newColumnOrder.forEach((column, index) => {
        column.componentorder = index + 1; // Adding 1 because componentorder starts from 1
      });

      //update local state
      setKanbanData(newColumnOrder);

      //send data to server for update changes
      const updateKanbanData = {
        source: null,
        destination: null,
        processid: selectedProcessId,
        processlist: newColumnOrder,
        draggableid: draggableId,
        updatedcolumn: null,
      };

      // await putData(requestURL, updateKanbanData);

      try {
        const response = await putData(requestURL, updateKanbanData);

        // console.log(response);

        if (response.status) {
          setMessageType("success");
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    }

    if (type === "task") {
      // Moving task within a column or to another column
      const sourceColumn = kanbanData.find(
        (column) => column.tagid.toString() === source.droppableId.toString()
      );

      const destinationColumn = kanbanData.find(
        (column) =>
          column.tagid.toString() === destination.droppableId.toString()
      );

      // console.log("Source column", sourceColumn);
      // console.log("Destination column", destinationColumn);

      // If the task is moved within the same column
      if (source.droppableId === destination.droppableId) {
        const newSourceColumnTasks = sourceColumn.tasks;

        const updatedTaskData = sourceColumn.tasks.filter(
          (item) => item._id === draggableId
        );

        newSourceColumnTasks.splice(source.index, 1); // Remove from source index

        newSourceColumnTasks.splice(destination.index, 0, updatedTaskData[0]); // Insert at destination index

        newSourceColumnTasks.forEach((task, index) => {
          task.order = index + 1;
        });

        const updatedColumn = {
          ...sourceColumn,
          tasks: newSourceColumnTasks,
        };

        const newColumnTasks = kanbanData.map((column) => {
          return column.componentid === sourceColumn.componentid
            ? updatedColumn
            : column;
        });

        setKanbanData(newColumnTasks);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnTasks,
          updatedsourcecolumn: updatedColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        // await putData(requestURL, updateKanbanData);

        try {
          const response = await putData(requestURL, updateKanbanData);

          // console.log(response);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }
      } else {
        // If the task is moved to another column
        const sourceTaskList = sourceColumn.tasks;
        const destinationTaskList = destinationColumn.tasks;

        const updatedTaskData = sourceColumn.tasks.filter(
          (item) => item._id === draggableId
        );

        sourceTaskList.splice(source.index, 1); // Remove from source index

        destinationTaskList.splice(destination.index, 0, updatedTaskData[0]); // Insert at destination index

        sourceTaskList.forEach((task, index) => {
          task.order = index + 1;
        });

        const updatedSourceColumn = {
          ...sourceColumn,
          tasks: sourceTaskList,
        };

        //add tag of destination component id which is tag id.
        destinationTaskList.forEach((taskData, index) => {
          if (taskData.tags.includes(source.droppableId)) {
            taskData.tags.splice(source.droppableId, 1);
            taskData.tags.push(destination.droppableId);
          }
          taskData.order = index + 1;
        });

        // console.log(sourceTaskList);
        // console.log(destinationTaskList);

        const updatedDestinationColumn = {
          ...destinationColumn,
          tasks: destinationTaskList,
        };

        const newColumnTasks = kanbanData.map((column) => {
          if (column.componentid === sourceColumn.componentid) {
            return updatedSourceColumn;
          }
          if (column.componentid === destinationColumn.componentid) {
            return updatedDestinationColumn;
          }
          return column;
        });

        setKanbanData(newColumnTasks);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnTasks,
          updatedsourcecolumn: updatedSourceColumn,
          updateddestinationcolumn: updatedDestinationColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        // await putData(requestURL, updateKanbanData);

        try {
          const response = await putData(requestURL, updateKanbanData);

          // console.log(response);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }
      }
    }

    setShowAlert(true);
  };

  //function for close process template modal
  const afterProcessModalClose = () => {
    getAllProcess();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);

    setReloadData(true);
  };

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskTitle != "" ||
      filterTaskCustomIds.length > 0 ||
      filterTaskLabels.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskProjects.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getProcessDetails(selectedProcessId);
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskProjects,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    getAllProcess();
  }, []);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getProcessDetails(selectedProcessId);
      setReloadData(false);
    }
  }, [reloadData]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter area ------- */}
            <MyTaskKanbanHeader reloadList={resetFilterData} />

            {/* -------- board here---------- */}
            {processListLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : showEmptyProcessMessage ? (
              <div className="challenges_empty text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    {t(
                      "You have not created any process yet please create one to start"
                    )}
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        {t("Add Process From Templates")}
                      </span>
                    </Link>
                    <Link
                      to="#activityProcessOffCanvas"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">{t("Add Process")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="project-tasks-container">
                <div className="challenges-process-row d-flex align-items-center justify-content-between mb-1 gap-3">
                  <div className="left-process-select">
                    <Select
                      // className="form-select fs-sm shadow-none"
                      isClearable
                      placeholder="Select process"
                      options={processList}
                      value={selectedProcessvalue}
                      onChange={(val) => proecessSelectionHandler(val)}
                      styles={customProcessStyle}
                    />
                  </div>
                  <div className="right-process-btn d-flex align-items-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        {t("Add Process From Templates")}
                      </span>
                    </Link>

                    <Link
                      to="#activityProcessOffCanvas"
                      className="btn btn-primary d-flex align-items-center gap-1"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">{t("Add Process")}</span>
                    </Link>
                  </div>
                </div>

                {processDetailsLoading ? (
                  <div className="board_outer">
                    <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view">
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <div
                            className="col-lg-3 px-2 d-flex flex-column gap-2"
                            key={index}
                          >
                            <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center mb-2">
                              <h3 className="lh-1 mb-0">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </h3>
                            </div>

                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="board_outer">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="all-columns"
                        direction="horizontal"
                        type="column"
                      >
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view"
                          >
                            {kanbanData.map((columnData, index) => {
                              return (
                                <Draggable
                                  key={columnData.componentid}
                                  draggableId={columnData.componentid}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="col-lg-3 px-2 d-flex flex-column gap-2"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center">
                                        <h3 className="lh-1 mb-0">
                                          {columnData.processtitle}
                                        </h3>
                                        {/* <div className="dropdown d-flex ms-auto">
                                      <button
                                        className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span className="d-block material-symbols-outlined icon-md">
                                          more_vert
                                        </span>
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#saveColumn"
                                            onClick={() => {
                                              seteditColumnId({
                                                id: columnData.componentid,
                                                title: columnData.processtitle,
                                                tasks: columnData.taskIds,
                                              });
                                            }}
                                          >
                                            Update Column
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                          >
                                            Delete Column
                                          </Link>
                                        </li>
                                      </ul>
                                    </div> */}
                                      </div>

                                      <Droppable
                                        droppableId={columnData.tagid}
                                        type="task"
                                      >
                                        {(provided) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            // style={{
                                            //   maxHeight: "400px",
                                            //   overflowY: "scroll",
                                            // }}
                                            className="pt-1"
                                          >
                                            {columnData.tasks.map(
                                              (task, taskIndex) => {
                                                return (
                                                  <Draggable
                                                    key={task._id}
                                                    draggableId={task._id}
                                                    index={taskIndex}
                                                  >
                                                    {(provided) => (
                                                      <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        className="single-card p-3 border border-gray-300 rounded-10 mb-2"
                                                        style={{
                                                          ...provided
                                                            .draggableProps
                                                            .style,
                                                        }}
                                                      >
                                                        <div className="title d-flex align-items-center gap-3 justify-content-between">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <p className="badge bg-primary mb-0">
                                                              {task.customid}
                                                            </p>
                                                            <p className="fs-md fw-semibold mb-0">
                                                              {task.title}
                                                            </p>
                                                          </div>

                                                          <div className="dropdown d-flex ms-auto">
                                                            <button
                                                              className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                                                              type="button"
                                                              data-bs-toggle="dropdown"
                                                              aria-expanded="false"
                                                            >
                                                              <span className="d-block material-symbols-outlined icon-md">
                                                                more_vert
                                                              </span>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="#"
                                                                  data-bs-toggle="offcanvas"
                                                                  data-bs-target="#addTask_view"
                                                                  aria-controls="addTask_view"
                                                                  onClick={() => {
                                                                    setSelectedEditTaskId(
                                                                      task._id
                                                                    );
                                                                  }}
                                                                >
                                                                  View Details
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="#"
                                                                  onClick={() => {
                                                                    deleteTaskHandler(
                                                                      task,
                                                                      columnData.tagid
                                                                    );
                                                                  }}
                                                                >
                                                                  Remove Task
                                                                </Link>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                        <p className="fs-sm text-gray d-flex align-items-center gap-1 mb-3">
                                                          <span className="d-block material-symbols-outlined icon-sm">
                                                            calendar_month
                                                          </span>
                                                          <span className="d-block">
                                                            {getCurrentDateInString(
                                                              task.start,
                                                              "dd/mm/yyyy"
                                                            )}
                                                          </span>
                                                          <span className="d-block ms-2">
                                                            {getCurrentDateInString(
                                                              task.end,
                                                              "dd/mm/yyyy"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <div className="d-flex align-items-center gap-3">
                                                          <div className="profile d-flex align-items-center gap-2">
                                                            <div
                                                              className="avatar rounded-circle overflow-hidden"
                                                              style={{
                                                                width: "22px",
                                                                height: "22px",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  task.userimage ===
                                                                  ""
                                                                    ? assetImages.defaultUser
                                                                    : url.SERVER_URL +
                                                                      task.userimage
                                                                }
                                                                alt="User"
                                                                className="w-100 h-100 object-fit-cover object-center"
                                                              />
                                                            </div>
                                                            <p className="fs-sm">
                                                              {task.user}
                                                            </p>
                                                          </div>
                                                          <p className="d-flex align-items-center gap-1">
                                                            <span className="d-block material-symbols-outlined icon-sm">
                                                              schedule
                                                            </span>
                                                            <span className="d-block fs-sm">
                                                              {task.duration} Hr
                                                            </span>
                                                          </p>
                                                          <span className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90">
                                                            {TaskPriority.map(
                                                              (
                                                                priority,
                                                                index
                                                              ) => {
                                                                if (
                                                                  task.priority ===
                                                                  priority.value
                                                                ) {
                                                                  return priority.label;
                                                                }
                                                              }
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                );
                                              }
                                            )}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>

                                      {/* <Link
                                    to="#"
                                    className="btn btn-outline-primary d-flex align-items-center justify-content-center gap-1"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#save_task_kanban"
                                    aria-controls="save_task_kanban"
                                    onClick={() => {
                                      setnewTaskCloumnId(columnData);
                                    }}
                                  >
                                    <span className="d-block material-symbols-outlined icon-md">
                                      add
                                    </span>
                                    <span className="d-block">Add Task</span>
                                  </Link> */}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}

                            {provided.placeholder}

                            <div className="col-md-3 col-lg-3 mb-3 mb-lg-0">
                              <Link
                                to="#"
                                className="d-flex align-items-center gap-2 fs-lg fw-semibold text-gray mb-3"
                                data-bs-toggle="modal"
                                data-bs-target="#saveColumn"
                              >
                                <span className="d-block material-symbols-outlined icon-lg">
                                  add_circle
                                </span>
                                <span className="d-block">Add Column</span>
                              </Link>
                              <div className="black-space">
                                <img src={assetImages.blankSpace} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        {/* ========== popups and modal area ======== */}

        <TaskManagementPopup
          projectId={null}
          leadId={null}
          contactId={null}
          selectedTaskModerator={selectedTaskModerator}
          selectedSectionValue={null}
          sectionOptions={[]}
          memberList={memberList}
          selectedTaskId={selectedEditTaskId}
          onPopUpClose={() => {
            setSelectedEditTaskId(null);
            // setselectedTaskModerator(null);
            setSelectedSectionValue(null);
          }}
          getTaskList={() => {
            setTimeout(() => {
              getProcessDetails(selectedProcessId);
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------- filter popup --------- */}
        <MyTaskFilterPopup
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          moduleName="mytaskkanban"
        />

        {/* dnd modals */}
        <SaveColumnModal
          selectedProcessId={selectedProcessId}
          setSelectedProcessId={setSelectedProcessId}
          afterModalClose={afterAddNewColumn}
        />

        <SaveActivityProcessPopup
          moduleSlug={"MOD_TASK"}
          afterPopupClose={getAllProcess}
          setSelectProcessId={() => {}}
          selectedProcessId={null}
          isTemplate={false}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddProcessFromTemplateModal
          moduleSlug={"MOD_TASK"}
          afterProcessModalClose={afterProcessModalClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default MyTaskKanbanBody;
