/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const CrmContactFilterPopup = ({
  visibleColoumns,
  setVisibleColoumns,
  columnOrder,
  setColumnOrder,
  moduleName,
  tagList,
  setfilterName,
  setfilterEmail,
  setfilterPhone,
  setfilterPostion,
  setfilterLabels,
  setfilterCompany,
  isFilterReset,
  setIsFilterReset,
  setIsNoDefaultFilter,
}) => {
  const token = localStorage.getItem("token");
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [position, setposition] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [company, setcompany] = useState("");
  const [tags, setTags] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign columns
      if (jsonFilterData.visibleColoumns) {
        setVisibleColoumns(jsonFilterData.visibleColoumns);
      }

      //assign column order
      if (jsonFilterData.columnOrder && jsonFilterData.columnOrder.length > 0) {
        setColumnOrder(jsonFilterData.columnOrder);
      }

      if (jsonFilterData.name) {
        setname(jsonFilterData.name);
      } else {
        setname("");
      }

      if (jsonFilterData.email) {
        setemail(jsonFilterData.email);
      } else {
        setemail("");
      }

      if (jsonFilterData.phone) {
        setphone(jsonFilterData.phone);
      } else {
        setphone("");
      }

      if (jsonFilterData.position) {
        setposition(jsonFilterData.position);
      } else {
        setposition("");
      }

      if (jsonFilterData.companyValue) {
        companySelectionHandler(jsonFilterData.companyValue);
      } else {
        setCompanyValue(null);
        setcompany("");
      }

      if (jsonFilterData.tags) {
        // Filtering the skills options based on values present in the json array
        const filteredTags = tagList.filter((item) =>
          jsonFilterData.tags.includes(item.value)
        );

        //run loop
        filteredTags.forEach((item) => {
          tagselectedHandler(item);
        });
      } else {
        setTags([]);
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setcompany(val.value);
    } else {
      setCompanyValue(null);
      setcompany("");
    }
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = tags;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById(
        "contacttagfilter-" + data.value
      );

      selectedTagItem.classList.remove("active");

      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);

      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "contacttagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");

      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");

    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected skills
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");

    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    setfilterName(name);
    setfilterEmail(email);
    setfilterPhone(phone);
    setfilterPostion(position);
    setfilterCompany(company);
    setfilterLabels(tags);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasCrmContactFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();

    // resetHandler();
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      //set filter data for saving
      let filterData = {
        name: filterName,
        parameters: JSON.stringify({
          visibleColoumns: visibleColoumns ?? {},
          columnOrder: columnOrder ?? [],

          name,
          email,
          phone,
          position,
          companyValue,
          company,
          tags,
        }),
        moduleslug: moduleName,
        isdefault: isDefaultFilter,
      };

      console.log(`filterData ==========>>>`, filterData);

      try {
        let requestUrl = url.API_BASE_URL;

        //check condition
        if (filterId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_FILTER +
            `/${filterId}` +
            `?token=${token}`;
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
        }

        const response = filterId
          ? await putData(requestUrl, filterData)
          : await postData(requestUrl, filterData);

        setFilterIsSaving(false);

        console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          searchHandler();
          if (filterId) {
            getAllFilters(false);
          } else {
            getAllFilters(true);
          }
        } else {
          setFilterSavingErrorMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(true);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            console.log(jsonFilterData);

            //assign visible columns
            if (jsonFilterData.visibleColoumns) {
              setVisibleColoumns(jsonFilterData.visibleColoumns);
            }

            //assign column order
            if (
              jsonFilterData.columnOrder &&
              jsonFilterData.columnOrder.length > 0
            ) {
              setColumnOrder(jsonFilterData.columnOrder);
            }

            //check date range type is not empty
            if (jsonFilterData.name) {
              setfilterName(jsonFilterData.name);
            } else {
              setfilterName("");
            }

            if (jsonFilterData.email) {
              setfilterEmail(jsonFilterData.email);
            } else {
              setfilterEmail("");
            }

            if (jsonFilterData.phone) {
              setfilterPhone(jsonFilterData.phone);
            } else {
              setfilterPhone("");
            }

            if (jsonFilterData.position) {
              setfilterPostion(jsonFilterData.position);
            } else {
              setfilterPostion("");
            }

            if (jsonFilterData.company) {
              setfilterCompany(jsonFilterData.company);
            } else {
              setfilterCompany("");
            }

            if (jsonFilterData.tags && jsonFilterData.tags.length > 0) {
              setfilterLabels(jsonFilterData.tags);
            } else {
              setfilterLabels([]);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setname("");
    setemail("");
    setphone("");
    setposition("");
    setcompanyInput("");
    setCompanyValue(null);
    setcompany("");
    setTags([]);
    removeActiveClass();
    resetSelectTags();

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    //  getAllTags();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCrmContactFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* name */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Name")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Name")}
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>

          {/* email */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Email")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>

          {/* Phone */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Phone")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Phone")}
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </div>

          {/* Position */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Position")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Position")}
              value={position}
              onChange={(e) => setposition(e.target.value)}
            />
          </div>

          {/* Company */}
          <div className="form-group mb-4">
            <label htmlFor="Company" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Company")}</h3>{" "}
            </label>
            <CreatableSelect
              placeholder={t("Type Company name")}
              isClearable
              options={companyOptionsToShow}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setcompanyInput(val);
              }}
            />
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ------ label accordion start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseSix"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagselectedHandler(tag)}
                            id={"contacttagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ------ label accordion end ------ */}
          </div>

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}

          {/* <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button> */}
        </form>
      </div>
    </div>
  );
};
export default CrmContactFilterPopup;
