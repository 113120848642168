/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CrmLeadDashboardBody from "components/CRMComponents/CRMLeadListComponents/CrmLeadDashboardBody/CrmLeadDashboardBody";

const CrmLeadDashboard = () => {
  useEffect(() => {
    document.title = "CRM Lead Dashboard";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmLeadDashboardBody />
    </main>
  );
};

export default CrmLeadDashboard;
