/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const DirectAccessHeader = ({ reloadList = () => {} }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Direct Access") }];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-5 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        <div className="col-md-6 col-lg-7 d-flex justify-content-end gap-1 gap-sm-2">
          {/* <!-- ========== Start refresh Section ========== --> */}
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* <!-- ========== End refresh Section ========== --> */}

          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCRMDirectAccessFilter"
            aria-controls="offcanvasCRMDirectAccessFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default DirectAccessHeader;
