/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RolesModal = ({ userRoles, setuserRoles }) => {
  const rolesTemplate = {
    rolestext: "",
  };

  const [rolesBlock, setRolesBlock] = useState([]);

  const addNewRolesBlock = () => {
    setRolesBlock([...rolesBlock, rolesTemplate]);
  };

  const deleteRolesBlock = (index) => {
    const deleteRolesArr = [...rolesBlock];
    deleteRolesArr.splice(index, 1);
    setRolesBlock(deleteRolesArr);
  };

  //function for change survey select
  const rolesInputHandler = (index, field, value) => {
    const updatedFormValues = rolesBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setRolesBlock(updatedFormValues);
  };

  const updatedRolesBlock = (updatedData = []) => {
    setRolesBlock(updatedData);
  };

  const saveInfoHandler = () => {
    if (rolesBlock.length > 0) {
      setuserRoles(rolesBlock);
    } else {
      setuserRoles([]);
    }

    resetHandler();

    let loginModal = document.querySelector("#roles_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setRolesBlock([]);
  };

  useEffect(() => {
    if (userRoles.length > 0) {
      updatedRolesBlock(userRoles);
    } else {
      updatedRolesBlock([]);
    }
  }, [userRoles]);

  return (
    <div
      className="modal fade"
      id="roles_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Roles</h3>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <label className="form-label">User Roles</label>
                {rolesBlock.map((rolesData, index) => {
                  return (
                    <div
                      className="d-flex align-items-center gap-1 mb-2"
                      key={index}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Roles"
                        value={rolesData.rolestext}
                        onChange={(e) => {
                          rolesInputHandler(index, "rolestext", e.target.value);
                        }}
                      />
                      <div className="adnew_btn">
                        <Link
                          onClick={() => {
                            deleteRolesBlock(index);
                          }}
                          to="#"
                          className="d-inline-flex align-items-center justify-content-center gap-1"
                        >
                          <i className="material-symbols-outlined">delete</i>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="form-group">
                <Link
                  to="#"
                  className="d-inline-flex align-items-center justify-content-center gap-1"
                  onClick={addNewRolesBlock}
                >
                  <i className="material-symbols-outlined icon-md">add </i>
                  <span className="fs-md">Add New</span>
                </Link>
              </div>

              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={saveInfoHandler}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesModal;
