/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";

import DashboardReportIssueModal from "components/DashboardComponents/Modal/DashboardReportIssueModal";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import DashboardSendOfferModal from "components/DashboardComponents/Modal/DashboardSendOfferModal";
import DashboardOldBody from "components/DashboardComponents/DashboardOld/DashboardOldBody";

const DashboardOld = () => {
  useEffect(() => {
    document.title = "FindelOI | Dashboard";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />
      <DashboardOldBody />
      {/* ------ all pop up render here ------ */}

      {/* ------ all modal render here ------ */}
      <AddLabelModal moduleName="dashboard" />

      <DashboardReportIssueModal />
      <DashboardSendOfferModal />
    </main>
  );
};

export default DashboardOld;
