/* eslint-disable */
import { useState } from "react";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

const WinnerSeclectionModal = ({
  challengeResponseId = null,
  afterWinnerResults = () => {},
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  const [isUpdating, setisUpdating] = useState(false);

  const onCancelWinner = () => {
    document.getElementById("winnercheckbox").checked = false;
  };

  const winnerStatusHandler = async () => {
    if (challengeResponseId) {
      try {
        setisUpdating(true);

        let requestUrl =
          url.API_BASE_URL +
          url.API_CHALLENGE_CHANGE_WINNER_STATUS +
          `/${challengeResponseId}?token=${token}`;

        const response = await putData(requestUrl);

        setisUpdating(false);

        console.log(response);

        if (response.status) {
          //hide modal
          let currentModal = document.querySelector("#winner_selection_mdl");
          let modal = bootstrap.Modal.getInstance(currentModal);
          modal.hide();

          document.getElementById("winnercheckbox").checked = false;
          afterWinnerResults();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="winner_selection_mdl"
      tabIndex="-1"
      aria-labelledby="eventGallerydeleteModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2 className="mb-3">{t("Are you sure")}?</h2>
            <h4>** Selected winner can't be update again</h4>
            <div className="d-flex gap-3 justify-content-center">
              <button
                className="btn btn-primary"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                disabled={isUpdating ? true : false}
                style={{
                  cursor: isUpdating ? "not-allowed" : "pointer",
                }}
                onClick={winnerStatusHandler}
              >
                {t("Confirm")}

                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              <button
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancelWinner}
              >
                {t("Not Sure")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerSeclectionModal;
