/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import Select from "react-select";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import RemoteOptions from "data/Prod/RemoteOptions.json";
import JobTypeOptions from "data/Prod/JobTypeOptions.json";
import JobAssociateOptions from "data/Prod/JobAssociateOptions.json";
import CurrencyOptions from "data/Prod/Currency.json";
import PaymentDurationOptions from "data/Prod/PaymentDuration.json";

import { assetImages } from "constants";
import SaveJobSuccessModal from "../Modal/SaveJobSuccessModal";
import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
} from "helper/ChallengeHelper/ChallengeHelper";
import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ckEditorConfig } from "Config/Config";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const JobInformationBody = () => {
  const params = useParams();

  const history = useHistory();

  const docFileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const [companyInput, setCompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [allSkills, setAllSkills] = useState([]);

  const [title, setTitle] = useState("");
  const [code, setcode] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [aboutCompany, setaboutCompany] = useState("");
  const [aboutPosition, setaboutPosition] = useState("");
  const [requiredSkillValue, setrequiredSkillValue] = useState(null);
  const [requiredSkills, setrequiredSkills] = useState([]);
  const [optionalSkillValue, setoptionalSkillValue] = useState(null);
  const [optionalSkills, setoptionalSkills] = useState([]);
  const [remoteJobValue, setremoteJobValue] = useState(null);
  const [jobTypeValue, setjobTypeValue] = useState(null);
  const [experienceAsValue, setexperienceAsValue] = useState(null);
  const [experienceYear, setexperienceYear] = useState("");
  const [experienceMonth, setexperienceMonth] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [paymentDurationValue, setpaymentDurationValue] = useState(null);
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [companyValue, setcompanyValue] = useState(null);
  const [companyName, setcompanyName] = useState("");
  const [companyAdress, setcompanyAdress] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savedJobId, setsavedJobId] = useState(null);

  const [validation, setValidation] = useState({
    titleError: false,
    codeError: false,
    summaryError: false,
    detailsError: false,
    companyError: false,
    skillError: false,
    currencyError: false,
    minSalaryError: false,
    maxSalaryError: false,
    salaryDiffError: false,
    paymentDurationError: false,
    zipcodeError: false,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(t("Details"), title, t);

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    if (code === "") {
      setValidation((prevState) => ({ ...prevState, codeError: true }));
      isValid = false;
    }

    if (summary === "") {
      setValidation((prevState) => ({ ...prevState, summaryError: true }));
      isValid = false;
    }

    if (details === "") {
      setValidation((prevState) => ({ ...prevState, detailsError: true }));
      isValid = false;
    }

    if (companyValue === null && companyName === "") {
      setValidation((prevState) => ({ ...prevState, companyError: true }));
      isValid = false;
    }

    if (requiredSkills.length === 0) {
      setValidation((prevState) => ({ ...prevState, skillError: true }));
      isValid = false;
    }

    if (currencyValue === null) {
      setValidation((prevState) => ({ ...prevState, currencyError: true }));
      isValid = false;
    }

    if (minSalary === "") {
      setValidation((prevState) => ({ ...prevState, minSalaryError: true }));
      isValid = false;
    }

    if (maxSalary === "") {
      setValidation((prevState) => ({ ...prevState, maxSalaryError: true }));
      isValid = false;
    }

    if (
      minSalary !== "" &&
      maxSalary !== "" &&
      parseInt(minSalary) > parseInt(maxSalary)
    ) {
      setValidation((prevState) => ({ ...prevState, salaryDiffError: true }));
      isValid = false;
    }

    if (paymentDurationValue === null) {
      setValidation((prevState) => ({
        ...prevState,
        paymentDurationError: true,
      }));
      isValid = false;
    }

    if (zipcode === "") {
      setValidation((prevState) => ({ ...prevState, zipcodeError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all category
  const getAllSkills = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setAllSkills(response);
    } catch (error) {
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for get all company list
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_LIST +
        `?token=${token}&filtername=${companyInput}`;

      console.log("url of company list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company list------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //select required skill
  const requiredSkillSelectionHandler = (val) => {
    setrequiredSkillValue(val);
    setrequiredSkills(val.map((item) => item.value));
  };

  //select optional skill
  const optionalSkillSelectionHandler = (val) => {
    setoptionalSkillValue(val);
    setoptionalSkills(val.map((item) => item.value));
  };

  //select remote job
  const remoteJobSelectionHandler = (val) => {
    setremoteJobValue(val);
  };

  //select job type
  const jobTypeSelectionHandler = (val) => {
    setjobTypeValue(val);
  };

  //select experience as
  const experienceAsSelectionHandler = (val) => {
    setexperienceAsValue(val);
  };

  //select currency type
  const currencySelectionHandler = (val) => {
    setcurrencyValue(val);
  };

  //select payment duration
  const paymentDurationSelectionHandler = (val) => {
    setpaymentDurationValue(val);
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setcompanyValue(val);
      setcompanyAdress(val.location);
      setaboutCompany(val.about);
    } else {
      setcompanyValue(null);
      setCompanyInput("");
      setCompanyList([]);
    }
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

        const response = await getData(url);

        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setcountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setcityName(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setstateName(item?.long_name);
              }
            });
            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);

    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedChallengeDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get project details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in job details ----->", response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
        setcode(data.jobcode);
        setsummary(data.summary);
        setdetails(data.description);
        setaboutPosition(data.aboutposition);
        requiredSkillSelectionHandler(data.requiredskillsdata);
        optionalSkillSelectionHandler(data.optionalskillsdata);
        for (let remoteData of RemoteOptions) {
          if (data.remotejob == remoteData.value) {
            remoteJobSelectionHandler(remoteData);
          }
        }

        for (let jobType of JobTypeOptions) {
          if (data.jobtype == jobType.value) {
            jobTypeSelectionHandler(jobType);
          }
        }

        for (let experienceData of JobAssociateOptions) {
          if (data.experienceposition == experienceData.value) {
            experienceAsSelectionHandler(experienceData);
          }
        }

        setexperienceYear(data.experienceyear);
        setexperienceMonth(data.experienceMonth);

        for (let currency of CurrencyOptions) {
          if (data.currency == currency.value) {
            currencySelectionHandler(currency);
          }
        }

        setminSalary(data.minsalary);
        setmaxSalary(data.maxsalary);

        for (let payment of PaymentDurationOptions) {
          if (data.paymentduration == payment.value) {
            paymentDurationSelectionHandler(payment);
          }
        }

        setcompanyValue(data.companydata);
        setaboutCompany(data.aboutcompany);
        setcompanyName(data.companystring);
        setcompanyAdress(data.location);
        setzipcode(data.zipcode);
        setcityName(data.city);
        setstateName(data.state);
        setcountry(data.country);

        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        setUploadedDocFileIds(response?.data?.uploadedfileids);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save project
  const saveJobHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);
        let projectData = {
          name: title,
          jobtype: jobTypeValue ? jobTypeValue.value : "",
          jobcode: code,
          summary,
          description: details,
          companyid: companyValue ? companyValue.value : null,
          companystring: companyValue ? "" : companyName,
          aboutcompany: aboutCompany,
          aboutposition: aboutPosition,
          requiredskills: requiredSkills,
          optionalskills: optionalSkills,
          currency: currencyValue ? currencyValue.value : "",
          minsalary: minSalary,
          maxsalary: maxSalary,
          paymentduration: paymentDurationValue
            ? paymentDurationValue.value
            : "",
          experienceposition: experienceAsValue ? experienceAsValue.value : "",
          experienceyear: experienceYear,
          experiencemonth: experienceMonth,
          location: companyAdress,
          city: cityName,
          state: stateName,
          country,
          zipcode,
          remotejob: remoteJobValue ? remoteJobValue.value : "",
          publish: "Yes",
          uploads: uploadedDocFileIds,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_CHALLENGE +
            `/${params.id}?token=${token}`;
          response = await putData(requestUrl, projectData);
        } else {
          requestUrl = requestUrl + url.API_ADD_CHALLENGE + `?token=${token}`;
          response = await postData(requestUrl, projectData);
        }

        // console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          setsavedJobId(response.data._id);
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveChallengeModal")
          );
          bootstrapModal.show();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up all required fields"));
    }

    setShowAlert(true);
  };

  //function for reset
  const resetHandler = () => {
    setCompanyInput("");
    setCompanyList([]);
    setTitle("");
    setcode("");
    setdetails("");
    setaboutCompany("");
    setaboutPosition("");
    setrequiredSkillValue(null);
    setrequiredSkills([]);
    setoptionalSkillValue(null);
    setoptionalSkills([]);
    for (let remoteData of RemoteOptions) {
      if (remoteData.value == "no") {
        remoteJobSelectionHandler(remoteData);
      }
    }
    for (let jobData of JobTypeOptions) {
      if (jobData.value == "fulltime") {
        jobTypeSelectionHandler(jobData);
      }
    }
    for (let experienceData of JobAssociateOptions) {
      if (experienceData.value == "associate") {
        experienceAsSelectionHandler(experienceData);
      }
    }
    setexperienceYear("");
    setexperienceMonth("");
    for (let currencyData of CurrencyOptions) {
      if (currencyData.value == "euro") {
        currencySelectionHandler(currencyData);
      }
    }

    for (let paymentData of PaymentDurationOptions) {
      if (paymentData.value == "Monthly") {
        paymentDurationSelectionHandler(paymentData);
      }
    }

    setminSalary("");
    setmaxSalary("");
    setcompanyValue(null);
    setcompanyName("");
    setcompanyAdress("");
    setstateName("");
    setcountry("");
    setzipcode("");
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
  };

  //function for aftermodal close
  const afterSaveModalClose = (pageName) => {
    if (pageName == "details") {
      history.push(`/admin/challenges/jobinformation/${savedJobId}`);
    } else {
      resetHandler();
      history.push("/admin/challenges/list");
    }
  };

  useEffect(() => {
    if (companyInput.length > 0) {
      getAllCompanyList();
    }
  }, [companyInput]);

  useEffect(() => {
    if (params.id) {
      getJobDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllSkills();

    //set remote job as no
    for (let remoteData of RemoteOptions) {
      if (remoteData.value == "no") {
        remoteJobSelectionHandler(remoteData);
      }
    }

    //set job type as fulltime
    for (let jobData of JobTypeOptions) {
      if (jobData.value == "fulltime") {
        jobTypeSelectionHandler(jobData);
      }
    }

    //set experince as associate
    for (let experienceData of JobAssociateOptions) {
      if (experienceData.value == "associate") {
        experienceAsSelectionHandler(experienceData);
      }
    }

    //set currency as euro
    for (let currencyData of CurrencyOptions) {
      if (currencyData.value == "euro") {
        currencySelectionHandler(currencyData);
      }
    }

    //set payment duration as monthly
    for (let paymentData of PaymentDurationOptions) {
      if (paymentData.value == "Monthly") {
        paymentDurationSelectionHandler(paymentData);
      }
    }
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Job Information")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- bread crumb ------- */}
            <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />

            {/* -------- form section ----------- */}
            <div className="row">
              <div className="col-lg-12 mb-4 mb-lg-0">
                <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* <!-- ========== Start title Section ========== --> */}
                    <div className="form-group mb-4">
                      <label
                        htmlFor="title"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Title")}
                      </label>
                      <input
                        type="text"
                        id="title"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Job Title")}
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            titleError: false,
                          }));
                        }}
                      />

                      {validation.titleError && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter title")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <!-- ========== End title Section ========== --> */}

                    {/* <!-- ========== Start code Section ========== --> */}
                    <div className="form-group mb-4">
                      <label
                        htmlFor="jobCode"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Job Code")}
                      </label>
                      <input
                        type="text"
                        id="jobCode"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Job Code")}
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            codeError: false,
                          }));
                        }}
                      />

                      {validation.codeError && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter code")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <!-- ========== End code Section ========== --> */}

                    {/* <!-- ========== Start jobdescription,skills,company accordion Section ========== --> */}
                    <div
                      className="accordion mb-4"
                      id="accordionPanelsStayOpenExample"
                    >
                      {/* <!-- ========== Start job summary and description Section ========== --> */}
                      <div className="accordion-item bg-transparent border-0">
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            {t("Job Description")}
                          </button>
                        </h3>
                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            {/* <!-- ========== Start summary Section ========== --> */}
                            <div className="form-group mb-4">
                              <label
                                htmlFor="jobSummary"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Job Summary")}
                              </label>
                              <textarea
                                name="jobSummarys"
                                id="jobSummarys"
                                cols="30"
                                rows="5"
                                className="form-control fs-sm shadow-none"
                                placeholder={t("Job Summary")}
                                value={summary}
                                onChange={(e) => {
                                  setsummary(e.target.value);
                                  setValidation((prevState) => ({
                                    ...prevState,
                                    summaryError: false,
                                  }));
                                }}
                              ></textarea>

                              {validation.summaryError && (
                                <div className="error-message mt-2">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-symbols-outlined">
                                      warning
                                    </span>
                                    <span>{t("Please enter summary")}!</span>
                                  </p>
                                </div>
                              )}
                            </div>
                            {/* <!-- ========== End summary Section ========== --> */}

                            {/* <!-- ========== Start details Section ========== --> */}
                            <div className="form-group mb-4">
                              <label
                                htmlFor="jobDetails"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Job Details")}
                              </label>
                              <CKEditor
                                className="form-control border-0 shadow-none"
                                editor={ClassicEditor}
                                config={ckEditorConfig(t("Type details"))}
                                data={details ? details : ""}
                                onReady={(editor) => {
                                  editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                      "height",
                                      "200px",
                                      editor.editing.view.document.getRoot()
                                    );
                                  });
                                }}
                                onChange={(event, editor) => {
                                  let editorContent = editor.getData();
                                  setdetails(editorContent);
                                  setValidation((prevState) => ({
                                    ...prevState,
                                    detailsError: false,
                                  }));
                                }}
                              />

                              {validation.detailsError && (
                                <div className="error-message mt-2">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-symbols-outlined">
                                      warning
                                    </span>
                                    <span>{t("Please enter details")}!</span>
                                  </p>
                                </div>
                              )}
                            </div>
                            {/* <!-- ========== End details Section ========== --> */}
                          </div>
                        </div>
                      </div>
                      {/* <!-- ========== End job summary and description Section ========== --> */}

                      {/* <!-- ========== Start company accordion Section ========== --> */}
                      <div className="accordion-item bg-transparent border-0">
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFive"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseFive"
                          >
                            {t("Company")}
                          </button>
                        </h3>
                        <div
                          id="panelsStayOpen-collapseFive"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            <div className="form-group mb-4">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label
                                    htmlFor="industry"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Select Company")}
                                  </label>
                                  <Select
                                    isClearable
                                    placeholder={t("Select options")}
                                    options={companyList}
                                    value={companyValue}
                                    onChange={(val) => {
                                      companySelectionHandler(val);
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        companyNameError: false,
                                      }));
                                    }}
                                    onInputChange={(val) => {
                                      setCompanyInput(val);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                  <label
                                    htmlFor="companyName"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t(
                                      "Enter Company Name (If not in the list)"
                                    )}
                                  </label>
                                  <input
                                    type="text"
                                    id="companyName"
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("Company Name")}
                                    value={companyName}
                                    onChange={(e) => {
                                      setcompanyName(e.target.value);
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        companyError: false,
                                      }));
                                    }}
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <label
                                    htmlFor="companyName"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Address")}
                                  </label>
                                  <input
                                    type="text"
                                    id="companyName"
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("Address")}
                                    value={companyAdress}
                                    onChange={(e) =>
                                      setcompanyAdress(e.target.value)
                                    }
                                  />
                                </div>

                                {validation.companyError && (
                                  <div className="error-message mt-2">
                                    <p className="d-flex align-items-center gap-1 text-danger">
                                      <span className="material-symbols-outlined">
                                        warning
                                      </span>
                                      <span>
                                        {t("Please select or type company")}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group mb-4">
                              <label
                                htmlFor="aboutCompany"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("About Company")}
                              </label>
                              <CKEditor
                                className="form-control border-0 shadow-none"
                                editor={ClassicEditor}
                                config={ckEditorConfig(
                                  t("Type Company Details")
                                )}
                                data={aboutCompany ? aboutCompany : ""}
                                onReady={(editor) => {
                                  editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                      "height",
                                      "200px",
                                      editor.editing.view.document.getRoot()
                                    );
                                  });
                                }}
                                onChange={(event, editor) => {
                                  let editorContent = editor.getData();
                                  setaboutCompany(editorContent);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="aboutPosition"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("About Position")}
                              </label>
                              <CKEditor
                                className="form-control border-0 shadow-none"
                                editor={ClassicEditor}
                                config={ckEditorConfig(
                                  t("Type Position Details")
                                )}
                                data={aboutPosition ? aboutPosition : ""}
                                onReady={(editor) => {
                                  editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                      "height",
                                      "200px",
                                      editor.editing.view.document.getRoot()
                                    );
                                  });
                                }}
                                onChange={(event, editor) => {
                                  let editorContent = editor.getData();
                                  setaboutPosition(editorContent);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ========== End company accordion Section ========== --> */}

                      {/* skills */}
                      <div className="accordion-item bg-transparent border-0">
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            {t("Add Skills")}
                          </button>
                        </h3>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="required"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Required")}
                              </label>
                              <Select
                                isMulti
                                placeholder={t("Select Required skills ...")}
                                options={allSkills}
                                value={requiredSkillValue}
                                onChange={(val) => {
                                  requiredSkillSelectionHandler(val);
                                  setValidation((prevState) => ({
                                    ...prevState,
                                    skillError: false,
                                  }));
                                }}
                              />

                              {validation.skillError && (
                                <div className="error-message mt-2">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-symbols-outlined">
                                      warning
                                    </span>
                                    <span>{t("Please select skills")}</span>
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="option"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Optional")}
                              </label>
                              <Select
                                isMulti
                                placeholder={t("Select Optional skills ...")}
                                options={allSkills}
                                value={optionalSkillValue}
                                onChange={(val) => {
                                  optionalSkillSelectionHandler(val);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* experience */}
                      <div className="accordion-item bg-transparent border-0">
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFour"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseFour"
                          >
                            {t("Experience")}
                          </button>
                        </h3>
                        <div
                          id="panelsStayOpen-collapseFour"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            <div className="form-group mb-4">
                              <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                  <label
                                    htmlFor="remotejob"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Remote Job")}
                                  </label>
                                  <Select
                                    placeholder={t("Select Remote Job ...")}
                                    options={RemoteOptions}
                                    value={remoteJobValue}
                                    onChange={(val) => {
                                      remoteJobSelectionHandler(val);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="jobType"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Type")}
                                  </label>
                                  <Select
                                    placeholder={t("Select Job Type...")}
                                    options={JobTypeOptions}
                                    value={jobTypeValue}
                                    onChange={(val) => {
                                      jobTypeSelectionHandler(val);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                  <label
                                    htmlFor="experience"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Experience as")}
                                  </label>
                                  <Select
                                    placeholder={t("Select experience as")}
                                    options={JobAssociateOptions}
                                    value={experienceAsValue}
                                    onChange={(val) => {
                                      experienceAsSelectionHandler(val);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <label
                                    htmlFor="experienceTime"
                                    className="d-block fs-sm fw-semibold mb-2"
                                  >
                                    {t("Experience Time")}
                                  </label>
                                  <div className="field-container d-flex flex-column flex-lg-row gap-4 gap-lg-0 body-lg-bg rounded-10">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control fs-sm shadow-none"
                                      placeholder={t("Year")}
                                      value={experienceYear}
                                      onChange={(e) =>
                                        setexperienceYear(e.target.value)
                                      }
                                    />
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control fs-sm shadow-none"
                                      placeholder={t("Month")}
                                      value={experienceMonth}
                                      onChange={(e) =>
                                        setexperienceMonth(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <label
                                  htmlFor="payRate"
                                  className="d-block fs-sm fw-semibold mb-2"
                                >
                                  {t("Pay Rate (Salary)")}
                                </label>
                                <div className="field-container d-flex flex-column flex-lg-row gap-4 gap-lg-0 body-lg-bg rounded-10">
                                  <Select
                                    className="form-select fs-sm shadow-none"
                                    placeholder={t("Select currency")}
                                    options={CurrencyOptions}
                                    value={currencyValue}
                                    onChange={(val) => {
                                      currencySelectionHandler(val);
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        currencyError: false,
                                      }));
                                    }}
                                  />
                                  <input
                                    type="number"
                                    min={0}
                                    id=""
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("Minimum")}
                                    value={minSalary}
                                    onChange={(e) => {
                                      setminSalary(e.target.value.toString());
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        minSalaryError: false,
                                        salaryDiffError: false,
                                      }));
                                    }}
                                  />
                                  <input
                                    type="number"
                                    min={0}
                                    id=""
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("Maximum")}
                                    value={maxSalary}
                                    onChange={(e) => {
                                      setmaxSalary(e.target.value.toString());
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        maxSalaryError: false,
                                        salaryDiffError: false,
                                      }));
                                    }}
                                  />
                                  <Select
                                    className="form-select fs-sm shadow-none"
                                    placeholder={t("Select payment duration")}
                                    options={PaymentDurationOptions}
                                    value={paymentDurationValue}
                                    onChange={(val) => {
                                      paymentDurationSelectionHandler(val);
                                      setValidation((prevState) => ({
                                        ...prevState,
                                        paymentDurationError: false,
                                      }));
                                    }}
                                  />
                                </div>

                                {/* <!-- ========== Start error Section ========== --> */}
                                <div className="d-flex align-items-center gap-3 mt-2">
                                  {/* payrate error */}
                                  {validation.currencyError && (
                                    <div className="error-message">
                                      <p className="d-flex align-items-center gap-1 text-danger">
                                        <span className="material-symbols-outlined">
                                          warning
                                        </span>
                                        <span>
                                          {t("Please select currency")}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {validation.minSalaryError && (
                                    <div className="error-message">
                                      <p className="d-flex align-items-center gap-1 text-danger">
                                        <span className="material-symbols-outlined">
                                          warning
                                        </span>
                                        <span>
                                          {t("Please enter minimum salary")}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {validation.maxSalaryError && (
                                    <div className="error-message">
                                      <p className="d-flex align-items-center gap-1 text-danger">
                                        <span className="material-symbols-outlined">
                                          warning
                                        </span>
                                        <span>
                                          {t("Please enter maximum salary")}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {validation.salaryDiffError && (
                                    <div className="error-message">
                                      <p className="d-flex align-items-center gap-1 text-danger">
                                        <span className="material-symbols-outlined">
                                          warning
                                        </span>
                                        <span>
                                          {t(
                                            "Maximum salary should be greater than minimum salary"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                  {validation.paymentDurationError && (
                                    <div className="error-message">
                                      <p className="d-flex align-items-center gap-1 text-danger">
                                        <span className="material-symbols-outlined">
                                          warning
                                        </span>
                                        <span>
                                          {t("Please select payment duration")}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {/* <!-- ========== End error Section ========== --> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- ========== Start location accordion Section ========== --> */}
                      <div className="accordion-item bg-transparent border-0">
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseFive"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseFive"
                          >
                            {t("Location")}
                          </button>
                        </h3>
                        <div
                          id="panelsStayOpen-collapseFive"
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            {/* ------ zipcode,location,city,country ----- */}
                            <div className="form-group mb-3">
                              <div className="row">
                                {/* ----- zipcode section start ------ */}
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                  <div className="row">
                                    <div className="col">
                                      <input
                                        type="text"
                                        className="form-control border-0 shadow-none"
                                        placeholder={t("Zipcode")}
                                        value={zipcode}
                                        onChange={(e) => {
                                          setzipcode(e.target.value);
                                          setValidation((prevState) => ({
                                            ...prevState,
                                            zipcodeError: false,
                                          }));
                                        }}
                                      />
                                    </div>
                                    <div className="col">
                                      <button
                                        className="btn btn-primary"
                                        onClick={getLocationFromZip}
                                      >
                                        {t("Fetch Location by zipcode")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* ----- zipcode section end ------ */}

                                {/* ----- city,state,country section start ------ */}
                                <div className="col-lg-8">
                                  <div className="row">
                                    {/* ----- city section start ------ */}
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                      <input
                                        type="text"
                                        className="form-control border-0 shadow-none"
                                        placeholder={t("City")}
                                        value={cityName}
                                        onChange={(e) => {
                                          setcityName(e.target.value);
                                        }}
                                      />
                                    </div>
                                    {/* ----- city section end ------ */}

                                    {/* ----- state section start ------ */}
                                    <div className="col-lg-4 mb-3 mb-lg-0">
                                      <input
                                        type="text"
                                        className="form-control border-0 shadow-none"
                                        placeholder={t("State")}
                                        value={stateName}
                                        onChange={(e) => {
                                          setstateName(e.target.value);
                                        }}
                                      />
                                    </div>
                                    {/* ----- state section end ------ */}

                                    {/* ----- country section start ------ */}
                                    <div className="col-lg-4">
                                      <input
                                        type="text"
                                        className="form-control border-0 shadow-none"
                                        placeholder={t("Country")}
                                        value={country}
                                        onChange={(e) => {
                                          setcountry(e.target.value);
                                        }}
                                      />
                                    </div>
                                    {/* ----- country section end ------ */}
                                  </div>
                                </div>
                                {/* ----- city,state,country section end ------ */}
                              </div>

                              {validation.zipcodeError && (
                                <div className="error-message mt-2">
                                  <p className="d-flex align-items-center gap-1 text-danger">
                                    <span className="material-symbols-outlined">
                                      warning
                                    </span>
                                    <span>{t("Please enter zipcode")}</span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ========== End location accordion Section ========== --> */}
                    </div>
                    {/* <!-- ========== End jobdescription,skills,company accordion Section ========== --> */}

                    {/* <div className="action mb-4">
                    <button
                      type="button"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined">
                        add
                      </span>
                      <span className="d-block">Add Survey</span>
                    </button>
                  </div> */}

                    {/* <!-- ========== Start uploaded documents Section ========== --> */}
                    <div className="form-group mb-3 mb-sm-4">
                      <label
                        htmlFor="documents"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Upload Documents")}
                      </label>
                      <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                        <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                          <Link
                            to="#"
                            data-target="upload"
                            className="upload-file text-center"
                            onClick={openDocFileInput}
                          >
                            <span className="d-block material-symbols-outlined">
                              upload
                            </span>
                            <span className="d-block fw-semibold">
                              {t("Upload")}
                            </span>
                          </Link>
                          <span className="fs-xs">Max 20mb doc/mp4</span>
                          <input
                            type="file"
                            data-id="upload"
                            id="uploadedChallengeDoc"
                            className="d-none"
                            ref={docFileInputRef}
                            onChange={docFileUploadHandler}
                            multiple
                          />
                        </li>

                        {uploadedDocFiles.map((docFile, index) => {
                          return (
                            <li
                              key={index}
                              className="file p-3 text-center d-flex flex-column align-items-center justify-content-center border border-gray rounded-8 position-relative"
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  fileDownloadHandler(docFile);
                                }}
                              >
                                <span className="d-block material-symbols-outlined icon-lg position-absolute top-0 start-0 mt-2 ms-2">
                                  download
                                </span>
                              </Link>
                              <img
                                src={
                                  docFile.path.split(".").pop() == "pdf"
                                    ? assetImages.PdfImg
                                    : docFile.path.split(".").pop() == "xlsx"
                                    ? assetImages.XlsImg
                                    : assetImages.DocImg
                                }
                                alt="docImage"
                                className="d-block img-fluid mx-auto mb-2"
                              />
                              <p className="fs-sm fw-semibold mb-0">
                                {docFile.name}
                              </p>
                              {/* <p className="size fs-xs lh-1">1.5mb</p> */}
                              <Link
                                to="#"
                                onClick={() => {
                                  closeDocFileHandler(index);
                                }}
                              >
                                <span className="d-block material-symbols-outlined icon-lg position-absolute top-0 end-0 mt-2 me-2">
                                  delete
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>

                      <div className="w-100">
                        {docFileUploading && (
                          <div className="d-flex align-items-center gap-1 mb-1">
                            <p className="fw-semibold fx-sm">
                              {t("Please wait while uploading")}
                            </p>{" "}
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                              return (
                                <div
                                  key={index}
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  style={{ height: "5px", width: "5px" }}
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!-- ========== End uploaded documents Section ========== --> */}

                    {/* agree checkbox */}
                    <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                      <input
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {t("Agree with the")}{" "}
                        <Link to="#" className="text-primary">
                          {t("Legal Notice")}
                        </Link>{" "}
                        &amp;{" "}
                        <Link to="#" className="text-primary">
                          {t("Privacy Policy")}
                        </Link>
                      </label>
                    </div>

                    {/* <!-- ========== Start button Section ========== --> */}
                    <div className="action d-flex align-items-center gap-3 flex-wrap">
                      <Link
                        onClick={resetHandler}
                        to="/admin/challenges/list"
                        className="btn btn-outline-primary d-inline-flex align-items-center gap-2"
                      >
                        <span className="d-block">{t("Cancel")}</span>
                      </Link>

                      <button
                        type="button"
                        className="btn btn-primary ms-auto"
                        onClick={saveJobHandler}
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                      >
                        {t("Post Now")}
                        {/* <span className="d-block material-symbols-outlined icon-lg">
                          save
                        </span> */}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                    {/* <!-- ========== End button Section ========== --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>

          <SaveJobSuccessModal afterSaveModalClose={afterSaveModalClose} />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default JobInformationBody;
