import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const InvoiceDocHeader = ({ reloadList = () => {}, onDelete = () => {} }) => {
  const { t } = useTranslation(); //for translation
  const breadcrumbTxt = [{ title: t("Invoice Docs") }];
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ------- search section -------- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbTxt} />
        </div>

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* ------- action section ---------- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* --------- filter ---------- */}
          {/* <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            //data-bs-toggle="offcanvas"
            //data-bs-target="#offcanvasCompanyListFilter"
            //aria-controls="offcanvasCompanyListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link> */}

          {/* ----------- add new ------------ */}
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#invoiceDocUploadModal"
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined icon-md">
              upload
            </span>
            <span className="d-block">{t("Upload")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDocHeader;
