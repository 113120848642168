/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const ConversationFilterPopup = ({
  tagList,
  templateList,
  setFilterDateRange,
  setfilterSubject,
  setFilterTags,
  setfilterTemplates,
  setfilterReciever,
  setFilterHasAttachment,
  setFilterHasOpened
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [recieverName, setRecieverName] = useState("");
  const [recieverList, setRecieverList] = useState([]);
  const [recieverValue, setRecieverValue] = useState(null);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [subject, setSubject] = useState("");
  const [templateValue, setTemplateValue] = useState(null);
  const [searchedTemplate, setSearchedTemplate] = useState([]);
  const [tagValues, settagValues] = useState([]);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);

  //function for get all user list
  const getAllRecieverList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${recieverName}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setRecieverList(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for sender selection
  const recieverSelectionHandler = (val) => {
    if (val) {
      setRecieverValue(val);
    } else {
      setRecieverValue(null);
      setRecieverList([]);
    }
  };

  //function for template selection
  const templateSectionHandler = (val) => {
    setTemplateValue(val);
    const templateData = val.map((data) => data.value);
    setSearchedTemplate(templateData);
  };

  //function for select event tags
  const mailTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "sendtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");

      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "sendtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");

      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }
    setfilterSubject(subject);
    setFilterTags(tagValues);
    setfilterReciever(recieverValue?.value);
    setfilterTemplates(searchedTemplate);
    setFilterHasAttachment(hasAttachment);
    setFilterHasOpened(hasOpened);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    setSubject("");
    setfromDate("");
    settoDate("");
    settagValues([]);
    setSearchedTemplate([]);
    setRecieverValue(null);
    setRecieverList([]);
    setHasAttachment(false);
    setHasOpened(false);
  };

  //use effect for search sender
  useEffect(() => {
    if (recieverName.length > 0) {
      getAllRecieverList();
    }
  }, [recieverName]);

  const recieverOptionsToShow = recieverList.length > 0 ? recieverList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("From date")}
                value={fromDate}
                onChange={(e) => setfromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("To date")}
                value={toDate}
                onChange={(e) => settoDate(e.target.value)}
              />
            </div>
          </div>
          {/* sent  */}
          <div className="form-group mb-4">
            <label htmlFor="Sent To" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Sent To")}</h3>{" "}
            </label>
            <Select
              isClearable
              options={recieverOptionsToShow}
              placeholder={t("Type reciever name")}
              value={recieverValue}
              onChange={(val) => {
                recieverSelectionHandler(val);
              }}
              onInputChange={(val) => {
                setRecieverName(val);
              }}
            />
          </div>
          {/* template */}
          <div className="form-group mb-4">
            <label htmlFor="Sent To" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select Template")}</h3>{" "}
            </label>
            <Select
              isMulti
              placeholder={t("Select template")}
              options={templateList}
              value={templateValue}
              onChange={(val) => {
                templateSectionHandler(val);
              }}
            />
          </div>
          {/* subject */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Subject")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter")}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => mailTagSelectionHandler(tagData)}
                            id={"sendtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 mt-3 m-0">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={hasOpened}
              onChange={(e) =>
                setHasOpened(e.target.checked ? true : false)
              }
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Has Opened")}
            </label>
          </div>

          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 mt-3 m-0">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={hasAttachment}
              onChange={(e) =>
                setHasAttachment(e.target.checked ? true : false)
              }
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Has Attachment")}
            </label>
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};
export default ConversationFilterPopup;
