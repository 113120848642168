import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import DropdownMenuHeader from "components/ProjectManagementComponents/Common/Header/DropdownMenuHeader";

const ReportListViewHeader = () => {
  const breadCrumbText = [{ title: "Full Report" }];

  return (
    <div class="filter-container py-3">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link className="btn btn-gray d-flex align-items-center" to="#">
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              List View
            </button>
            <DropdownMenuHeader />
          </div>

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReportListViewHeader;
