/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const SaveDirectAccessPopup = ({
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
  selectedAccessId = null,
  setselectedAccessId = () => {},
  afterPopupClose = () => {},
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [accessTitle, setaccessTitle] = useState("");
  const [accessDetails, setaccessDetails] = useState("");
  const [accessModule, setaccessModule] = useState("");
  const [accessUrl, setaccessUrl] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //get direct access details
  const getDirectAccessDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DIRECT_ACCESS_DETAILS +
        `/${selectedAccessId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setaccessTitle(response.data.title);
        setaccessDetails(response.data.description);
        setaccessModule(response.data.moduleslug);
        setaccessUrl(response.data.accessurl);
      }
    } catch (error) {
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //function for saving access
  const saveAccessHandler = async () => {
    if (accessTitle === "") {
      seterrorMessage(t("Please enter access title"));
    } else {
      seterrorMessage("");

      setIsSaving(true);
      try {
        let accessData = {
          moduleslug: accessModule,
          title: accessTitle,
          description: accessDetails,
          accessurl: accessUrl,
        };

        // console.log(accessData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (selectedAccessId) {
          requestUrl +=
            url.API_UPDATE_DIRECT_ACCESS +
            `/${selectedAccessId}?token=${token}`;

          response = await putData(requestUrl, accessData);
        } else {
          requestUrl += url.API_ADD_DIRECT_ACCESS + `?token=${token}`;

          response = await postData(requestUrl, accessData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          resetHandler();
          setMessageType("success");

          // close the popup
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveDirectAccessPopup")
          ).hide();

          afterPopupClose();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
  };

  const resetHandler = () => {
    setaccessTitle("");
    setaccessDetails("");
    setaccessUrl("");
    setaccessModule("");
    setselectedAccessId(null);
  };

  useEffect(() => {
    if (selectedAccessId) {
      getDirectAccessDetails();
    }
  }, [selectedAccessId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0 mobile_offcanvas"
      tabIndex="-1"
      id="saveDirectAccessPopup"
      aria-labelledby="saveDirectAccessPopup"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3
          className="offcanvas-title"
          id="offcanvasLabelsaveDirectAccessLabel"
        >
          {t("Save Bookmark to Direct Access")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="urlname"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Access Name")}
              </label>
              <input
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Access Name")}
                value={accessTitle}
                onChange={(e) => {
                  setaccessTitle(e.target.value);
                  seterrorMessage("");
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Details")}
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="6"
                className="form-control border-0 shadow-none"
                placeholder={t("Details")}
                value={accessDetails}
                onChange={(e) => setaccessDetails(e.target.value)}
              ></textarea>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="role" className="d-block fs-sm fw-semibold mb-2">
                {t("Access Url")}
              </label>

              <input
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Access Name")}
                value={accessUrl}
                onChange={(e) => {
                  setaccessUrl(e.target.value);
                  seterrorMessage("");
                }}
              />
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3 pb-4">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveAccessHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveDirectAccessPopup;
