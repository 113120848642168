import React from "react";
import { Link } from "react-router-dom";
//import dnds
import { Draggable } from "react-beautiful-dnd";

import KanbanCollapsedColumn from "../KanbanColumn/KanbanCollapsedColumn";
import KanbanColumn from "../KanbanColumn/KanbanColumn";

const KanbanBoard = ({
  provided = {},
  kanbanData = [],
  isProcessOwner = false,
  collapseHandler = () => {},
  setSelectedComponentTagId = () => {},
  setCardItemCount = () => {},
  setCardValueCount = () => {},
  deleteResponseHandler = () => {},
  createContactChatHandler = () => {},
  showAddNewIcon = false,
}) => {
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let totalCardCount = 0; // Initialize
  let totalCardValue = 0;

  return (
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
      className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view gap-2"
    >
      {kanbanData.map((columnData, index) => {
        const viewAccessCount = columnData.challengeresponse.filter(
          (responseItem) => isProcessOwner
        ).length;

        totalCardCount += viewAccessCount; // Update card item count

        const totalCardAmount = columnData.challengeresponse
          .filter((invoiceItem) => isProcessOwner)
          .reduce((total, current) => total + current.providerbudget, 0);

        totalCardValue += totalCardAmount; // update card value

        if (columnData.iscollapsed) {
          return (
            <KanbanCollapsedColumn
              collapseHandler={collapseHandler}
              viewAccessCount={viewAccessCount}
              columnData={columnData}
            />
          );
        } else {
          return (
            <Draggable
              key={columnData.componentid}
              draggableId={columnData.componentid}
              index={index}
            >
              {(provided) => {
                return (
                  <KanbanColumn
                    provided={provided}
                    columnData={columnData}
                    viewAccessCount={viewAccessCount}
                    setSelectedComponentTagId={setSelectedComponentTagId}
                    collapseHandler={collapseHandler}
                    isProcessOwner={isProcessOwner}
                    deleteResponseHandler={deleteResponseHandler}
                    createContactChatHandler={createContactChatHandler}
                    showAddNewIcon={showAddNewIcon}
                  />
                );
              }}
            </Draggable>
          );
        }
      })}
      {provided.placeholder}
      {isProcessOwner ? (
        <div className="col-md-3 col-lg-3 mb-3 mb-lg-0">
          <Link
            to="#"
            className="d-flex align-items-center gap-2 fs-lg fw-semibold text-gray my-3"
            data-bs-toggle="modal"
            data-bs-target="#saveColumn"
          >
            <span className="d-block material-symbols-outlined icon-lg">
              add_circle
            </span>
            <span className="d-block">Add Column</span>
          </Link>
          <div className="black-space bg-gray-200">
            <div className="black-space-card"></div>
          </div>
        </div>
      ) : null}
      {/* Set the totalCount */}
      {setCardItemCount(totalCardCount)}
      {setCardValueCount(totalCardValue)}{" "}
    </div>
  );
};

export default KanbanBoard;
