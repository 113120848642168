/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Material UI table
import { MaterialReactTable } from "material-react-table";
import { LineChart } from '@mui/x-charts/LineChart';

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { formatNumber } from "helper/Common/CommonHelper";
import { assetImages } from "constants";

const PavonTicketsPerYearTab = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,
  zipcodes,
  operationType,

  applyFilter,
  refreshData,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");


  const [avgSales, setAvgSales] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [chartData, setChartData] = useState(null);

  const monthMap = {
    'Enero': 1,
    'Febrero': 2,
    'Marzo': 3,
    'Abril': 4,
    'Mayo': 5,
    'Junio': 6,
    'Julio': 7,
    'Agosto': 8,
    'Septiembre': 9,
    'Octubre': 10,
    'Noviembre': 11,
    'Diciembre': 12,
  };

  const sortData = (data) => {
    if(data.length > 0){
      return data.sort((a, b) => {
        if (a.year === b.year) {
          return monthMap[a.month] - monthMap[b.month];
        }
        return a.year - b.year;
      });
    }else{
      return data;
    }
    
  };

const prepareChartData = (data) => {
  const sortedData = sortData(data);
  return {
    labels: sortedData.map(item => `${item.month} ${item.year}`),
    datasets: [{
      label: 'Total Sales',
      data: sortedData.map(item => item.total_sales),
    }],
  };
};

// useEffect(() => {
//   if(avgSales.length > 0){
//     const chartRawData = prepareChartData(avgSales);
//     console.log('chartRawData', chartRawData);

//     setChartData(prepareChartData(chartRawData));
//   }
    
//   }, [avgSales]);

  const columnsTicketsPerYear = useMemo(
    () => [
      { accessorKey: "year", header: "Year" },
      { accessorKey: "month", header: "Month" },
      {
        accessorKey: "total_sales",
        header: "Total Sales",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} &euro;</span>
        ),
      }
    ],
    []
  );




  // structure the params received from filter in url params
  const getFilterParamsInUrl = () => {

    console.log('applyFilter in getFIlterParamsInUrl', applyFilter);

    let urlParams = "";

    if (applyFilter === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }

    if (operationStartDate !== "" && operationEndDate !== "") {
      urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
    }

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    if (zipcodes && zipcodes.length > 0) {
      zipcodes.forEach((data) => {
        urlParams += `&filterzipcode[]=${encodeURIComponent(data)}`;
      });
    }


    if (operationType && operationType.length > 0) {
      operationType.forEach((data) => {
        urlParams += `&filteroperationtype[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };

  //reset all states
  const resetHandler = () => {
    setAvgSales([]);
  };

  // get records -----------------------------------
  const getTicketsPerYearRecords = async () => {
    try {
      setisLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_BY_YEAR +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log('getTicketsPerYearRecords request',requestUrl);

      const response = await getData(requestUrl);

      setisLoading(false);
      console.log("getTicketsPerYearRecords response", response);

      if (response.status) {
        setAvgSales(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getTicketsPerYearRecords();
  }, []);


  // call all api if filter is applied again
  useEffect(() => {

      // call api to get filtered data
        getTicketsPerYearRecords();
      
  }, [startDate, 
      endDate,
      operationStartDate, 
      operationEndDate,
      channels, 
      organizations, 
      enclosures, 
      sectors,
      eventTypes,
      events,
      operationType,
      channels,
      zones,
      zipcodes
    ]);



  return (
        
            <div className="dashboard_innr">
                <div className="container-fluid px-lg-4">
                    
                    {/* Events sales graph */}
                    <div className="event_graph p-4 rounded-10 shadow-sm bg-white mb-4">
                        <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                            <h3 className="fs-lg text-black mb-0 fw-semibold">Sales Per Year</h3>
                            
                        </div>
                        
                        {/* {chartData ? ''
                            // <LineChart
                            //   data={chartData}
                            //   xAxisLabel="Month/Year"
                            //   yAxisLabel="Total Sales"
                            //   width={600}
                            //   height={400}
                            // />
                        :
                           (<span
                              className="mx-2 spinner-border spinner-border-sm"
                              role="status"
                            >
                              <i className="visually-hidden">Loading...</i>
                            </span>)
                        } */}
                        

                    </div>
                        
                    <div className="graph_outer">
                        <div className="row">
                            <div className=" mb-3">
                                <div className="graph_bx h-100 bg-white rounded-10 shadow-none p-3">
                                    <h6 className="text-black fs-sm mb-2 fw-semibold">Ticket Sales Per Year</h6>

                                    
                                    
                                    <div className="graph_info d-flex flex-wrap justify-content-between gap-2" style={{ overflow: 'hidden' }}>
                                       {isLoading ? 
            
                                          (<span
                                                  className="mx-2 spinner-border spinner-border-sm"
                                                  role="status"
                                                >
                                                  <i className="visually-hidden">Loading...</i>
                                                </span>)
                                        :
                                        <MaterialReactTable
                                            columns={columnsTicketsPerYear} // map columns to be displayed with api data,
                                            data={avgSales} // data from api to be displayed
                                            //enableBottomToolbar={false}
                                            //enableGlobalFilterModes={false}
                                            enablePagination={true}
                                            enableRowNumbers={false}
                                            //enableRowVirtualization={false}
                                        />
                                      }
                                    </div>
                                </div>
                            </div>
                      

                        </div>
                    </div>
                </div>
            </div>
            
       
  );
};

export default PavonTicketsPerYearTab;
