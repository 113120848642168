/* eslint-disable */
import React, { useEffect } from "react";

//** import components */
import Header from "components/Common/Header/Header";
import TicketDashboardBody from "components/DashboardComponents/TicketDashboard/TicketDashboardBody";
import DashboardReportIssueModal from "components/DashboardComponents/Modal/DashboardReportIssueModal";
import DashboardSendOfferModal from "components/DashboardComponents/Modal/DashboardSendOfferModal";

const TicketDashboard = () => {
  //assign browser title is dashboard
  useEffect(() => {
    document.title = "Ticket Dashboard";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />
      <TicketDashboardBody />

      {/* <AddLabelModal moduleName="dashboard" /> */}
      <DashboardReportIssueModal />

      <DashboardSendOfferModal />
    </main>
  );
};

export default TicketDashboard;
