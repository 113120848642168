/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const ExperienceModal = ({
  experienceIndex,
  experienceData,
  setExperienceData,
  experienceListBlock,
  setExperienceListBlock,
  setExperienceIndex,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_BY_SEARCH +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add edcuation block
  const addExperienceHandler = () => {
    setExperienceListBlock([...experienceListBlock, experienceData]);
    resetHandler();
  };

  //function for edit education block
  const editExperienceBlockHandler = () => {
    const updatedExperienceList = [...experienceListBlock];
    updatedExperienceList[experienceIndex] = experienceData;
    setExperienceListBlock(updatedExperienceList);
    resetHandler();
  };

  //close modal handler
  const resetHandler = () => {
    setExperienceData({
      companyid: null,
      company: "",
      companylogo: "",
      position: "",
      start: "",
      end: "",
    });
    setExperienceIndex(null);
  };

  const saveInfoHandler = () => {
    if (experienceIndex != null) {
      editExperienceBlockHandler();
    } else {
      addExperienceHandler();
    }

    let loginModal = document.querySelector("#experience_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="modal fade"
      id="experience_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Past work experience</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                {experienceData.companyid ? (
                  <div className="form-group mb-3">
                    <label className="form-label">Company</label>
                    <p className="form-control py-3">
                      {experienceData.companyid.label}
                    </p>
                  </div>
                ) : (
                  <div className="form-group mb-3">
                    <label className="form-label">Company </label>
                    <Select
                      placeholder="Select Company"
                      options={companyOptionsToShow}
                      value={experienceData.companyid}
                      onChange={(val) => {
                        console.log(val);
                        setExperienceData((prevData) => ({
                          ...prevData,
                          companyid: val,
                          company: val.label,
                          companylogo: val?.other?.companylogo ?? "",
                        }));
                      }}
                      onInputChange={(val) => {
                        setcompanyInput(val);
                      }}
                    />
                  </div>
                )}

                {experienceData.companyid ? null : (
                  <div className="form-group mb-3">
                    <label className="form-label">
                      Other Company (* If not in the list)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company name"
                      value={experienceData.company}
                      onChange={(e) => {
                        setExperienceData((prevData) => ({
                          ...prevData,
                          company: e.target.value,
                          companylogo: "",
                        }));
                      }}
                    />
                  </div>
                )}

                {/* <!-- ========== Start positon and ctc Section ========== --> */}
                <div className="form-group d-flex align-items-center gap-3 mb-3">
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">Position</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Position"
                        value={experienceData.position}
                        onChange={(e) => {
                          setExperienceData((prevData) => ({
                            ...prevData,
                            position: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">CTC</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter ctc"
                        value={experienceData.ctc}
                        onChange={(e) => {
                          setExperienceData((prevData) => ({
                            ...prevData,
                            ctc: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End positon and ctc Section ========== --> */}

                {/* <!-- ========== Start year Section ========== --> */}
                <div className="form-group d-flex align-items-center gap-3 mb-3">
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">Start Year</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Start year"
                        value={experienceData.start}
                        onChange={(e) => {
                          setExperienceData((prevData) => ({
                            ...prevData,
                            start: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">End Year</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="End Year"
                        value={experienceData.end}
                        onChange={(e) => {
                          setExperienceData((prevData) => ({
                            ...prevData,
                            end: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End year Section ========== --> */}

                {/* <!-- ========== Start responsibilities Section ========== --> */}
                <div className="form-group mb-3">
                  <label>Responsibilities</label>
                  <textarea
                    name=""
                    id=""
                    rows="5"
                    className="form-control"
                    placeholder="Enter responsibilities"
                    value={experienceData.responsibilities}
                    onChange={(e) => {
                      setExperienceData((prevData) => ({
                        ...prevData,
                        responsibilities: e.target.value,
                      }));
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End responsibilities Section ========== --> */}
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <button
                  onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={saveInfoHandler}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceModal;
