import React from "react";
import { Link } from "react-router-dom";

const ChallengesCommonHeader = ({ componentName = "" }) => {
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item flex-fill">
            <Link
              to="/admin/challenges/dashboard"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "dashboard" ? "active" : ""
              } `}
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/admin/challenges/list"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "challengelist" ? "active" : ""
              } `}
            >
              Challenges
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to="/admin/challenges/globalreport"
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                componentName === "fullreport" ? "active" : ""
              } `}
            >
              Report
            </Link>
          </li>
          
        </ul>
      </div>
    </div>
  );
};

export default ChallengesCommonHeader;
