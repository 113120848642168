/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const TicketsListFilterPopup = ({
  moduleName = "MOD_TICKETS",

  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,

  setOperationStartDate,
  setOperationEndDate,

  setOrganizations,
  setEnclosures,
  setSectors,
  setEventTypes,
  setEvents,
  setChannels,
  setZones,
  setOperationType,
  setZipcodes,
  setEntrycode,
  
  setCustomerName,
  setCustomerEmail,
  setCustomerLocation,
  
  setApplyFilter,
  refreshData
  
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();

  // filter value states 
  const [filterStartDate, setFilterStartDate] = useState("");  
  const [filterStartTime, setFilterStartTime] = useState("00:00");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterEndTime, setFilterEndTime] = useState("23:59");

  const [filterOperationStartDate, setFilterOperationStartDate] = useState("");
  const [filterOperationEndDate, setFilterOperationEndDate] = useState("");

  const [filterChannels, setFilterChannels] = useState([]);
  const [filterZones, setFilterZones] = useState([]);
  const [filterOperationType, setFilterOperationType] = useState([]);
  const [filterOrganizations, setFilterOrganizations] = useState([]);
  const [filterEnclosers, setFilterEnclosers] = useState([]);
  const [filterSector, setFilterSector] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterZipcodes, setFilterZipcodes] = useState([]);
  const [filterEntryCode, setFilterEntryCode] = useState("");
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterCustomerEmail, setFilterCustomerEmail] = useState("");
  const [filterCustomerLocation, setFilterCustomerLocation] = useState("");

  // distinct filter options based on data ================
  const [companyFilterOptions, setCompanyFilterOptions] = useState([]);
  const [channelFilterOptions, setChannelFilterOptions] = useState([]);
  const [encloserFilterOptions, setEncloserFilterOptions] = useState([]);
  const [eventFilterOptions, setEventFilterOptions] = useState([]);
  const [sectorFilterOptions, setSectorFilterOptions] = useState([]);
  const [zoneFilterOptions, setZoneFilterOptions] = useState([]);
  const [operationTypeFilterOptions, setOperationTypeFilterOptions] = useState([]);

  // save filter data states ============================
  const [filterList, setFilterList] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);
  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");
  const [filterValidError, setFilterValidError] = useState(false);

  // error message
  const [errorMessage, seterrorMessage] = useState("");

  //function for handling creatable select
  const createSelectOptions = (values) =>
    values.map((value) => ({ value, label: value }));

  const handleSelectChange = (setter) => (selectedOptions) => {
    setter(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    dateCompareWarning: false,
    opDateCompareWarning : false,
  });


  // get distinct filter options -----------------------
  const getFilterOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_TICKETS_FILTER_OPTIONS + `?token=${token}`;

      console.log("filter option req url", requestUrl);

      const response = await getData(requestUrl);

      console.log(`filterData data ==========>>>`, response);

      if (response.status) {
        // company
        const companyOptions =
          response.data.company &&
          response.data.company.map((option) => ({
            value: option,
            label: option,
          }));
        setCompanyFilterOptions(companyOptions);

        // channel
        const channelOptions =
          response.data.channel &&
          response.data.channel.map((option) => ({
            value: option,
            label: option,
          }));
        setChannelFilterOptions(channelOptions);

        // encloser
        const encloserOptions =
          response.data.encloser &&
          response.data.encloser.map((option) => ({
            value: option,
            label: option,
          }));
        setEncloserFilterOptions(encloserOptions);

        // event
        const eventOptions =
          response.data.event &&
          response.data.event.map((option) => ({
            value: option,
            label: option,
          }));
        setEventFilterOptions(eventOptions);

        // sector
        const sectorOptions =
          response.data.sector &&
          response.data.sector.map((option) => ({
            value: option,
            label: option,
          }));
        setSectorFilterOptions(sectorOptions);

        // zone
        const zoneOptions =
          response.data.zone &&
          response.data.zone.map((option) => ({
            value: option,
            label: option,
          }));
        setZoneFilterOptions(zoneOptions);

        // operation type
        const typeOptions =
          response.data.type &&
          response.data.type.map((option) => ({
            value: option,
            label: option,
          }));
        setOperationTypeFilterOptions(typeOptions);


      } else {
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            //filterDays,
            filterStartDate,
            filterEndDate,
            //filterOperationalDays,
            filterOperationStartDate,
            filterOperationEndDate,
            filterOrganizations,
            filterEnclosers,
            filterSector,
            filterEventType,
            filterEvents,
            filterChannels,
            filterZones,
          }),
          moduleslug: moduleName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    
    setFilterSavingErrorMessage("");
    setFilterValidError(false);

    // reset all previous selected value
    resetHandler();
    // change state to initiate useEffect of ApplyFilter = true
    setApplyFilter(false);

    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);
      const jsonFilterData = JSON.parse(val.parameters);
      // console.log(jsonFilterData);

      

      if (
        jsonFilterData.filterStartDate &&
        (!jsonFilterData.filterDays || jsonFilterData.filterDays === "")
      ) {
        setFilterStartDate(jsonFilterData.filterStartDate);
      }

      if (
        jsonFilterData.filterEndDate &&
        (!jsonFilterData.filterDays || jsonFilterData.filterDays === "")
      ) {
        setFilterEndDate(jsonFilterData.filterEndDate);
      }

      if (jsonFilterData.filterOperationalDays) {
        setfilterOperationalDays(jsonFilterData.filterOperationalDays);
      }

      if (
        jsonFilterData.filterOperationStartDate &&
        (!jsonFilterData.filterOperationalDays ||
          jsonFilterData.filterOperationalDays === "")
      ) {
        setFilterOperationStartDate(jsonFilterData.filterOperationStartDate);
      }

      if (
        jsonFilterData.filterOperationEndDate &&
        (!jsonFilterData.filterOperationalDays ||
          jsonFilterData.filterOperationalDays === "")
      ) {
        setFilterOperationEndDate(jsonFilterData.filterOperationEndDate);
      }

      if (jsonFilterData.filterChannels) {
        setFilterChannels(jsonFilterData.filterChannels);
      }

      if (jsonFilterData.filterZones) {
        setFilterZones(jsonFilterData.filterZones);
      }

      if (jsonFilterData.filterOrganizations) {
        setFilterOrganizations(jsonFilterData.filterOrganizations);
      }

      if (jsonFilterData.filterEnclosers) {
        setFilterEnclosers(jsonFilterData.filterEnclosers);
      }

      if (jsonFilterData.filterSector) {
        setFilterSector(jsonFilterData.filterSector);
      }

      if (jsonFilterData.filterEvents) {
        setFilterEvents(jsonFilterData.filterEvents);
      }

      if (jsonFilterData.filterEventType) {
        setFilterEventType(jsonFilterData.filterEventType);
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  // Handle change in Zipcodes ------------------
  const handleZipcodeChange = (newValue) => {
    // Extract the zip code values from the selected options
    const values = (newValue || []).map(option => option.value);
    setFilterZipcodes(values);

    console.log('filter Zip Code values', filterZipcodes);
  };
  const zipcodeOptions = filterZipcodes.map(zipCode => ({ value: zipCode, label: zipCode }));


  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (filterStartDate !== "" && filterEndDate !== "") {
      if (filterStartDate > filterEndDate) {
        setValidation((prevState) => ({ ...prevState, dateWarning: true }));
        isValid = false;
      }
    }
    if (filterOperationStartDate !== "" && filterOperationEndDate !== "") {
      if (filterOperationStartDate > filterOperationEndDate) {
        setValidation((prevState) => ({ ...prevState, opDateCompareWarning: true }));
        isValid = false;
      }
    }

    console.log('isValid', isValid);

    return isValid;
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {

    if (validate()) {
      seterrorMessage("");

      if (filterStartDate !== "" && filterEndDate !== "") {
        setStartDate(filterStartDate);
        setEndDate(filterEndDate);

        if (filterStartTime !== "" && filterEndTime !== "") {
          setStartTime(filterStartTime);
          setEndTime(filterEndTime);
        }
      }
      

      if (filterOperationStartDate !== "" && filterOperationEndDate !== "") {
        setOperationStartDate(filterOperationStartDate);
        setOperationEndDate(filterOperationEndDate);
      }

      setEvents(filterEvents);
      setOrganizations(filterOrganizations);
      setEnclosures(filterEnclosers)
      setSectors(filterSector)
      setChannels(filterChannels)
      setZones(filterZones)
      setZipcodes(filterZipcodes)
      setOperationType(filterOperationType)

      setCustomerName(filterCustomerName);
      setCustomerEmail(filterCustomerEmail);
      setCustomerLocation(filterCustomerLocation);

      setEntrycode(filterEntryCode);

      setApplyFilter(true);

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasTicketListFilter");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();

    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //function for reset search
  const resetHandler = () => {
    // reset component state
    setFilterStartDate("");
    setFilterStartTime("00:00");
    setFilterEndDate("");
    setFilterEndTime("23:59");
    setFilterOperationStartDate("");
    setFilterOperationEndDate("");
    setFilterEvents([]);
    setFilterOrganizations([]);
    setFilterEnclosers([]);
    setFilterSector([]);
    setFilterChannels([]);
    setFilterZones([]);
    setFilterZipcodes([]);
    setFilterCustomerName("");
    setFilterCustomerEmail("");
    setFilterCustomerLocation("");
    setFilterEntryCode("");

    // // reset props state
    // setStartDate("");
    // setEndDate("");
    // setStartTime("");
    // setEndTime("");
    // setOperationStartDate("");
    // setOperationEndDate("");
    // setOrganizations([]);
    // setZipcodes([]);
    // setSectors([]);
    // setChannels([]);
    // setZones([]);
    // setEntrycode("");
    // setCustomerName("");
    // setCustomerEmail("");

    //setApplyFilter(false);
  };


  useEffect(() => {
    getFilterOptions();
    //getAllFilters(false);
    //getDefaultFilter();
  }, []);


  // reset the filter input fields if refresh is triggred
  useEffect(() => {
    if (refreshData) {
      resetHandler();
    }
  }, [refreshData]);

  // const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasTicketListFilter"
      aria-labelledby="offcanvasCompanyListFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>

          {/* Session date range */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Session Dates</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={filterStartDate}
                onChange={(e) => {
                  setFilterStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="time"
                className="form-control fs-sm shadow-none"
                placeholder="Start Time"
                value={filterStartTime}
                onChange={(e) => {
                  setFilterStartTime(e.target.value);
                }}
              />
            </div>
            <div className="d-flex gap-2 mt-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={filterEndDate}
                onChange={(e) => {
                  setFilterEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="time"
                className="form-control fs-sm shadow-none"
                placeholder="End Time"
                value={filterEndTime}
                onChange={(e) => {
                  setFilterEndTime(e.target.value);
                }}
              />
            </div>

            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>

          {/* Operation purchase date  */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Operation/Purchase Dates</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={filterOperationStartDate}
                onChange={(e) => {
                  setFilterOperationStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    opDateCompareWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={filterOperationEndDate}
                onChange={(e) => {
                  setFilterOperationEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    opDateCompareWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>

            {validation.opDateCompareWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Both dates are required. End date should be greater than Start date</span>
                </p>
              </div>
            )}
          </div>

          {/* Event ================== */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Event</h3>{" "}
            </label>
            {/* <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            /> */}
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={eventFilterOptions}
              value={createSelectOptions(filterEvents)}
              onChange={handleSelectChange(setFilterEvents)}
            />
          </div>

          

          {/* Company ================================= */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>Organization</h3>{" "}
            </label>
            {/* <Select
              placeholder="Type Company or Organization name"
              isMulti
              options={companyOptionsToShow}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setcompanyInput(val);
              }}
            /> */}
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={companyFilterOptions}
              value={createSelectOptions(filterOrganizations)}
              onChange={handleSelectChange(setFilterOrganizations)}
            />
          </div>

          {/* operation type as status ================================================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Status</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={operationTypeFilterOptions}
              value={createSelectOptions(filterOperationType)}
              onChange={handleSelectChange(setFilterOperationType)}
            />
          </div>
          {/* operation type as status end ============================================ */}

          {/* buyer name ========================= */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Customer Name</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={filterCustomerName}
              onChange={(e) => setFilterCustomerName(e.target.value)}
            />
          </div>

          {/* buyer email ======================== */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>Customer Email</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Email"
              value={filterCustomerEmail}
              onChange={(e) => setFilterCustomerEmail(e.target.value)}
            />
          </div>

          {/* Location */}
          {/* <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>Location</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder="Location"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
          </div> */}

          

          {/* Zipcodes ===========================  */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Zipcodes</h3>{" "}
            </label>
            
            

            <CreatableSelect
              isMulti
              onChange={handleZipcodeChange}
              options={zipcodeOptions}
              placeholder="Add zip codes"
              value={zipcodeOptions.filter(option => filterZipcodes.includes(option.value))}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>

          {/* sector =========================================== */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>Sector</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={sectorFilterOptions}
              value={createSelectOptions(filterSector)}
              onChange={handleSelectChange(setFilterSector)}
            />
          </div>

          {/* channel name ============================================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Canal</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={channelFilterOptions}
              value={createSelectOptions(filterChannels)}
              onChange={handleSelectChange(setFilterChannels)}
            />
          </div>

          {/* zone name ================================================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Zone</h3>{" "}
            </label>
            <CreatableSelect
              isClearable
              className="nosearch_select"
              isMulti
              options={zoneFilterOptions}
              value={createSelectOptions(filterZones)}
              onChange={handleSelectChange(setFilterZones)}
            />
          </div>
          {/* zone name end ============================================ */}

          {/* entry code ================ */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>Entry Code</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="customer entry code"
              value={filterEntryCode}
              onChange={(e) => setFilterEntryCode(e.target.value)}
            />
          </div>

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}


        </form>
      </div>
    </div>
  );
};

export default TicketsListFilterPopup;
