/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

// import for excel download
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import ProjectTaskHeader from "../ProjectTaskHeader/ProjectTaskHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import TaskManagementPopup from "components/Common/Popup/TaskManagementPopup/TaskManagementPopup";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";
import { assetImages } from "constants";
import { prjectManageSlug } from "Config/Config";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import AssignBulkTaskModal from "components/Common/Modal/AssignBulkTaskModal";
import SuccessBulkTaskModal from "components/Common/Modal/SuccessBulkTaskModal";
import DeleteTaskWarningModal from "components/Common/Modal/DeleteTaskWarningModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import { useTranslation } from "react-i18next";
import UpdateTaskSchedulePopup from "components/Common/Popup/UpdateTaskSchedulePopup";

const ProjectTaskBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation
  const placeHolderCount = [1, 2, 3];

  const [title, settitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [sectionList, setSectionList] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [isProjectModerator, setisProjectModerator] = useState(false);

  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedSectionValue, setSelectedSectionValue] = useState(null);
  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedEditTaskId, setSelectedEditTaskId] = useState(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [afterSavedTaskData, setAfterSavedTaskData] = useState(null); //store new saved task to prevent refresh

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");
  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [projectMemberStatus, setProjectMemberStatus] = useState("");
  const [projectModStatus, setprojectModStatus] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [bulkSavedTaskList, setBulkSavedTaskList] = useState([]);

  const [updateScheduleTaskUserList, setUpdateScheduleTaskUserList] = useState(
    []
  );

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // download to excel
  const [excelData, setExcelData] = useState([]);

  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        settitle(data.title);

        setselectedTaskModerator(data.moderator._id);

        let isManager = false;

        // Loop through the array
        data.projectmemberlist.forEach((member) => {
          if (
            member._id.toString() === userInfo._id.toString() &&
            member.roleslug === prjectManageSlug
          ) {
            isManager = true;
          }
        });

        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          isManager ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisProjectModerator(isModerator);
        getAllTasks(isModerator);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setProjectMemberStatus(response.data.memberstatus);
        setprojectModStatus(response.data.moderatorstatus);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get all event members
  const getAllProjectMembers = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_MEMBER +
        `?token=${token}&project=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);
      setIsLoading(false);
      if (response.status) {
        let approvedMembers = [];

        response.data.map((memberData) => {
          if (
            memberData.memberstatus == "1" &&
            memberData.moderatorstatus == "1"
          ) {
            approvedMembers.push({
              label: memberData.memebername,
              value: memberData.user._id,
            });
          }
        });

        setMemberList(approvedMembers);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //get all section with task
  const getAllSections = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_SECTION +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);

      setSectionList(response.data);
      setSectionOptions(
        response.data.map((sectionData) => ({
          label: sectionData.title,
          value: sectionData._id,
          startdate: sectionData.startdate,
          enddate: sectionData.enddate,
        }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all section with task
  const getAllTasks = async (isModerator) => {
    setSelectedTaskIds([]);
    setRowSelection({});
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&projectid=${params.id}&ismoderator=${isModerator}`;

      if (filterTaskDateRangeType != "") {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != "" || filterTaskToDate != "") {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }
      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }
      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }
      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);
      console.log(response);

      if (response.status) {
        setMessageType("success");
        setTaskList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].rank = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change complete percent
  const completePercentInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].taskpercent = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change planed hours
  const plannedHoursInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].hours = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change start date
  const startDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].startdate = value;

      console.log(updatedTaskList);

      setTaskList(updatedTaskList);
    }
  };

  //function for change end date
  const endDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].enddate = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for update rank
  const updateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        //run loop of updated company
        for (let task of taskList) {
          let taskData = {};

          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (task._id == updatedTaskId) {
            taskData.rank = task.rank;
            taskData.taskpercent = task.taskpercent;
            taskData.startdate = task.startdate;
            taskData.enddate = task.enddate;
            taskData.hours = task.hours;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_PROJECT_TASK +
                `/${updatedTaskId}` +
                `?token=${token}`;

              const response = await putData(requestUrl, taskData);

              // console.log(response);

              if (response.status) {
                setMessageType("success");
                responseArr.push(response.status);
              } else {
                setMessageType("error");
              }

              setAlertMessage(response.message);
            } catch (error) {
              setAlertMessage(error.message);
              setMessageType("error");
            }

            setShowAlert(true);
          }
        }
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTasks(isProjectModerator);
        }, 2200);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //delete multiple tasks
  const deActivateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        try {
          let requestUrl =
            url.API_BASE_URL +
            url.API_DEACTIVATE_PROJECT_TASK +
            `/${updatedTaskId}` +
            `?token=${token}`;

          const response = await putData(requestUrl);

          // console.log(response);

          if (response.status) {
            setMessageType("success");
            responseArr.push(response.status);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTasks(isProjectModerator);
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //change status for section
  const changeSectionStatusHandler = async () => {
    try {
      let sectionData = {
        status: "0",
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_PROJECT_SECTION +
        `/${selectedSectionId}?token=${token}`;

      const response = await putData(requestUrl, sectionData);

      console.log(response);

      if (response.status) {
        setSelectedSectionId(null);
        getAllTasks();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //change status for task
  const changeTaskStatusHandler = async () => {
    try {
      let taskData = {
        status: "0",
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_PROJECT_TASK +
        `/${selectedEditTaskId}?token=${token}`;

      const response = await putData(requestUrl, taskData);

      if (response.status) {
        setSelectedEditTaskId(null);
        getAllTasks();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //cancel section warning
  const onCancelSectionWarning = () => {
    setSelectedSectionId(null);
  };

  //cancel task warning
  const onCancelTaskWarning = () => {
    setSelectedEditTaskId(null);
  };

  const afterPopupClose = () => {
    getAllTasks();
  };

  //update schedule to state
  const updateBulkTaskScheduleStateHandler = () => {
    if (selectedTaskIds.length > 0) {
      const selectedTasksList = taskList.filter((item) =>
        selectedTaskIds.includes(item._id)
      );

      let filteredTaskList = [];

      if (
        userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN"
      ) {
        filteredTaskList = selectedTasksList;
      } else {
        filteredTaskList = selectedTasksList.filter(
          (item) => item.moderator.toString() === userInfo._id.toString()
        );
      }

      const transformedTasksArray = [];

      // Helper function to initialize weekly work days
      const initializeWeekly = () => ({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      });

      // Group tasks by assigned user
      const userTasksMap = filteredTaskList.reduce((acc, task) => {
        const userId = task.assigneduser || null;
        if (!acc[userId]) {
          acc[userId] = {
            assigneduser: task.assigneduser || null,
            assignedusername: task.assignedusername || "",
            assigneduserimage: task.assigneduserimage || "",
            tasks: [],
            workhours: 0,
            ...initializeWeekly(),
          };
        }
        acc[userId].tasks.push(task);
        return acc;
      }, {});

      // Transform the map into the desired array format
      // for (const userId in userTasksMap) {
      //   transformedTasksArray.push(userTasksMap[userId]);
      // }
      for (const userId in userTasksMap) {
        // Sort tasks by priority: 1, 2, then 3
        userTasksMap[userId].tasks.sort((a, b) => {
          return Number(a.rank) - Number(b.rank);
        });

        transformedTasksArray.push(userTasksMap[userId]);
      }

      setUpdateScheduleTaskUserList(transformedTasksArray);

      if (filteredTaskList.length > 0) {
        let offCanvasPopup = document.querySelector("#UpdateTaskSchedule");
        let offCanvas = new bootstrap.Offcanvas(offCanvasPopup);
        offCanvas.show();
      } else {
        setMessageType("error");
        setAlertMessage("You are not authorized to perform this action");
        setShowAlert(true);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);
    setReloadData(true);
    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterModalClose = () => {
    setTimeout(() => {
      getAllTasks();
    }, 2200);
  };

  const afterCloseModalHandler = (isGetAllList) => {
    if (isGetAllList) {
      getAllTasks();
    } else {
      setRowSelection({});
      setSelectedTaskIds([]);
    }
  };

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskCustomIds.length > 0 ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskProjects.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getAllTasks(isProjectModerator);
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskProjects,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    if (params.id) {
      getAllProjectMembers();
      getProjectDetails();
      getAllSections();
    }
  }, [params.id]);

  // State to manage the filter values for each column
  const [columnFilterValues, setColumnFilterValues] = useState({});
  const [showGlobalFilters, setShowGlobalFilters] = useState(false);

  const globalFilterTextHandler = ({ column }) => (
    <Box>
      <TextField
        value={columnFilterValues[column.id] || ""}
        onChange={(e) => {
          // console.log(column);
          setColumnFilterValues({
            ...columnFilterValues,
            [column.id]: e.target.value,
          });

          column.setFilterValue(e.target.value || undefined); // Apply the filter to the table or remove filter if empty
        }}
        placeholder={`Filter by ${column.columnDef.header}`}
        variant="standard"
      />
      {columnFilterValues[column.id] && ( // Display clear filter button only if filter is applied
        <IconButton onClick={() => clearGlobalFilterhandler(column)}>
          <Clear />
        </IconButton>
      )}
    </Box>
  );

  const clearGlobalFilterhandler = (column) => {
    column.setFilterValue("");
    setColumnFilterValues({
      ...columnFilterValues,
      [column.id]: "", // Clear filter value for the specified column
    });
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#addTask_view"
          aria-controls="addTask_view"
          onClick={() => {
            setSelectedEditTaskId(row.original._id);
            setselectedTaskModerator(row.original.moderator);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "customid",
      header: t("ID"),
      size: 100,
    },
    {
      accessorKey: "title",
      header: t("Title"),
      size: 200,
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: "200px" }}>
          <div className="d-flex align-items-center gap-4">
            {/* <p className="fs-md fw-semibold"></p> */}
            <Link
              to={`/admin/task/details/${row.original._id}`}
              className="d-flex align-items-center gap-1 fs-md"
              // data-bs-toggle="offcanvas"
              // role="button"
              // aria-controls="addTask_view"
              // onClick={() => {
              //   setSelectedEditTaskId(row.original._id);
              //   setselectedTaskModerator(row.original.moderator._id);
              // }}
            >
              {row.original.title}
            </Link>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <input
              type="text"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-3">{row.original.rank}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "taskstatus",
      header: t("Task Status"),
    },
    {
      accessorKey: "taskpercent",
      header: t("Complete %"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.taskpercent}
              onChange={(e) =>
                completePercentInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.taskpercent}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "taskpriority",
      header: t("Priority"),
    },
    {
      accessorKey: "hours",
      header: t("Planned Hours"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.hours}
              onChange={(e) =>
                plannedHoursInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.hours}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "loggedhours",
      header: t("Dedicated Hours"),
    },
    {
      accessorKey: "balancehours",
      header: t("Balance Hours"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          <p
            className={`ps-3 ${
              row.original.balancehours < 0 ? "text-danger" : ""
            }`}
          >
            {row.original.balancehours}
          </p>
        </div>
      ),
    },
    {
      accessorKey: "startdate",
      header: t("Start Date"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "140px" }}
              value={row.original.startdate}
              onChange={(e) => startDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">{row.original.taskstartdate}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "enddate",
      header: t("End Date"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "140px" }}
              value={row.original.enddate}
              onChange={(e) => endDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">{row.original.taskenddate}</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "tasknumberofdaysleft",
      header: t("Days Left"),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: "200px" }}>
          <div className="d-flex align-items-center gap-4">
            <p
              className={
                row.original.tasknumberofdaysleft <= 3 ? "text-primary" : ""
              }
            >
              {" "}
              {row.original.tasknumberofdaysleft}
            </p>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "moderatorname",
      header: t("Assigned By"),
      size: 250,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          <img
            className="rounded-circle"
            height={35}
            width={35}
            src={
              row.original.moderatorimage == ""
                ? assetImages.defaultUser
                : url.SERVER_URL + row.original.moderatorimage
            }
            alt="Babcock"
          />
          <p>
            <span className="d-block">{row.original.moderatorname}</span>
            <span className="d-block">{row.original.moderatoremail}</span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "assignedusername",
      header: t("Assigned To"),
      size: 250,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          {row.original.assignedusername === "" ? null : (
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={
                row.original.assigneduserimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.assigneduserimage
              }
              alt="Babcock"
            />
          )}
          <p>
            <span className="d-block">{row.original.assignedusername}</span>
            <span className="d-block">{row.original.assigneduseremail}</span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "followersnames",
      header: t("Followers"),
      size: 250,
    },
    {
      accessorKey: "projectsectionname",
      header: t("Section"),
      size: 200,
    },
    {
      accessorKey: "tasklogupdatedperson",
      header: t("Log Updated By"),
      size: 250,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          {row.original.tasklogupdatedpersonname === "" ? null : (
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={
                row.original.tasklogupdatedpersonimage === ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.tasklogupdatedpersonimage
              }
              alt="Babcock"
            />
          )}
          <p>
            <span className="d-block">
              {row.original.tasklogupdatedpersonname}
            </span>
            <span className="d-block">
              {row.original.tasklogupdatedpersonemail}
            </span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "tasklogupdatedon",
      header: t("Log updated on"),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
    // {
    //   accessorKey: "#",
    //   header: "Action",
    //   Cell: ({ row }) => (
    //     <div className="action border-bottom-0">
    //       <Link
    //         to="#addTask_view"
    //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
    //         data-bs-toggle="offcanvas"
    //         role="button"
    //         aria-controls="addTask_view"
    //         onClick={() => {
    //           setSelectedEditTaskId(row.original._id);
    //           setselectedTaskModerator(row.original.moderator._id);
    //         }}
    //       >
    //         <span className="d-block">Details</span>
    //         <span className="d-block material-symbols-outlined">
    //           chevron_right
    //         </span>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   */
  const columns = useMemo(() => initialTableColumns, [taskList, i18n.language]);

  /**
   * Updates the column visibility state by toggling the visibility of the column with the given accessor key.
   *
   * @param {function} newColumnState - A function that returns the accessor key of the column whose visibility is being changed.
   */
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /**
   ** Represents the state of column visibility in the table.
   */
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /**
   * Updates the column visibility state based on the new column state.
   *
   * @param {function} newColumnState - A function that returns the new column state.
   * @return {void} This function does not return anything.
   */
  const onColumnVisiblityHandler = (newColumnState) => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllTasks(isProjectModerator);
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  //refresh icon function
  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  // const initialGlobalFilter = Object.entries(columnFilterValues).map(
  //   ([id, value]) => ({ id, value })
  // );

  // const toggleColumnFiltersChange = () => {
  //   setShowGlobalFilters(!showGlobalFilters);
  // };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  //download as excel
  const downloadAsExcel = () => {
    if (taskList.length > 0) {
      const createRowData = (apiData) => [
        apiData.customid,
        apiData.title,
        apiData.rank,
        apiData.taskstatus,
        apiData.completedpercent,
        apiData.projectname,
        apiData.taskpriority,
        apiData.hours,
        apiData.loggedhours,
        apiData.balancehours,
        apiData.startdate,
        apiData.enddate,
        apiData.tasknumberofdaysleft,
        apiData.moderatorname,
        apiData.assignedusername,
        apiData.followersnames,
        apiData.projectsectionname,
        apiData.tasklogupdatedperson,
        apiData.tasklogupdatedon,
        apiData.leadtitle,
        apiData.contactname,
        apiData.tagstring,
      ];

      const filteredList =
        selectedTaskIds.length > 0
          ? taskList.filter((item) => selectedTaskIds.includes(item._id))
          : taskList;

      filteredList.map((apiData) => {
        const rowData = createRowData(apiData);
        setExcelData((prevState) => [...prevState, rowData]);
      });
    }
  };

  const createExcel = () => {
    const fileName = "tasks";

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const Heading = [
      [
        "ID",
        "Title",
        "Rank",
        "Task Status",
        "Complete %",
        "Project",
        "Priority",
        "Planned Hours",
        "Dedicated Hours",
        "Balance Hours",
        "Start Date",
        "End Date",
        "Days Left",
        "Assigned By",
        "Assigned To",
        "Followers",
        "Section",
        "Log Updated By",
        "Log Updated On",
        "Lead",
        "Contact",
        "Label",
      ],
    ];

    const ws = XLSX.utils.json_to_sheet(excelData, {
      origin: "A2",
      skipHeader: true,
    });

    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const filedata = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(filedata, fileName + fileExtension);
  };

  useEffect(() => {
    // console.log("excelData", excelData);

    if (excelData.length > 0) {
      createExcel();
      setExcelData([]);
    }
  }, [excelData]);

  // download excel ends

  useEffect(() => {
    if (afterSavedTaskData) {
      // console.log("afterSavedTaskData---->", afterSavedTaskData);

      setTaskList((prevTasks) => {
        const existingIndex = taskList.findIndex(
          (task) => task._id.toString() === afterSavedTaskData._id.toString()
        );

        if (existingIndex !== -1) {
          // task exists, update the existing record
          const updatedTasks = [...prevTasks];
          updatedTasks[existingIndex] = afterSavedTaskData;
          return updatedTasks;
        } else {
          // Roll number does not exist, add new student at index 0
          return [afterSavedTaskData, ...prevTasks];
        }
      });

      setAfterSavedTaskData(null);
    }
  }, [afterSavedTaskData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Tasks")}
            />
          )}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter area ------- */}
            <ProjectTaskHeader
              projectid={params.id}
              isProjectModerator={isProjectModerator}
              projectMemberStatus={projectMemberStatus}
              projectModStatus={projectModStatus}
              reloadList={refreshRecords}
              updateBulkTaskHandler={updateBulkTaskHandler}
              breadCrumbText={breadCrumbText}
              title={title}
              userInfo={userInfo}
              setselectedTaskModerator={setselectedTaskModerator}
              updateScheduleStateHandler={updateBulkTaskScheduleStateHandler}
              downloadAsExcel={downloadAsExcel}
            />

            {/* -------- list area ---------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isProjectModerator ||
              (projectMemberStatus === "1" && projectModStatus === "1") ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  enableColumnOrdering={true}
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }}
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  // onShowColumnFiltersChange={toggleColumnFiltersChange}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : projectMemberStatus === "" && projectModStatus === "" ? null : (
              <div className="project-tasks-container">
                <div className="challenges_empty text-center">
                  <div className="empty_pic mb-4">
                    {" "}
                    <img src={assetImages.emptyVector} alt="" />
                  </div>
                  <div className="empty_text">
                    <p className="fs-lg text-gray fw-semibold mb-4">
                      {t(
                        "Sorry....! You don't have privilege to see this content"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* ========== popups and modal area ======== */}

        {/* -------- add task popup -------- */}
        <TaskManagementPopup
          selectedModuleSlug="MOD_PROJECT"
          moduleName={"proejcttask"}
          leadId={null}
          contactId={null}
          sectionId={selectedSectionId}
          projectId={params.id}
          selectedTaskId={selectedEditTaskId}
          selectedTaskModerator={selectedTaskModerator}
          isProjectModerator={isProjectModerator}
          projectValue={{ label: title, value: params.id }}
          selectedSectionValue={selectedSectionValue}
          leadValue={null}
          contactValue={null}
          onPopUpClose={() => {
            setSelectedEditTaskId(null);
            setSelectedSectionValue(null);
          }}
          getTaskList={(data) => {
            if (data) {
              setTimeout(() => {
                setAfterSavedTaskData(data);
              }, 1500);
            } else {
              setAfterSavedTaskData(null);
            }
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------- filter popup --------- */}
        <MyTaskFilterPopup
          visibleColoumns={visibleColoumns}
          setVisibleColoumns={setVisibleColoumns}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columnFilterValues={columnFilterValues}
          setColumnFilterValues={setColumnFilterValues}
          showGlobalFilters={showGlobalFilters}
          setShowGlobalFilters={setShowGlobalFilters}
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          isModerator={isProjectModerator}
          moduleName="projecttask"
        />

        <AddLabelModal
          moduleName="task"
          selectedIds={selectedTaskIds}
          afterTagModalClose={afterModalClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddAlertModal
          selectedIds={selectedTaskIds}
          afterModalClose={() => {
            setSelectedTaskIds([]);
            setRowSelection({});
          }}
          moduleName={"task"}
        />

        <AssignBulkTaskModal
          moduleName={"proejcttask"}
          userInfo={userInfo}
          afterCloseModalHandler={afterCloseModalHandler}
          selectedTasks={selectedTaskIds}
          setBulkSavedTaskList={setBulkSavedTaskList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <SuccessBulkTaskModal
          bulkSavedTaskList={bulkSavedTaskList}
          afterModalClose={afterCloseModalHandler}
        />

        <DeleteTaskWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          onDelete={deActivateBulkTaskHandler}
        />

        <AddFollowerModal
          moduleName="task"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedTaskIds}
          afterModalClose={afterModalClose}
        />

        <UpdateTaskSchedulePopup
          updateScheduleTaskUserList={updateScheduleTaskUserList}
          setUpdateScheduleTaskUserList={setUpdateScheduleTaskUserList}
          reloadList={() => {
            setTimeout(() => {
              getAllTask();
            }, 2500);
          }}
          closePopupHandler={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectTaskBody;
