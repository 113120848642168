/* eslint-disable */
import { useState } from "react";

const CreateLeadFromDocModal = ({
  token,
  url,
  uploadSingleFile = () => {},
  setUploadedDocUrl = () => {},
  setDocContent = () => {},
  setuploadedFileId = () => {},
}) => {
  const [isUploading, setIsUploading] = useState(false);

  //** upload doc funtion
  const docUploadHandler = async (e) => {
    try {
      setIsUploading(true);

      // reset dependent data to avoid duplicate
      setUploadedDocUrl("");
      setDocContent("");

      const file = e.target.files[0];

      let requestURL =
        url.API_BASE_URL +
        url.API_SINGLE_FILE_UPLOAD +
        `?token=${token}&textcontent=1`;

      console.log("req url", requestURL);

      const response = await uploadSingleFile(requestURL, file);

      if (response.status) {
        console.log("file upload response", response);

        setuploadedFileId(response.data._id);

        // set the uploaded url path
        setUploadedDocUrl(response.data.path);
        // set the content of the file here
        setDocContent(response.textcontent);

        setIsUploading(false);

        let uploadModal = document.querySelector("#create_lead_from_doc");
        let modal = bootstrap.Modal.getInstance(uploadModal);
        modal.hide();
      }
    } catch (error) {
      console.log(error.message);
    }
    resetDocFileInput();
  };

  //** function for reset video inputs
  const resetDocFileInput = () => {
    const file = document.getElementById("docFile_lead");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  return (
    <div
      className="modal fade"
      id="create_lead_from_doc"
      tabIndex="-1"
      aria-labelledby="create_lead_from_doc"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {/* <!-- ========== Start modal title Section ========== --> */}
            <h5 className="modal-title" id="csvUploadModalLabel">
              Upload PDF/TXT/DOC File
            </h5>

            {/* <!-- ========== End modal title Section ========== --> */}

            {/* <!-- ========== Start modal close button ========== --> */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setUploadedDocUrl("");
              }}
            ></button>
            {/* <!-- ========== End modal close button ========== --> */}
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="docFile" className="form-label">
                Choose a PDF/TXT/DOC file to upload
              </label>

              <input
                type="file"
                className="form-control border-grey"
                id="docFile_lead"
                onChange={docUploadHandler}
              />
            </div>

            <h6
              className={`text text-primary mt-3 ${
                isUploading ? "d-flex align-items-center" : "d-none"
              }`}
            >
              <div className="mx-2 spinner-border spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              File uploading in process
            </h6>

            {/* <!-- ========== End file upload Section ========== --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLeadFromDocModal;
