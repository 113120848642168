/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

//import common header objects
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import CrmLeadDashboardHeader from "../Header/CrmLeadDashboardHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const CrmLeadDashboardBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get dashboard details
  const leadDashboardDetails = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_LEAD_DASHBOARD + `?token=${token}`;

      const response = await getData(requestURL);

      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setDashboardData(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    leadDashboardDetails();
  }, []);

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="dashboard-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Leads")}
          />

          <div className="container-fluid px-lg-5">
            <CrmLeadDashboardHeader reloadView={leadDashboardDetails} />

            <div className="tab-links-container bg-white py-4 shadow-sm">
              <div className="container-fluid">
                <h1 className="h2 mb-2">Leads Dashboard</h1>
              </div>
            </div>

            <div className="activity-container py-3 bg-gray-200">
              {isLoading ? (
                <div>
                  <span className="mx-2 spinner-border" role="status">
                    <i className="visually-hidden">Loading...</i>
                  </span>
                </div>
              ) : (
                <div className="row">
                  {/* <!-- ========== Start lead Section ========== --> */}
                  <div className="col-md-6 col-lg-3 mb-4">
                    <div className="contacts card-content p-3 bg-white rounded-10 shadow-sm">
                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Leads</h3>
                          {/* <p className="fs-sm fw-semibold">1100</p> */}
                        </div>
                      </div>
                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Total</span>
                          <span className="ms-auto">
                            {dashboardData?.totalleadcount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Leads prev.</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Prev .</span>
                          <span className="ms-auto">
                            {dashboardData?.previousmonthleadcount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Difference</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            difference
                          </span>
                          <span>Diff</span>
                          <span className="ms-auto">
                            {dashboardData?.leadcountdifference}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Rank</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            bookmark_star
                          </span>
                          <span>100</span>
                          <span className="ms-auto">
                            {dashboardData?.leadrankhundredcount}
                          </span>
                        </li>
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            bookmark_star
                          </span>
                          <span>99</span>
                          <span className="ms-auto">
                            {dashboardData?.leadrankninetyninecount}
                          </span>
                        </li>
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            bookmark_star
                          </span>
                          <span>98</span>
                          <span className="ms-auto">
                            {dashboardData?.leadrankninetyeightcount}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- ========== End lead Section ========== --> */}

                  {/* <!-- ========== Start Following Section ========== --> */}
                  <div className="col-md-6 col-lg-3 mb-4">
                    <div className="contacts card-content p-3 bg-white rounded-10 shadow-sm">
                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Following</h3>
                          {/* <p className="fs-sm fw-semibold">1100</p> */}
                        </div>
                      </div>
                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Total</span>
                          <span className="ms-auto">
                            {dashboardData?.followingleadcount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Follow prev.</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Prev.</span>
                          <span className="ms-auto">
                            {dashboardData?.prevmonthfollowcount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Difference</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            difference
                          </span>
                          <span>Diff</span>
                          <span className="ms-auto">
                            {dashboardData?.followdifference}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- ========== End Following Section ========== --> */}

                  {/* <!-- ========== Start Opportunities Section ========== --> */}
                  <div className="col-md-6 col-lg-3 mb-4">
                    <div className="contacts card-content p-3 bg-white rounded-10 shadow-sm">
                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Opportunities</h3>
                          {/* <p className="fs-sm fw-semibold">1100</p> */}
                        </div>
                      </div>
                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Total</span>
                          <span className="ms-auto">
                            {dashboardData?.opportunitiescount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Opportunities prev.</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Prev.</span>
                          <span className="ms-auto">
                            {dashboardData?.opportunitiesprevmonthcount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Difference</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            difference
                          </span>
                          <span>Diff</span>
                          <span className="ms-auto">
                            {dashboardData?.opportunitiesdifference}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- ========== End Opportunities Section ========== --> */}

                  {/* <!-- ========== Start Notes Section ========== --> */}
                  <div className="col-md-6 col-lg-3 mb-4">
                    <div className="contacts card-content p-3 bg-white rounded-10 shadow-sm">
                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Notes</h3>
                          {/* <p className="fs-sm fw-semibold">1100</p> */}
                        </div>
                      </div>
                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Total</span>
                          <span className="ms-auto">
                            {dashboardData?.notescount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Notes prev.</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            event
                          </span>
                          <span>Prev.</span>
                          <span className="ms-auto">
                            {dashboardData?.prevmonthnotecount}
                          </span>
                        </li>
                      </ul>

                      <div className="head d-flex align-items-center justify-content-between gap-3 mb-2">
                        <div className="title">
                          <h3 className="mb-0">Difference</h3>
                        </div>
                      </div>

                      <ul className="items fs-sm d-flex flex-column gap-2 mb-4">
                        <li className="item d-flex align-items-center body-bg p-2 rounded-5">
                          <span className="d-block material-symbols-outlined icon-lg me-2">
                            difference
                          </span>
                          <span>Diff</span>
                          <span className="ms-auto">
                            {dashboardData?.notedifference}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- ========== End Notes Section ========== --> */}
                </div>
              )}
            </div>
          </div>
        </section>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CrmLeadDashboardBody;
