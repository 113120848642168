/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DetailsModal = ({ setuserDetails, userDetails }) => {
  const { t } = useTranslation(); // for translations

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };

  const [details, setdetails] = useState("");

  const saveDetailsHandler = () => {
    if (details !== "") {
      setuserDetails(details);
    }

    resetHandler();

    let loginModal = document.querySelector("#details_modal");
    let modal = bootstrap.Modal.getInstance(loginModal);
    modal.hide();
  };

  const resetHandler = () => {
    setdetails("");
  };

  useEffect(() => {
    if (userDetails !== "") {
      setdetails(userDetails);
    }
  }, [userDetails]);

  return (
    <div
      className="modal fade"
      id="details_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Details</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                <div className="form-group summery_textbx">
                  <label className="form-label">
                    A brief details about candidate
                  </label>
                  <CKEditor
                    className="form-control"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={userDetails ? userDetails : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setuserDetails(editorContent);
                    }}
                  />
                </div>
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                  // onClick={resetHandler}
                >
                  Cancel
                </button>
                <button className="btn btn-primary" data-bs-dismiss="modal">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
