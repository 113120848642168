/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import LeftMenuPanelPopup from "../Popup/LeftMenuPanelPopup";

//* import image
import { assetImages } from "constants";

//* import translations
import { useTranslation, Trans } from "react-i18next";

//import options
import languageOptions from "data/Prod/LanguageOptions.json";

//* import url and method
import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { getAlertDateFormat } from "helper/TimeCalculation";

import { GlobalProvider } from "context/GlobalContext";
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import SaveDirectAccessModal from "../Modal/SaveDirectAccessModal";

const Header = ({ moduleName }) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemLogo = localStorage.getItem("ecosystemlogo");
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const {
    reloadAlertList,
    setReloadAlertList,
    reloadRequestList,
    setreloadRequestList,
    setReloadContactList,
  } = useContext(GlobalProvider);

  const [todayUnDoneAlertList, setTodayUnDoneAlertList] = useState([]);
  const [todayDoneAlertList, setTodayDoneAlertList] = useState([]);
  const [otherDayUndoneAlertList, setOtherDayUndoneAlertList] = useState([]);
  const [otherDayDoneAlertList, setOtherDayDoneAlertList] = useState([]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [recievedInviteList, setRecievedInviteList] = useState([]);
  const [sentRequestList, setSentRequestList] = useState([]);

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  //function get all alerts
  const getTodaysAlerts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TODAYS_ALERT +
        `?token=${token}&filterdate=${todayValue}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setTodayUnDoneAlertList(
            response.data.filter((alert) => !alert.isdone)
          );
          setTodayDoneAlertList(response.data.filter((alert) => alert.isdone));
        } else {
          setTodayUnDoneAlertList([]);
          setTodayDoneAlertList([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getOtherDaysAlerts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_OTHERDAYS_ALERT + `?token=${token}`;

      if (startDate !== "" && endDate !== "") {
        const filterDateRange = `${startDate},${endDate}`;
        requestUrl += `&filterdate=${filterDateRange}`;
      } else {
        if (startDate !== "") {
          requestUrl += `&filterdate=${startDate}`;
        }

        if (endDate !== "") {
          requestUrl += `&filterdate=${endDate}`;
        }
      }

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setOtherDayUndoneAlertList(
            response.data.filter((alert) => !alert.isdone)
          );
          setOtherDayDoneAlertList(
            response.data.filter((alert) => alert.isdone)
          );
        } else {
          setOtherDayUndoneAlertList([]);
          setOtherDayDoneAlertList([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //view alert
  const viewAndUpdateAlert = async (id, isDone, isDoneValue) => {
    try {
      let alertData = {};

      if (isDone) {
        alertData.isdone = isDoneValue;
      } else {
        alertData.isread = true;
      }

      let requestUrl = `${url.API_BASE_URL}${url.API_UPDATE_ALERT}/${id}?token=${token}`;

      const response = await putData(requestUrl, alertData);

      console.log(response);

      if (response.status) {
        getTodaysAlerts();
        getOtherDaysAlerts();
        if (!isDone) {
          if (response.data) {
            if (response.data.leadid) {
              history.push(
                `/admin/crm/lead/information/${response.data.leadid}`
              );
            }

            if (response.data.contactid) {
              history.push(
                `/admin/crm/contact/details/timeline/${response.data.contactid}`
              );
            }

            if (response.data.taskid) {
              history.push(`/admin/task/details/${response.data.taskid}`);
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all invite
  const getAllInvites = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_RECIEVED_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setRecievedInviteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all sent requests
  const getAllSentRequests = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_SEND_INVITE + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setSentRequestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for accept invite
  const acceptInviteHandler = async (contactId) => {
    try {
      let inviteData = {
        memberstatus: "1",
        connecteddate: getCurrentDateInString(),
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_ACCEPT_INVITE +
        `/${contactId}?token=${token}`;

      const response = await putData(requestURL, inviteData);

      if (response.status) {
        setReloadContactList(true);
        getAllInvites();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reject invite
  const rejectInviteHandler = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_REJECT_CONTACT_REQUEST +
        `?token=${token}&personid=${personId}`;

      const response = await deleteData(requestURL);

      console.log(response);

      if (response.status) {
        getAllInvites();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (reloadAlertList) {
      getTodaysAlerts();
      getOtherDaysAlerts();
      setReloadAlertList(false);
    }
  }, [reloadAlertList]);

  useEffect(() => {
    if (reloadRequestList) {
      getAllSentRequests();
      setreloadRequestList(false);
    }
  }, [reloadRequestList]);

  useEffect(() => {
    getTodaysAlerts();
    getOtherDaysAlerts();
    getAllInvites();
    getAllSentRequests();
    // i18n.changeLanguage("en");
  }, []);

  return (
    <header id="header">
      <nav className="navbar navbar-expand-lg bg-white shadow-sm">
        <div className="container-fluid px-lg-5">
          {/* ------ open left menu panel button start ------ */}
          <button
            className="p-0 text-black bg-transparent border-0 me-3"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMainNav"
            aria-controls="offcanvasMainNav"
          >
            <span className="d-block material-symbols-outlined fs-2">menu</span>
          </button>
          {/* ------ open left menu panel button end ------ */}

          {/* ------ logo section start ------ */}
          <Link
            className="navbar-brand p-0 me-3 me-md-4 me-lg-5"
            // href={
            //   url.FRONTEND_BASE_URL +
            //   `/auth/validate` +
            //   `?token=${token}&email=${userInfo?.email}`
            // }
            to="#"
          >
            {userInfo.companylogopath === "" ? (
              ecosystemLogo == "" ? (
                <img
                  src={assetImages.blankLogo}
                  alt="Logo"
                  className="img-fluid rounded"
                />
              ) : (
                <img
                  src={url.SERVER_URL + ecosystemLogo}
                  alt="Logo"
                  className="img-fluid"
                />
              )
            ) : (
              <img
                src={url.SERVER_URL + userInfo.companylogopath}
                alt="Logo"
                className="img-fluid"
              />
            )}
          </Link>
          {/* ------ logo section end ------ */}

          {/* ------ search form section start ------ */}
          {/* <form
            className="d-none d-md-block position-relative"
            role="search"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              className="form-control fs-sm body-bg px-3 border-0 rounded-10 shadow-none me-2"
              type="text"
              placeholder={t("Search")}
              aria-label="Search"
            />
            <button
              className="p-0 bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-3"
              type="submit"
            >
              <span className="d-block material-symbols-outlined">search</span>
            </button>
          </form> */}
          {/* ------ search form section end ------ */}

          {/* ------ user details section start ------ */}
          <ul className="navbar-nav flex-row align-items-center gap-3 ms-auto">
            <li className="nav-item friends">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#saveDirectAccess"
                className="position-relative"
              >
                <span className="d-block material-symbols-outlined">
                  bookmark
                </span>
              </Link>
            </li>
            {/* <!-- ========== Start connection list Section ========== --> */}
            <li className="nav-item dropdown notification">
              <Link
                to="#"
                className="dropdown-toggle position-relative"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-block material-symbols-outlined">group</span>
                <span className="has-notification d-block position-absolute end-0 bg-primary border border-2 border-white rounded-circle"></span>
              </Link>
              <ul className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg notification-dropdown border-0">
                <div className="tabs_links_wrap">
                  <div
                    className="nav nav-tabs body-bg"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <i className="material-symbols-outlined fs-lg">
                        {" "}
                        outbox{" "}
                      </i>
                      <span>Sent</span>
                    </button>
                    <button
                      className="nav-link fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <i className="material-symbols-outlined fs-lg"> inbox </i>
                      <span>Recived</span>
                    </button>
                  </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  {/* <!-- ========== Start sent request Section ========== --> */}
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                    tabIndex="0"
                  >
                    <div className="notify_wrapper">
                      {sentRequestList.length > 0 ? (
                        <div className="notify_innrscroll">
                          {sentRequestList.map((inviteData, index) => {
                            return (
                              <div
                                className="notify_row d-flex align-items-center gap-2 justify-content-between"
                                key={index}
                              >
                                <div className="notify_user_info d-flex align-items-center gap-2">
                                  <div className="notify_icon notify_user d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center gradient-light">
                                    <img
                                      height={30}
                                      width={30}
                                      className="rounded-circle object-fit-cover"
                                      src={
                                        inviteData.memberimage == ""
                                          ? assetImages.defaultUser
                                          : url.SERVER_URL +
                                            inviteData.memberimage
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="notify_text notify_user_info">
                                    <h4 className="text-black fs-sm mb-0 fw-semibold">
                                      {inviteData.membername}
                                    </h4>
                                    <h5 className="fs-sm text-gray mb-0">
                                      {inviteData.memberposition}
                                    </h5>
                                  </div>
                                </div>
                                <div className="notify_status d-flex align-items-center gap-2">
                                  <h6 className="fs-xs text-gray">
                                    {inviteData.memberstatus == "1"
                                      ? "Accepted"
                                      : "Pending"}
                                  </h6>
                                  <div className="notify_cross">
                                    <Link
                                      to="#"
                                      className={`${
                                        inviteData.memberstatus == "1"
                                          ? "text-success"
                                          : ""
                                      }`}
                                    >
                                      <span
                                        className={`material-symbols-outlined ${
                                          inviteData.memberstatus === "1"
                                            ? ""
                                            : "icon-fill"
                                        }`}
                                      >
                                        {inviteData.memberstatus == "1"
                                          ? "check_circle"
                                          : "cancel"}
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                          <img
                            height={150}
                            width={150}
                            src={assetImages.noAlert}
                            alt="noalert"
                          />
                          <h6 className="text-secondary fw-semibold">
                            {" "}
                            Sorry... there is no peding requests
                          </h6>
                        </div>
                      )}
                      <div className="refresh">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                          onClick={(e) => {
                            e.stopPropagation();
                            setreloadRequestList(true);
                          }}
                        >
                          <span className="material-symbols-outlined fs-lg">
                            {" "}
                            refresh{" "}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ========== End sent request Section ========== --> */}

                  {/* <!-- ========== Start invite list Section ========== --> */}
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                    tabIndex="0"
                  >
                    <div className="notify_wrapper">
                      {recievedInviteList.length > 0 ? (
                        <div className="notify_innrscroll">
                          {recievedInviteList.map((inviteData, index) => {
                            return (
                              <div
                                className="notify_row d-flex align-items-center gap-2 justify-content-between"
                                key={index}
                              >
                                <div className="notify_user_info d-flex align-items-center gap-2">
                                  <div className="notify_icon notify_user d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center gradient-light">
                                    <img
                                      height={30}
                                      width={30}
                                      className="rounded-circle object-fit-cover"
                                      src={
                                        inviteData.senderimage == ""
                                          ? assetImages.defaultUser
                                          : url.SERVER_URL +
                                            inviteData.senderimage
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="notify_text notify_user_info">
                                    <h4 className="text-black fs-sm mb-0 fw-semibold">
                                      {inviteData.sendername}
                                    </h4>
                                    <h5 className="fs-sm text-gray mb-0">
                                      {inviteData.senderposition}
                                    </h5>
                                  </div>
                                </div>
                                <div className="notify_status d-flex align-items-center gap-2">
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      acceptInviteHandler(inviteData._id);
                                    }}
                                    className="btn btn-outline-primary d-inline-flex align-items-center justify-content-center"
                                  >
                                    Accept
                                  </Link>
                                  <div className="notify_cross">
                                    <Link
                                      to="#"
                                      className=""
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        rejectInviteHandler(
                                          inviteData.owner._id
                                        );
                                      }}
                                    >
                                      <span className="material-symbols-outlined icon-fill">
                                        {" "}
                                        cancel{" "}
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                          <img
                            height={150}
                            width={150}
                            src={assetImages.noAlert}
                            alt="noalert"
                          />
                          <h6 className="text-secondary fw-semibold">
                            {" "}
                            Sorry... there is no peding invites
                          </h6>
                        </div>
                      )}
                      <div className="refresh">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                          onClick={(e) => {
                            e.stopPropagation();
                            getAllInvites();
                          }}
                        >
                          <span className="material-symbols-outlined fs-lg">
                            {" "}
                            refresh{" "}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ========== End invite list Section ========== --> */}
                </div>
              </ul>
            </li>
            {/* <!-- ========== End connection list Section ========== --> */}

            {/* <!-- ========== Start notification Section ========== --> */}
            <li className="nav-item dropdown notification">
              {/* <!-- ========== start notification button ========== --> */}
              <Link
                to="#"
                className="dropdown-toggle position-relative"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-block material-symbols-outlined">
                  notifications
                </span>
                <span className="has-notification d-block position-absolute end-0 bg-primary border border-2 border-white rounded-circle"></span>
              </Link>
              {/* <!-- ========== end notification button ========== --> */}

              {/* <!-- ========== start notification list ========== --> */}
              <ul className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg notification-dropdown border-0">
                <div className="tabs_links_wrap">
                  <div
                    className="nav nav-tabs body-bg"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className="nav-link active fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                      id="today-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#today"
                      type="button"
                      role="tab"
                      aria-controls="today"
                      aria-selected="true"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <i className="material-symbols-outlined fs-lg"> today </i>
                      <span>Today</span>
                    </button>
                    <button
                      className="nav-link fs-sm fw-semibold border-0 d-flex align-items-center gap-1 justify-content-center"
                      id="other-day-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#other-day"
                      type="button"
                      role="tab"
                      aria-controls="other-day"
                      aria-selected="false"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <i className="material-symbols-outlined fs-lg">
                        {" "}
                        calendar_month{" "}
                      </i>
                      <span>Other Day</span>
                    </button>
                  </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="today"
                    role="tabpanel"
                    aria-labelledby="today-tab"
                  >
                    <div className="notify_wrapper">
                      <div className="notify_innrscroll">
                        {todayUnDoneAlertList.length > 0 ? (
                          todayUnDoneAlertList.map((alert, index) => {
                            return (
                              <div
                                className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                  alert.isread ? "" : "body-bg"
                                }`}
                                key={index}
                              >
                                <div className="notify_text">
                                  <p className="fs-xs mb-0">
                                    {alert.alerttype}
                                  </p>
                                  <h4 className="text-black fs-sm mb-0">
                                    {alert.title}
                                  </h4>
                                  <h5 className="fs-sm text-gray mb-0">
                                    {getAlertDateFormat(alert.date)} at{" "}
                                    {alert.time}
                                  </h5>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    viewAndUpdateAlert(alert._id, true, true);
                                  }}
                                  className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                    alert.isread
                                      ? "gradient-light"
                                      : "bg-gray-300"
                                  } `}
                                >
                                  <span
                                    className={`material-symbols-outlined fs-lg icon-fill ${
                                      alert.isread
                                        ? "text-primary"
                                        : "text-gray"
                                    }`}
                                  >
                                    check
                                  </span>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    viewAndUpdateAlert(alert._id, false, false);
                                  }}
                                  className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                    alert.isread
                                      ? "gradient-light"
                                      : "bg-gray-300"
                                  }`}
                                >
                                  <span
                                    className={`material-symbols-outlined fs-lg icon-fill ${
                                      alert.isread
                                        ? "text-primary"
                                        : "text-gray"
                                    }`}
                                  >
                                    redo
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                            <img
                              height={150}
                              width={150}
                              src={assetImages.noAlert}
                              alt="noalert"
                            />
                            <h6 className="text-secondary fw-semibold">
                              {" "}
                              Sorry... there is no pending alert
                            </h6>
                          </div>
                        )}

                        {todayDoneAlertList.length === 0 ? null : (
                          <div className="task_complete_list dropdown">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              className="px-3 py-2 bg-primary text-white fw-semibold completed d-flex align-items-center justify-content-between"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              Completed{" "}
                              <span className="material-symbols-outlined  down_arw">
                                {" "}
                                keyboard_arrow_down{" "}
                              </span>
                            </Link>

                            <div className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg">
                              {todayDoneAlertList.map((alert, index) => {
                                return (
                                  <div
                                    className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                      alert.isread ? "" : "body-bg"
                                    }`}
                                    key={index}
                                  >
                                    <div className="notify_text">
                                      <p className="fs-xs mb-0">
                                        {alert.alerttype}
                                      </p>
                                      <h4 className="text-black fs-sm mb-0">
                                        <del>{alert.title}</del>
                                      </h4>
                                      <h5 className="fs-sm text-gray mb-0">
                                        {getAlertDateFormat(alert.date)} at{" "}
                                        {alert.time}
                                      </h5>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        viewAndUpdateAlert(
                                          alert._id,
                                          true,
                                          false
                                        );
                                      }}
                                      className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                        alert.isread
                                          ? "gradient-light"
                                          : "bg-gray-300"
                                      } `}
                                    >
                                      <span
                                        className={`material-symbols-outlined fs-lg icon-fill ${
                                          alert.isread
                                            ? "text-primary"
                                            : "text-gray"
                                        }`}
                                      >
                                        check
                                      </span>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        viewAndUpdateAlert(
                                          alert._id,
                                          false,
                                          false
                                        );
                                      }}
                                      className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                        alert.isread
                                          ? "gradient-light"
                                          : "bg-gray-300"
                                      } `}
                                    >
                                      <span
                                        className={`material-symbols-outlined fs-lg icon-fill ${
                                          alert.isread
                                            ? "text-primary"
                                            : "text-gray"
                                        }`}
                                      >
                                        redo
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="refresh">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                          onClick={(e) => {
                            e.stopPropagation();
                            setstartDate("");
                            setendDate("");
                            setReloadAlertList(true);
                          }}
                        >
                          <span className="material-symbols-outlined fs-lg">
                            {" "}
                            refresh{" "}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="other-day"
                    role="tabpanel"
                    aria-labelledby="other-day-tab"
                  >
                    <div className="notify_wrapper">
                      <div className="date_fill_outer d-flex align-items-center p-2 gap-2">
                        <input
                          type="date"
                          className="form-control bg-white border-0"
                          placeholder=""
                          value={startDate}
                          onChange={(e) => setstartDate(e.target.value)}
                        />
                        <input
                          type="date"
                          className="form-control bg-white border-0"
                          placeholder=""
                          value={endDate}
                          onChange={(e) => setendDate(e.target.value)}
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            getOtherDaysAlerts();
                          }}
                          className="apply_btn fs-md fw-semibold text-primary gap-1 d-flex align-items-center"
                        >
                          <i className="material-symbols-outlined fs-lg icon-fill text-primary">
                            {" "}
                            check{" "}
                          </i>
                          <span>Apply</span>
                        </button>
                      </div>
                      <div className="notify_innrscroll">
                        {otherDayUndoneAlertList.length > 0 ? (
                          otherDayUndoneAlertList.map((alert, index) => {
                            return (
                              <div
                                className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                  alert.isread ? "" : "body-bg"
                                }`}
                                key={index}
                              >
                                <div className="notify_text">
                                  <p className="fs-xs mb-0">
                                    {alert.alerttype}
                                  </p>
                                  <h4 className="text-black fs-sm mb-0">
                                    {alert.title}
                                  </h4>
                                  <h5 className="fs-sm text-gray mb-0">
                                    {getAlertDateFormat(alert.date)} at{" "}
                                    {alert.time}
                                  </h5>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    viewAndUpdateAlert(alert._id, true, true);
                                  }}
                                  className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                    alert.isread
                                      ? "gradient-light"
                                      : "bg-gray-300"
                                  } `}
                                >
                                  <span
                                    className={`material-symbols-outlined fs-lg icon-fill ${
                                      alert.isread
                                        ? "text-primary"
                                        : "text-gray"
                                    }`}
                                  >
                                    check
                                  </span>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    viewAndUpdateAlert(alert._id, false, false);
                                  }}
                                  className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                    alert.isread
                                      ? "gradient-light"
                                      : "bg-gray-300"
                                  }`}
                                >
                                  <span
                                    className={`material-symbols-outlined fs-lg icon-fill ${
                                      alert.isread
                                        ? "text-primary"
                                        : "text-gray"
                                    }`}
                                  >
                                    redo
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                            <img
                              height={150}
                              width={150}
                              src={assetImages.noAlert}
                              alt="noalert"
                            />
                            <h6 className="text-secondary fw-semibold">
                              {" "}
                              Sorry... there is no pending alert
                            </h6>
                          </div>
                        )}

                        {otherDayDoneAlertList.length === 0 ? null : (
                          <div className="task_complete_list dropdown">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              className="px-3 py-2 bg-primary text-white fw-semibold completed d-flex align-items-center justify-content-between"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              Completed{" "}
                              <span className="material-symbols-outlined  down_arw">
                                {" "}
                                keyboard_arrow_down{" "}
                              </span>
                            </Link>

                            <div className="dropdown-menu py-0 dropdown-menu-end bg-white rounded-15 shadow-lg">
                              {otherDayDoneAlertList.map((alert, index) => {
                                return (
                                  <div
                                    className={`notify_row  d-flex align-items-center gap-2 justify-content-between ${
                                      alert.isread ? "" : "body-bg"
                                    }`}
                                    key={index}
                                  >
                                    <div className="notify_text">
                                      <p className="fs-xs mb-0">
                                        {alert.alerttype}
                                      </p>
                                      <h4 className="text-black fs-sm mb-0">
                                        <del>{alert.title}</del>
                                      </h4>
                                      <h5 className="fs-sm text-gray mb-0">
                                        {getAlertDateFormat(alert.date)} at{" "}
                                        {alert.time}
                                      </h5>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        viewAndUpdateAlert(
                                          alert._id,
                                          true,
                                          false
                                        );
                                      }}
                                      className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                        alert.isread
                                          ? "gradient-light"
                                          : "bg-gray-300"
                                      }`}
                                    >
                                      <span
                                        className={`material-symbols-outlined fs-lg icon-fill ${
                                          alert.isread
                                            ? "text-primary"
                                            : "text-gray"
                                        }`}
                                      >
                                        check
                                      </span>
                                    </div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        viewAndUpdateAlert(
                                          alert._id,
                                          false,
                                          false
                                        );
                                      }}
                                      className={`notify_icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden text-center ${
                                        alert.isread
                                          ? "gradient-light"
                                          : "bg-gray-300"
                                      }`}
                                    >
                                      <span
                                        className={`material-symbols-outlined fs-lg icon-fill ${
                                          alert.isread
                                            ? "text-primary"
                                            : "text-gray"
                                        }`}
                                      >
                                        redo
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="refresh">
                        <Link
                          onClick={(e) => {
                            e.stopPropagation();
                            setstartDate("");
                            setendDate("");
                            setReloadAlertList(true);
                          }}
                          to="#"
                          className="d-flex align-items-center justify-content-center rounded-circle bg-white text-gray"
                        >
                          <span className="material-symbols-outlined fs-lg">
                            {" "}
                            refresh{" "}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              {/* <!-- ========== End notification list ========== --> */}
            </li>
            {/* <!-- ========== End notification Section ========== --> */}

            <li className="nav-item dropdown">
              {/* ------ user name and image section start ------ */}
              <Link
                className="nav-link dropdown-toggle p-0 h4 fw-semibold d-flex align-items-center gap-2 mb-0"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex gap-1">
                  <span>{t("Hello")}</span>
                  <span className="name text-black">
                    {userInfo.name} {userInfo.surname}
                  </span>
                </div>
                <div className="avatar rounded-circle overflow-hidden">
                  <img
                    src={
                      userInfo?.photoimage
                        ? url.SERVER_URL + userInfo?.photoimage?.path
                        : assetImages?.defaultUser
                    }
                    alt="user"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
              </Link>
              {/* ------ user name and image section end ------ */}

              {/* ------ dropdown section start ------ */}
              <ul className="dropdown-menu dropdown-menu-end p-0 pb-2 fw-semibold bg-white border-0 rounded-10 shadow-sm overflow-hidden">
                {/* ------ user name and image li start ------ */}
                <li className="gradient-light p-3 mb-2">
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="profile d-flex align-items-center gap-2">
                      <div className="avatar rounded-circle overflow-hidden">
                        <img
                          src={
                            userInfo?.photoimage
                              ? url.SERVER_URL + userInfo?.photoimage?.path
                              : assetImages?.defaultUser
                          }
                          alt="UserImage"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <p>
                        {userInfo.name} {userInfo.surname}
                      </p>
                    </div>
                    <Link
                      to="/logout"
                      className="logout bg-primary text-white d-flex align-items-center justify-content-center rounded-5"
                    >
                      <span className="d-block material-symbols-outlined">
                        logout
                      </span>
                    </Link>
                  </div>
                </li>
                {/* ------ user name and image li end ------ */}

                {/* ------ dasboard link start ------ */}
                <li className="px-3 py-2">
                  <Link
                    to="/admin/dashboard"
                    className="d-flex align-items-center gap-3 text-black active"
                    aria-current="page"
                  >
                    <span className="d-block material-symbols-outlined">
                      space_dashboard
                    </span>
                    <span className="d-block">{t("App")}</span>
                  </Link>
                </li>
                {/* ------ dasboard link end ------ */}

                {/* ------ conversation link start ------ */}
                <li className="px-3 py-2">
                  <Link
                    to={`/admin/appuser/profile/${userInfo._id}`}
                    className="d-flex align-items-center gap-3 text-black"
                  >
                    <span className="d-block material-symbols-outlined">
                      account_circle
                    </span>
                    <span className="d-block">{t("My Profile")}</span>
                  </Link>
                </li>
                {/* ------ conversation link end ------ */}

                {/* <!-- ========== Start calender Section ========== --> */}
                <li className="px-3 py-2">
                  <Link
                    to={`/admin/availabilitycalendar`}
                    className="d-flex align-items-center gap-3 text-black"
                  >
                    <span className="d-block material-symbols-outlined">
                      calendar_month
                    </span>
                    <span className="d-block">{t("My Calendar")}</span>
                  </Link>
                </li>
                {/* <!-- ========== End calender Section ========== --> */}

                <li className="px-3 py-2">
                  <ul className="language-selector d-flex gap-1 rounded-8 overflow-hidden">
                    <li
                      className={`language d-flex flex-fill gap-2 align-items-center justify-content-center body-bg p-2 cursor-pointer ${
                        i18n.language === "en" && "active"
                      } `}
                      onClick={(e) => {
                        e.stopPropagation();
                        i18n.changeLanguage("en");
                      }}
                    >
                      <Link
                        to="#"
                        className="icon rounded-circle overflow-hidden"
                        style={{ width: "1rem", height: "1rem" }}
                      >
                        <img
                          src={assetImages.EN}
                          alt="En"
                          className="d-block w-100 h-100 object-fit-cover object-center"
                        />
                      </Link>
                      <span className="d-block">En</span>
                    </li>
                    <li
                      className={`language d-flex flex-fill gap-2 align-items-center justify-content-center body-bg p-2 cursor-pointer ${
                        i18n.language === "es" && "active"
                      } `}
                      onClick={(e) => {
                        e.stopPropagation();
                        i18n.changeLanguage("es");
                      }}
                    >
                      <Link
                        to="#"
                        className="icon rounded-circle overflow-hidden"
                        style={{ width: "1rem", height: "1rem" }}
                      >
                        <img
                          src={assetImages.ES}
                          alt="Es"
                          className="d-block w-100 h-100 object-fit-cover object-center"
                        />
                      </Link>
                      <span className="d-block">Es</span>
                    </li>
                    <li
                      className={`language d-flex flex-fill gap-2 align-items-center justify-content-center body-bg p-2 cursor-pointer ${
                        i18n.language === "ca" && "active"
                      } `}
                      onClick={(e) => {
                        e.stopPropagation();
                        i18n.changeLanguage("ca");
                      }}
                    >
                      <Link
                        to="#"
                        className="icon rounded-circle overflow-hidden"
                        style={{ width: "1rem", height: "1rem" }}
                      >
                        <img
                          src={assetImages.ES}
                          alt="Cat"
                          className="d-block w-100 h-100 object-fit-cover object-center"
                        />
                      </Link>
                      <span className="d-block">Cat</span>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* ------ dropdown section end ------ */}
            </li>
          </ul>
          {/* ------ user details section start ------ */}
        </div>
      </nav>
      {/* ------ import form popup folder ------ */}
      <LeftMenuPanelPopup moduleName={moduleName} userInfo={userInfo} />

      <SaveDirectAccessModal />
    </header>
  );
};

export default Header;
