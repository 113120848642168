/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

import { useTranslation } from "react-i18next";

import CrmContactDetailsCommonHeader from "../../common/CrmContactDetailsCommonHeader";
import CrmContactTimeLineLeftPanel from "../LeftPanel/CrmContactTimeLineLeftPanel";
import CrmContactTimeLineFilterPopup from "../Popup/CrmContactTimeLineFilterPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import NoteSection from "components/Common/NoteComponent/NoteSection";

const CrmContactDetailsTimeLineBody = () => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // for translations
  const params = useParams();
  const moduleAccess = localStorage.getItem("moduleaccess");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [contactName, setContactName] = useState("");
  const [contactImage, setContactImage] = useState("");
  const [contactPersonId, setContactPersonId] = useState(null);
  const [timeLinesData, settimeLinesData] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get contact details
  const getDetailsContact = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CONTACT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status && response.data) {
        setContactName(response.data.contactname);
        setContactImage(response.data.contactimage);
        setContactPersonId(response.data.contactpersonid);
        if (response.data.contactpersonid) {
          getContactTimeLine(response.data.contactpersonid);
        }
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");

      setShowAlert(true);
    }
  };

  //function for get contact timeline
  const getContactTimeLine = async (personId) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CONTACT_TIMELINE +
        `/${params.id}?token=${token}&personid=${personId}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        settimeLinesData(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  /**
   * In this case, the useEffect is used to fetch data and update the state when the 'id'
   * in the URL parameters changes.
   *
   * The 'params.id' is the dependency of the useEffect hook. Whenever the 'id' changes,
   * the useEffect is triggered.
   *
   * The 'useEffect' is called with a dependency array as the second argument.
   * The dependency array is used to determine when to re-run the effect.
   * In this case, the dependency array is [params.id], which means the effect will
   * only be re-run when the 'id' in the URL parameters changes.
   */

  useEffect(() => {
    /**
     * getDetailsContact is a function that is responsible for fetching the
     * details of a contact.
     *
     * It is called inside the useEffect hook whenever the 'id' in the URL
     * parameters changes.
     */
    if (params.id) {
      getDetailsContact();
    }

    /**
     * The 'if (params.id)' condition is used to check if the 'id' in the
     * URL parameters is truthy before calling the getDetailsContact function.
     *
     * This is to prevent the getDetailsContact function from being called
     * unnecessarily when the 'id' is empty or falsy.
     */
  }, [params.id]);

  /**
   * onAlertClose is a function that is responsible for
   * closing the alert. It is called when the user clicks
   * on the 'x' button to dismiss the alert.
   *
   * This function updates the state variables to hide
   * the alert and reset the message and type.
   *
   * @return {void} This function does not return anything.
   */
  const onAlertClose = () => {
    // update the state variable 'showAlert' to hide the alert
    setShowAlert(false);

    // reset the state variable 'alertMessage' to clear the message
    setAlertMessage("");

    // reset the state variable 'messageType' to clear the type
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_CONTACT"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-contact-timeline-wrapper bg-white pb-5">
          <CrmContactDetailsCommonHeader
            moduleName="timeline"
            contactImage={contactImage}
            contactName={contactName}
          />
          <div className="container-fluid px-lg-5">
            {/* ---- filter section start ---- */}
            <div className="filter-container py-3">
              <div className="d-flex justify-content-end gap-1 gap-sm-2">
                <Link
                  to="#"
                  className="btn btn-gray d-flex align-items-center"
                  onClick={() => {
                    getContactTimeLine(contactPersonId);
                  }}
                >
                  <span className="d-block material-symbols-outlined icon-md">
                    refresh
                  </span>
                </Link>
              </div>
            </div>
            {/* ---- filter section end ---- */}
            <div className="row">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <CrmContactTimeLineLeftPanel timeLinesData={timeLinesData} />
              </div>
              <div className="col-lg-4">
                <NoteSection
                  noteAccessUserList={[]}
                  contactId={params.id}
                  setShowAlert={setShowAlert}
                  setAlertMessage={setAlertMessage}
                  setMessageType={setMessageType}
                />
              </div>
            </div>
          </div>
          {/* ----- filter popup ----- */}
          <CrmContactTimeLineFilterPopup />
        </section>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CrmContactDetailsTimeLineBody;
