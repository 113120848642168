import ChallengeResponsesDetailsBody from "components/ChallengeComponents/ChallengeDetailsComponents/ChallengeResponse/Details/ChallengeResponseDetailsBody";
import Header from "components/Common/Header/Header";
import React from "react";

const ChallengeResponseDetails = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="challenges" />

      {/* ---------- body section ------------- */}
      <ChallengeResponsesDetailsBody />
    </main>
  );
};

export default ChallengeResponseDetails;