/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Material UI table
import { MaterialReactTable } from "material-react-table";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { formatNumber } from "helper/Common/CommonHelper";
import { assetImages } from "constants";

const PavonActivityTab = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,
  zipcodes,
  operationType,

  applyFilter,
  refreshData,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");


  const [totalTickets, setTotalTickets] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [baseIncome, setBaseIncome] = useState(0);
  const [organizerSurcharge, setOrganizerSurcharge] = useState(0);
  
    // is loading
    const [ticketIncomeLoading, setTicketIncomeLoading] = useState(false);
    const [totalIncomeLoading, setTotalIncomeLoading] = useState(false);
    const [newCustomerLoading, setNewCustomerLoading] = useState(false);
    const [organizationSurchargeLoading, setOrganizationSurchargeLoading] = useState(false);


  const [incomeByEvents, setIncomeByEvents] = useState([]);
  const [salesByChannel, setSalesByChannel] = useState([]);
  const [salesByZone, setSalesByZone] = useState([]);
  const [incomeByZipcode, setIncomeByZipcode] = useState([]);

  const [pieDataChannel, setPieDataChannel] = useState([]);
  const [pieDataZone, setPieDataZone] = useState([]);
  const [pieDataCustomer, setPieDataCustomer] = useState([]);
  const [pieDataZipcode, setPieDataZipcode] = useState([]);

  const [miltiTicketsDiffDate, setMultiTicketsDiffDate] = useState(0);

  const [ticketsWithUniqueEmails, setTicketsWithUniqueEmails] = useState(0);
  const [ticketsWithZipcode, setTicketsWithZipcode] = useState(0);
  const [ticketsInMadrid, setTicketsInMadrid] = useState(0);
  
  const [customersWithEmail, setCustomersWithEmail] = useState(0);
  const [customersWithZipcode, setCustomersWithZipcode] = useState(0);
  const [customersFromMadrid, setCustomersFromMadrid] = useState(0);

  const [uniqueCustomerCount, setUniqueCustomerCount] = useState(0);
  const [repeatCustomerCount, setRepeatCustomerCount] = useState(0);
  const [allCustomerCount, setAllCustomerCount] = useState(0);
  const [organizationSurcharge, setOrganizationSurcharge] = useState(0);

  // graphs and charts states - Events Tickets Sold
    const [salesByEventUData, setSalesByEventUData] = useState([]);
    const [salesByEventXLabels, setSalesByEventXLabels] = useState([]);

  // column - income by event
  const columnsIncomeEvent = useMemo(
    () => [
      { accessorKey: "event", header: "Event" },
      {
        accessorKey: "ticketCount",
        header: "Tickets",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} Euro</span>
        ),
      },
    ],
    []
  );

  // column - sales by channel
  const columnsSalesByChannel = useMemo(
    () => [
      { accessorKey: "channel", header: "Channel" },
    //   {
    //     accessorKey: "ticketCount",
    //     header: "Tickets",
    //     Cell: ({ renderedCellValue, row }) => (
    //       <span>{formatNumber(renderedCellValue)}</span>
    //     ),
    //   },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} Euro</span>
        ),
      },
    ],
    []
  );

  // column - sales by zone
  const columnsSalesByZone = useMemo(
    () => [
      { accessorKey: "zone", header: "Zone" },
    //   {
    //     accessorKey: "ticketCount",
    //     header: "Tickets",
    //     Cell: ({ renderedCellValue, row }) => (
    //       <span>{formatNumber(renderedCellValue)}</span>
    //     ),
    //   },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} Euro</span>
        ),
      },
    ],
    []
  );

  // column - income by zipcode
  const columnsIncomeByZipcode = useMemo(
    () => [
      { accessorKey: "zipcode", header: "Zipcode" },
      {
        accessorKey: "income",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} Euro</span>
        ),
      },
    ],
    []
  );

  // structure the params received from filter in url params
  const getFilterParamsInUrl = () => {

    console.log('applyFilter in getFIlterParamsInUrl', applyFilter);

    let urlParams = "";

    if (applyFilter === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }

    if (operationStartDate !== "" && operationEndDate !== "") {
      urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
    }

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    if (zipcodes && zipcodes.length > 0) {
      zipcodes.forEach((data) => {
        urlParams += `&filterzipcode[]=${encodeURIComponent(data)}`;
      });
    }


    if (operationType && operationType.length > 0) {
      operationType.forEach((data) => {
        urlParams += `&filteroperationtype[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };

  //reset all states
  const resetHandler = () => {
    //setApplyFilter(false);
    setTotalTickets(0);
    setTotalIncome(0);
    setIncomeByEvents([]);
    setSalesByChannel([]);
    setSalesByZone([]);
    setPieDataChannel([]);
    setPieDataZone([]);
  };

  // API Request --- get tickets and income ----------------------------
  const getTicketsAndIncome = async () => {
    try {
      setTicketIncomeLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_INCOME +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log("getTicketsAndIncome", requestUrl);

      const response = await getData(requestUrl);

      console.log("Tickets and Income response >>", response);

      if (response.status) {
        setTotalTickets(formatNumber(response.data.ticketCount));

        setTotalIncome(formatNumber(response.data.totalFinalPrice));

        setBaseIncome(formatNumber(response.data.totalBasePrice))

        //setOrganizerSurcharge(formatNumber(response.data.totalOrganizerSurcharge))
      }

      setTicketIncomeLoading(false);

      //console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get income by events ----------------------------
  const getIncomeByEvent = async () => {
    try {

      setTotalIncomeLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_EVENT_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log("income by event", requestUrl);

      const response = await getData(requestUrl);

      console.log("Income by event response", response);

      setTotalIncomeLoading(false);

      if (response.status) {
        setIncomeByEvents(response.data);

        const processedData = await processData(response.data);

        //console.log('processedData', processedData);

        setSalesByEventUData(processedData.uData);
        setSalesByEventXLabels(processedData.xLabels);

        //console.log('setSalesByEventUData', processedData.uData)
      }

      
    } catch (error) {
      console.log(error.message);
    }
  };

  const processData = (data) => {
        // Sort data by ticketCount in descending order and get the top 20 records
        const sortedData = data.sort((a, b) => b.ticketCount - a.ticketCount).slice(0, 20);
        const uData = sortedData.map(item => item.ticketCount);
        const xLabels = sortedData.map(item => item.event);
        return { uData, xLabels };
    };

  // API Request --- get sales by channel ----------------------------
  const getSalesByChannel = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_CHANNEL_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setSalesByChannel(response.data);

        const channelPData = response.data
          .map((item, index) => ({
            id: index,
            value: parseFloat(item.totalIncome),
            label: item.channel ? item.channel.slice(0, 10) : "NULL",
          }))
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 5);

        setPieDataChannel(channelPData);
      }

      console.log("Income Channel response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get sales by zone ----------------------------
  const getSalesByZone = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_ZONE_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setSalesByZone(response.data);

        const zonePData = response.data
          .map((item, index) => ({
            id: index,
            value: parseFloat(item.totalIncome),
            label: item.zone ? item.zone.slice(0, 10) : "NULL",
          }))
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 5);

        setPieDataZone(zonePData);
      }

      console.log("InCome Zone response >>", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get income by zipcode
  const getIncomeByZipcode = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_INCOME_ZIPCODE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log('getIncomeByZipcode', requestUrl);

      const response = await getData(requestUrl);

        console.log('Income Zipcode response >>', response);

      if (response.status) {
        setIncomeByZipcode(response.data);

        const zipcodePData = response.data
          .map((item, index) => ({
            id: index,
            value: parseFloat(item.income),
            label: item.zipcode,
          }))
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 5);

        setPieDataZipcode(zipcodePData);

      }

    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get unique customers count
  const getUniqueCustomers = async () => {
    try {

        setNewCustomerLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_COUNT_UNIQUE_CUSTOMER +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log('getUniqueCustomers', requestUrl);

      const response = await getData(requestUrl);

        console.log('New Customers response >>', response);
        setNewCustomerLoading(false);

      if (response.status) {
        setUniqueCustomerCount( formatNumber(response.data?.uniqueCustomerCount??0));
      }

    } catch (error) {
      console.log(error.message);
    }
  };

  // get all customer count
  const getAllCustomers = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_COUNT_ALL_CUSTOMER +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log('getAllCustomers', requestUrl);

      const response = await getData(requestUrl);

        console.log('getAllCustomers response >>', response);

      if (response.status) {
        setAllCustomerCount(response.data?.totalCustomerCount??0);
      }

    } catch (error) {
      console.log(error.message);
    }
  };

  // get all customer count
  const getOrganizationSurcharge = async () => {

    try {
        setOrganizationSurchargeLoading(true);

      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_ORGANIZATION_SURCHARGE +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      //console.log('OrganizationSurcharge', requestUrl);

      const response = await getData(requestUrl);

       console.log('OrganizationSurcharge response >>', response);

      if (response.status) {
        setOrganizationSurcharge(formatNumber(response.data?.totalSurcharge??0));
      }

      setOrganizationSurchargeLoading(false);

    } catch (error) {
      console.log(error.message);
    }
  };

  // call all api on intial loading
  useEffect(() => {
    
    console.log("default triggred DashboardActivityTab >>>", applyFilter);
    getTicketsAndIncome();
    getIncomeByEvent();
    getSalesByChannel();
    getSalesByZone();
    getIncomeByZipcode();
    getUniqueCustomers();
    getAllCustomers();
    getOrganizationSurcharge();

  }, []);


  // call all api if filter is applied again
  useEffect(() => {

      console.log('Refresh filter', refreshData);
      console.log('apply filter', applyFilter);


      // call api to get filtered data
        getTicketsAndIncome();
        getIncomeByEvent();
        getSalesByChannel();
        getSalesByZone();
        getIncomeByZipcode();
        getUniqueCustomers();
        getAllCustomers();
        getOrganizationSurcharge();

  }, [startDate, 
      endDate,
      operationStartDate, 
      operationEndDate,
      channels, 
      organizations, 
      enclosures, 
      sectors,
      eventTypes,
      events,
      operationType,
      channels,
      zones,
      zipcodes
    ]);

    // generate the pie chart when we get customer count
    useEffect(()=>{

        if(allCustomerCount && uniqueCustomerCount){

            const repeatCustomer = parseInt(allCustomerCount) - parseInt(uniqueCustomerCount);
            setRepeatCustomerCount(repeatCustomer);

            const customerChartData = [
                { label: 'New', value: uniqueCustomerCount },
                { label: 'Repeat', value: repeatCustomer },
            ];

            setPieDataCustomer(customerChartData);
        }

    }, [allCustomerCount, uniqueCustomerCount])

  return (
        
            <div className="dashboard_innr">
                <div className="container-fluid px-lg-4">
                    {/* KPIs on card */}
                    <div className="dashboard_top bg-white shadow-sm p-4 d-flex gap-4 rounded-10 mb-4">
                        <div className="col">
                            <div className="activity_item d-flex align-items-center gap-2">
                                <div className="activity_info">
                                    <h6 className="fs-sm fw-semibold text-gray mb-2">Tickets</h6>
                                    <h3 className="text-dark fs-xl fw-semibold mb-1">
                                        { ticketIncomeLoading ? 
                   
                                        (<span
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                        >
                                        <i className="visually-hidden">Loading...</i>
                                        </span>)
                                        
                                        :   totalTickets
                                        }                                        
                                        <span className="fs-sm ms-2">purchased</span>
                                        
                                    </h3>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div className="activity_item d-flex align-items-center gap-2">
                                <div className="activity_info">
                                    <h6 className="fs-sm fw-semibold text-gray mb-2">Base Price</h6>
                                    <h3 className="text-dark fs-xl fw-semibold mb-1">
                                        { ticketIncomeLoading ? 
                   
                                        (<span
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                        >
                                        <i className="visually-hidden">Loading...</i>
                                        </span>)
                                        
                                        :   baseIncome
                                        }                                        
                                        <span className="fs-sm ms-2">Euro</span>
                                        
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="activity_item d-flex align-items-center gap-2">
                                <div className="activity_info">
                                    <h6 className="fs-sm fw-semibold text-gray mb-2">Income</h6>
                                    <h3 className="text-dark fs-xl fw-semibold mb-1">
                                        { totalIncomeLoading ? 
                   
                                        (<span
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                        >
                                        <i className="visually-hidden">Loading...</i>
                                        </span>)
                                        
                                        :   totalIncome
                                        }                                        
                                        <span className="fs-sm ms-2">Euro</span>
                                        
                                    </h3>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col">
                            <div className="activity_item d-flex align-items-center gap-2">
                                <div className="activity_info">
                                    <h6 className="fs-sm fw-semibold text-gray mb-2">New Customers</h6>
                                    <h3 className="text-dark fs-xl fw-semibold mb-1">
                                        { newCustomerLoading ? 
                   
                                        (<span
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                        >
                                        <i className="visually-hidden">Loading...</i>
                                        </span>)
                                        
                                        :   uniqueCustomerCount
                                        } 
                                        
                                    </h3>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col">
                            <div className="activity_item d-flex align-items-center gap-2">
                                <div className="activity_info">
                                    <h6 className="fs-sm fw-semibold text-gray mb-2">Organization Surcharge</h6>
                                    <h3 className="text-dark fs-xl fw-semibold mb-1">
                                        { organizationSurchargeLoading ? 
                   
                                        (<span
                                        className="mx-2 spinner-border spinner-border-sm"
                                        role="status"
                                        >
                                        <i className="visually-hidden">Loading...</i>
                                        </span>)
                                        
                                        :   organizationSurcharge
                                        }                                        
                                        <span className="fs-sm ms-2">Euro</span>
                                        
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Events sales graph */}
                    <div className="event_graph p-4 rounded-10 shadow-sm bg-white mb-4">
                        <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                            <h3 className="fs-lg text-black mb-0 fw-semibold">Events Tickets Sold</h3>
                            {/* <div className="form-group">
                                <select className="form-select graph_select fs-sm shadow-none">
                                    <option value="">Select</option>
                                    <option value="">2024</option>
                                    <option value="">2023</option>
                                    <option value="">2022</option>
                                </select>
                            </div> */}
                        </div>
                        
                        {salesByEventUData.length > 0 ?
                            <BarChart
                                height={300}
                                series={[
                                    { data: salesByEventUData,  },
                                ]}
                                xAxis={[{ data: salesByEventXLabels, scaleType: 'band' }]}
                            />
                        :''}
                        

                    </div>
                        
                    <div className="graph_outer">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6 mb-3">
                                <div className="graph_bx h-100 bg-white rounded-10 shadow-none p-3">
                                    <h6 className="text-black fs-sm mb-2 fw-semibold">Sales By Channel</h6>

                                    <div className="graph_pic  d-flex align-items-center justify-content-center text-center mb-3">
                                        
                                        <PieChart
                                            series={[
                                            {   
                                                data: pieDataChannel,
                                              
                                                innerRadius: 60,
                                                outerRadius: 120,
                                            },
                                            ]}
                                            width={400}
                                            height={200}
                                        />
                                    </div>
                                    
                                    <div className="graph_info d-flex flex-wrap justify-content-between gap-2" style={{ overflow: 'hidden' }}>
                                        <MaterialReactTable
                                            columns={columnsSalesByChannel} // map columns to be displayed with api data,
                                            data={salesByChannel} // data from api to be displayed
                                            //enableBottomToolbar={false}
                                            //enableGlobalFilterModes={false}
                                            enablePagination={true}
                                            enableRowNumbers={false}
                                            //enableRowVirtualization={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 mb-3">
                                <div className="graph_bx h-100 bg-white rounded-10 shadow-none p-3">
                                    <h6 className="text-black fs-sm mb-2 fw-semibold">Sales By Zone</h6>
                                    <div className="graph_pic  d-flex align-items-center justify-content-center text-center mb-3">
                                        <PieChart
                                            series={[
                                            {
                                                data: pieDataZone,
                                                innerRadius: 60,
                                                outerRadius: 120,
                                            },
                                            ]}
                                            width={400}
                                            height={200}
                                        />
                                    </div>
                                    
                                    <div className="graph_info d-flex flex-wrap justify-content-between gap-2"  style={{ overflow: 'hidden' }}>
                                        <MaterialReactTable
                                            columns={columnsSalesByZone} // map columns to be displayed with api data,
                                            data={salesByZone} // data from api to be displayed
                                            enablePagination={true}
                                            enableRowNumbers={false}
                                            />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-3 col-sm-6 mb-3">
                                <div className="graph_bx h-100 bg-white rounded-10 shadow-none p-3">
                                    <h6 className="text-black fs-sm mb-2 fw-semibold">Customer Ratio (New/Repeat)</h6>

                                    <div className="graph_pic  d-flex align-items-center justify-content-center text-center mb-3">
                                        
                                        <PieChart
                                            series={[
                                            {   
                                                data: pieDataCustomer,
                                              
                                                innerRadius: 60,
                                                outerRadius: 120,
                                            },
                                            ]}
                                            width={400}
                                            height={200}
                                        />
                                    </div>
                                    
                                    <div className="graph_info d-flex flex-wrap justify-content-between gap-2">
                                        <div className="graph_info_item body-bg rounded-5 py-2 px-3 w-100  d-flex align-items-center justify-content-between gap-3 w-100">
                                            
                                            {repeatCustomerCount}
                                            <h6 className="text-black fs-sm mb-0">Repeat Customer</h6>
                                        </div>
                                        <div className="graph_info_item body-bg rounded-5 py-2 px-3  d-flex align-items-center justify-content-between gap-3 w-100">
                                         
                                            {uniqueCustomerCount}
                                            <h6 className="text-black fs-sm mb-0">New Customer</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Income by zipcode *********************************************** */}
                            <div className="col-xl-3 col-sm-6 mb-3">
                                <div className="graph_bx h-100 bg-white rounded-10 shadow-none p-3">
                                    <h6 className="text-black fs-sm mb-2 fw-semibold">Income by Postal Code</h6>
                                    <div className="graph_pic  d-flex align-items-center justify-content-center text-center mb-3">
                                        <PieChart
                                            series={[
                                            {
                                                data: pieDataZipcode,
                                                innerRadius: 60,
                                                outerRadius: 120,
                                            },
                                            ]}
                                            width={400}
                                            height={200}
                                        />
                                    </div>
                                    
                                    <div className="graph_info d-flex flex-wrap justify-content-between gap-2"  style={{ overflow: 'hidden' }}>
                                        <MaterialReactTable
                                            columns={columnsIncomeByZipcode} // map columns to be displayed with api data,
                                            data={incomeByZipcode} // data from api to be displayed
                                            enablePagination={true}
                                            enableRowNumbers={false}
                                            />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
       
  );
};

export default PavonActivityTab;
