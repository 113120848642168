/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const ProjectListHeader = ({
  userInfo,
  moduleAccess,
  reloadList,
  updateRankHandler,
  projectList = [],
  followingProjectCount = 0,
  totalTaskCount = 0,
  totalLogsCount = 0,
}) => {
  const { t } = useTranslation(); //for translation
  const breadcrumbTxt = [{ title: t("Projects") }];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-4 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          {/* ------ bread crumb ----------- */}
          <BreadCrumb breadCrumbText={breadcrumbTxt} />
        </div>
        <div className="col-md-8 col-lg-9 d-flex justify-content-end gap-1 gap-sm-2">
          <ul className="d-flex align-items-center">
            <li className="me-2" role="presentation">
              Projects :{" "}
              <span className="text-primary">{projectList.length}</span>
            </li>
            {/* <li className="me-2" role="presentation">
              Following :{" "}
              <span className="text-primary">{followingProjectCount}</span>
            </li> */}

            <li className="me-2" role="presentation">
              Tasks : <span className="text-primary">{totalTaskCount}</span>
            </li>

            <li className="me-2" role="presentation">
              Logs : <span className="text-primary">{totalLogsCount}</span>
            </li>
          </ul>
          {/* -------------- refresh ----------------- */}
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* ----- update rank section start -----  */}
              {userInfo.role.slug === "ADMIN" ||
              userInfo.role.slug === "SUPER_ADMIN" ||
              (moduleAccess.includes("MOD_PROJECT") &&
                moduleAccess.includes("MOD_CREATE_PROJECT")) ? (
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-1"
                    onClick={updateRankHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      progress_activity
                    </span>
                    <span className="d-block">{t("Update Rank")}</span>
                  </Link>
                </li>
              ) : null}
              {/* ----- update rank section end -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              {userInfo.role.slug === "ADMIN" ||
              userInfo.role.slug === "SUPER_ADMIN" ||
              (moduleAccess.includes("MOD_PROJECT") &&
                moduleAccess.includes("MOD_CREATE_PROJECT")) ? (
                <li>
                  <Link
                    // onClick={changeStatusHandler}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#projectWarningDeleteModal"
                    className="dropdown-item d-flex align-items-center gap-1"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      delete
                    </span>
                    <span className="d-block">{t("Delete")}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasProjectFilter"
            aria-controls="offcanvasProjectFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          (moduleAccess.includes("MOD_PROJECT") &&
            moduleAccess.includes("MOD_CREATE_PROJECT")) ? (
            <Link
              to="/admin/projectmanagement/details"
              className="btn btn-primary d-flex align-items-center gap-1"
            >
              <span className="d-block material-symbols-outlined icon-md">
                add
              </span>
              <span className="d-block">{t("Add New")}</span>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectListHeader;
