/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

// import for excel download
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import { assetImages } from "constants";

import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

import LogFilterPopup from "../Popup/LogFilterPopup";
import SaveLogPopup from "../Popup/SaveLogPopup";
import LogListHeader from "../Header/LogListHeader";

const AllLogListBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation
  // -------- breadcrumb text --------
  const breadCrumbText = [{ title: t("Logs") }];

  const [tagList, setTagList] = useState([]);
  const [taskList, settaskList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [logList, setLogList] = useState([]);

  const [totalLogHours, settotalLogHours] = useState(0);
  const [totalLogCosts, settotalLogCosts] = useState(0);

  const [selectLogIds, setSelectLogIds] = useState([]);
  const [selectedLogId, setSelectedLogId] = useState(null);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  const [filterUserIds, setfilterUserIds] = useState([]);
  const [filterProjectIds, setfilterProjectIds] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // download to excel
  const [excelData, setExcelData] = useState([]);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all task
  const getAllTasks = async () => {
    try {
      let isModerator = false;

      if (
        userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN"
      ) {
        isModerator = true;
      }

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&ismoderator=${isModerator}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        settaskList(
          response.data.map((taskData) => ({
            label: taskData.title,
            value: taskData._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all section with task
  const getAllProjects = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_PROJECTS + `?token=${token}`;

      const response = await getData(requestUrl);

      setProjectList(
        response.data.map((sectionData) => ({
          label: sectionData.title,
          value: sectionData._id,
          startdate: sectionData.startdate,
          enddate: sectionData.enddate,
        }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    setRowSelection({});
    setSelectLogIds([]);

    try {
      let isModerator = false;

      if (
        userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN"
      ) {
        isModerator = true;
      }

      setisLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&ismoderator=${isModerator}`;

      if (filterStartDate != "") {
        requestUrl = requestUrl + `&filterstartdate=${filterStartDate}`;
      }

      if (filterEndDate != "") {
        requestUrl = requestUrl + `&filterenddate=${filterEndDate}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      if (filterUserIds.length > 0) {
        requestUrl = requestUrl + `&filterlogusers=${filterUserIds}`;
      }

      if (filterProjectIds.length > 0) {
        requestUrl = requestUrl + `&filterlogprojects=${filterProjectIds}`;
      }

      console.log("request url", requestUrl);

      const response = await getData(requestUrl);

      console.log("response", response);

      setisLoading(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setLogList(response.data);

        const totalHours = response.data.reduce(
          (acc, curr) => acc + curr.hours,
          0
        );

        settotalLogHours(totalHours);

        const totalPrice = response.data.reduce(
          (acc, curr) => acc + curr.price,
          0
        );

        settotalLogCosts(totalPrice);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectLogIds) {
      try {
        let statusData = {
          status: "0",
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_TASK_LOG +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getTaskLogs();
  };

  //function for reset filter
  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setFilterTags([]);
    setfilterUserIds([]);
    setfilterProjectIds([]);

    setReloadData(true);
    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  //download as excel
  const downloadAsExcel = () => {
    if (logList.length > 0) {
      const createRowData = (apiData) => [
        apiData.projectname,
        apiData.sectionname,
        apiData.tasktitle,
        apiData.projectmoderator,
        apiData.username,
        apiData.startdate,
        apiData.hours,
        apiData.price,
        apiData.taskpercent,
        apiData.taskstatus,
        apiData.tagstring,
        apiData.description.length > 32000
          ? "Too large to display"
          : apiData.description,
      ];

      const filteredLogList =
        selectLogIds.length > 0
          ? logList.filter((logItem) => selectLogIds.includes(logItem._id))
          : logList;

      filteredLogList.map((apiData) => {
        const rowData = createRowData(apiData);
        setExcelData((prevState) => [...prevState, rowData]);
      });
    }
  };

  const createExcel = () => {
    const fileName = "logs";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const Heading = [
      [
        "Project",
        "Section",
        "Task",
        "Owner",
        "Provider",
        "Date",
        "Hours",
        "Price",
        "% Complete",
        "Status",
        "Label",
        "Task Description",
      ],
    ];
    const ws = XLSX.utils.json_to_sheet(excelData, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const filedata = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(filedata, fileName + fileExtension);
  };

  useEffect(() => {
    if (excelData.length > 0) {
      createExcel();
      setExcelData([]);
    }
  }, [excelData]);

  // download excel ends

  useEffect(() => {
    getAllTags();
    getAllTasks();
    getAllProjects();
    // getTaskLogs();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#saveLog"
          aria-controls="saveLog"
          onClick={() => {
            setSelectedLogId(row.original._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "customid",
      header: t("Id"),
    },
    {
      accessorKey: "taskcustomid",
      header: t("Task Id"),
      size: 100,

      Cell: ({ row }) => (
        <Box className="title border-bottom-0">
          <div className="d-flex align-items-center gap-4">
            <Link
              to={`/admin/task/details/${row.original.projecttaskid}`}
              className="d-flex align-items-center gap-1 fs-md"
            >
              {row.original.taskcustomid}
            </Link>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
    // {
    //   accessorKey: "description",
    //   header: "Log",
    // },
    {
      accessorKey: "tasktitle",
      header: t("Task"),
      size: 250,
    },
    {
      accessorKey: "projectname",
      header: t("Project"),
    },
    {
      accessorKey: "leadtitle",
      header: t("Lead"),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0">
          <div className="d-flex align-items-center gap-4">
            <Link
              to={
                row.original.leadid
                  ? `/admin/crm/lead/information/${row.original.leadid}`
                  : "#"
              }
              className="d-flex align-items-center gap-1 fs-md"
            >
              {row.original.leadtitle}
            </Link>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "contactname",
      header: t("Contact"),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0">
          <div className="d-flex align-items-center gap-4">
            <Link
              to={
                row.original.contactid
                  ? `/admin/crm/contact/details/timeline/${row.original.contactid}`
                  : "#"
              }
              className="d-flex align-items-center gap-1 fs-md"
            >
              {row.original.contactname}
            </Link>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "username",
      header: t("Log Added By"),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0">
          <div className="d-flex align-items-center gap-4">
            <Link
              to={
                row.original.logownerid
                  ? `/admin/mytasklist/${row.original.logownerid}`
                  : "#"
              }
              className="d-flex align-items-center gap-1 fs-md"
            >
              {row.original.username}
            </Link>
          </div>
        </Box>
      ),
    },
    {
      accessorKey: "startdate",
      header: t("From Date"),
    },
    {
      accessorKey: "enddate",
      header: t("To Date"),
    },
    {
      accessorKey: "hours",
      header: t("Hours"),
    },
    // {
    //   accessorKey: "#",
    //   header: "Action",
    //   Cell: ({ row }) => (
    //     <div className="action border-bottom-0">
    //       <Link
    //         to="#"
    //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
    //         data-bs-toggle="offcanvas"
    //         data-bs-target="#saveLog"
    //         onClick={() => {
    //           setSelectedLogId(row.original._id);
    //         }}
    //       >
    //         <span className="d-block">Details</span>
    //         <span className="d-block material-symbols-outlined">
    //           chevron_right
    //         </span>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  /*
   * Material React Table Column and States
   */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  /* Updates the column visibility state by toggling the visibility of the column with the given accessor key.*/
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /* Represents the state of column visibility in the table.*/
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /* Updates the column visibility state based on the new column state.*/
  const onColumnVisiblityHandler = (newColumnState) => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  useEffect(() => {
    if (
      filterStartDate != "" ||
      filterEndDate != "" ||
      filterTags.length > 0 ||
      filterUserIds.length > 0 ||
      filterProjectIds.length > 0
    ) {
      getTaskLogs();
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterUserIds,
    filterTags,
    filterProjectIds,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getTaskLogs();
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getTaskLogs();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectLogIds(selectedIdsArray);
    } else {
      setSelectLogIds([]);
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  //refresh icon function
  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_LOGS")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- bread crumb -------- */}

            {/* ------- filter area --------- */}
            <LogListHeader
              breadCrumbText={breadCrumbText}
              reloadList={refreshRecords}
              downloadAsExcel={downloadAsExcel}
              logList={logList}
              totalLogHours={totalLogHours}
              totalLogCosts={totalLogCosts}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={logList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  enableColumnOrdering={true}
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }}
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  // onShowColumnFiltersChange={toggleColumnFiltersChange}

                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem key="edit">
                  //     <Link className="dropdown-item" to="#">
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Update
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to Challenge
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link
                  //       className="dropdown-item"
                  //       to="#"
                  //       data-bs-toggle="modal"
                  //       data-bs-target="#addLabelModal"
                  //     >
                  //       Add to Tag
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to List
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ========= */}
        {/* --------- Add label modal ---------- */}
        <AddLabelModal
          moduleName="tasklog"
          selectedIds={selectLogIds}
          afterTagModalClose={getTaskLogs}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          setShowAlert={setShowAlert}
        />

        <SaveLogPopup
          selectedLogId={selectedLogId}
          onPopUpClose={() => {
            setSelectedLogId(null);
            getTaskLogs();
          }}
        />

        {/* ---------- filter popup --------- */}
        <LogFilterPopup
          moduleName={"alltasklogs"}
          visibleColoumns={visibleColoumns}
          setVisibleColoumns={setVisibleColoumns}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          taskList={taskList}
          // projectList={projectList}
          tagList={tagList}
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setFilterTags={setFilterTags}
          setfilterUserIds={setfilterUserIds}
          setfilterProjectIds={setfilterProjectIds}
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectLogIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t("log")}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default AllLogListBody;
