/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import CvCustomerFeedbackStatus from "data/Prod/CvCustomerFeedbackStatus.json";
//import CustomerJobFeedback from "pages/CustomerJobFeedback/CustomerJobFeedback";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const JobReviewFeedbackPopup = ({
  challengeResponseId
}) => {

  const { t } = useTranslation(); //for translation

  const [customerEmail, setCustomerEmail] = useState("");
  const [feedbackDate, setFeedbackDate] = useState("");
  const [feedbackValueData, setFeedbackValueData] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [feedbackComments, setFeedbackComments] = useState("");

  const [customersFeedbacks, setCustomersFeedbacks] = useState([]);
  const [showFeedbacksOnly, setShowFeedbacksOnly] = useState(false);


  //function for select language
  const feedbackValueSelectionHandler = (val) => {
    setFeedbackValueData(val);
    setFeedbackValue(val.value);
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    emailWarning: false,
    dateWarning: false,
    feedbackWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (customerEmail === "") {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    }

    if (feedbackDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (feedbackValue === "") {
      setValidation((prevState) => ({ ...prevState, feedbackWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  const resetHandler = () =>{
    setCustomerEmail("");
    setFeedbackDate("");
    setFeedbackValueData("");
    setFeedbackValue("");
    setCustomersFeedbacks([]);
  }

  //approval action
  const submitFeedback = async () => {
    if (validate()) {
      try {
        let feedbackData = {
          customeremail: customerEmail,
          feedbackdate: feedbackDate,
          feedbackvalue: feedbackValue,
          comments: feedbackComments,
        };

        console.log("feedbackData", feedbackData);

        let requestUrl =
          url.API_BASE_URL +
          url.API_CHALLENGE_SAVE_CUSTOMER_FEEDBACK +
          `/${challengeResponseId}`;

        const response = await postData(requestUrl, feedbackData);

        console.log('Challange Activity Response', response);

        if (response.status) {
          
          resetHandler();
          
          getCustomerFeedbacks();    
          
          setShowFeedbacksOnly(true);
        }

      } catch (error) {
        console.log(error.message);
      }
    }
  };


  // get all customer data from challenge response activity
  const getCustomerFeedbacks = async () => {
      try {

          let requestUrl =
              url.API_BASE_URL +
              url.API_CHALLENGE_GET_CUSTOMER_FEEDBACK +
              `/${challengeResponseId}`;

          console.log('get customer feedback url', requestUrl);

          const response = await getData(requestUrl);

          if(response.status){
            setCustomersFeedbacks(response.data);
          }

          console.log('customer feedback response >>', response);
      
      } catch (error) {

          console.log(error.message);
      }
  };


  useEffect(()=>{

    if(challengeResponseId){
      getCustomerFeedbacks()
    }    

  }, [challengeResponseId])


  return (
    <div className="review_canvas_left">
      <div
        className="offcanvas offcanvas-start bg-white"
        tabindex="-1"
        id="review_canvas"
        //aria-labelledby="offcanvasExampleLabel"
        data-bs-backdrop="static"
        style={{width:'500px'}}
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-black fs-xl fw-semibold"
            id="offcanvasExampleLabel"
          >
            Customer's Feedback{" "}
          </h5>
          <button
            type="button"
            className="btn-close p-0 rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={()=>{
              setShowFeedbacksOnly(false);
              resetHandler();
            }}
          ></button>
        </div>
        <div className="offcanvas-body">

          {/* Submit feedback for customer */}
          <div className={`review_form white_shadow_bx bg-white py-4 px-3 mb-3 rounded-10 ${showFeedbacksOnly ? 'd-none' : ''}`}>
            <form
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="form_innr">
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Email Id *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={customerEmail}
                    onChange={(e)=>{
                      setCustomerEmail(e.target.value)
                      setValidation((prevState) => ({
                        ...prevState,
                        emailWarning: false,
                      }));
                    }}
                  />
                  {/* warning */}
                  {validation.emailWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">warning</span>
                        <span>{t("Please enter a valid email")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Date *
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of feedback"
                    value={feedbackDate}
                    onChange={(e) => {
                      setFeedbackDate(e.target.value);

                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));

              
                    }}
                  />
                  {/* warning */}
                  {validation.dateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">warning</span>
                        <span>{t("Please enter a valid data")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Select a Feedback Options *
                  </label>
                  <Select
                    placeholder={t("Select status")}
                    options={CvCustomerFeedbackStatus}
                    value={feedbackValueData}
                    onChange={(val) => {
                      feedbackValueSelectionHandler(val);

                      setValidation((prevState) => ({
                        ...prevState,
                        feedbackWarning: false,
                      }));

                     

                    }}
                  />
                  {/* warning */}
                  {validation.feedbackWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">warning</span>
                        <span>{t("Please select a feedback option")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-group description_fill mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Add Comments
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Comments"
                    value={feedbackComments}
                    onChange={(e) => {
                      setFeedbackComments(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button className="btn w-100 btn-primary"
                  onClick={submitFeedback}
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>



          <div className="review_form white_shadow_bx bg-white py-4 px-3 rounded-10">
            <ul className="mt-3"> 
              

              {customersFeedbacks && customersFeedbacks.length > 0 &&
              
                customersFeedbacks.map((feedback, index)=>{

                  return (
                    <li className={`step position-relativ`} key={index}>
                        <p className="fs-sm fw-semibold mb-2">
                          {feedback.customerfeedback}
                        </p>
                        <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
                            {/* add in class if process passed = gradient-light  */}
                            <li className="tag d-flex align-items-center gap-2  py-2 px-3 rounded-90 gradient-light">
                                <span className="d-block material-symbols-outlined icon-md">
                                event_note
                                </span>
                                
                                <span className="d-block">
                                  {feedback.customerfeedbackdate}
                                </span>  
                                <span className="d-block">
                                  ({feedback.customeremail})
                                </span>                                            
                                
                            </li>                   
                        </ul>
                        <p className="fs-sm mb-2">
                          {feedback.comments}
                        </p>
                    </li> 

                  )
                })
              
              }
                

            </ul>
          </div>


        </div>
      </div>
    </div>
  );
};

export default JobReviewFeedbackPopup;
