/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import {
  createdAtToDateConverter,
  getDateFormatInSpanish,
} from "helper/Common/CommonHelper";
import WinnerSeclectionModal from "../Modals/WinnerSeclectionModal";

const ChallengeResponseRightPanel = ({
  challengeResponseId,
  challengeResponseDetails,
  cvId,
  setapprovalStatus,
  setSelectedHiringProcessId,
  setapproveComments,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  //all job process
  const [jobProcess, setJobProcess] = useState([]);

  const [customersFeedbacks, setCustomersFeedbacks] = useState([]);
  const [providerFeedbacks, setProviderFeedbacks] = useState([]);

  const [rejectedProcessId, setRejectedProcessId] = useState(null);

  // get all processes in the challenge
  const getAllJobProcess = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_PROCESS +
        `?token=${token}&challengeid=${params.id}`;

      console.log("Job Process", requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setJobProcess(response.data);
      }

      console.log("Job Process response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // get all customer feedbacks
  const getCustomerFeedbacks = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_CHALLENGE_GET_CUSTOMER_FEEDBACK +
        `/${challengeResponseId}`;

      console.log("get customer feedback url", requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setCustomersFeedbacks(response.data);
      }

      console.log("customer feedback response >>", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // get all provider feedbacks
  const getProviderFeedbacks = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_CHALLENGE_GET_PROVIDER_FEEDBACK +
        `/${challengeResponseId}`;

      console.log("get customer feedback url", requestUrl);

      const response = await getData(requestUrl);

      console.log("provider feedback response >>", response);

      if (response.status) {
        setProviderFeedbacks(response.data);

        const rejectedFeedback = response.data.filter(
          (item) => item.approvalstatus === "2"
        );

        if (rejectedFeedback.length > 0) {
          setRejectedProcessId(rejectedFeedback[0].hiringprocessid);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for set winner
  const winnerSelectionHandler = (e) => {
    if (e.target.checked) {
      const bootstrapModal = new bootstrap.Modal(
        document.getElementById("winner_selection_mdl")
      );
      bootstrapModal.show();
    }
  };

  // fetch all job process to show
  useEffect(() => {
    console.log(
      "challengeResponseDetails from RIght Panel",
      challengeResponseDetails
    );

    getAllJobProcess();

    getProviderFeedbacks();

    getCustomerFeedbacks();
  }, [challengeResponseDetails]);

  return (
    <aside
      id="sidebar"
      className=" builder_rtpnl p-3 p-lg-4 border border-gray-300 rounded-10 "
    >
      <p className="fw-bold mb-4">Application Processing phases</p>

      <ul className="hiring-process-flow">
        {/* Job published */}
        <li className="step position-relative active">
          <p className="fs-sm fw-semibold mb-2">Job Published on</p>
          <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
              <span className="d-block material-symbols-outlined icon-md">
                event_note
              </span>
              {challengeResponseDetails.jobpublishdate ? (
                <span className="d-block">
                  {challengeResponseDetails.jobpublishdate}
                </span>
              ) : (
                ""
              )}
            </li>
            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
              <span className="d-block material-symbols-outlined icon-md">
                location_on
              </span>
              <span className="d-block">
                {challengeResponseDetails.challengeid
                  ? challengeResponseDetails.challengeid.city
                  : ""}
              </span>
            </li>
          </ul>
        </li>
        <li className="step position-relative active">
          <p className="fs-sm fw-semibold mb-2">CV Submitted on</p>
          <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
              <span className="d-block material-symbols-outlined icon-md">
                event_note
              </span>
              {challengeResponseDetails.cvresponsedate ? (
                <span className="d-block">
                  {challengeResponseDetails.cvresponsedate}
                </span>
              ) : (
                ""
              )}
            </li>
            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
              <span className="d-block material-symbols-outlined icon-md">
                person
              </span>
              {challengeResponseDetails.providerid ? (
                <span className="d-block">
                  {challengeResponseDetails.providerid.name}{" "}
                  {challengeResponseDetails.providerid.surname}
                </span>
              ) : (
                ""
              )}
            </li>
          </ul>
        </li>
      </ul>

      {/* CV reviewed on */}
      {/* approvalstatus (0= pending, 1 = accepted, 2 = rejected) */}

      {/* If pending approval, allow to approve or reject */}
      {challengeResponseDetails.approvalstatus === "0" && (
        <ul>
          <li className="step position-relative active">
            <p className="fs-sm fw-semibold mb-2">Status</p>
            <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
              <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  pending_actions
                </span>

                <span className="d-block">Pending Approval</span>
              </li>
              <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                <span className="d-block material-symbols-outlined icon-md">
                  person
                </span>

                <span className="d-block">Moderator / Interview Team</span>
              </li>
            </ul>
          </li>

          {/* Allow user to approve or reject the response */}
          <li className="step position-relative action">
            <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
              <li className="tag d-flex align-items-center gap-2 py-2 px-3 rounded-90">
                <Link
                  to="#offcanvasCommentYourThought"
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasCommentYourThought"
                  aria-controls="offcanvasResponseApproved"
                  onClick={() => {
                    setapprovalStatus("1");
                  }}
                >
                  Accept application
                </Link>
              </li>
              <li className="tag d-flex align-items-center gap-2 py-2 px-3 rounded-90">
                <Link
                  to="#offcanvasCommentYourThought"
                  className="btn btn-outline-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasCommentYourThought"
                  aria-controls="offcanvasResponseApproved"
                  onClick={() => {
                    setapprovalStatus("2");
                  }}
                >
                  Reject
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      )}

      {/* If already accepted / reject, show the reponse and the data and person who did that */}

      {challengeResponseDetails.approvalstatus !== "0" && (
        <>
          <ul>
            <li className="step position-relative active">
              <p className="fs-sm fw-semibold mb-2">
                Status:
                <span className="text-success">
                  {" "}
                  {challengeResponseDetails.approvalstatus === "1"
                    ? "Accepted"
                    : ""}
                </span>
                <span className="text-danger">
                  {" "}
                  {challengeResponseDetails.approvalstatus === "2"
                    ? "Rejected"
                    : ""}
                </span>
              </p>

              <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
                <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                  <span className="d-block material-symbols-outlined icon-md">
                    pending_actions
                  </span>

                  <span className="d-block">
                    {getDateFormatInSpanish(
                      challengeResponseDetails.approvaldate
                    )}
                  </span>
                </li>
                <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                  <span className="d-block material-symbols-outlined icon-md">
                    person
                  </span>

                  <span className="d-block">Moderator / Team</span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Comments :{" "}
            <span className="fs-sm">{challengeResponseDetails.comments}</span>
          </p>
        </>
      )}

      {/* Check provider feedbacks in different process, if no feedback in any process, allow to give accept / reject feedback */}
      <ul className="mt-3">
        {challengeResponseDetails.approvalstatus !== "2" &&
          jobProcess &&
          jobProcess.length > 0 &&
          jobProcess.map((process, index) => {
            // check if current process is already feedback by moderator
            let processAccepted = false;
            let processRejected = false;
            let processResponseRecord = null;

            providerFeedbacks &&
              providerFeedbacks.some((response) => {
                if (response.hiringprocessid === process._id) {
                  processResponseRecord = response;

                  if (response.approvalstatus === "1") {
                    processAccepted = true;
                  } else if (response.approvalstatus === "2") {
                    processRejected = true;
                  }
                  return true; // Exit the loop early
                }
                return false;
              });

            return (
              <li
                className={`mt-2 step position-relative ${
                  challengeResponseDetails.approvalstatus === "1"
                    ? processAccepted === false &&
                      processRejected === false &&
                      rejectedProcessId
                      ? "text-gray"
                      : "text-black"
                    : "text-gray"
                }`}
                key={index}
              >
                <p className="fs-sm fw-semibold mb-2">
                  Process : {process.title}
                  {/* check if applicant has already qualified this round */}
                  {processResponseRecord ? (
                    <span
                      className={`ms-3 ${
                        processAccepted === true
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {processAccepted === true ? "Qualified" : "Rejected"}
                    </span>
                  ) : (
                    ""
                  )}
                </p>

                <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
                  {/* show process date or action (qualify or reject) date */}
                  <li
                    className={`tag d-flex align-items-center gap-2  py-2 px-3 rounded-90 ${
                      processResponseRecord ? "gradient-light" : ""
                    }`}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>

                    <span className="d-block">
                      {processResponseRecord === null
                        ? getDateFormatInSpanish(process.startdate)
                        : createdAtToDateConverter(
                            processResponseRecord.createdAt
                          )}
                    </span>
                  </li>

                  {/* show person responsive */}
                  <li
                    className={`tag d-flex align-items-center gap-2  py-2 px-3 rounded-90 ${
                      processResponseRecord ? "gradient-light" : ""
                    }`}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      person
                    </span>

                    {processResponseRecord === null ? (
                      <span className="d-block">Moderator / Team Member</span>
                    ) : (
                      <span className="d-block">
                        {processResponseRecord.userid?.name ?? ""}{" "}
                        {processResponseRecord.userid?.surname ?? ""}
                      </span>
                    )}
                  </li>
                </ul>

                {challengeResponseDetails.approvalstatus === "1" ? (
                  processResponseRecord === null ? (
                    rejectedProcessId &&
                    processAccepted === false &&
                    processRejected === false ? null : (
                      <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
                        <li className="tag d-flex align-items-center gap-2 py-2 px-3 rounded-90">
                          <Link
                            to="#offcanvasCommentYourThought"
                            className="btn btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasCommentYourThought"
                            aria-controls="offcanvasResponseApproved"
                            onClick={() => {
                              setapprovalStatus("1");
                              setSelectedHiringProcessId(process._id);
                            }}
                          >
                            Mark Qualified
                          </Link>
                        </li>
                        <li className="tag d-flex align-items-center gap-2 py-2 px-3 rounded-90">
                          <Link
                            to="#offcanvasCommentYourThought"
                            className="btn btn-outline-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasCommentYourThought"
                            aria-controls="offcanvasResponseApproved"
                            onClick={() => {
                              setapprovalStatus("2");
                              setSelectedHiringProcessId(process._id);
                            }}
                          >
                            Mark Failed
                          </Link>
                        </li>
                      </ul>
                    )
                  ) : (
                    <p>
                      Comments :{" "}
                      <span className="fs-sm">
                        {processResponseRecord.comments}
                      </span>
                    </p>
                  )
                ) : null}
              </li>
            );
          })}
      </ul>

      {/* <!-- ========== Start winner Section ========== --> */}
      {challengeResponseDetails ? (
        challengeResponseDetails.approvalstatus ===
        "2" ? null : challengeResponseDetails.approvalstatus === "1" &&
          jobProcess.length ===
            0 ? null : rejectedProcessId ? null : challengeResponseDetails.iswinner ? null : (
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="winnercheckbox"
              onChange={winnerSelectionHandler}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Confirm as Selected")}
            </label>
          </div>
        )
      ) : null}
      {/* <!-- ========== End winner Section ========== --> */}

      <hr />

      <p className="fw-bold mb-4">
        <Link
          className="d-flex flex-auto"
          target="_blank"
          to={`/admin/customerfeedback/${challengeResponseId}`}
        >
          <span className="d-block material-symbols-outlined icon-md">
            link
          </span>
          <span className="ms-2">Customer's Feedbacks</span>
        </Link>
      </p>

      {/* if the response is not reject, allow moderator to mark response as qualify in different rounds */}
      <ul className="mt-3">
        {customersFeedbacks &&
          customersFeedbacks.length > 0 &&
          customersFeedbacks.map((feedback, index) => {
            return (
              <li className={`step position-relative `} key={index}>
                <p className="fs-sm fw-semibold mb-2">
                  {feedback.customerfeedback}
                </p>
                <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-2">
                  {/* add in class if process passed = gradient-light  */}
                  <li
                    className={`tag d-flex align-items-center gap-2  py-2 px-3 rounded-90`}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      event_note
                    </span>

                    <span className="d-block">
                      {feedback.customerfeedbackdate}
                    </span>
                    <span className="d-block">({feedback.customeremail})</span>
                  </li>
                </ul>
                <p className="fs-sm mb-2">{feedback.comments}</p>
              </li>
            );
          })}
      </ul>
    </aside>
  );
};

export default ChallengeResponseRightPanel;
