import SentListReport from "components/CRMComponents/CRMConversationComponents/SentComponents/SentListReport/SentListReport";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const CrmConversationSentReport = () => {
  useEffect(() => {
    document.title = "Sent Mail Conversation";
  }, []);
  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body component --- */}
      <SentListReport />
    </main>
  );
};

export default CrmConversationSentReport;
