/* eslint-disable */
import { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";

const SaveTaskPopup = ({
  setShowAlert,
  setAlertMessage,
  setMessageType,
  selectedTaskId,
  setSelectedTaskId,
  AfterPopupClose,
}) => {
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];

  const params = useParams();
  const token = localStorage.getItem("token");

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");

  const [hours, sethours] = useState("");
  const [score, setScore] = useState("");

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    hoursWarning: false,
  });

  const editor = useRef(null);

  //const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestUrl =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestUrl, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  // jodit config 2 ends -----------------------------------------------

  const editorConfig = useMemo(() => editorCustomConfig, []);

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (hours === "") {
      setValidation((prevState) => ({
        ...prevState,
        hoursWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task details", response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
        sethours(response.data.hours ?? 0);
        setScore(response.data.score ?? 0);
      } else {
        seterrorMessage(response.message);
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //save task
  const saveTaskHandler = async () => {
    if (validationHandler()) {
      seterrorMessage("");

      try {
        setIsSaving(true);

        let taskData = {
          leadid: null,
          project: null,
          contact: null,
          lessonid: params.lessonid,
          course: params.id,
          ismilestone: false,
          projectsection: null,
          previoustask: null,
          title,
          description: details,
          startdate: todayValue,
          enddate: todayValue,
          hours,
          score,
          priority: "2",
          completedpercent: "0",
          taskpercent: 0,
          assigneduser: null,
          followers: [],
          rank: 1,
          uploads: [],
        };

        // console.log(taskData);

        let response = {};

        if (selectedTaskId) {
          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_PROJECT_TASK +
            `/${selectedTaskId}?token=${token}`;

          response = await putData(requestUrl, taskData);
        } else {
          let requestUrl =
            url.API_BASE_URL + url.API_ADD_NEW_PROJECT_TASK + `?token=${token}`;

          response = await postData(requestUrl, taskData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          resetHandler();
          AfterPopupClose();
          setMessageType("success");

          let offCanvasPopup = document.querySelector("#saveTask");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
        } else {
          seterrorMessage(response.message);
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      seterrorMessage("Please fill up all required fields");
      setAlertMessage("Please fill up all required fields");
    }
    setShowAlert(true);
  };

  //reset all states
  const resetHandler = () => {
    settitle("");
    setdetails("");
    sethours("");
    setScore("");
    setSelectedTaskId(null);
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
    }
  }, [selectedTaskId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveTask"
      aria-labelledby="saveTask"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="addTaskLabel">
          {t("Save Task")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          className="d-flex flex-column h-100"
          onSubmit={(e) => e.preventDefault()}
        >
          {/* <!-- ========== Start title Section ========== --> */}
          <div className="form-group mb-4">
            <label
              htmlFor="taskName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Task Name")}
            </label>
            <input
              type="text"
              id="taskName"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter Name")}
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  nameWarning: false,
                }));
                seterrorMessage("");
              }}
            />
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter title!")}</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End title Section ========== --> */}

          {/* <!-- ========== Start details Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="details" className="d-block fs-sm fw-semibold mb-2">
              {t("Details")}
            </label>

            <JoditEditor
              ref={editor}
              value={details}
              config={editorConfig} //{joditconfig}
              onChange={(newContent, event) => {
                console.log("newCOntent to save", newContent);
                setdetails(newContent);
              }}
            />
          </div>
          {/* <!-- ========== End details Section ========== --> */}

          {/* hours and score */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <label
                  htmlFor="time"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Duration")}
                </label>
                <input
                  type="number"
                  id="time"
                  min={0}
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Hours required")}
                  value={hours}
                  onChange={(e) => {
                    sethours(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      hoursWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                {validation.hoursWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter duration!")}</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="price"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Score")}
                </label>
                <input
                  type="number"
                  min={0}
                  id="price"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Score")}
                  value={score}
                  onChange={(e) => {
                    setScore(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="action d-flex gap-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveTaskHandler}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            {errorMessage == "" ? null : (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{errorMessage} !</span>
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveTaskPopup;
