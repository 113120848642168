/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const ProjectReferencesModal = ({
  projectIndex,
  projectData,
  setProjectData,
  projectListBlock,
  setProjectListBlock,
  setProjectIndex,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  //function for add project reference block
  const addProjectReferenceHandler = () => {
    setProjectListBlock([...projectListBlock, projectData]);
    resetHandler();
  };

  //function for edit project reference block
  const editProjectReferenceBlockHandler = () => {
    const updatedProjectList = [...projectListBlock];
    updatedProjectList[projectIndex] = projectData;
    setProjectListBlock(updatedProjectList);
    resetHandler();
  };

  //close modal handler
  const resetHandler = () => {
    setProjectData({
      companyid: null,
      company: "",
      companylogo: "",
      position: "",
      start: "",
      end: "",
      technologies: "",
      responsibilities: "",
    });
    setProjectIndex(null);
  };

  const saveInfoHandler = () => {
    if (projectIndex != null) {
      editProjectReferenceBlockHandler();
    } else {
      addProjectReferenceHandler();
    }

    let projectModal = document.querySelector("#projectreference_modal");
    let modal = bootstrap.Modal.getInstance(projectModal);
    modal.hide();
  };

  return (
    <div
      className="modal fade"
      id="projectreference_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Project References</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                {/* <!-- ========== company and role ========== --> */}
                <div className="form-group d-flex align-items-center gap-3 mb-3">
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">Company</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company name"
                        value={projectData.company}
                        onChange={(e) => {
                          setProjectData((prevData) => ({
                            ...prevData,
                            company: e.target.value,
                            companylogo: "",
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label>Role in Project</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Position"
                        value={projectData.position}
                        onChange={(e) => {
                          setProjectData((prevData) => ({
                            ...prevData,
                            position: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End company and role ========== --> */}

                {/* <!-- ========== Start year Section ========== --> */}
                <div className="form-group d-flex align-items-center gap-3 mb-3">
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">Start Year</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Start year"
                        value={projectData.start}
                        onChange={(e) => {
                          setProjectData((prevData) => ({
                            ...prevData,
                            start: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="date_check_row d-flex align-items-center justify-content-between">
                      <label className="form-label">End Year</label>
                    </div>
                    <div className="experince_date_row d-flex gap-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="End Year"
                        value={projectData.end}
                        onChange={(e) => {
                          setProjectData((prevData) => ({
                            ...prevData,
                            end: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End year Section ========== --> */}

                {/* <!-- ========== Start Technologies Section ========== --> */}
                <div className="form-group mb-3">
                  <label className="form-label">Technologies</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Technologies Used"
                    value={projectData.technologies}
                    onChange={(e) => {
                      setProjectData((prevData) => ({
                        ...prevData,
                        technologies: e.target.value,
                      }));
                    }}
                  />
                </div>
                {/* <!-- ========== End technologies Section ========== --> */}

                {/* <!-- ========== Start responsibilities Section ========== --> */}
                <div className="form-group mb-3">
                  <label className="form-label">Responsibilities</label>
                  <textarea
                    name=""
                    id=""
                    rows="5"
                    className="form-control"
                    placeholder="Enter responsibilities"
                    value={projectData.responsibilities}
                    onChange={(e) => {
                      setProjectData((prevData) => ({
                        ...prevData,
                        responsibilities: e.target.value,
                      }));
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End responsibilities Section ========== --> */}
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={saveInfoHandler}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectReferencesModal;
