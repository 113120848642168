/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

/*import url and gateway methods */
import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import { useTranslation } from "react-i18next";

const MyTaskFilterPopup = ({
  visibleColoumns,
  setVisibleColoumns,
  columnOrder,
  setColumnOrder,
  columnFilterValues,
  setColumnFilterValues,
  showGlobalFilters,
  setShowGlobalFilters,
  isFilterReset,
  setIsFilterReset,
  isModerator,
  moduleName,
  filterTaskDateRangeType,
  setFilterTaskDateRangeType,
  filterTaskFromDate,
  setfilterTaskFromDate,
  filterTaskToDate,
  setfilterTaskToDate,
  filterTaskCustomIds,
  setfilterTaskCustomIds,
  filterTaskTitle,
  setfilterTaskTitle,
  filterTaskProjects,
  setfilterTaskProjects,
  filterTaskAssignedByUsers,
  setfilterTaskAssignedByUsers,
  filterTaskAssignedToUsers,
  setfilterTaskAssignedToUsers,
  filterTaskStatus,
  setfilterTaskStatus,
  filterTaskPriorities,
  setfilterTaskPriorities,
  filterTaskLabels,
  setfilterTaskLabels,
  setIsNoDefaultFilter = () => {},
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const params = useParams();

  const dateSelectOptions = [
    { label: t("Search by End Date Between"), value: "enddate" },
    { label: t("Search by Start Date Between"), value: "startdate" },
    { label: t("Search Task in this Date Range"), value: "daterange" },
  ];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const userObj = {
    label: `${userInfo.name ?? userInfo.email} ${userInfo.surname ?? ""}`,
    value: userInfo._id,
  };

  const [tagList, setTagList] = useState([]);
  const [projectInput, setprojectInput] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [assignByInput, setassignByInput] = useState("");
  const [assignedByList, setassignedByList] = useState([]);
  const [assignedToInput, setassignedToInput] = useState("");
  const [assignedToList, setassignedToList] = useState([]);

  //date range
  const [dateRangeTypeValue, setdateRangeTypeValue] = useState("");
  const [dateRangeType, setdateRangeType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [customIdValue, setcustomIdValue] = useState(null);
  const [taskCustomIds, settaskCustomIds] = useState([]);
  const [projectValue, setProjectValue] = useState(null);
  const [selectedProjects, setselectedProjects] = useState([]);
  const [assignedByValue, setassignedByValue] = useState(null);
  const [selectedAssignedBy, setselectedAssignedBy] = useState([]);
  const [assignedToValue, setassignedToValue] = useState(null);
  const [selectedAssignedTo, setselectedAssignedTo] = useState([]);
  const [title, settitle] = useState("");
  const [searchedLabel, setSearchedLabel] = useState([]);
  const [priorities, setpriorities] = useState([]);
  const [taskStatusValues, settaskStatusValues] = useState([]);

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  const [isDefaultFilterLoad, setIsDefaultFilterLoad] = useState(false);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);

    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign columns
      if (jsonFilterData.visibleColoumns) {
        setVisibleColoumns(jsonFilterData.visibleColoumns);
      }

      //assign column order
      if (jsonFilterData.columnOrder && jsonFilterData.columnOrder.length > 0) {
        setColumnOrder(jsonFilterData.columnOrder);
      }

      //assign show/hide columns
      // if (jsonFilterData.showGlobalFilters) {
      //   setShowGlobalFilters(jsonFilterData.showGlobalFilters);
      // }

      //assign column filter values
      // if (jsonFilterData.columnFilterValues) {
      //   setColumnFilterValues(jsonFilterData.columnFilterValues);
      // }

      //assign date range
      if (jsonFilterData.dateRangeTypeValue) {
        dateRangeSelectionHandler(jsonFilterData.dateRangeTypeValue);
      }

      //assign from date
      if (jsonFilterData.fromDate) {
        setFromDate(jsonFilterData.fromDate);
      }

      //assign to date
      if (jsonFilterData.toDate) {
        setToDate(jsonFilterData.toDate);
      }

      //assing custom id
      if (jsonFilterData.customIdValue) {
        customIdSelectionHandler(jsonFilterData.customIdValue);
      }

      //assing task title
      if (jsonFilterData.title) {
        settitle(jsonFilterData.title);
      }

      //assign assigned by
      if (jsonFilterData.assignedByValue) {
        assignedBySelectionHandler(jsonFilterData.assignedByValue);
      }

      //assign assigned to
      if (jsonFilterData.assignedToValue) {
        assignedToSelectionHandler(jsonFilterData.assignedToValue);
      }

      //assign project
      if (jsonFilterData.projectValue) {
        projectSelectionHandler(jsonFilterData.projectValue);
      }

      //assign task status
      if (jsonFilterData.taskStatus) {
        // Filtering the task status options based on values present in the json array
        const filteredStatus = TaskStatusOptions.filter((item) =>
          jsonFilterData.taskStatus.includes(item.value)
        );

        //run loop
        filteredStatus.forEach((item) => {
          taskStatusSelectionHandler(item);
        });
      }

      //asign priority
      if (jsonFilterData.priorities) {
        // Filtering the task priority options based on values present in the json array
        const filteredPriority = TaskPriority.filter((item) =>
          jsonFilterData.priorities.includes(item.value)
        );

        //run loop
        filteredPriority.forEach((item) => {
          prioritySelectionHandler(item);
        });
      }

      //assign label
      if (jsonFilterData.labels) {
        // Filtering the task label options based on values present in the json array
        const filteredLabels = tagList.filter((item) =>
          jsonFilterData.labels.includes(item.value)
        );

        //run loop
        filteredLabels.forEach((item) => {
          tagSelectionHandler(item);
        });
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //search input
  const projectInputHandler = (val) => {
    setprojectInput(val);
  };

  //assinged by input
  const assignedByInputHandler = (val) => {
    setassignByInput(val);
  };

  //assinged to input
  const assignedToInputHandler = (val) => {
    setassignedToInput(val);
  };

  //function for get project list
  const getProjectList = async () => {
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_PROJECTLIST_BY_SEARCH}?token=${token}&searchtitle=${projectInput}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setProjectList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select date range type
  const dateRangeSelectionHandler = (val) => {
    setdateRangeTypeValue(val);
    setdateRangeType(val.value);
  };

  //function for select task custom id
  const customIdSelectionHandler = (val) => {
    setcustomIdValue(val);
    const selectedTaskCustomIds = val
      .map((item) => {
        const taskIsNotNumber = isNaN(item.value);
        const taskIdNumber = taskIsNotNumber ? null : parseInt(item.value);
        return taskIdNumber;
      })
      .filter((item) => item !== null);
    settaskCustomIds(selectedTaskCustomIds);
  };

  //function for get all assigned By list
  const getAssignedByUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${assignByInput}&modulename=MOD_TASK`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data && response.data.length > 0) {
          setassignedByList([...response.data, userObj]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all assigned to list
  const getAssignedToUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${assignedToInput}&modulename=MOD_TASK`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setassignedToList([...response.data, userObj]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select assigned by/moderator
  const assignedBySelectionHandler = (val) => {
    if (val) {
      setassignedByValue(val);
      setselectedAssignedBy(val.map((item) => item.value));
    } else {
      setassignedByValue(null);
      setselectedAssignedBy([]);
    }
  };

  //function for select assigned user
  const assignedToSelectionHandler = (val) => {
    if (val) {
      setassignedToValue(val);
      setselectedAssignedTo(val.map((item) => item.value));
    } else {
      setassignedToValue(null);
      setselectedAssignedTo([]);
    }
  };

  //function user Selection
  const projectSelectionHandler = (val) => {
    setProjectValue(val);
    setselectedProjects(val.map((item) => item.value));
  };

  //select status
  const taskStatusSelectionHandler = (data) => {
    let prevTagValues = taskStatusValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "taskstatusfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);

      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "taskstatusfilter-" + data.value
      );

      selectedTagItem.classList.add("active");

      // not exist in array, thus add
      prevTagValues.push(data.value);
    }

    settaskStatusValues(prevTagValues);
  };

  //proitority select
  const prioritySelectionHandler = (data) => {
    let prevTagValues = priorities;
    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "taskpriorityfilter-" + data.value
      );
      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "taskpriorityfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    setpriorities(prevTagValues);
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    setFilterSavingErrorMessage("");
    let prevTagValues = searchedLabel;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "tasktagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);

      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "tasktagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");

      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    setSearchedLabel(prevTagValues);
  };

  //* Function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //* Function for assign search data for filter and close pop up
  const searchHandler = () => {
    if (dateRangeType != "") {
      setFilterTaskDateRangeType(dateRangeType);
    } else {
      setFilterTaskDateRangeType(filterTaskDateRangeType);
    }

    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "") {
      setfilterTaskFromDate(fromDate);
    } else {
      setfilterTaskFromDate(filterTaskFromDate);
    }

    if (toDate != "") {
      setfilterTaskToDate(toDate);
    } else {
      setfilterTaskToDate(filterTaskToDate);
    }

    if (taskCustomIds.length > 0) {
      setfilterTaskCustomIds(taskCustomIds);
    } else {
      setfilterTaskCustomIds([]);
    }

    if (title != "") {
      setfilterTaskTitle(title);
    } else {
      setfilterTaskTitle(filterTaskTitle);
    }

    if (searchedLabel.length > 0) {
      setfilterTaskLabels(searchedLabel);
    } else {
      setfilterTaskLabels([]);
    }

    if (taskStatusValues.length > 0) {
      setfilterTaskStatus(taskStatusValues);
    } else {
      setfilterTaskStatus([]);
    }

    if (priorities.length > 0) {
      setfilterTaskPriorities(priorities);
    } else {
      setfilterTaskPriorities([]);
    }

    if (selectedProjects.length > 0) {
      setfilterTaskProjects(selectedProjects);
    } else {
      setfilterTaskProjects([]);
    }

    if (selectedAssignedBy.length > 0) {
      setfilterTaskAssignedByUsers(selectedAssignedBy);
    } else {
      setfilterTaskAssignedByUsers([]);
    }

    if (selectedAssignedTo.length > 0) {
      setfilterTaskAssignedToUsers(selectedAssignedTo);
    } else {
      setfilterTaskAssignedToUsers([]);
    }

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasTaskFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    // resetHandler();
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      //set filter data for saving
      let filterData = {
        name: filterName,
        parameters: JSON.stringify({
          visibleColoumns: visibleColoumns ?? {},
          columnOrder: columnOrder ?? [],
          columnFilterValues: columnFilterValues ?? {},
          showGlobalFilters: showGlobalFilters ?? false,
          dateRangeTypeValue: dateRangeTypeValue,
          dateRangeType: dateRangeType,
          fromDate: fromDate,
          toDate: toDate,
          customIdValue: customIdValue,
          customIds: taskCustomIds,
          title: title,
          labels: searchedLabel,
          taskStatus: taskStatusValues,
          priorities: priorities,
          projectValue: projectValue,
          projects: selectedProjects,
          assignedByValue: assignedByValue,
          assignedBy: selectedAssignedBy,
          assignedToValue: assignedToValue,
          assignedTo: selectedAssignedTo,
        }),
        moduleslug: moduleName,
        isdefault: isDefaultFilter,
      };

      // console.log(`filterData ==========>>>`, filterData);

      try {
        let requestUrl = url.API_BASE_URL;

        //check condition
        if (filterId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_FILTER +
            `/${filterId}` +
            `?token=${token}`;
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
        }

        const response = filterId
          ? await putData(requestUrl, filterData)
          : await postData(requestUrl, filterData);

        setFilterIsSaving(false);

        console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          searchHandler();
          if (filterId) {
            getAllFilters(false);
          } else {
            getAllFilters(true);
          }
        } else {
          setFilterSavingErrorMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(false);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            console.log(jsonFilterData);

            //assign visible columns
            if (jsonFilterData.visibleColoumns) {
              setVisibleColoumns(jsonFilterData.visibleColoumns);
            }

            //assign column order
            if (
              jsonFilterData.columnOrder &&
              jsonFilterData.columnOrder.length > 0
            ) {
              setColumnOrder(jsonFilterData.columnOrder);
            }

            //assign show/hide columns
            // if (jsonFilterData.showGlobalFilters) {
            //   setShowGlobalFilters(jsonFilterData.showGlobalFilters);
            // }

            //assign column filter values
            // if (jsonFilterData.columnFilterValues) {
            //   setColumnFilterValues(jsonFilterData.columnFilterValues);
            // }

            //check date range type is not empty
            if (jsonFilterData.dateRangeType != "") {
              setFilterTaskDateRangeType(jsonFilterData.dateRangeType);
            } else {
              setFilterTaskDateRangeType(filterTaskDateRangeType);
            }

            //check start date and end date are not empty.If end date empty then only search by start date
            if (jsonFilterData.fromDate != "") {
              setfilterTaskFromDate(jsonFilterData.fromDate);
            } else {
              setfilterTaskFromDate(filterTaskFromDate);
            }

            if (jsonFilterData.toDate != "") {
              setfilterTaskToDate(jsonFilterData.toDate);
            } else {
              setfilterTaskToDate(filterTaskToDate);
            }

            if (jsonFilterData.customIds.length > 0) {
              setfilterTaskCustomIds(jsonFilterData.customIds);
            } else {
              setfilterTaskCustomIds(filterTaskCustomIds);
            }

            if (jsonFilterData.title != "") {
              setfilterTaskTitle(jsonFilterData.title);
            } else {
              setfilterTaskTitle(filterTaskTitle);
            }

            if (jsonFilterData.labels.length > 0) {
              setfilterTaskLabels(jsonFilterData.labels);
            } else {
              setfilterTaskLabels(filterTaskLabels);
            }

            if (jsonFilterData.taskStatus.length > 0) {
              setfilterTaskStatus(jsonFilterData.taskStatus);
            } else {
              setfilterTaskStatus(filterTaskStatus);
            }

            if (jsonFilterData.priorities.length > 0) {
              setfilterTaskPriorities(jsonFilterData.priorities);
            } else {
              setfilterTaskPriorities(filterTaskPriorities);
            }

            if (jsonFilterData.projects.length > 0) {
              setfilterTaskProjects(jsonFilterData.projects);
            } else {
              setfilterTaskProjects(filterTaskProjects);
            }

            if (jsonFilterData.assignedBy.length > 0) {
              setfilterTaskAssignedByUsers(jsonFilterData.assignedBy);
            } else {
              setfilterTaskAssignedByUsers(filterTaskAssignedByUsers);
            }

            if (jsonFilterData.assignedTo.length > 0) {
              setfilterTaskAssignedToUsers(jsonFilterData.assignedTo);
            } else {
              setfilterTaskAssignedToUsers(filterTaskAssignedToUsers);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* Function for reset search
  const resetHandler = () => {
    setdateRangeTypeValue(null);
    setdateRangeType("");
    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);
    setprojectInput("");
    setcustomIdValue(null);
    settaskCustomIds([]);
    setassignByInput("");
    setassignedToInput("");
    setProjectList([]);
    setassignedByList([userObj]);
    setassignedByValue(null);
    setselectedAssignedBy([]);
    setassignedToList([userObj]);
    setassignedToValue(null);
    setselectedAssignedTo([]);
    removeActiveClass();
    settitle("");
    setFromDate("");
    setToDate("");
    setSearchedLabel([]);
    setpriorities([]);
    settaskStatusValues([]);
    setProjectValue(null);
    setselectedProjects([]);
    setFilterValidError(false);
    setFilterSavingErrorMessage("");
    // setIsDefaultFilterLoad(true);
  };

  useEffect(() => {
    getAllTags();
    getAllFilters(false);
    getDefaultFilter();
    dateRangeSelectionHandler({
      label: "Search by End Date Between ",
      value: "enddate",
    });

    setassignedByList([userObj]);
    setassignedToList([userObj]);
  }, []);

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    if (projectInput.length > 0) {
      getProjectList();
    }
  }, [projectInput]);

  useEffect(() => {
    if (assignByInput.length > 0) {
      getAssignedByUserList();
    }
  }, [assignByInput]);

  useEffect(() => {
    if (assignedToInput.length > 0) {
      getAssignedToUserList();
    }
  }, [assignedToInput]);

  const projectOptionsToShow = projectList.length > 0 ? projectList : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasTaskFilter"
      aria-labelledby="offcanvasTaskFilter"
    >
      {/* -------- select filter ---------- */}
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column"
        >
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* <!-- ========== Start date select Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Date")}</h3>{" "}
            </label>
            {/* <!-- ========== Start date type select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                placeholder={t("Select Date Range")}
                options={dateSelectOptions}
                value={dateRangeTypeValue}
                onChange={(val) => {
                  dateRangeSelectionHandler(val);
                }}
              />
            </div>
            {/* <!-- ========== End date type select Section ========== --> */}

            {/* <!-- ========== Start date input Section ========== --> */}
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            {/* <!-- ========== End date input Section ========== --> */}
          </div>
          {/* <!-- ========== End date select Section ========== --> */}

          {/* <!-- ========== Start search by id Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("ID")}</h3>{" "}
            </label>
            <CreatableSelect
              placeholder={t("Enter task ID")}
              isMulti
              value={customIdValue}
              onChange={(val) => {
                customIdSelectionHandler(val);
                setFilterSavingErrorMessage("");
              }}
            />
          </div>
          {/* <!-- ========== End search by id Section ========== --> */}

          {/* <!-- ========== Start task title Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Search By title")}
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
                setFilterSavingErrorMessage("");
              }}
            />
          </div>
          {/* <!-- ========== End task title Section ========== --> */}

          {/* <!-- ========== Start assigned by Section ========== --> */}
          {moduleName == "mytask" ||
          moduleName == `mytask${params.userid}` ||
          moduleName == "mytaskkanban" ||
          moduleName == "mytaskgantt" ||
          moduleName == "projecttask" ||
          moduleName == "projectkanban" ||
          moduleName == "projectgantt" ||
          moduleName == "projectsection" ? (
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Assigned By")}</h3>{" "}
              </label>
              <Select
                isMulti
                placeholder={t("Type user name or email")}
                options={assignedByList}
                value={assignedByValue}
                onChange={(val) => {
                  assignedBySelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
                onInputChange={(val) => {
                  assignedByInputHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
          ) : null}
          {/* <!-- ========== End assigned by Section ========== --> */}

          {/* <!-- ========== Start assigned to select Section ========== --> */}
          {moduleName == "mytask" ||
          moduleName == "mytaskkanban" ||
          moduleName == "mytaskgantt" ||
          moduleName == "projecttask" ||
          moduleName == "projectkanban" ||
          moduleName == "projectgantt" ||
          moduleName == "projectsection" ? (
            params.userid ? null : (
              <div className="form-group mb-4">
                <label
                  htmlFor="Name"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  <h3>{t("Assigned To")}</h3>{" "}
                </label>
                <Select
                  isMulti
                  placeholder={t("Type user name or email")}
                  options={assignedToList}
                  value={assignedToValue}
                  onChange={(val) => {
                    assignedToSelectionHandler(val);
                    setFilterSavingErrorMessage("");
                  }}
                  onInputChange={(val) => {
                    assignedToInputHandler(val);
                    setFilterSavingErrorMessage("");
                  }}
                />
              </div>
            )
          ) : null}
          {/* <!-- ========== End assigned to select Section ========== --> */}

          {/* <!-- ========== Start project select Section ========== --> */}
          {moduleName == "mytask" || moduleName == `mytask${params.userid}` ? (
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Search by Project")}</h3>{" "}
              </label>
              <Select
                isMulti
                placeholder={t("Type Project name")}
                options={projectOptionsToShow}
                value={projectValue}
                onChange={(val) => {
                  projectSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
                onInputChange={(val) => {
                  projectInputHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
          ) : null}
          {/* <!-- ========== End project select Section ========== --> */}

          {/* <!-- ========== Start accordion task status,priority and Labels Section ========== --> */}
          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            {/* <!-- ========== Start task status Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Task Status")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {TaskStatusOptions.map((statusData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => {
                              taskStatusSelectionHandler(statusData);
                              setFilterSavingErrorMessage("");
                            }}
                            id={"taskstatusfilter-" + statusData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{statusData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End task status Section ========== --> */}

            {/* <!-- ========== Start priority Section ========== --> */}
            {moduleName == "courseLessonTask" ? null : (
              <div className="accordion-item bg-transparent border-0">
                <h3 className="accordion-header">
                  <button
                    className="accordion-button bg-transparent p-0 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFour"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseFour"
                  >
                    {t("Task Priority")}
                  </button>
                </h3>
                <div
                  id="panelsStayOpen-collapseFour"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0 pt-4">
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                      {TaskPriority.map((priority, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => {
                                prioritySelectionHandler(priority);
                                setFilterSavingErrorMessage("");
                              }}
                              id={"taskpriorityfilter-" + priority.value}
                              className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                            >
                              <span>{priority.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- ========== End priority Section ========== --> */}

            {/* <!-- ========== Start label Section ========== --> */}
            {moduleName == "projectkanban" ||
            moduleName === "mytaskkanban" ? null : (
              <div className="accordion-item bg-transparent border-0">
                <h3 className="accordion-header">
                  <button
                    className="accordion-button bg-transparent p-0 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseFive"
                  >
                    {t("Labels")}
                  </button>
                </h3>
                <div
                  id="panelsStayOpen-collapseFive"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0 pt-4">
                    <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                      {tagList.map((tagData, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(tagData)}
                              id={"tasktagfilter-" + tagData.value}
                              className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                            >
                              <span>{tagData.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- ========== End label Section ========== --> */}
          </div>
          {/* <!-- ========== End accordion task status,priority and Labels Section ========== --> */}

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter a title or select a filter!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}

          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>

          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default MyTaskFilterPopup;
