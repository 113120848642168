/* eslint-disable */
import Header from "components/Common/Header/Header";
import InvoiceDocDetailsBody from "components/InvoiceDocComponents/InvoiceDocDetails/InvoiceDocDetailsBody";
import React, { useEffect } from "react";

const InvoiceDocDetails = () => {
  useEffect(() => {
    document.title = "Invoice Doc";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="invoicedoc" />

      {/* --------- body section ------------ */}
      <InvoiceDocDetailsBody />
    </main>
  );
};

export default InvoiceDocDetails;
