/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const LanguageModal = ({ userLanguages, setUserLanguages }) => {
  const languagesTemplate = {
    languagetext: "",
  };

  const [languagesBlock, setLanguagesBlock] = useState([]);

  const addNewLanguagesBlock = () => {
    setLanguagesBlock([...languagesBlock, languagesTemplate]);
  };

  const deleteLanguagesBlock = (index) => {
    const deleteLanguagesArr = [...languagesBlock];
    deleteLanguagesArr.splice(index, 1);
    setLanguagesBlock(deleteLanguagesArr);
  };

  // function for change survey select
  const languagesInputHandler = (index, field, value) => {
    const updatedFormValues = languagesBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setLanguagesBlock(updatedFormValues);
  };

  const updatedLanguagesBlock = (updatedData) => {
    setLanguagesBlock(updatedData);
  };

  const saveInfoHandler = () => {
    // console.log("languagesBlock", languagesBlock);

    if (languagesBlock.length > 0) {
      setUserLanguages(languagesBlock);
    } else {
      setUserLanguages([]);
    }

    resetHandler();

    let languageModal = document.querySelector("#language_modal");
    let modal = bootstrap.Modal.getInstance(languageModal);
    modal.hide();
  };

  const resetHandler = () => {
    setLanguagesBlock([]);
  };

  useEffect(() => {
    if (userLanguages.length > 0) {
      updatedLanguagesBlock(userLanguages);
    } else {
      updatedLanguagesBlock([]);
    }
  }, [userLanguages]);

  return (
    <div
      className="modal fade"
      id="language_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Languages</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                <div className="form-group mb-3">
                  {languagesBlock.map((languagesData, index) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-1 mb-2"
                        key={index}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Languages"
                          value={languagesData.languagetext}
                          onChange={(e) => {
                            languagesInputHandler(
                              index,
                              "languagetext",
                              e.target.value
                            );
                          }}
                        />
                        <div className="adnew_btn">
                          <Link
                            onClick={() => {
                              deleteLanguagesBlock(index);
                            }}
                            to="#"
                            className="d-inline-flex align-items-center justify-content-center gap-1"
                          >
                            <i className="material-symbols-outlined">delete</i>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="form-group mb-3">
                  <div className="adnew_btn">
                    <Link
                      to="#"
                      className="d-inline-flex align-items-center justify-content-center gap-1"
                      onClick={addNewLanguagesBlock}
                    >
                      <i className="material-symbols-outlined">add </i>
                      <span>Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" onClick={saveInfoHandler}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageModal;
