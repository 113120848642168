/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";

import { assetImages } from "constants";

//import { ecosystemSlug } from "Config/Config";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

import PersonalInfoModal from "components/Common/Modal/CvComponentModals/PersonalInfoModal";
import RolesModal from "components/Common/Modal/CvComponentModals/RolesModal";
import CarrierHighlightsModal from "components/Common/Modal/CvComponentModals/CarrierHighlightsModal";
import SummaryModal from "components/Common/Modal/CvComponentModals/SummaryModal";
import DetailsModal from "components/Common/Modal/CvComponentModals/DetailsModal";
import SkillPointModal from "components/Common/Modal/CvComponentModals/SkillPointModal";
import ExperienceModal from "components/Common/Modal/CvComponentModals/ExperienceModal";
import ProjectReferencesModal from "components/Common/Modal/CvComponentModals/ProjectReferencesModal";
import EducationModal from "components/Common/Modal/CvComponentModals/EducationModal";
import TechnicalCertificateModal from "components/Common/Modal/CvComponentModals/TechnicalCertificateModal";
import LanguageModal from "components/Common/Modal/CvComponentModals/LanguageModal";
import SalaryModal from "components/Common/Modal/CvComponentModals/SalaryModal";
import OtherSkillsModal from "components/Common/Modal/CvComponentModals/OtherSkillsModal";
import PortfolioModal from "components/Common/Modal/CvComponentModals/PortfolioModal";
import HobbiesModal from "components/Common/Modal/CvComponentModals/HobbiesModal";

const ChallengeResponseLeftPanel = ({
  challengeResponseDetails = {},
  cvId = null,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const imageFileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isLoading, setIsLoading] = useState(false);
  const [isCvLoading, setIsCvLoading] = useState(false);
  const [isCvUpdating, setIsCvUpdating] = useState(false);

  //const [challengeResponseDetails, setChallengeResponseDetails] = useState({});

  const [reloadCvList, setreloadCvList] = useState("");

  const [startFetchFromProfile, setStartFetchFromProfile] = useState(false);

  const [cvContent, setCvContent] = useState("");

  const [cvAdded, setCvAdded] = useState(false); // check if CV data is added by the user or not
  const [skillList, setSkillList] = useState([]);

  const [title, setTitle] = useState("");
  const [minSalary, setminSalary] = useState("");
  const [maxSalary, setmaxSalary] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [companyLogoPath, setCompanyLogoPath] = useState("");
  const [jobTypeValue, setjobTypeValue] = useState("");
  const [hideApplyButton, setHideApplyButton] = useState(false);

  const [cvTitle, setCvTitle] = useState("");
  const [userImagePath, setuserImagePath] = useState("");
  const [userImageId, setuserImageId] = useState(null);
  const [userPosition, setuserPosition] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [userPhone, setuserPhone] = useState("");
  const [userName, setuserName] = useState("");
  const [userSurname, setuserSurname] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userZipcode, setUserZipcode] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userExperienceInYears, setUserExperienceInYears] = useState("");

  const [userSummary, setuserSummary] = useState("");
  const [userDetails, setuserDetails] = useState("");
  const [fetchingProfileData, setFetchingProfileData] = useState(false);
  const [uploadedDocUrl, setUploadedDocUrl] = useState(""); //uploaed doc requiremtnts
  const [fetchingUploadedData, setFetchingUploadedData] = useState(false);

  /* ----- skill point block requirement start ------*/
  const [skillPointData, setSkillPointData] = useState({
    skill: null,
    skillvalue: null,
    skillname: "",
    points: "",
    experienceyear: "",
  });
  const [skillPointListBlock, setSkillPointListBlock] = useState([]);
  const [skillPointIndex, setSkillPointIndex] = useState(null);

  /* ----- skill point block requirement end ------*/

  /* ----- experience block requirement start ------*/
  const [experienceData, setExperienceData] = useState({
    companyid: null,
    company: "",
    companylogo: "",
    position: "",
    start: "",
    end: "",
    ctc: "",
    responsibilities: "",
  });

  const [experienceListBlock, setExperienceListBlock] = useState([]);
  const [experienceIndex, setExperienceIndex] = useState(null);
  /* ----- experience block requirement end ------*/

  /* ----- project reference block requirement start ------*/
  const [projectData, setProjectData] = useState({
    companyid: null,
    company: "",
    technologies: "",
    position: "",
    start: "",
    end: "",
    responsibilities: "",
  });

  const [projectListBlock, setProjectListBlock] = useState([]);
  const [projectIndex, setProjectIndex] = useState(null);
  /* ----- project reference block requirement end ------*/

  /* ----- education block requirement start ------*/
  const [educationData, setEducationData] = useState({
    degree: "",
    year: "",
    grade: "",
    institute: "",
  });

  const [educationListBlock, setEducationListBlock] = useState([]);
  const [educationIndex, setEducationIndex] = useState(null);
  /* ----- education block requirement end ------*/

  /* ----- Certificates block requirement start ------*/
  const [technicalExperienceData, settechnicalExperienceData] = useState({
    name: "",
    companyvalue: null,
    companyname: "",
    start: "",
    end: "",
    description: "",
    grade: "",
  });
  const [technicalExpBlock, settechnicalExpBlock] = useState([]);
  const [technicalExpIndex, settechnicalExpIndex] = useState(null);
  /* ----- Certificates block requirement end ------*/

  const [userLanguages, setUserLanguages] = useState([]);

  const [userCurrency, setuserCurrency] = useState("");
  const [userCurrCtc, setuserCurrCtc] = useState("");
  const [userExpctCtc, setuserExpctCtc] = useState("");
  const [userWorkingHours, setuserWorkingHours] = useState("");
  const [userStartTime, setuserStartTime] = useState("");
  const [userEndTime, setuserEndTime] = useState("");
  const [userCountry, setuserCountry] = useState("");

  const [userOptionalSkills, setuserOptionalSkills] = useState([]);

  const [userPortfolioLinks, setuserPortfolioLinks] = useState([]);

  const [userHobbies, setuserHobbies] = useState([]);
  const [userRoles, setuserRoles] = useState([]);
  const [userOtherSkills, setuserOtherSkills] = useState([]);
  const [userCarrierHighlights, setUserCareerHighlights] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [isSaving, setisSaving] = useState(false);

  const [isSaveChallangeAvailable, setIsSaveChallangeAvailable] =
    useState(false);

  // ai params for file handeling and chat ---------------------
  const [assistantId, setAssistantId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [docContent, setDocContent] = useState("");
  const [aiJsonContent, setAiJsonContent] = useState({});

  const [validationError, setValidationError] = useState({
    cvTitleError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (cvTitle === "") {
      setValidationError((prev) => ({
        ...prev,
        cvTitleError: true,
      }));

      isValid = false;
    }

    return isValid;
  };

  //function for get all categories
  const getAllSkills = async () => {
    // try {
    //   let requestUrl =
    //     url.API_BASE_URL +
    //     url.API_GET_CATEGORIES +
    //     `?token=${token}&slug=skills&ecosystemslug=${ecosystemSlug}`;
    //   const response = await getData(requestUrl);
    //   console.log(response);
    //   if (response.status) {
    //     setSkillList(response.data);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    console.log(file);

    // try {
    //   const requestUrl =
    //     url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

    //   const response = await uploadSingleFile(requestUrl, file);

    //   console.log("upload response-------->", response);

    //   if (response.status) {
    //     setuserImagePath(response.data.path);
    //     setuserImageId(response.data._id);
    //     resetLeadImageFile();
    //   }
    // } catch (error) {
    //   console.error(error.message);
    // }
  };

  //function for close image file
  const closeLeadImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetLeadImageFile();
  };

  // function for clear file value
  const resetLeadImageFile = () => {
    const file = document.getElementById("uploadedImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //update skillpoint block
  const updateSkillPointBlock = (skillpoint, index) => {
    setSkillPointIndex(index);
    setSkillPointData(skillpoint);
  };

  //function for delete skillpoint block
  const onDeleteSkillPointBlock = (index) => {
    const filteredSkillPointBlock = [...skillPointListBlock];
    filteredSkillPointBlock.splice(index, 1);
    setSkillPointListBlock(filteredSkillPointBlock);
  };

  //function for view skillpoint blocks
  const getSkillPointsBlock = (skillPoints) => {
    const skillPintArr =
      skillPoints &&
      skillPoints.map((skillPoint) => {
        return {
          _id: skillPoint?._id,
          skill: skillPoint?.skill,
          skillvalue: skillPoint?.skillvalue,
          skillname: skillPoint?.skillname,
          points: skillPoint?.points,
          experienceyear: skillPoint?.experienceyear,
        };
      });
    setSkillPointListBlock(skillPintArr);
  };

  //*function for edit experience block
  const updateExperienceBlock = (experience, index) => {
    setExperienceIndex(index);
    setExperienceData(experience);
  };

  //function for delete experience block
  const onDeleteExperienceBlock = (index) => {
    const filteredExperienceBlock = [...experienceListBlock];
    filteredExperienceBlock.splice(index, 1);
    setExperienceListBlock(filteredExperienceBlock);
  };

  // function for assign experience blocks */
  const getExperienceBlock = (experiences) => {
    let experienceArr = [];

    experiences &&
      experiences.length > 0 &&
      experiences.map((experience) => {
        let expSavedData = {
          _id: experience?._id,
          companyid: experience.companyid,
          company: experience?.company,
          end: experience?.end,
          position: experience?.position,
          start: experience?.start,
          ctc: experience?.ctc,
          responsibilities: experience?.responsibilities,
        };
        experienceArr.push(expSavedData);
      });
    setExperienceListBlock(experienceArr);
  };

  //function for edit project block ---------------------------------------
  const updateProjectBlock = (project, index) => {
    setProjectIndex(index);
    setProjectData(project);
  };

  //function for delete project block
  const onDeleteProjectBlock = (index) => {
    const filteredProjectBlock = [...projectListBlock];
    filteredProjectBlock.splice(index, 1);
    setProjectListBlock(filteredProjectBlock);
  };

  //** function for assign project blocks */
  const getProjectBlock = (projects) => {
    let projectArr = [];

    projects &&
      projects.length > 0 &&
      projects.map((project) => {
        let projectSavedData = {
          _id: project?._id,
          company: project?.company,
          end: project?.end,
          position: project?.position,
          start: project?.start,
          ctc: project?.ctc,
          technologies: project?.technologies,
          responsibilities: project?.responsibilities,
        };
        projectArr.push(projectSavedData);
      });

    setProjectListBlock(projectArr);
  };

  // function for edit eduction block */ -------------------------------------
  const updateEductionBlock = (education, index) => {
    setEducationIndex(index);
    setEducationData(education);
  };

  //** function for delete eduction block from education block list array */
  const onDeleteEducationBlock = (index) => {
    const filteredEducationBlock = [...educationListBlock];
    filteredEducationBlock.splice(index, 1);
    setEducationListBlock(filteredEducationBlock);
  };

  //** function for assign education blocks */
  const getEducationBlock = (educations) => {
    let educationArr = [];

    educations &&
      educations.length > 0 &&
      educations.map((education) => {
        let educationSavedData = {
          _id: education?._id,
          degree: education?.degree,
          grade: education?.grade,
          year: education?.year,
          institute: education?.institute,
        };
        educationArr.push(educationSavedData);
      });
    setEducationListBlock(educationArr);
  };

  //** function for edit technical experence block */ ----------------------------------
  const updateTechnicalExpBlock = (techExp, index) => {
    settechnicalExperienceData(techExp);
    settechnicalExpIndex(index);
  };

  //** delete tech exp block from expericence list */
  const onDeleteTechExpBlock = (index) => {
    const filteredTechExpBlock = [...technicalExpBlock];
    filteredTechExpBlock.splice(index, 1);
    settechnicalExpBlock(filteredTechExpBlock);
  };

  //** function for view tech exp blocks */
  const getTechExpBlock = (techExp) => {
    let techExpArr = [];

    techExp &&
      techExp.length > 0 &&
      techExp.map((techExpData) => {
        const companyvalue = {
          label: techExpData?.companyname,
          value: techExpData?.companyname,
        };
        let certificateSavedData = {
          _id: techExpData?._id,
          name: techExpData?.name,
          companyname: techExpData?.companyname,
          companyvalue,
          start: techExpData?.start,
          end: techExpData?.end,
          description: techExpData?.description,
          grade: techExpData?.grade,
        };
        techExpArr.push(certificateSavedData);
      });
    settechnicalExpBlock(techExpArr);
  };

  //** function for get profile information of logged in user */
  const getUserProfileDetails = async () => {
    // reset all
    resetCVHandler();
    setFetchingProfileData(true);
    // try {
    //   let requestUrl =
    //     url.API_BASE_URL +
    //     url.API_GET_USER_DETAILS +
    //     `/${userInfo._id}` +
    //     `?token=${token}`;

    //   console.log("requestUrl", requestUrl);

    //   const response = await getData(requestUrl);

    //   console.log("response in fetch profile", response);

    //   if (response.status && response.data) {
    //     const data = response.data;

    //     setuserImagePath(data.photoimage?.path ?? "");
    //     setuserImageId(data.photoimage?._id ?? "");
    //     setuserPosition(data.position);
    //     setuserEmail(data.email);
    //     setuserName(data.name);
    //     setuserSurname(data.surname);

    //     setuserSummary(data.summary);
    //     setuserDetails(data.about);

    //     getSkillPointsBlock(data.skillpointidsextended);
    //     getExperienceBlock(data.experienceids);
    //     getEducationBlock(data.educationids);
    //     getTechExpBlock(data.certifications);
    //     setuserOptionalSkills(data.userskills);
    //     setuserPortfolioLinks(
    //       data.portfoliolink.map((item) => ({
    //         linktext: item,
    //       }))
    //     );

    //     setuserHobbies(data.hobbies);

    //     if (data.experienceids.length > 0) {
    //       let maxCtc = data.experienceids[0].ctc; // Initialize maxSalary with the salary of the first element

    //       for (let i = 1; i < data.experienceids.length; i++) {
    //         if (data.experienceids[i].ctc > maxCtc) {
    //           maxCtc = data.experienceids[i].ctc; // Update maxSalary if current salary is larger
    //         }
    //       }

    //       setuserCurrCtc(maxCtc);
    //     }

    //     setFetchingProfileData(false);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
  };

  //** initially set user cv info from local storage */
  const setUserCvDetails = () => {
    setuserImagePath("");
    setuserImageId();
    setuserPosition("Applicant");
    setuserEmail("applicant@lobees.com");
    setuserName("Name");
    setuserSurname("Surname");
  };

  //** function for get cv details of logged in user */
  const getCvDetails = async () => {
    try {
      setIsCvLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_CV_DETAILS + `/${cvId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in cv details", response);

      if (response.status && response.data) {
        const data = response.data;

        setCvTitle(data.resumetitle);
        setuserImagePath(data.cvimagepath);
        setuserImageId(data.cvimageid);
        setuserEmail(data.candidateemail);
        setuserName(data.candidatename);
        setuserSurname(data?.candidatesurnname);
        setuserPhone(data.candidatephone);
        setUserExperienceInYears(data.experience);

        setuserSummary(data.summary);
        setuserDetails(data.details);

        // get blocks ---------------
        getSkillPointsBlock(data.cvskillpoint);
        getExperienceBlock(data.cvexperience);
        getProjectBlock(data.projectexperiences);
        getEducationBlock(data.educationids);
        getTechExpBlock(data.certifications);

        setuserCurrency(data.currency);
        setuserCurrCtc(data.currentctc);
        setuserExpctCtc(data.expectedctc);
        setuserWorkingHours(data.weeklyworkhour);
        setuserStartTime(data.starttime);
        setuserEndTime(data.endtime);
        setuserCountry(data.country);
        setuserOptionalSkills(data.optionalskillsarr);

        setuserPortfolioLinks(
          data.portfoliolink && data.portfoliolink.length > 0
            ? data.portfoliolink.map((item) => ({
                linktext: item,
              }))
            : []
        );

        setuserHobbies(data.hobbies);

        setuserRoles(
          data.roles && data.roles.length > 0
            ? data.roles.map((item) => ({
                rolestext: item,
              }))
            : []
        );

        setUserLanguages(
          data.languages && data.languages.length > 0
            ? data.languages.map((item) => ({
                languagetext: item,
              }))
            : []
        );

        setuserOtherSkills(
          data.otherskills && data.otherskills.length > 0
            ? data.otherskills.map((item) => ({
                skilltext: item,
              }))
            : []
        );

        setUserCareerHighlights(
          data.carrierhighlights && data.carrierhighlights.length > 0
            ? data.carrierhighlights.map((item) => ({
                highlighttext: item,
              }))
            : []
        );
      }

      setIsCvLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //** save cv function */
  const saveCvHandler = async (isChallangeAvailable) => {
    if (validationHandler()) {
      setIsCvUpdating(true);
      try {
        const savedOptionalSkills = userOptionalSkills.map(
          (item) => item.value
        );
        let userCvData = {
          //candidateid: userInfo._id,
          candidatename: userName,
          candidatesurnname: userSurname,
          candidateposition: userPosition,
          post: title,
          candidatephone: userPhone,
          candidateemail: userEmail,
          roles: userRoles,
          resumetitle: cvTitle,
          summary: userSummary,
          details: userDetails,
          skillpointids: skillPointListBlock,
          educationids: educationListBlock,
          experienceinyears: userExperienceInYears,
          experienceids: experienceListBlock,
          projectreferenceids: projectListBlock,
          certificationids: technicalExpBlock,
          optionalskills: savedOptionalSkills,
          otherskills: userOtherSkills,
          languages: userLanguages,
          currentctc: userCurrCtc,
          expectedctc: userExpctCtc,
          currency: userCurrency,
          weeklyworkhour: userWorkingHours,
          starttime: userStartTime,
          endtime: userEndTime,
          country: userCountry,
          portfoliolink: userPortfolioLinks,
          hobbies: userHobbies,
          carrierhighlights: userCarrierHighlights,
          profilepicture: userImageId || null,
          //issaveandapply: isChallangeAvailable,
          challengeid: params.id,
        };

        console.log("update userCvData", userCvData);
        //setisSaving(true);

        let requestUrl = url.API_BASE_URL;
        //let response = {};

        if (cvId && userCvData) {
          requestUrl += url.API_UPDATE_CV + `/${cvId}?token=${token}`;

          console.log("reqUrl", requestUrl);

          const response = await putData(requestUrl, userCvData);

          console.log("response >>", response);
        }

        setIsCvUpdating(false);

        // if (response.status) {
        //   let successModal = new bootstrap.Modal(
        //     document.getElementById("apply_success_mdl")
        //   );
        //   successModal.show();
        // }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrorMessage("Please fill up required fields");
    }
  };

  //** function for reload after apply function */
  // const afterJobApply = () => {
  //   setIsSaveChallangeAvailable(false);
  //   if (cvId) {
  //     getCvDetails();
  //   } else {
  //     resetCVHandler();
  //   }
  //   setreloadCvList(true);
  // };

  //reset all state after save cv
  const resetCVHandler = () => {
    setuserImagePath("");
    setuserImageId("");
    setUserCvDetails();
    setCvTitle("");
    setuserSummary("");
    setuserDetails("");
    setSkillPointData({
      skill: null,
      skillvalue: null,
      skillname: "",
      points: "",
      experienceyear: "",
    });
    setSkillPointListBlock([]);
    setSkillPointIndex(null);

    setExperienceData({
      companyid: null,
      company: "",
      position: "",
      start: "",
      end: "",
      ctc: "",
      responsibilities: "",
    });
    setExperienceListBlock([]);
    setExperienceIndex(null);

    setProjectData({
      company: "",
      position: "",
      start: "",
      end: "",
      ctc: "",
      technologies: "",
      responsibilities: "",
    });
    setProjectListBlock([]);
    setProjectIndex(null);

    setEducationData({
      degree: "",
      year: "",
      grade: "",
      institute: "",
    });
    setEducationListBlock([]);
    setEducationIndex(null);

    settechnicalExperienceData({
      name: "",
      companyvalue: null,
      companyname: "",
      start: "",
      end: "",
      description: "",
      grade: "",
    });
    settechnicalExpBlock([]);
    settechnicalExpIndex(null);

    setUserLanguages([]);
    setuserCurrency("");
    setuserCurrCtc("");
    setuserExpctCtc("");
    setuserWorkingHours("");
    setuserStartTime("");
    setuserEndTime("");
    setuserCountry("");
    setuserOptionalSkills([]);
    setuserPortfolioLinks([]);
    setuserHobbies([]);
    setuserRoles([]);
    setuserOtherSkills([]);
    setUserCareerHighlights([]);
  };

  //** function for send uploaded doc file url to server or gpt api */
  const getDocumentDataUsingAI = async () => {
    // try {
    //   // show wait while we fetch and map the data from AI
    //   setFetchingUploadedData(true);
    //   if (docContent.length > 10) {
    //     let aiParams = {
    //       message: docContent,
    //       assistantid: assistantId,
    //       threadid: threadId,
    //     };
    //     console.log("aiParams", aiParams);
    //     let requestUrl =
    //       url.API_BASE_URL + url.API_CHAT_GPT_CV_ASSISTANT + `?token=${token}`;
    //     console.log("AI requestUrl", requestUrl);
    //     const response = await postData(requestUrl, aiParams);
    //     console.log("gpt response data", response);
    //     if (response.status) {
    //       setFetchingUploadedData(false); // stop showing loading
    //       setAssistantId(response.assistantid); // keep the assistantid for next request
    //       setThreadId(response.threadid);
    //       setAiJsonContent(response.jsoncontent); // add the json content as received for mapping
    //     }
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
  };

  // reset AI request params, before next upload
  const resetAiData = () => {
    setAiJsonContent({});
    setFetchingUploadedData(false);
    setUploadedDocUrl("");
  };

  // Map the content from ai with our cv builder -----------------
  const mapAiContent = (aidata) => {
    if (aidata) {
      setuserImagePath("");
      setuserImageId("");
      setCvTitle(aidata.cvtitle);
      setuserName(aidata.name);
      setuserSurname(aidata.surname);
      setuserEmail(aidata.email);
      setuserPhone(aidata.phone);
      setuserPosition(aidata.current_position);
      setcompanyName(aidata.company);
      setUserAddress(aidata.address);
      setUserZipcode(aidata.zipcode);

      setuserSummary(aidata.summary);
      setuserDetails(aidata.details);
      setUserExperienceInYears(aidata.total_experience_in_years);

      //assign skillpoints
      if (aidata.skills) {
        const skillPintArr = aidata?.skills
          ?.filter(
            (item) =>
              (item.name && item.name !== null) ||
              (item.name && item.name !== "")
          )
          .map((skill) => {
            return {
              skill: null,
              skillvalue: null,
              skillname: skill?.name || "",
              points: skill?.score || "0",
              experienceyear: skill?.experience || "0",
            };
          });
        setSkillPointListBlock(skillPintArr);
      }

      //assign experience
      if (aidata.experience) {
        const experienceArr = aidata?.experience
          ?.filter(
            (item) =>
              (item.company && item.company != null) ||
              (item.company && item.company != "")
          )
          .map((experience) => {
            return {
              companyid: null,
              company: experience?.company || "",
              end: experience?.end_year || "",
              position: experience?.position || "",
              start: experience?.start_year || "",
              ctc: experience?.salary || "",
              responsibilities: experience?.responsibilities || "",
            };
          });
        setExperienceListBlock(experienceArr);
      }

      //assign education
      if (aidata.education) {
        const educationArr = aidata?.education
          ?.filter(
            (item) =>
              (item.degree && item.degree !== null) ||
              (item.degree && item.degree != "")
          )
          .map((educationData) => {
            return {
              degree: educationData?.degree,
              grade: educationData?.grade,
              year: educationData?.year,
              institute: educationData?.institute,
            };
          });
        setEducationListBlock(educationArr);
      }

      // assign technical certificates
      if (aidata.certification) {
        const techExpArr = aidata?.certification
          ?.filter(
            (item) =>
              (item.course && item.course != null) ||
              (item.course && item.course != "")
          )
          .map((techExpData) => {
            const companyvalue = {
              label: techExpData?.company || "",
              value: techExpData?.company || "",
            };

            return {
              name: techExpData?.course || "",
              companyname: techExpData?.company || "",
              companyvalue,
              start: techExpData.start_date || "",
              end: techExpData.end_date || "",
              description: techExpData?.description || "",
              grade: techExpData?.grade || "",
            };
          });
        settechnicalExpBlock(techExpArr);
      }

      //assign portfolio links
      if (aidata.portfolio_links) {
        const filteredPortfolioLinks = aidata.portfolio_links.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );
        setuserPortfolioLinks(
          filteredPortfolioLinks.map((item) => ({
            linktext: item,
          }))
        );
      }

      //assign user hobbies
      if (aidata.hobbies) {
        const filteredHobbies = aidata.hobbies.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserHobbies(filteredHobbies);
      }

      //assign user roles
      if (aidata.roles) {
        const filteredRoles = aidata.roles.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserRoles(
          filteredRoles.map((item) => ({
            rolestext: item,
          }))
        );
      }

      //assign user language
      if (aidata.languages) {
        const filteredLanguages = aidata.languages.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setUserLanguages(
          filteredLanguages.map((item) => ({
            languagetext: item,
          }))
        );
      }

      //assign other skill or soft skill to say
      if (aidata.otherskills) {
        const filteredOtherskills = aidata.otherskills.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserOtherSkills(
          filteredOtherskills.map((item) => ({
            skilltext: item,
          }))
        );
      }

      // carrier highlights
      if (aidata.carrierhighlights) {
        const filteredCarrierHighlights = aidata.carrierhighlights.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setUserCareerHighlights(
          filteredCarrierHighlights.map((item) => ({
            highlighttext: item,
          }))
        );
      }

      //assign experience
      if (aidata.projects) {
        const projectArr = aidata?.projects
          ?.filter(
            (item) =>
              (item.company && item.company != null) ||
              (item.company && item.company != "")
          )
          .map((experience) => {
            return {
              companyid: null,
              company: experience?.company || "",
              end: experience?.end_year || "",
              position: experience?.role || "",
              start: experience?.start_year || "",
              technologies: experience?.technologies || "",
              responsibilities: experience?.responsibilities || "",
            };
          });
        setProjectListBlock(projectArr);
      }
    }
  };

  const handelCustomerCvContent = () => {
    const cvContentData = {
      userImagePath: userImagePath,
      userName: userName,
      userSurname: userSurname,
      userEmail: userEmail,
      userPhone: userPhone,
      userPosition: userPosition,
      userRoles: userRoles,
      userExperienceInYears: userExperienceInYears,
      companyName: companyName,
      userAddress: userAddress,
      userZipcode: userZipcode,
      userSummary: userSummary,
      userDetails: userDetails,
      skillPointListBlock: skillPointListBlock,
      experienceListBlock: experienceListBlock,
      projectListBlock: projectListBlock,
      educationListBlock: educationListBlock,
      technicalExpBlock: technicalExpBlock,
      userLanguages: userLanguages,
      userOtherSkills: userOtherSkills,
      userPortfolioLinks: userPortfolioLinks,
      userCarrierHighlights: userCarrierHighlights,
      userHobbies: userHobbies,
    };

    setCvContent(cvContentData);
  };

  //function for get project details
  // const getChallengeResponseDetails = async () => {

  //   setIsLoading(true);
  //   try {
  //     let requestUrl =
  //       url.API_BASE_URL +
  //       url.API_GET_CHALLENGE_RESPONSE_DETAILS +
  //       `/${challengeResponseId}?token=${token}`;

  //     const response = await getData(requestUrl);

  //     console.log('challenge response', response);

  //     if (response.status && response.data) {
  //       const data = response.data;
  //       setChallengeResponseDetails(data);

  //       setCvId(data.candidatecvid ? data.candidatecvid._id : null);

  //     }

  //     setIsLoading(false);

  //   } catch (error) {
  //     console.log(error.message);
  //   }

  // };

  // useEffect(() => {
  //   if (challengeResponseId) {
  //     getChallengeResponseDetails(challengeResponseId);
  //   }
  // }, [challengeResponseId]);

  // map the content only when we have the json data from AI api
  useEffect(() => {
    // console.log("aiJsonContent >>>", aiJsonContent);
    mapAiContent(aiJsonContent);
  }, [aiJsonContent]);

  // send cvContent to right panel
  useEffect(() => {
    handelCustomerCvContent();
  }, [
    userImagePath,
    userName,
    userSurname,
    userEmail,
    userPhone,
    userPosition,
    companyName,
    userAddress,
    userZipcode,
    userSummary,
    userDetails,
    skillPointListBlock,
    experienceListBlock,
    projectListBlock,
    educationListBlock,
    technicalExpBlock,
    userLanguages,
    userPortfolioLinks,
    userCarrierHighlights,
    userExperienceInYears,
    userSummary,
    userHobbies,
  ]);

  //** use effect for send uploaded doc file url to server
  useEffect(() => {
    if (docContent.length > 10) {
      console.log("docContent", docContent);
      getDocumentDataUsingAI();
    }
  }, [docContent]);

  //** use effect for reload cv details when user select a cv from previously created cv from right panel */
  useEffect(() => {
    if (cvId) {
      getCvDetails();
    }
  }, [cvId]);

  //** use effect for reload user profile details when user click fetch from profile from right panel */
  useEffect(() => {
    if (startFetchFromProfile) {
      getUserProfileDetails();
      setStartFetchFromProfile(false);
    }
  }, [startFetchFromProfile]);

  //   //** use effect for initially fetch job details , user cv details , all skill list */
  //   useEffect(() => {
  //     //if (params.id) {
  //     setUserCvDetails();
  //     getAllSkills();
  //     //}
  //   }, []);

  // set as CV is added or not
  //   useEffect(() => {
  //     if (
  //       (userName.length > 2 && userName != "Name") ||
  //       (userEmail.length > 3 && userEmail != "applicant@lobees.com")
  //     ) {
  //       setCvAdded(true);
  //     } else {
  //       setCvAdded(false);
  //     }
  //   }, [userName, userSurname, userEmail]);

  return (
    <div className="builder_leftpnl">
      {/* <!-- ========== Start CV Section ========== --> */}
      <div className="builder_item white_shadow_bx px-4 py-0 pt-4 mb-3">
        {/* <!-- ========== Important CV information ========== --> */}
        <p className="text-primary">
          {isLoading ? (
            <span
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <i className="visually-hidden">Loading...</i>
            </span>
          ) : (
            <>
              <span>
                <b className="text-black">Code</b> :{" "}
                {challengeResponseDetails.responsecode}{" "}
              </span>
              <span className="ms-4">
                {" "}
                <b className="text-black"> Status </b>:{" "}
                {challengeResponseDetails.approvalstatus === "1"
                  ? "Accepted"
                  : challengeResponseDetails.approvalstatus === "2"
                  ? "Rejected"
                  : "Pending Approval"}
              </span>

              <span className="ms-4">
                {" "}
                <b className="text-black"> Expected CTC </b>:{" "}
                {challengeResponseDetails.cvexpectedctc}{" "}
                {challengeResponseDetails.cvcurrency}
              </span>
            </>
          )}
        </p>

        {/* <!-- ========== Start cv title Section ========== --> */}
        <h3 className="mt-2 ">
          {isCvLoading ? (
            <span
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <i className="visually-hidden">Loading...</i>
            </span>
          ) : (
            ""
          )}

          {cvTitle ? cvTitle : ""}
        </h3>

        {/* <!-- ========== Start name, image and email Section ========== --> */}
        <div className="build_innr_row py-4 overflow-hidden">
          <div className="builder_usr_row d-flex justify-content-between">
            <div className="build_usrleft_pnl d-flex gap-3">
              <div className="build_usrpic position-relative">
                <figure>
                  <img
                    className="upload_img"
                    src={
                      userImagePath == ""
                        ? assetImages.defaultUser
                        : url.SERVER_URL + userImagePath
                    }
                    alt=""
                  />
                </figure>
                <a href="#" className="upload_camera">
                  <input
                    type="file"
                    className="file_btn"
                    id="uploadedImageFile"
                    onChange={imageUploadHandler}
                  />
                  <span className="material-symbols-outlined">
                    photo_camera
                  </span>
                </a>
              </div>

              <div className="build_usrinfo">
                <h3 className={`${cvAdded ? "" : "text-gray"} `}>
                  {userName} {userSurname}
                  <span className="d-block">{userPosition}</span>{" "}
                </h3>
                <ul className="list_stye_none d-flex align-items-center mt-3 gap-4 mb-3">
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">email</i>
                    <span>{userEmail}</span>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">phone</i>
                    <span>{userPhone}</span>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">work_history</i>
                    <span>{userExperienceInYears}</span> + Years
                  </li>
                </ul>
              </div>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          {validationError.cvTitleError && (
            <div className="d-flex align-items-center justify-content-end">
              <p className="text-danger fw-semibold">* CV title required</p>
            </div>
          )}
        </div>

        {/* <!-- ========== End name, image and email Section ========== --> */}

        {/* <!-- ========== Start roles Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Roles</h3>
            </div>
            <div className="build_edit_icon">
              <Link to="#" data-bs-toggle="modal" data-bs-target="#roles_modal">
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none flex-wrap">
              {userRoles.map((roleData, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{roleData.rolestext}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End roles Section ========== --> */}

        {/* <!-- ========== Carrier Highlights Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Carrier Highlights</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#carrierHighlights_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {userCarrierHighlights.map((highlight, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-0"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo"></div>
                    <div className="exp_logo_details">
                      <h4>
                        <span>{highlight.highlighttext} </span>
                      </h4>
                    </div>
                  </div>
                  <div className="build_edit_icon"></div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== Carrier Highlights Section ========== --> */}

        {/* <!-- ========== Start summary Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Summary</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#summery_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="build_para">
            <p>{userSummary}</p>
          </div>
        </div>
        {/* <!-- ========== End summary Section ========== --> */}

        {/* <!-- ========== Start details Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Details</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#details_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="build_para">
            <p dangerouslySetInnerHTML={{ __html: userDetails }}></p>
          </div>
        </div>
        {/* <!-- ========== End details Section ========== --> */}

        {/* <!-- ========== Start skill points Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Skill Points</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#skill_modal"
                onClick={() => {
                  setSkillPointIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {skillPointListBlock.map((skillBlock, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo"></div>
                    <div className="exp_logo_details">
                      <h4>
                        {skillBlock?.skillname}{" "}
                        <span>with rating {skillBlock?.points}/10 </span>
                      </h4>

                      <h6>
                        Experience
                        <span> {skillBlock?.experienceyear} year</span>
                      </h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#skill_modal"
                      onClick={() => {
                        updateSkillPointBlock(skillBlock, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <button
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-black border-0 bg-white"
                      onClick={() => {
                        onDeleteSkillPointBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End skill points Section ========== --> */}

        {/* <!-- ========== Start experiences Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Work Experience</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#experience_modal"
                onClick={() => {
                  setExperienceIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {experienceListBlock.map((experience, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      <img
                        src={
                          experience.companylogo == ""
                            ? ""
                            : url.SERVER_URL + experience.companylogo
                        }
                        alt=""
                      />
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {experience.position}
                        <span>
                          {" "}
                          at{" "}
                          {experience.companyid
                            ? experience.companyid.label
                            : experience.company}
                        </span>
                      </h4>
                      <h6>
                        {experience.start.length > 5
                          ? getDateFormatInSpanish(experience.start) +
                            " - " +
                            getDateFormatInSpanish(experience.end)
                          : experience.start + " - " + experience.end}
                      </h6>
                      <p>{experience.responsibilities}</p>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_modal"
                      onClick={() => {
                        updateExperienceBlock(experience, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteExperienceBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End experiences Section ========== --> */}

        {/* <!-- ========== Start project references Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Project References</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#projectreference_modal"
                onClick={() => {
                  setProjectIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {projectListBlock.map((project, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo"></div>
                    <div className="exp_logo_details">
                      <h4>
                        {project.company}
                        <span>
                          {" "}
                          ||{" "}
                          {project.start.length > 5
                            ? getDateFormatInSpanish(project.start) +
                              " - " +
                              getDateFormatInSpanish(project.end)
                            : project.start + " - " + project.end}
                        </span>
                      </h4>
                      <h6>
                        <p>Role : {project.position}</p>
                        <p>Technologies : {project.technologies}</p>
                      </h6>
                      <h6>{project.responsibilities}</h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#projectreference_modal"
                      onClick={() => {
                        updateProjectBlock(project, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteProjectBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End project references Section ========== --> */}

        {/* <!-- ========== Start education Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Education</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#education_modal"
                onClick={() => {
                  setEducationIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {educationListBlock.map((education, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      <img src="" alt="" />
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {education.degree}
                        <span> from {education.institute}</span>
                      </h4>
                      <h6>{education.year}</h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#education_modal"
                      onClick={() => {
                        updateEductionBlock(education, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteEducationBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End education Section ========== --> */}

        {/* <!-- ========== Start technical certificates  Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Technical Certificates</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#technical_certificate"
                onClick={() => {
                  settechnicalExpIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {technicalExpBlock.map((techExpData, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      <img src="images/technical-icon1.png" alt="" />
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {techExpData.name}{" "}
                        <p>
                          From {techExpData.companyname}
                          <span> with grade: {techExpData.grade}</span>
                        </p>
                      </h4>
                      <h6>
                        {techExpData.start &&
                          getDateFormatInSpanish(techExpData.start)}{" "}
                        -{" "}
                        {techExpData.end &&
                          getDateFormatInSpanish(techExpData.end)}
                      </h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#technical_certificate"
                      onClick={() => {
                        updateTechnicalExpBlock(techExpData, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteTechExpBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End technical certificates  Section ========== --> */}

        {/* <!-- ========== Start languages Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Languages</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#language_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags language_filed">
            <ul className="list_stye_none">
              {userLanguages.map((language, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{language.languagetext}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End languages Section ========== --> */}

        {/* <!-- ========== Start salary and timings Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Salary and Availability</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#salary_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="gray_bx_outer d-flex gap-2">
            <div className="gray_bx_item">
              <h4 className="mb-2">Current CTC</h4>
              <h6>
                {userCurrCtc} {userCurrency}
              </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Expected CTC</h4>
              <h6>
                {userExpctCtc} {userCurrency}
              </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Total Monthly Hours</h4>
              <h6>{userWorkingHours} </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Availability Timings</h4>
              <h6>
                {userStartTime} - {userEndTime} {userCountry}
              </h6>
            </div>
          </div>
        </div>
        {/* <!-- ========== End salary and timings Section ========== --> */}

        {/* <!-- ========== Start optional soft skills Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Soft Skills</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#otherSkills_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none flex-wrap">
              <div className="event_tags">
                <ul className="list_stye_none flex-wrap">
                  {userOtherSkills.map((skillData, index) => {
                    return (
                      <li key={index}>
                        <Link to="#">{skillData.skilltext}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </ul>
          </div>
        </div>
        {/* <!-- ========== End optional soft skills Section ========== --> */}

        {/* <!-- ========== Start portfolio Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Portfolio Links</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#portfolio_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags portfolio_links">
            <ul className="list_stye_none">
              {userPortfolioLinks.map((portfolioLink, index) => {
                return (
                  <li key={index}>
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i>
                        <img src="images/portfolio-link-icon1.png" alt="" />
                      </i>
                      <span>{portfolioLink.linktext}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End portfolio Section ========== --> */}

        {/* <!-- ========== Start hobbies Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <h3>Hobbies</h3>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#hobbies_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none flex-wrap">
              {userHobbies.map((hobbiesData, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{hobbiesData}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End hobbies Section ========== --> */}
      </div>
      {/* <!-- ========== End CV Section ========== --> */}

      {/* <!-- ========== Start apply and submit section component ========== --> */}
      <div className="action d-flex flex-wrap align-items-center gap-2 mt-4">
        <Link className="btn btn-outline-primary" to="#">
          Back to Responses
        </Link>
        <button className="btn btn-primary" onClick={saveCvHandler}>
          {isCvUpdating ? (
            <span
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <i className="visually-hidden">Loading...</i>
            </span>
          ) : (
            ""
          )}
          Save Changes
        </button>
      </div>
      {/* <!-- ========== End apply and submit section component ========== --> */}

      {/* <!-- ========== Start all modal components ========== --> */}
      <PersonalInfoModal
        setErrorMessage={setErrorMessage}
        setCvTitle={setCvTitle}
        setuserName={setuserName}
        setuserSurname={setuserSurname}
        setuserEmail={setuserEmail}
        setuserPosition={setuserPosition}
        setuserPhone={setuserPhone}
        setUserExperienceInYears={setUserExperienceInYears}
        cvTitle={cvTitle}
        userName={userName}
        userSurname={userSurname}
        userEmail={userEmail}
        userPosition={userPosition}
        userPhone={userPhone}
        userExperienceInYears={userExperienceInYears}
      />

      <RolesModal userRoles={userRoles} setuserRoles={setuserRoles} />

      <CarrierHighlightsModal
        userCarrierHighlights={userCarrierHighlights}
        setUserCareerHighlights={setUserCareerHighlights}
      />

      <SummaryModal userSummary={userSummary} setuserSummary={setuserSummary} />

      <DetailsModal userDetails={userDetails} setuserDetails={setuserDetails} />

      <SkillPointModal
        setSkillPointData={setSkillPointData}
        setSkillPointIndex={setSkillPointIndex}
        setSkillPointListBlock={setSkillPointListBlock}
        skillPointData={skillPointData}
        skillPointIndex={skillPointIndex}
        skillPointListBlock={skillPointListBlock}
      />

      <ExperienceModal
        experienceData={experienceData}
        setExperienceData={setExperienceData}
        experienceListBlock={experienceListBlock}
        setExperienceListBlock={setExperienceListBlock}
        experienceIndex={experienceIndex}
        setExperienceIndex={setExperienceIndex}
      />

      <ProjectReferencesModal
        projectData={projectData}
        setProjectData={setProjectData}
        projectListBlock={projectListBlock}
        setProjectListBlock={setProjectListBlock}
        projectIndex={projectIndex}
        setProjectIndex={setProjectIndex}
      />

      <EducationModal
        educationIndex={educationIndex}
        setEducationIndex={setEducationIndex}
        educationData={educationData}
        setEducationData={setEducationData}
        educationListBlock={educationListBlock}
        setEducationListBlock={setEducationListBlock}
      />

      <TechnicalCertificateModal
        technicalExpIndex={technicalExpIndex}
        settechnicalExpIndex={settechnicalExpIndex}
        technicalExperienceData={technicalExperienceData}
        settechnicalExperienceData={settechnicalExperienceData}
        technicalExpBlock={technicalExpBlock}
        settechnicalExpBlock={settechnicalExpBlock}
      />

      <LanguageModal
        userLanguages={userLanguages}
        setUserLanguages={setUserLanguages}
      />

      <SalaryModal
        setuserCurrency={setuserCurrency}
        setuserCurrCtc={setuserCurrCtc}
        setuserExpctCtc={setuserExpctCtc}
        setuserWorkingHours={setuserWorkingHours}
        setuserStartTime={setuserStartTime}
        setuserEndTime={setuserEndTime}
        setuserCountry={setuserCountry}
        userCurrency={userCurrency}
        userCurrCtc={userCurrCtc}
        userExpctCtc={userExpctCtc}
        userWorkingHours={userWorkingHours}
        userStartTime={userStartTime}
        userEndTime={userEndTime}
        userCountry={userCountry}
      />

      {/* soft skills  */}
      <OtherSkillsModal
        userOtherSkills={userOtherSkills}
        setuserOtherSkills={setuserOtherSkills}
      />

      <PortfolioModal
        userPortfolioLinks={userPortfolioLinks}
        setuserPortfolioLinks={setuserPortfolioLinks}
      />

      <HobbiesModal userHobbies={userHobbies} setuserHobbies={setuserHobbies} />

      {/* <ApplyWarningModal />
        <ApplySuccessModal
          isSaveChallangeAvailable={isSaveChallangeAvailable}
          afterModalClose={afterJobApply}
        /> */}

      {/* <!-- ========== End all modal components ========== --> */}
    </div>
  );
};

export default ChallengeResponseLeftPanel;
