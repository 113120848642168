import { assetImages } from "constants";
import React from "react";

const ApplySuccessModal = ({
  isApplied = false,
  jobTitle = "",
  showChallenge = false,
  afterModalClose = () => {},
}) => {
  return (
    <div
      className="modal fade"
      id="apply_success_mdl"
      tabIndex="-1"
      aria-labelledby="apply_success_mdl"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.congratulationsIcon}
              alt="successfuly."
              className="img-fluid mb-2"
            />
            <h2 className="mb-2">{isApplied ? "Sorry" : "Congratulations"}</h2>
            <p className="fs-lg fw-semibold mb-5">
              {isApplied
                ? "Challenge already applied."
                : showChallenge
                ? `You have applied to ${jobTitle} job successfully`
                : "You have saved a CV successfuly."}
            </p>
            <div className="action d-flex justify-content-center gap-3">
              <button
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={afterModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplySuccessModal;
