/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

// Material UI table
import { MaterialReactTable } from "material-react-table";

//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";

import GroupMembersHeader from "../GroupMembersHeader/GroupMembersHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import MemberDetailsModal from "../Modals/MemberDetailsModal";
import ChangeRoleModal from "../Modals/ChangeRoleModal";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AddNewMemberModal from "../Modals/AddNewMemberModal";
import MemberDetailsPopup from "../Popup/MemberDetailsPopup";
import { groupDetailsHeaderLinks } from "helper/GroupHelper/GroupHelper";
import { useTranslation } from "react-i18next";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const GroupMembersBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  // -------- tabs header object --------------------
  const commonHeaderObject = groupDetailsHeaderLinks(params.id, t);

  // const { screenHeight } = useContext(GlobalProvider);

  const [groupTitle, setgroupTitle] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);

  const [memberId, setMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get group details
  const getgroupDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${params.id}?token=${token}`;

      // console.log("url of group details------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response of group details------>", response);

      if (response.status) {
        //assign title
        response.data?.title && setgroupTitle(response.data?.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_GROUP`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllGroupMembers = async () => {
    setRowSelection({});
    setSelectedMemberIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_GROUP_MEMBERS +
        `?token=${token}&group=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);
      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setMemberList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //function for change status
  const deleteMassiveMemberHandler = async () => {
    if (selectedMemberIds.length > 0) {
      const memberListToBeDelete = memberList.filter((item) =>
        selectedMemberIds.includes(item._id)
      );

      try {
        let memberInfo = {
          groupmembers: memberListToBeDelete,
          group: params.id,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_GROUP_MEMBERS +
          `?token=${token}`;

        const response = await putData(requestURL, memberInfo);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllGroupMembers();
          }, 2000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (params.id) {
      getAllGroupMembers();
      getgroupDetails();
    }
  }, [params.id]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Action"),
      Cell: ({ row }) => {
        const isModerator = row.original.role === "Moderator";
        return (
          <button
            className="action_btn_mui"
            {...(isModerator
              ? {}
              : {
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#offcanvasMemberDetails",
                  "aria-controls": "offcanvasMemberDetails",
                })}
            onClick={() => {
              if (!isModerator) {
                setMemberId(row.original._id);
              }
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        );
      },
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: t("Name"),
      Cell: ({ row }) => (
        <div className="title border-bottom-0">
          <div className="profile d-flex align-items-center gap-2">
            <div
              className="avatar rounded-circle overflow-hidden"
              style={{ width: "35px", height: "35px" }}
            >
              <img
                src={
                  row.original.image == ""
                    ? assetImages.defaultUser
                    : url.SERVER_URL + row.original.image
                }
                alt="member"
                className="w-100 h-100 object-fit-cover object-center"
              />
            </div>
            <div>
              <p className="fs-md fw-semibold mb-0">{row.original.name}</p>
              <Link to="#" className="d-block lh-1">
                {row.original.email}
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "role",
      header: t("Role"),
    },
    {
      accessorKey: "moderatorstatus",
      header: t("Status"),
      Cell: ({ row }) => (
        <div className="status border-bottom-0">
          <ul className="d-flex flex-wrap gap-3">
            {row.original.moderatorstatus == "" ? (
              <li className="d-flex align-items-center gap-1">
                <span className="d-block fw-semibold"></span>
              </li>
            ) : (
              <li className="d-flex align-items-center gap-1">
                <span
                  className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                    row.original.moderatorstatus == "1"
                      ? "text-success"
                      : row.original.moderatorstatus == "0"
                      ? "text-primary"
                      : "text-gray"
                  }`}
                >
                  check_circle
                </span>
                <span className="d-block fw-semibold">Moderator</span>
              </li>
            )}
            {row.original.memberstatus == "" ? (
              <li className="d-flex align-items-center gap-1">
                <span className="d-block fw-semibold">--</span>
              </li>
            ) : (
              <li className="d-flex align-items-center gap-1">
                <span
                  className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                    row.original.memberstatus == "1"
                      ? "text-success"
                      : row.original.memberstatus == "0"
                      ? "text-gray"
                      : "text-warning"
                  }`}
                >
                  check_circle
                </span>
                <span className="d-block fw-semibold">Member</span>
              </li>
            )}
          </ul>
        </div>
      ),
    },
    {
      accessorKey: "requestdate",
      header: t("Date"),
    },
    {
      accessorKey: "tagstring",
      header: t("Labels"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  /** Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [memberList, i18n.language]
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedMemberIds(selectedIdsArray);
    } else {
      setSelectedMemberIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <div id="content_wrapper">
        <section className="group-details-wrapper bg-white pb-5">
          {/* --- common header --- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Members")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- search / filter */}
            <GroupMembersHeader
              groupTitle={groupTitle}
              reloadList={getAllGroupMembers}
              deleteMemberHandler={deleteMassiveMemberHandler}
            />

            {/* ----- table section ----- */}

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={memberList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem
                  //     key="edit"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <li>
                  //       <Link to="#" className="dropdown-item">
                  //         <div className="form-check form-switch d-flex align-items-center gap-3 justify-content-between p-0 cursor-pointer m-0">
                  //           <label
                  //             className="form-check-label"
                  //             htmlFor="flexSwitchCheckDefault"
                  //           >
                  //             Enable/Disable
                  //           </label>
                  //           <input
                  //             className="form-check-input float-none m-0 shadow-none"
                  //             type="checkbox"
                  //             role="switch"
                  //             id="flexSwitchCheckDefault"
                  //           />
                  //         </div>
                  //       </Link>
                  //     </li>
                  //   </MenuItem>,
                  //   <MenuItem
                  //     key="delete"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <Link
                  //       className="dropdown-item"
                  //       to="#"
                  //       data-bs-toggle="modal"
                  //       data-bs-target="#changeRole"
                  //     >
                  //       Change the Role
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== modals and popup section ==== */}
        {/* ---- add label modal --- */}
        <AddLabelModal moduleName="" />

        {/* add new member modal */}
        <AddNewMemberModal
          roleList={roleList}
          afterCloseModalHandler={getAllGroupMembers}
        />

        {/* --- member details popup ---- */}
        <MemberDetailsPopup
          groupId={params.id}
          roleList={roleList}
          memberId={memberId}
          setMemberId={setMemberId}
          afterPopupClose={getAllGroupMembers}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default GroupMembersBody;
