import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import SaveLeadCvForm from "../common/SaveLeadCvForm";

const LeadCvBuilderBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const { id } = useParams();

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/lead/list" },
    { title: t("CV Builder") },
  ];

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Leads")}
        />
        <div className="container-fluid px-lg-5">
          <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />
          <SaveLeadCvForm
            showChallenge={false}
            previousUrl={`/admin/crm/lead/information/${id}`}
          />
        </div>
      </section>
    </div>
  );
};

export default LeadCvBuilderBody;
