/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import InvoiceDocListBody from "components/InvoiceDocComponents/InvoiceDocList/InvoiceDocListBody";

const InvoiceDocList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Invoice Docs";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="invoicedoc" />
      <InvoiceDocListBody />
    </main>
  );
};

export default InvoiceDocList;
