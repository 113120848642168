/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import CourseCertificationHeader from "../CourseCertificationHeader/CourseCertificationHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import CourseCertificationFilterPopup from "../Popup/CourseCertificationFilterPopup";
import CertificateDetailsPopup from "../Popup/CertificateDetailsPopup";
import { assetImages } from "constants";
import { courseDetailsHeaderObject } from "helper/CourseHelper/CourseHelper";

const CourseCertificationBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  const [title, settitle] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [certificateList, setCertificateList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});

  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // --get course details --
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all member
  const getAllCourseCertificates = async () => {
    setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COURSE_CERTIFICATE_LIST +
        `?token=${token}&filtercourse=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      setisLoading(false);

      if (response.status) {
        setCertificateList(response.data);
        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => {
          return (
            <button
              className="action_btn_mui"
              data-bs-toggle="offcanvas"
              data-bs-target="#certificateDetails"
              aria-controls="certificateDetails"
              onClick={() => {
                setSelectedSubscriberId(row.original._id);
              }}
            >
              <span className="d-block material-symbols-outlined horz_icon">
                more_horiz
              </span>
            </button>
          );
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: t("Name"),
        size: 200,
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    row.original.image == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.image
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">{row.original.name}</p>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "email",
        header: t("Email"),
      },
      {
        accessorKey: "joiningdate",
        header: t("Joined Date"),
        Cell: ({ row }) => (
          <div className="joined border-bottom-0">
            <p className="d-flex align-items-center gap-2">
              <span className="d-block material-symbols-outlined icon-md">
                calendar_month
              </span>
              <span className="d-block">{row.original.joiningdate}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: "completionpercentage",
        header: "% " + t("Completed"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <p>{row.original.completionpercentage} %</p>
              <div
                className="progress"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "90px", height: "8px" }}
              >
                <div
                  className={`${
                    row.original.coursestatus === "Completed"
                      ? "bg-success"
                      : "bg-primary"
                  }`}
                  style={{ width: `${row.original.completionpercentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "courseobtainedscore",
        header: t("Obtained Score"),
      },
      {
        accessorKey: "certificatestatus",
        header: t("Certificate Status"),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-2 fs-md fw-semibold ${
                row.original.iscertified ? "text-success" : "text-primary"
              }`}
            >
              <span className="d-block material-symbols-outlined icon-md">
                {row.original.iscertified ? "check_circle" : "access_time"}
              </span>

              <span className="d-block">
                {row.original.iscertified ? "Certified" : "In Progress"}
              </span>
            </p>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllCourseCertificates();
    }
  }, [params.id]);

  useEffect(() => {}, [rowSelection]);

  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Certification")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseCertificationHeader
              title={title}
              reloadList={getAllCourseCertificates}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={certificateList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modal section =========== */}

        {/* --------- add label modal ------------ */}
        <AddLabelModal moduleName="" />

        {/* ------- filter popup ------------- */}
        <CourseCertificationFilterPopup />

        {/* ------------ certificate detials popup --------- */}
        <CertificateDetailsPopup
          title={title}
          selectedSubscriberId={selectedSubscriberId}
          setSelectedSubscriberId={setSelectedSubscriberId}
          afterPopupClose={getAllCourseCertificates}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseCertificationBody;
