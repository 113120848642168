/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const DirectAccessFilterPopup = ({
  visibleColoumns,
  setVisibleColoumns,
  columnOrder,
  setColumnOrder,
  moduleName,

  setfilterTitle,
  setfilterUrl,
  setfilterUserIds,
  setfilterTags,
  setfilterStartDate,
  setfilterEndDate,

  isFilterReset,
  setIsFilterReset,

  setIsNoDefaultFilter,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [tagList, setTagList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, settitle] = useState("");
  const [accessUrl, setaccessUrl] = useState("");
  const [tags, setTags] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign columns
      if (jsonFilterData.visibleColoumns) {
        setVisibleColoumns(jsonFilterData.visibleColoumns);
      }

      //assign column order
      if (jsonFilterData.columnOrder && jsonFilterData.columnOrder.length > 0) {
        setColumnOrder(jsonFilterData.columnOrder);
      }

      //check date range type is not empty
      if (jsonFilterData.startDate) {
        setStartDate(jsonFilterData.startDate);
      }

      if (jsonFilterData.endDate) {
        setEndDate(jsonFilterData.endDate);
      }

      if (jsonFilterData.title) {
        settitle(jsonFilterData.title);
      }

      if (jsonFilterData.accessUrl) {
        setaccessUrl(jsonFilterData.accessUrl);
      }

      if (jsonFilterData.tags) {
        // Filtering the skills options based on values present in the json array
        const filteredTags = tagList.filter((item) =>
          jsonFilterData.tags.includes(item.value)
        );

        //run loop
        filteredTags.forEach((item) => {
          tagSelectionHandler(item);
        });
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = tags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");
      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      if (title !== "") {
        setfilterTitle(title);
      } else {
        setfilterTitle("");
      }

      if (accessUrl !== "") {
        setfilterUrl(accessUrl);
      } else {
        setfilterUrl("");
      }

      if (tags.length > 0) {
        setfilterTags(tags);
      } else {
        setfilterTags([]);
      }

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector(
        "#offcanvasCRMDirectAccessFilter"
      );

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            visibleColoumns: visibleColoumns ?? {},
            columnOrder: columnOrder ?? [],

            startDate,
            endDate,
            title,
            accessUrl,
            tags,
          }),
          moduleslug: moduleName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(true);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${moduleName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            console.log(jsonFilterData);

            //assign visible columns
            if (jsonFilterData.visibleColoumns) {
              setVisibleColoumns(jsonFilterData.visibleColoumns);
            }

            //assign column order
            if (
              jsonFilterData.columnOrder &&
              jsonFilterData.columnOrder.length > 0
            ) {
              setColumnOrder(jsonFilterData.columnOrder);
            }

            //check date range type is not empty
            if (jsonFilterData.startDate) {
              setfilterStartDate(jsonFilterData.startDate);
            } else {
              setfilterStartDate(filterStartDate);
            }

            if (jsonFilterData.endDate) {
              setfilterEndDate(jsonFilterData.endDate);
            } else {
              setfilterEndDate(filterEndDate);
            }

            if (jsonFilterData.title) {
              setfilterTitle(jsonFilterData.title);
            } else {
              setfilterTitle("");
            }

            if (jsonFilterData.accessUrl) {
              setfilterUrl(jsonFilterData.accessUrl);
            } else {
              setfilterUrl("");
            }

            if (jsonFilterData.tags.length > 0) {
              setfilterTags(jsonFilterData.tags);
            } else {
              setfilterTags(filterLabels);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setValidation({ dateWarning: false });
    setStartDate("");
    setEndDate("");
    settitle("");
    setaccessUrl("");
    setTags([]);
    removeActiveClass();
    resetSelectTags();

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    getAllTags();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCRMDirectAccessFilter"
      aria-labelledby="offcanvasCRMDirectAccessFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        {/* <!-- ========== Start popup close button Section ========== --> */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* <!-- ========== End popup close button Section ========== --> */}
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* <!-- ========== Start date range Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>

            {/* <Select
              placeholder={t("Filter date by")}
              isClearable
              options={FilterDateOptions}
              value={subscribeMailValue}
              onChange={(val) => {
                subscribeSelectionHandler(val);
              }}
            /> */}

            <div className="d-flex gap-2 ">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start title Section ========== --> */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter Title")}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>
          {/* <!-- ========== End title Section ========== --> */}

          {/* <!-- ========== Start access url Section ========== --> */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Access Url")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter Bookmark Url")}
              value={accessUrl}
              onChange={(e) => setaccessUrl(e.target.value)}
            />
          </div>
          {/* <!-- ========== End access url Section ========== --> */}

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start label/tag accordion Section ========== --> */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapselabels"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapselabels"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapselabels"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tag)}
                            id={"tagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End label/tag accordion Section ========== --> */}
          </div>

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default DirectAccessFilterPopup;
