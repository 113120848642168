import React from "react";
import { Link } from "react-router-dom";
//import dnds
import { Droppable, Draggable } from "react-beautiful-dnd";

import KanbanCardElem from "../KanbanCard/KanbanCardElem";
// import { formatNumber } from "helper/Common/CommonHelper";

const KanbanColumn = ({
  provided = {},
  columnData = {},
  viewAccessCount = 0,
  // totalCardCount = 0,
  setSelectedComponentTagId = () => {},
  collapseHandler = () => {},
  isProcessOwner = false,
  // setSelectedLeadId = () => {},
  deleteResponseHandler = () => {},
  createContactChatHandler = () => {},
  showAddNewIcon = false,
}) => {
  return (
    <div
      className="col-lg-3 d-flex flex-column gap-2 "
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center">
        <h3 className="lh-1 mb-0">{columnData.processtitle}</h3>
        <div className="counting_arws d-flex align-items-center gap-3">
          <h4 className="text-primary fw-semibold fs-md mb-0">
            {viewAccessCount}
          </h4>
          {/* {showAddNewIcon ? (
            <Link
              to="#"
              className="bg-gray-300 rounded-5 p-1"
              data-bs-toggle="offcanvas"
              data-bs-target="#addNewKanbanLead"
              aria-controls="addNewKanbanLead"
              onClick={() => {
                setSelectedComponentTagId(columnData.tagid);
              }}
            >
              <span className="material-symbols-outlined fs-lg">
                add_circle
              </span>
            </Link>
          ) : null} */}
          <Link
            to="#"
            className="bg-gray-300 rounded-5 p-1"
            onClick={() => {
              collapseHandler(true, columnData.componentid);
            }}
          >
            <span className="material-symbols-outlined fs-lg">
              chevron_left
            </span>
          </Link>
        </div>
      </div>

      <Droppable droppableId={columnData.tagid} type="job_response">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              height: "450px",
              overflowY: "scroll",
            }}
            className="pt-1"
          >
            {columnData?.challengeresponse.map(
              (challengeresponsedata, responseIndex) => {
                return (
                  <Draggable
                    key={challengeresponsedata._id}
                    draggableId={challengeresponsedata._id}
                    index={responseIndex}
                  >
                    {(provided) => {
                      return (
                        <KanbanCardElem
                          columnData={columnData}
                          deleteResponseHandler={deleteResponseHandler}
                          responseItem={challengeresponsedata}
                          isProcessOwner={isProcessOwner}
                          provided={provided}
                          // setSelectedLeadId={setSelectedLeadId}
                          createContactChatHandler={createContactChatHandler}
                        />
                      );
                    }}
                  </Draggable>
                );
              }
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default KanbanColumn;
