/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResponseCommentPopup = ({
  approveComments,
  setapproveComments,
  approvalHandler,
  approvalStatus
}) => {

  useEffect(()=>{
    setapproveComments("");
  }, [])

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCommentYourThought"
      aria-labelledby="offcanvasCommentYourThoughtLabel"
      data-bs-backdrop="static"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h5 className="offcanvas-title" id="offcanvasCommentYourThoughtLabel">
          Comment Your Thoughts
        </h5>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setapproveComments("");
          }}
        ></button>
      </div>
      <div className="offcanvas-body px-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-4">
            <label htmlFor="comment" className="d-block fs-sm fw-semibold mb-2">
              Add a note about why decided to 
                <span className="text-success"> { approvalStatus === '1' ? 'promote' : ''} {' '} </span> 
                <span className="text-danger"> { approvalStatus === '2' ? 'reject' : ''} {' '}</span>
              this applicantion?
            </label>
            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="10"
              className="form-control shadow-none"
              placeholder="Enter Comments..."
              value={approveComments}
              onChange={(e) => {
                setapproveComments(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="previous-comments">
            <div className="action d-flex justify-content-between flex-wrap gap-3 mt-4">
              <button
                className="btn btn-outline-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  setapproveComments("");
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={approvalHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResponseCommentPopup;
