/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import DirectAccessBody from "components/DirectAccessComponents/DirectAccessBody/DirectAccessBody";

const DirectAccess = () => {
  useEffect(() => {
    document.title = "Direct Access";
  }, []);

  return (
    <main id="app">
      <Header moduleName="directaccess" />
      <DirectAccessBody />
    </main>
  );
};

export default DirectAccess;
