/* eslint-disable */
import React from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const OpportunityHeader = ({
  reloadList = () => {},
  invoiceList = [],
  followingOpportunityCount = 0,
  totalNotesCount = 0,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Opportunity") }];

  const params = useParams();

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
          {/* --- breadcrumb start --- */}
          <BreadCrumb breadCrumbText={breadcrumbText} />
          {/* --- breadcrumb end --- */}
        </div>
        <div className="col-md-8 col-lg-9 d-flex gap-2 justify-content-end align-items-center">
          <ul className="d-flex align-items-center">
            <li className="me-2" role="presentation">
              Opportunities :{" "}
              <span className="text-primary">{invoiceList.length}</span>
            </li>
            <li className="me-2" role="presentation">
              Following :{" "}
              <span className="text-primary">{followingOpportunityCount}</span>
            </li>

            <li className="me-2" role="presentation">
              Notes : <span className="text-primary">{totalNotesCount}</span>
            </li>
          </ul>

          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          {/* <!-- ========== Start view switch Section ========== --> */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("List View")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/opportunity/list`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("List View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/opportunity/kanban`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("Kanban View")}</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- ========== End view switch Section ========== --> */}

          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addFollowers"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    person
                  </span>
                  <span className="d-block">{t("Add Followers")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasInvoiceListFilter"
            aria-controls="offcanvasInvoiceListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="#"
            data-bs-toggle="offcanvas"
            data-bs-target="#addNewInvoice"
            aria-controls="addNewInvoice"
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OpportunityHeader;
