export const courseDetailsHeaderObject = (id, t) => {
  return [
    {
      title: t("Course Details"),
      link: `/admin/course/save/${id}`,
    },
    { title: t("Team"), link: `/admin/course/team/${id}` },
    {
      title: t("Chapters"),
      link: `/admin/course/chapter/list/${id}`,
    },
    {
      title: t("Lessons"),
      link: `/admin/course/lessons/list/${id}`,
    },
    {
      title: t("Subscribers"),
      link: `/admin/course/subscribers/${id}`,
    },
    {
      title: t("Task Submitted"),
      link: `/admin/course/task/list/${id}`,
    },
    { title: t("Questions Asked"), link: `/admin/course/faq/${id}` },
    {
      title: t("Certification"),
      link: `/admin/course/certification/${id}`,
    },
  ];
};
