/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SummaryModal = ({ userSummary, setuserSummary }) => {
  const [summary, setsummary] = useState("");

  const saveSummaryHandler = () => {
    if (summary !== "") {
      setuserSummary(summary);
    }

    resetHandler();

    // let loginModal = document.querySelector("#summery_modal");
    // let modal = bootstrap.Modal.getInstance(loginModal);
    // modal.hide();
  };

  const resetHandler = () => {
    setsummary("");
  };

  useEffect(() => {
    if (userSummary !== "") {
      setsummary(userSummary);
    }
  }, [userSummary]);

  return (
    <div
      className="modal fade"
      id="summery_modal"
      tabIndex="-1"
      aria-labelledby="addDetailsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Summary</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                <div className="form-group summery_textbx">
                  <label className="form-label">
                    A brief statement about candidate
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter here"
                    value={userSummary}
                    onChange={(e) => {
                      setuserSummary(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  // onClick={resetHandler}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" data-bs-dismiss="modal">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;
