/* eslint-disable */
import React, { useEffect, useState } from "react";

import * as url from "helper/UrlHelper";
import { postData, uploadSingleFile } from "utils/Gateway";
import { getAiPromptText } from "helper/InvoiceDocHelper/InvoiceDocHelper";
import { gptPromtSlug } from "Config/Config";

const UploadInvoiceDocModal = ({
  afterModalClose = () => {},
  setAlertMessage = () => {},
  setShowAlert = () => {},
  setMessageType = () => {},
}) => {
  const [file, setFile] = useState(null);
  const [uploadedDocTextContent, setUploadedDocTextContent] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);

  const token = localStorage.getItem("token");

  //** upload doc funtion
  const docUploadHandler = async (e) => {
    try {
      setIsUploading(true);
      setIsSavingData(false);

      // reset dependent data to avoid duplicate
      setUploadedDocTextContent("");

      let requestURL =
        url.API_BASE_URL +
        url.API_SINGLE_FILE_UPLOAD +
        `?token=${token}&textcontent=1`;

      console.log("req url", requestURL);

      const response = await uploadSingleFile(requestURL, file);

      if (response.status) {
        console.log("file upload response", response);

        // set the uploaded url path
        setUploadedDocTextContent(response.textcontent);

        setIsUploading(false);
        resetFileInput();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const saveUploadedDocData = async () => {
    try {
      // show wait while we fetch and map the data from AI
      setIsSavingData(true);

      if (uploadedDocTextContent && uploadedDocTextContent.length > 0) {
        let postParams = {
          filetextcontent: uploadedDocTextContent,
          promptslug: gptPromtSlug,
        };

        console.log(postParams);

        let requestURL =
          url.API_BASE_URL + url.API_SAVE_INVOICE_DOC + `?token=${token}`;

        console.log("requestUrl", requestURL);

        const response = await postData(requestURL, postParams);

        console.log("FIle response data", response);

        resetAllStates();

        setIsUploading(false);
        setIsSavingData(false); // stop showing loading

        if (response.status) {
          let uploadModal = document.querySelector("#invoiceDocUploadModal");
          let modal = bootstrap.Modal.getInstance(uploadModal);
          modal.hide();

          // setAlertMessage(response.message);

          afterModalClose();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetFileInput = () => {
    const file = document.getElementById("docFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  const resetAllStates = () => {
    setFile(null);
    setUploadedDocTextContent("");
  };

  //** use effect for send uploaded doc file url to server
  useEffect(() => {
    if (uploadedDocTextContent && uploadedDocTextContent.length > 0) {
      saveUploadedDocData();
    }
  }, [uploadedDocTextContent]);

  return (
    <div
      className="modal fade"
      id="invoiceDocUploadModal"
      tabIndex="-1"
      aria-labelledby="invoiceDocUploadModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="csvUploadModalLabel">
              Upload PDF/JPG File
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetAllStates}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="docFile" className="form-label">
                Choose a PDF/JPG file to upload Invoice Docs
              </label>

              <input
                type="file"
                className="form-control border-grey"
                id="docFile"
                // accept=".csv"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>

            <h6
              className={`text text-primary mt-3 ${
                isUploading ? "" : "d-none"
              }`}
            >
              <div className="spinner-border me-4" role="status">
                <span className="sr-only"></span>
              </div>
              File uploading in process
            </h6>
            <h6
              className={`text text-primary mt-3 ${
                isSavingData ? "" : "d-none"
              }`}
            >
              1. File Uploaded Successfully.
              <br />
              <br />
              2.Saving docs data. Please wait for while.
              {/* {[1, 2, 3, 4, 5, 6].map((item, index) => {
                return (
                  <div
                    className="spinner-grow spinner-grow-sm ms-2"
                    role="status"
                    key={index}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                );
              })} */}
            </h6>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary me-2"
              data-bs-dismiss="modal"
              disabled={isUploading || isSavingData ? true : false}
              style={{
                cursor: isUploading || isSavingData ? "not-allowed" : "pointer",
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => docUploadHandler()}
              disabled={isUploading || isSavingData ? true : false}
              style={{
                cursor: isUploading || isSavingData ? "not-allowed" : "pointer",
              }}
            >
              Upload
              {isUploading ||
                (isSavingData && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ))}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadInvoiceDocModal;
