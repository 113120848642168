/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import VatOptions from "data/Invoice/VAT.json";

import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import { assetImages } from "constants";
import NoteSection from "components/Common/NoteComponent/NoteSection";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import SaveInvoiceSuccessModal from "components/Common/Modal/SaveInvoiceSuccessModal";

const InvoiceDocDetailsBody = () => {
  const token = localStorage.getItem("token");
  const ecosystemLogo = localStorage.getItem("ecosystemlogo");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation

  const history = useHistory();
  const params = useParams();

  const breadcrumbText = [
    { title: t("Invoice Doc"), link: "/admin/invoicedoc/list" },
    { title: t("Save/Details") },
  ];

  const [providerName, setproviderName] = useState("");
  const [providerCif, setproviderCif] = useState("");
  const [providerLocation, setproviderLocation] = useState("");
  const [providerZipCode, setproviderZipCode] = useState("");
  const [providerCity, setproviderCity] = useState("");
  const [providerCountry, setproviderCountry] = useState("");
  const [totalAmount, settotalAmount] = useState(0);
  const [customerName, setcustomerName] = useState("");
  const [customerCif, setcustomerCif] = useState("");
  const [customerLocation, setcustomerLocation] = useState("");
  const [customerZipcode, setcustomerZipcode] = useState("");
  const [customerCity, setcustomerCity] = useState("");
  const [customerCountry, setcustomerCountry] = useState("");
  const [invoiceNo, setinvoiceNo] = useState("");
  const [invoiceOrderDate, setinvoiceOrderDate] = useState("");
  const [invoiceDueDate, setinvoiceDueDate] = useState("");
  const [invoiceDocItems, setinvoiceDocItems] = useState([]);
  const [invoiceTotalTax, setinvoiceTotalTax] = useState(0);
  const [invoicePayableAmount, setInvoicePayableAmount] = useState(0);

  const invoiceDocItemTemplate = {
    _id: null,
    description: "",
    quantity: 0,
    price: 0,
    taxvalue: 0,
    amount: 0,
    taxselectedvalue: null, // non-functional used for mapping react select
    descriptionwarning: false, // non-functional used for showing warning
    quantitywarning: false, // non-functional, used for showing warning
    pricewarning: false, // non-functional, used for showing warning
  };

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get invoice doc details
  const getInvoiceDocDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_INVOICE_DOC_DETAILS +
        `/${params.id}`;

      const response = await getData(requestURL);

      console.log("response", response);

      if (response.status) {
        if (response.data) {
          setproviderName(response.data.providername);
          setproviderCif(response.data.providercifno);
          setproviderLocation(response.data.providerlocation);
          setproviderZipCode(response.data.providerzipcode);
          setproviderCity(response.data.providercity);
          setproviderCountry(response.data.providercountry);
          settotalAmount(response.data.invoicetotalamount);

          setcustomerName(response.data.customername);
          setcustomerCif(response.data.customercifno);
          setcustomerLocation(response.data.customerlocation);
          setcustomerZipcode(response.data.customerzipcode);
          setcustomerCity(response.data.customercity);
          setcustomerCountry(response.data.customercountry);

          setinvoiceNo(response.data.invoiceno);

          setinvoiceOrderDate(response.data.invoiceorderdate);
          setinvoiceDueDate(response.data.invoiceduedate);

          updateInvoiceBlock(response.data.invoicedocitems);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for add new invoice doc item
  const addNewInvoiceDocItem = () => {
    setinvoiceDocItems([...invoiceDocItems, invoiceDocItemTemplate]);
  };

  // on click delete a invoice doc item
  const onDeleteInvoiceDocItem = (index) => {
    const deletedItemBlock = [...invoiceDocItems];
    deletedItemBlock.splice(index, 1);
    setinvoiceDocItems(deletedItemBlock);
  };

  //update invoice item block
  const updateInvoiceBlock = (invoiceDocItems = []) => {
    const invoiceDocItemsArr = invoiceDocItems.map((item) => {
      let invoiceItemSavedData = {
        _id: item._id ?? null,
        description: item.description ?? "",
        quantity: item.quantity ?? "",
        price: item.price ?? "",
        taxvalue: item.taxvalue ?? 0,
        amount: item.amount ?? 0,
        taxselectedvalue: getTaxSelectedSelectOptionValue(item.taxvalue), // non-functional used for mapping react select
        descriptionwarning: false, // non-functional used for showing warning
        quantitywarning: false, // non-functional, used for showing warning
        pricewarning: false, // non-functional, used for showing warning
      };

      return invoiceItemSavedData;
    });

    setinvoiceDocItems(invoiceDocItemsArr);
  };

  //change invoice item input value
  const changeInvoiceDocItemsInputs = (index, field, value) => {
    const updatedFormValues = invoiceDocItems.map((block, i) => {
      if (field === "description") {
        if (value !== "") {
          block.descriptionwarning = false;
        } else {
          block.descriptionwarning = true;
        }
      } else if (field === "quantity") {
        if (value !== "") {
          block.quantitywarning = false;
        } else {
          block.quantitywarning = true;
        }
      } else if (field === "price") {
        if (value !== "") {
          block.pricewarning = false;
        } else {
          block.pricewarning = true;
        }
      } else {
      }
      return index == i ? Object.assign(block, { [field]: value }) : block;
    });

    setinvoiceDocItems(updatedFormValues);

    updateItemGrossVlues(index);
  };

  //function for select tax
  const invoiceDoctaxSelectionHandler = (index, field1, field2, val) => {
    const updatedFormValues = invoiceDocItems.map((block, i) =>
      index == i
        ? Object.assign(block, { [field1]: val, [field2]: parseInt(val.value) })
        : block
    );

    setinvoiceDocItems(updatedFormValues);

    updateItemGrossVlues(index);
  };

  // on update or view, show the pre-selected value of Tax for each invoice items
  const getTaxSelectedSelectOptionValue = (val) => {
    let selectedVatOption = VatOptions[0];

    for (let vatOption of VatOptions) {
      if (vatOption.value === val.toString()) {
        selectedVatOption = vatOption;
      }
    }

    return selectedVatOption;
  };

  // update invoice calculation
  const invoiceDocItemUpdateHandler = () => {
    const updatedItems = [...invoiceDocItems];

    const totalPaybleAmount = updatedItems.reduce(
      (amount, item) => amount + item.price * item.quantity,
      0
    );

    const totalTax = updatedItems.reduce((acc, curr) => acc + curr.taxvalue, 0);

    setinvoiceTotalTax(totalTax);

    const payableAmount = updatedItems.reduce(
      (totalamount, item) => totalamount + item.amount,
      0
    );

    settotalAmount(totalPaybleAmount);

    setInvoicePayableAmount(payableAmount);
  };

  // set dependent item value on params change
  const updateItemGrossVlues = (index) => {
    const updatedItems = [...invoiceDocItems];
    const quantity = parseInt(updatedItems[index].quantity);
    const price = parseInt(updatedItems[index].price);
    const tax = parseInt(updatedItems[index].taxvalue);

    if (quantity > 0 && price > 0) {
      const taxToPay = parseInt((quantity * price * tax) / 100, 10);
      const totalPrice = parseInt(quantity * price, 10) + taxToPay;

      updatedItems[index].taxvalue = taxToPay;
      updatedItems[index].amount = totalPrice;

      setinvoiceDocItems(updatedItems);
    }
  };

  //function for save invoice doc
  const saveInvoiceDocHandler = async () => {
    try {
      setIsSaving(true);

      let invoiceDocDataForSave = {
        orderdate: invoiceOrderDate,
        duedate: invoiceDueDate,
        invoicedocitems: invoiceDocItems,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_INVOICE_DOC +
        `/${params.id}?token=${token}`;

      const response = await putData(requestURL, invoiceDocDataForSave);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        setMessageType("success");

        setTimeout(() => {
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveInvoiceModal")
          );
          bootstrapModal.show();
        }, 1000);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  const afterSuccessModalClose = (responsType) => {
    if (responsType === "list") {
      history.push(`/admin/invoicedoc/list`);
    } else if (responsType === "details") {
      history.push(`/admin/invoicedoc/details/${params.id}`);
    }
  };

  // update cost and other info when any invoiceitem data changes in invoice
  useEffect(() => {
    if (invoiceDocItems.length > 0) {
      invoiceDocItemUpdateHandler();
    }
  }, [invoiceDocItems]);

  useEffect(() => {
    getInvoiceDocDetails();
  }, []);

  //close alert function
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="invoice-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <BreadCrumb breadCrumbText={breadcrumbText} />
          <div className="row mt-2">
            <div className="col-lg-8">
              <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                {/* Invoice sender and basic info */}
                <div className="invoice mt-4">
                  <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                    <img
                      src={url.SERVER_URL + ecosystemLogo}
                      alt="FindelOI"
                      className="img-fluid"
                      style={{ maxHeight: "28px" }}
                    />
                  </div>

                  <div className="d-flex flex-column flex-sm-row">
                    <p className="fs-md flex-fill mb-3">
                      <span className="d-block">{providerName}</span>
                      <span className="d-block">{providerCif}</span>
                      <span className="d-block text-black">
                        {providerLocation}
                      </span>
                      <span className="d-block text-black">
                        {providerCity} , {providerCountry}
                      </span>
                      <span className="d-block text-black">
                        {providerZipCode}
                      </span>
                    </p>

                    <ul className="flex-fill h3 d-flex flex-column gap-4 fw-semibold">
                      <li className="d-flex align-items-center gap-3">
                        <span className="d-block">{t("Invoice Number")}: </span>
                        <span className="d-block text-primary">
                          {invoiceNo}
                        </span>
                      </li>
                      <li className="d-flex align-items-center gap-3">
                        <span className="d-block">
                          {t("Subscription Plan")}:{" "}
                        </span>
                        <span className="d-block text-primary">N/A</span>
                      </li>
                      <li className="d-flex align-items-center gap-3">
                        <span className="d-block">{t("Amount Payable")}: </span>
                        <span className="d-block text-primary">
                          {totalAmount} €
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Invoice details */}
                <div className="p-3 border border-gray-300 rounded-8 mb-3 mt-3">
                  <div className="row">
                    {/* Client or customer details */}
                    <div className="col-md mb-4 mb-md-0">
                      <h5 className="fw-semibold mb-2 mb-md-3">
                        {t("Customer Information")}
                      </h5>

                      <p className="fs-sm mt-2">
                        <span className="d-block text-uppercase">
                          {customerName}{" "}
                        </span>
                        <span className="d-block text-uppercase">
                          {customerCif}{" "}
                        </span>
                        <span className="d-block text-black">
                          {customerLocation}
                        </span>
                        <span className="d-block text-black">
                          {customerCity} {customerCountry}
                        </span>
                        <span className="d-block text-black">
                          {customerZipcode}
                        </span>
                      </p>
                    </div>

                    {/* Order or Invoice info */}
                    <div className="col-md mb-4 mb-md-0">
                      <h5 className="fw-semibold mb-2 mb-md-3">
                        {t("Order Information")}
                      </h5>
                      <div className="form-group d-flex align-items-center mb-2">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                          style={{ minWidth: "6.25rem" }}
                        >
                          {t("Invoice No")}:
                        </label>
                        <p className="form-control px-3 py-2 shadow-none">
                          {invoiceNo}
                        </p>
                      </div>
                      <div className="form-group d-flex align-items-center mb-2">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                          style={{ minWidth: "6.25rem" }}
                        >
                          {t("Order Date")}:
                        </label>
                        <input
                          type="date"
                          className="form-control px-3 py-2 shadow-none"
                          value={invoiceOrderDate}
                          onChange={(e) => {
                            setinvoiceOrderDate(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group d-flex align-items-center">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold mb-2"
                          style={{ minWidth: "6.25rem" }}
                        >
                          {t("Due Date")}:
                        </label>
                        <input
                          type="date"
                          className="form-control px-3 py-2 shadow-none"
                          value={invoiceDueDate}
                          onChange={(e) => {
                            setinvoiceDueDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* Payment info */}
                    <div className="col-md">
                      <h5 className="fw-semibold mb-2 mb-md-3">
                        {t("Payment Information")}
                      </h5>

                      <div className="form-group d-flex align-items-center mb-2">
                        <label
                          htmlFor=""
                          className="d-block fs-sm fw-semibold"
                          style={{ minWidth: "6.25rem" }}
                        >
                          {t("Amount")}:
                        </label>
                        <span className="d-block">{totalAmount}</span>
                      </div>
                      {/* <div className="form-group d-flex align-items-center">
                        <label
                          for=""
                          className="d-block fs-sm fw-semibold mb-2"
                          style={{ minWidth: "6.25rem" }}
                        >
                          {t("Payment Date")}:
                        </label>
                        <span className="d-block">{invoiceOrderDate}</span>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* Invoice Items */}
                <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
                  <div className="table-responsive">
                    <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                      <thead>
                        <tr>
                          <th
                            width="45%"
                            className="description bg-transparent fs-md fw-semibold border-0"
                          >
                            {t("Description")}
                          </th>
                          <th className="quantity bg-transparent fs-md fw-semibold border-0">
                            {t("Quantity")}
                          </th>
                          <th className="amt bg-transparent fs-md fw-semibold border-0">
                            {t("Price")}
                          </th>
                          <th
                            className="taxes bg-transparent fs-md fw-semibold border-0"
                            width="20%"
                          >
                            {t("Tax (IVA)")}
                          </th>
                          <th className="pay-amt bg-transparent fs-md fw-semibold border-0">
                            {t("Total Amount")}
                          </th>
                          <th className="bg-transparent fs-md fw-semibold border-0"></th>
                        </tr>
                      </thead>
                      <tbody style={{ height: "200px" }}>
                        {invoiceDocItems.map((item, index) => {
                          return (
                            <tr key={index} style={{ verticalAlign: "top" }}>
                              <td className="description border-bottom-0 p-1">
                                <input
                                  type="text"
                                  name="description"
                                  className={`form-control bg-white shadow-none ${
                                    item.descriptionwarning
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  value={item.description}
                                  onChange={(e) =>
                                    changeInvoiceDocItemsInputs(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="quantity border-bottom-0 p-1">
                                <input
                                  name="quantity"
                                  className={`form-control bg-white shadow-none ${
                                    item.quantitywarning
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  type="number"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    changeInvoiceDocItemsInputs(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="amt border-bottom-0 p-1">
                                <input
                                  className={`form-control bg-white shadow-none ${
                                    item.pricewarning
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                  name="price"
                                  type="number"
                                  value={item.price}
                                  onChange={(e) =>
                                    changeInvoiceDocItemsInputs(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="taxes border-bottom-0 p-0">
                                <Select
                                  placeholder="IVA %"
                                  options={VatOptions}
                                  className="form-control "
                                  value={item.taxselectedvalue}
                                  onChange={(val) =>
                                    invoiceDoctaxSelectionHandler(
                                      index,
                                      "taxselectedvalue",
                                      "taxvalue",
                                      val
                                    )
                                  }
                                />
                              </td>
                              <td className="pay-amt border-bottom-0 p-1">
                                <span
                                  className={`form-control bg-white shadow-none ${
                                    item.pricewarning
                                      ? "border border-danger"
                                      : ""
                                  }`}
                                >
                                  {item.amount}
                                </span>
                              </td>
                              <td className="action border-bottom-0">
                                <Link
                                  className={`text-black`}
                                  to="#"
                                  onClick={() => onDeleteInvoiceDocItem(index)}
                                >
                                  <span className="d-block material-symbols-outlined">
                                    delete
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Link
                    to="#"
                    className={`btn btn-outline-primary `}
                    onClick={addNewInvoiceDocItem}
                  >
                    + {t("Add Item")}
                  </Link>

                  <button
                    className={`btn btn-primary ms-5 `}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                    onClick={saveInvoiceDocHandler}
                  >
                    {t("Save Invoice Doc")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>

                {/* Invoice final amount */}
                <div className="table-wrapper pb-3 mb-3 border-bottom border-gray">
                  <div className="table-responsive">
                    <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                      <tbody>
                        <tr>
                          <td className="p-0 fw-semibold bg-transparent border-0">
                            {t("Total Amount")}
                          </td>
                          <td className="p-0 bg-transparent text-end border-0">
                            {totalAmount} €
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0 fw-semibold bg-transparent border-0">
                            {t("Total tax")}
                          </td>
                          <td className="p-0 bg-transparent text-end border-0">
                            {invoiceTotalTax} €
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0 fs-lg fw-semibold bg-transparent border-0">
                            {t("Total Payable Amount")}
                          </td>
                          <td className="p-0 fs-lg fw-semibold text-success bg-transparent text-end border-0">
                            {invoicePayableAmount} €
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <NoteSection
                moduleName="invoicedoc"
                invoiceDocId={params.id}
                setAlertMessage={setAlertMessage}
                setShowAlert={setShowAlert}
                setMessageType={setMessageType}
              />
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          alertType={messageType}
          message={alertMessage}
          onClose={onAlertClose}
        />
      )}

      <SaveInvoiceSuccessModal
        moduleType="invoicedoc"
        afterSuccessModalClose={afterSuccessModalClose}
      />
    </div>
  );
};

export default InvoiceDocDetailsBody;
