/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";

// Localization
// import { MRT_Localization_ES } from "material-react-table/locales/es";

import AddLabelModal from "components/Common/Modal/AddLabelModal";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import AppUserListHeader from "../Header/AppUserListHeader";
import AppUserListFilterPopup from "../Popup/AppUserListFilterPopup";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const AppUserListBody = () => {
  //const { screenHeight } = useContext(GlobalProvider);

  const { t, i18n } = useTranslation(); //for translation

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const currentEcosystemId = localStorage.getItem("ecosystem");

  const [tagList, setTagList] = useState([]);
  const [appUserList, setAppUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterPhone, setfilterPhone] = useState("");
  const [filterPostion, setfilterPostion] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterLabels, setfilterLabels] = useState([]);
  const [filterSubsribeStatus, setFilterSubsribeStatus] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState("");

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get all contacts
  const getAllAppUsers = async () => {
    const userPublicIp = await getPublicIP();

    setRowSelection({});
    setSelectedUserIds([]);
    setSelectedUserEmails("");

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&filterecosystemid=${currentEcosystemId}&userip=${userPublicIp}`;

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestUrl = requestUrl + `&filterphone=${filterPhone}`;
      }

      if (filterPostion != "") {
        requestUrl = requestUrl + `&filterposition=${filterPostion}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterLabels.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterLabels}`;
      }

      if (filterSubsribeStatus !== "") {
        requestUrl =
          requestUrl + `&filtersubsribestatus=${filterSubsribeStatus}`;
      }

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setAppUserList(response.data);
        resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //* function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedUserList = [...appUserList];

    const rowIndex = updatedUserList.findIndex(
      (user) => user._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedUserList[rowIndex].rank = value;

      setAppUserList(updatedUserList);
    }
  };

  //* function for update rank
  const updateRankHandler = async (e) => {
    if (selectedUserIds.length > 0) {
      const userPublicIp = await getPublicIP();

      let responseArr = [];

      //run loop checkbox wise
      for (let selectedId of selectedUserIds) {
        //run loop of updated userlist
        for (let user of appUserList) {
          let userData = {};
          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (user._id == selectedId) {
            userData.rank = user.rank;

            userData.position = user.position;

            userData.zipcode = user.zipcode;

            userData.mapcoordinate = user.mapcoordinate;

            userData.address = user.address;

            userData.costperhour = user.costperhour;

            userData.city = user.city;

            userData.state = user.state;

            userData.country = user.country;

            userData.phone = user.phone;

            userData.website = user.website;

            userData.latitude = user.latitude;

            userData.longitude = user.longitude;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_APP_USER +
                `/${selectedId}` +
                `?token=${token}&userip=${userPublicIp}`;

              const response = await putData(requestUrl, userData);

              if (response.status) {
                setMessageType("success");
                setAlertMessage("Rank updated successfully");
                responseArr.push(response.status);
              } else {
                setMessageType("error");
                setAlertMessage(response.message);
              }
            } catch (error) {
              setMessageType("error");
              setAlertMessage(error.message);
            }

            setShowAlert(true);
          }
        }
      }

      setRowSelection({});
      setSelectedUserIds([]);
    } else {
      setMessageType("error");
      setAlertMessage("Please select user for change rank");
      setShowAlert(true);
    }
  };

  //* function for change status
  const changeStatusHandler = async (statusInfo) => {
    if (selectedUserIds.length > 0) {
      let responseArr = [];

      const userPublicIp = await getPublicIP();

      for (let selectedId of selectedUserIds) {
        // try {
        //   let statusData = {
        //     status: statusInfo,
        //   };

        //   let requestUrl =
        //     url.API_BASE_URL +
        //     url.API_UPDATE_APP_USER +
        //     `/${selectedId}` +
        //     `?token=${token}&userip=${userPublicIp}`;

        //   const response = await putData(requestUrl, statusData);

        //   // console.log(response);
        // } catch (error) {
        //   console.log(error.message);
        // }

        for (let user of appUserList) {
          let userData = {};
          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (user._id == selectedId) {
            userData.status = statusInfo;

            userData.position = user.position;

            userData.zipcode = user.zipcode;

            userData.mapcoordinate = user.mapcoordinate;

            userData.address = user.address;

            userData.costperhour = user.costperhour;

            userData.city = user.city;

            userData.state = user.state;

            userData.country = user.country;

            userData.phone = user.phone;

            userData.website = user.website;

            userData.latitude = user.latitude;

            userData.longitude = user.longitude;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_APP_USER +
                `/${selectedId}` +
                `?token=${token}&userip=${userPublicIp}`;

              const response = await putData(requestUrl, userData);

              if (response.status) {
                setMessageType("success");
                if (statusInfo === "0") {
                  setAlertMessage("User disabled successfully");
                } else {
                  setAlertMessage("User enabled successfully");
                }
                responseArr.push(true);
              } else {
                setMessageType("error");
                setAlertMessage(response.message);
              }
            } catch (error) {
              setMessageType("error");
              setAlertMessage(error.message);
            }

            setShowAlert(true);
          }
        }
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllAppUsers();
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select user for update status");
      setShowAlert(true);
    }
  };

  //function for change admin status
  // const changeAdminStatusHandler = async (status) => {
  //   const checkboxes = document.querySelectorAll(
  //     'input[name="selectExpertRecord"]'
  //   );
  //   for (let checkbox of checkboxes) {
  //     if (checkbox.checked) {
  //       try {
  //         let adminStatusData = {
  //           adminstatus: status,
  //         };
  //         let requestUrl =
  //           url.API_BASE_URL +
  //           url.API_UPDATE_APP_USER +
  //           `/${checkbox.value}` +
  //           `?token=${token}`;
  //         const response = await putData(requestUrl, adminStatusData);
  //         if (response.status) {
  //         }
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     }
  //   }
  //   getAllAppUsers();
  // };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedUserIds([]);
    setSelectedUserEmails("");
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterEmail("");
    setfilterPhone("");
    setfilterPostion("");
    setfilterCompany("");
    setfilterLabels([]);
    setFilterSubsribeStatus("");
  };

  useEffect(() => {
    if (
      filterName != "" ||
      filterEmail != "" ||
      filterPhone != "" ||
      filterPostion != "" ||
      filterCompany != "" ||
      filterLabels.length > 0 ||
      filterSubsribeStatus !== ""
    ) {
      getAllAppUsers();
    }
  }, [
    filterName,
    filterEmail,
    filterPostion,
    filterCompany,
    filterPhone,
    filterLabels,
    filterSubsribeStatus,
  ]);

  useEffect(() => {
    getAllTags();
    getAllAppUsers();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "150px",
            }}
          >
            <img
              height={30}
              width={30}
              src={
                row.original.userimage
                  ? url.SERVER_URL + row.original.userimage
                  : assetImages.defaultUser
              }
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "surname",
        header: t("Surname"),
      },
      {
        accessorKey: "userrole",
        header: t("Role"),
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "150px",
            }}
          >
            <input
              // loading="lazy"
              type="number"
              name="rank"
              id="rank"
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
            />
          </Box>
        ),
      },
      {
        accessorKey: "userstatus",
        header: t("Status"),
      },
      {
        accessorKey: "email",
        header: t("Email"),
      },
      {
        accessorKey: "subscribetomail",
        header: t("Subscribe to Mail"),
      },
      {
        accessorKey: "position",
        header: t("Position"),
      },
      {
        accessorKey: "company",
        header: t("Company"),
      },
      {
        accessorKey: "phone",
        header: t("Phone"),
      },
      {
        accessorKey: "city",
        header: t("Location"),
      },
      {
        accessorKey: "country",
        header: t("Country"),
      },
      {
        accessorKey: "logintime",
        header: t("Login Time"),
      },
      {
        accessorKey: "logindate",
        header: t("Login Date"),
      },
      {
        accessorKey: "browser",
        header: t("Login Browser"),
      },
      {
        accessorKey: "userip",
        header: t("Login Ip"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [appUserList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      const selectUserIds = selectedIdsArray.map((id) => {
        const [userId] = id.split("-");
        return userId;
      });
      const selectUserEmails = selectedIdsArray.map((id) => {
        const [, userEmail] = id.split("-");
        return userEmail;
      });
      setSelectedUserIds(selectUserIds);
      const selectUserEmailString = selectUserEmails.join(",");
      setSelectedUserEmails(selectUserEmailString);
    } else {
      setSelectedUserIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* ---- header ----*/}
            <AppUserListHeader
              reloadUserList={getAllAppUsers}
              changeStatusHandler={changeStatusHandler}
              // changeAdminStatusHandler={changeAdminStatusHandler}
              updateRankHandler={updateRankHandler}
            />
            {/* ---- table with pagination section start ---- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={appUserList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => `${row._id}-${row.email}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  //localization={MRT_Localization_ES} // localization
                  renderRowActionMenuItems={({ row }) => {
                    const [appUserId] = row.id.split("-");

                    return [
                      <MenuItem key="Profile">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/profile/${appUserId}`}
                        >
                          {t("Manage Profile")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="CV">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/cv/${appUserId}`}
                        >
                          {t("Manage CV")}
                        </Link>
                      </MenuItem>,
                      // <MenuItem
                      //   key="Surveys"
                      //   //onClick={() => console.info(expertId)}
                      // >
                      //   <Link
                      //     className="dropdown-item"
                      //     to={`/admin/appuser/surveys/${expertId}`}
                      //   >
                      //     Manage Surveys
                      //   </Link>
                      // </MenuItem>,
                      <MenuItem
                        key="Privacy"
                        //onClick={() => console.info(expertId)}
                      >
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/privacy/${appUserId}`}
                        >
                          {t("Manage Privacy")}
                        </Link>
                      </MenuItem>,

                      <MenuItem key="Password">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/password/${appUserId}`}
                        >
                          {t("Manage Password")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="avalibility">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/availability/${appUserId}`}
                        >
                          {t("Manage Availability")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="settings">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/settings/${appUserId}`}
                        >
                          {t("Manage Settings")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="survey">
                        <Link
                          className="dropdown-item"
                          to={`/admin/appuser/surveyinvites/${appUserId}`}
                        >
                          {t("View Survey Invites")}
                        </Link>
                      </MenuItem>,
                    ];
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ---- table with pagination section end ---- */}
          </div>
        </section>
        {/* ======== popups and modals area ======== */}
        {/* ------ add label modal-------- */}
        <AddLabelModal
          selectedIds={selectedUserIds}
          moduleName="appuser"
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllAppUsers();
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        {/* ------- filter popup ------- */}
        <AppUserListFilterPopup
          tagList={tagList}
          setfilterName={setfilterName}
          setfilterEmail={setfilterEmail}
          setfilterPhone={setfilterPhone}
          setfilterPostion={setfilterPostion}
          setfilterCompany={setfilterCompany}
          setfilterLabels={setfilterLabels}
          setFilterSubsribeStatus={setFilterSubsribeStatus}
        />
        {/* ---- new mail modal ------- */}
        <ConversationNewMailPopup
          contactPersonEmail={selectedUserEmails}
          reloadList={afterEmailClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default AppUserListBody;
