/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import ChallengesCommonHeader from "components/ChallengeComponents/Common/Header/ChallengesCommonHeader";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import ProjectChart from "../DashboardSections/ProjectChart";
import ProjectGraph from "../DashboardSections/ProjectGraph";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import CurrentProjectList from "../DashboardSections/CurrentProjectList";
import ProjectCard from "components/ChallengeComponents/Common/Cards/ProjectCard";
import TaskCard from "components/ChallengeComponents/Common/Cards/TaskCard";
import InvoiceCard from "components/ChallengeComponents/Common/Cards/InvoiceCard";
import { assetImages } from "constants";

const ChallengeDashboardBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();
  const breadcrumbTxt = [{ title: t("Challenges") + ` / ` + t("Dashboard") }];

  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get project list
  // const getProjectList = async () => {
  //   try {
  //     setIsLoading(true);

  //     let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_PROJECTS}?token=${token}&userrolestring=${userInfo.role.slug}`;

  //     const response = await getData(requestUrl);

  //     // console.log("projects >>>", response);

  //     setIsLoading(false);

  //     if (response.status) {
  //       setMessageType("success");

  //       setProjectList(response.data.slice(0, 10));
  //     } else {
  //       setMessageType("error");
  //     }

  //     setAlertMessage(response.message);
  //   } catch (error) {
  //     setAlertMessage(error.message);
  //     setMessageType("error");
  //   }

  //   setShowAlert(true);
  // };

  // useEffect(() => {
  //   getProjectList();
  // }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <section className="projects-wrapper bg-white pb-5">
      <ChallengesCommonHeader componentName="dashboard" />

      <div className="container-fluid px-lg-5">
        <BreadCrumb breadCrumbText={breadcrumbTxt} bottom={true} />

        <div className="row mb-4">
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Total Challenges</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">210+</h3>
                <p className="d-flex align-items-center text-success">
                  <span className="d-block">18%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_upward
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Total participants</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">500k+</h3>
                <p className="d-flex align-items-center text-success">
                  <span className="d-block">18%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_upward
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Average score</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">86.00</h3>
                <p className="d-flex align-items-center text-danger">
                  <span className="d-block">8%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_downward
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Candidate Drop-Off Rate</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">86.00</h3>
                <p className="d-flex align-items-center text-success">
                  <span className="d-block">12%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_upward
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Sponsorship Funds</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">$100K</h3>
                <p className="d-flex align-items-center text-success">
                  <span className="d-block">12%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_upward
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="h-100 p-3 shadow-md rounded-8">
              <p className="mb-2">Completion Time</p>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fw-bold mb-0">14:00m</h3>
                <p className="d-flex align-items-center text-danger">
                  <span className="d-block">8%</span>
                  <span className="d-block material-symbols-outlined fs-sm">
                    arrow_downward
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Challenges in Each Status</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <img
                src="assets/img/hr-dashboard-graph-2.png"
                alt=""
                className="img-fluid w-100 mb-4"
              />
              <p className="fw-semibold mb-2">Count in Each Status</p>
              <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                  <span
                    className="d-block rounded-circle"
                    style={{
                      width: "0.5rem",
                      height: "0.5rem",
                      backgroundColor: "#21960F",
                    }}
                  ></span>
                  <span className="d-block">Active</span>
                  <p className="d-flex align-items-center gap-1 ms-auto">
                    <span className="d-block fw-semibold">96+</span>
                    <span className="d-block material-symbols-outlined icon-md text-success">
                      trending_up
                    </span>
                  </p>
                </li>
                <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                  <span
                    className="d-block rounded-circle"
                    style={{
                      width: "0.5rem",
                      height: " 0.5rem",
                      backgroundColor: "#FF6C65",
                    }}
                  ></span>
                  <span className="d-block">Completed</span>
                  <p className="d-flex align-items-center gap-1 ms-auto">
                    <span className="d-block fw-semibold">92+</span>
                    <span className="d-block material-symbols-outlined icon-md text-success">
                      trending_up
                    </span>
                  </p>
                </li>
                <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                  <span
                    className="d-block rounded-circle"
                    style={{
                      width: "0.5rem",
                      height: "0.5rem",
                      backgroundColor: "#EE523C",
                    }}
                  ></span>
                  <span className="d-block">Others</span>
                  <p className="d-flex align-items-center gap-1 ms-auto">
                    <span className="d-block fw-semibold">21+</span>
                    <span className="d-block material-symbols-outlined icon-md text-danger">
                      trending_down
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Number of Candidates by Phase</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <div className="d-flex flex-fill align-items-center gap-4">
                <div
                  className="graph flex-shrink-0"
                  style={{ width: "10.625rem", height: "10.625rem" }}
                >
                  <img
                    src={assetImages.numberOfCandidatePhase}
                    alt="Number of Candidates by Phase"
                    className="w-100 h-100 object-center object-fit-cover"
                  />
                </div>
                <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: " 0.5rem",
                        backgroundColor: "#FF6C65",
                      }}
                    ></span>
                    <span className="d-block">First Interview</span>
                    <span className="d-block fw-semibold ms-auto">204</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: "#21960F",
                      }}
                    ></span>
                    <span className="d-block">Screening Interview</span>
                    <span className="d-block fw-semibold ms-auto">140</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: "#4285F4",
                      }}
                    ></span>
                    <span className="d-block">Skills Assessment</span>
                    <span className="d-block fw-semibold ms-auto">80</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: " #76859D",
                      }}
                    ></span>
                    <span className="d-block">HR Round</span>
                    <span className="d-block fw-semibold ms-auto">76</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Average Judge Score</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <img
                src={assetImages.averageJudgeScore}
                alt="Average Judge Score"
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Top-Ranked Candidates</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <ul className="d-flex flex-column gap-2">
                <li className="d-flex align-items-center gap-3 py-2 border-bottom">
                  <div
                    className="avatar rounded-circle"
                    style={{ width: " 2.625rem", height: "2.625rem" }}
                  >
                    <img
                      src={assetImages.richardGomes}
                      alt="Arthur Taylor"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>
                  <div>
                    <p className="name lh-1 fw-semibold mb-1">Arthur Taylor</p>
                    <p className="fs-sm lh-1 text-gray">Design Lead</p>
                  </div>
                  <p className="fs-sm text-gray mx-auto mb-0">1st</p>
                  <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      description
                    </span>
                    <span className="d-block">86</span>
                  </p>
                </li>
                <li className="d-flex align-items-center gap-3 py-2 border-bottom">
                  <div
                    className="avatar rounded-circle"
                    style={{ width: " 2.625rem", height: "2.625rem" }}
                  >
                    <img
                      src={assetImages.johnDesoza}
                      alt="Getar Beter jnr"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>
                  <div>
                    <p className="name lh-1 fw-semibold mb-1">
                      Getar Beter jnr
                    </p>
                    <p className="fs-sm lh-1 text-gray">Design Lead</p>
                  </div>
                  <p className="fs-sm text-gray mx-auto mb-0">2nd</p>
                  <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      description
                    </span>
                    <span className="d-block">78</span>
                  </p>
                </li>
                <li className="d-flex align-items-center gap-3 py-2 border-bottom">
                  <div
                    className="avatar rounded-circle"
                    style={{ width: " 2.625rem", height: "2.625rem" }}
                  >
                    <img
                      src={assetImages.rockDisket}
                      alt="Arthur Taylor"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>
                  <div>
                    <p className="name lh-1 fw-semibold mb-1">Arthur Taylor</p>
                    <p className="fs-sm lh-1 text-gray">Design Lead</p>
                  </div>
                  <p className="fs-sm text-gray mx-auto mb-0">3rd</p>
                  <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      description
                    </span>
                    <span className="d-block">70</span>
                  </p>
                </li>
                <li className="d-flex align-items-center gap-3 py-2 border-bottom">
                  <div
                    className="avatar rounded-circle"
                    style={{ width: " 2.625rem", height: "2.625rem" }}
                  >
                    <img
                      src={assetImages.johnDesoza}
                      alt="Getar Beter jnr"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>
                  <div>
                    <p className="name lh-1 fw-semibold mb-1">
                      Getar Beter jnr
                    </p>
                    <p className="fs-sm lh-1 text-gray">Design Lead</p>
                  </div>
                  <p className="fs-sm text-gray mx-auto mb-0">4th</p>
                  <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      description
                    </span>
                    <span className="d-block">68</span>
                  </p>
                </li>
                <li className="d-flex align-items-center gap-3 py-2">
                  <div
                    className="avatar rounded-circle"
                    style={{ width: " 2.625rem", height: "2.625rem" }}
                  >
                    <img
                      src={assetImages.richardGomes}
                      alt="Arthur Taylor"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>
                  <div>
                    <p className="name lh-1 fw-semibold mb-1">Arthur Taylor</p>
                    <p className="fs-sm lh-1 text-gray">Design Lead</p>
                  </div>
                  <p className="fs-sm text-gray mx-auto mb-0">5th</p>
                  <p className="d-inline-flex align-items-center gap-1 px-2 py-1 bg-gray fs-xs text-white rounded-90 ms-auto">
                    <span className="d-block material-symbols-outlined icon-fill icon-md">
                      description
                    </span>
                    <span className="d-block">54</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div className="p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Latest Challenges</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <div className="table-wrapper">
                <div className="table-responsive">
                  <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                    <thead>
                      <tr>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Title
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Corporation
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Type
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Status
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Total Candidates
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Type
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Phase
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Sponsorship Amo...
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Average Scores
                        </th>
                        <th className="bg-transparent fs-md fw-normal border-0">
                          Top-Ranked Candidates
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-bottom-0">
                          <p className="fw-semibold">
                            The best data analysis tool
                          </p>
                        </td>
                        <td className="border-bottom-0">Corporation Name</td>
                        <td className="border-bottom-0">Corporate Job</td>
                        <td className="border-bottom-0">203</td>
                        <td className="border-bottom-0">
                          <p className="d-inline-block px-3 py-2 bg-success lh-1 text-white rounded-90">
                            Completed
                          </p>
                        </td>
                        <td className="border-bottom-0">Entry-level</td>
                        <td className="border-bottom-0">First Phase</td>
                        <td className="border-bottom-0">$104</td>
                        <td className="border-bottom-0">77%</td>
                        <td className="border-bottom-0">
                          <ul className="d-flex avatar-collage">
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.johnDesoza}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.richardGomes}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-bottom-0">
                          <p className="fw-semibold">
                            The best data analysis tool
                          </p>
                        </td>
                        <td className="border-bottom-0">Corporation Name</td>
                        <td className="border-bottom-0">Corporate Job</td>
                        <td className="border-bottom-0">203</td>
                        <td className="border-bottom-0">
                          <p className="d-inline-block px-3 py-2 bg-gray lh-1 text-white rounded-90">
                            Pending
                          </p>
                        </td>
                        <td className="border-bottom-0">Entry-level</td>
                        <td className="border-bottom-0">First Phase</td>
                        <td className="border-bottom-0">$104</td>
                        <td className="border-bottom-0">77%</td>
                        <td className="border-bottom-0">
                          <ul className="d-flex avatar-collage">
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.johnDesoza}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.richardGomes}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-bottom-0">
                          <p className="fw-semibold">
                            The best data analysis tool
                          </p>
                        </td>
                        <td className="border-bottom-0">Corporation Name</td>
                        <td className="border-bottom-0">Corporate Job</td>
                        <td className="border-bottom-0">203</td>
                        <td className="border-bottom-0">
                          <p className="d-inline-block px-3 py-2 bg-success lh-1 text-white rounded-90">
                            Completed
                          </p>
                        </td>
                        <td className="border-bottom-0">Entry-level</td>
                        <td className="border-bottom-0">First Phase</td>
                        <td className="border-bottom-0">$104</td>
                        <td className="border-bottom-0">77%</td>
                        <td className="border-bottom-0">
                          <ul className="d-flex avatar-collage">
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.johnDesoza}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.richardGomes}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-bottom-0">
                          <p className="fw-semibold">
                            The best data analysis tool
                          </p>
                        </td>
                        <td className="border-bottom-0">Corporation Name</td>
                        <td className="border-bottom-0">Corporate Job</td>
                        <td className="border-bottom-0">203</td>
                        <td className="border-bottom-0">
                          <p className="d-inline-block px-3 py-2 bg-gray lh-1 text-white rounded-90">
                            Pending
                          </p>
                        </td>
                        <td className="border-bottom-0">Entry-level</td>
                        <td className="border-bottom-0">First Phase</td>
                        <td className="border-bottom-0">$104</td>
                        <td className="border-bottom-0">77%</td>
                        <td className="border-bottom-0">
                          <ul className="d-flex avatar-collage">
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.johnDesoza}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.richardGomes}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-bottom-0">
                          <p className="fw-semibold">
                            The best data analysis tool
                          </p>
                        </td>
                        <td className="border-bottom-0">Corporation Name</td>
                        <td className="border-bottom-0">Corporate Job</td>
                        <td className="border-bottom-0">203</td>
                        <td className="border-bottom-0">
                          <p className="d-inline-block px-3 py-2 bg-success lh-1 text-white rounded-90">
                            Completed
                          </p>
                        </td>
                        <td className="border-bottom-0">Entry-level</td>
                        <td className="border-bottom-0">First Phase</td>
                        <td className="border-bottom-0">$104</td>
                        <td className="border-bottom-0">77%</td>
                        <td className="border-bottom-0">
                          <ul className="d-flex avatar-collage">
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.johnDesoza}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.richardGomes}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                            <li
                              className="rounded-circle border border-white"
                              style={{ width: "1.75rem", height: "1.75rem" }}
                            >
                              <img
                                src={assetImages.rockDisket}
                                alt=""
                                className="w-100 h-100 object-fit-cover object-center"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Sponsorship Status</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <div className="d-flex flex-fill align-items-center gap-4">
                <div
                  className="graph flex-shrink-0"
                  style={{ width: "10.625rem", height: "10.625rem" }}
                >
                  <img
                    src={assetImages.sponsorShipStatus}
                    alt="Sponsorship Status"
                    className="w-100 h-100 object-center object-fit-cover"
                  />
                </div>
                <ul className="d-flex flex-fill flex-column gap-2 fs-sm">
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: " 0.5rem",
                        backgroundColor: "#FF6C65",
                      }}
                    ></span>
                    <span className="d-block">Sponsorship 01</span>
                    <span className="d-block fw-semibold ms-auto">$40K</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: "#21960F",
                      }}
                    ></span>
                    <span className="d-block">Sponsorship 02</span>
                    <span className="d-block fw-semibold ms-auto">$28K</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: "#4285F4",
                      }}
                    ></span>
                    <span className="d-block">Sponsorship 03</span>
                    <span className="d-block fw-semibold ms-auto">$20K</span>
                  </li>
                  <li className="d-flex gap-2 align-items-center body-bg rounded-8 px-3 py-2">
                    <span
                      className="d-block rounded-circle"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        backgroundColor: " #76859D",
                      }}
                    ></span>
                    <span className="d-block">Sponsorship 04</span>
                    <span className="d-block fw-semibold ms-auto">$12K</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Candidate Drop-Off Rate</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <ul className="d-flex flex-column gap-3">
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">First Interview</span>
                    <span className="d-block">
                      Total drop-off <span className="h3 fw-bold">104</span>{" "}
                      Candidate
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ width: "75%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">Screening Interview</span>
                    <span className="d-block">
                      Total drop-off <span className="h3 fw-bold">80</span>{" "}
                      Candidate
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#21960F", width: "50%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">Skills Assessment</span>
                    <span className="d-block">
                      Total drop-off <span className="h3 fw-bold">60</span>{" "}
                      Candidate
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#4285F4", width: "25%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">HR Round</span>
                    <span className="d-block">
                      Total drop-off <span className="h3 fw-bold">41</span>{" "}
                      Candidate
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#EE523C", width: "10%" }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="p-3 p-sm-4 shadow-md rounded-10">
              <div className="mb-4">
                <h3 className="mb-0">Sponsorship Funds</h3>
              </div>
              <img
                src={assetImages.sponsorShipFunds}
                alt="Sponsorship Funds"
                className="img-fluid w-100"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Completion Time</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <ul className="d-flex flex-column gap-3">
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">First Interview</span>
                    <span className="d-block">
                      Average time <span className="h3 fw-bold">15</span> Min
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ width: "40%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">Screening Interview</span>
                    <span className="d-block">
                      Average time <span className="h3 fw-bold">24</span> Min
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#21960F", width: "50%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">Skills Assessment</span>
                    <span className="d-block">
                      Average time <span className="h3 fw-bold">28</span> Min
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#4285F4", width: "60%" }}
                    ></div>
                  </div>
                </li>
                <li>
                  <p className="d-flex flex-wrap align-items-center justify-content-between gap-2 fs-sm mb-2">
                    <span className="d-block">HR Round</span>
                    <span className="d-block">
                      Average time <span className="h3 fw-bold">60</span> Min
                    </span>
                  </p>
                  <div
                    className="progress body-bg"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="progress-bar rounded-90"
                      style={{ backgroundColor: "#EE523C", width: "70%" }}
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 p-3 p-sm-4 shadow-md rounded-10">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="mb-0">Judge Feedback</h3>
                <Link
                  to="#"
                  className="d-inline-block btn-link fw-bold text-primary text-underline"
                >
                  View Details
                </Link>
              </div>
              <div className="d-flex flex-fill align-items-center gap-4">
                <div
                  className="graph flex-shrink-0"
                  style={{ width: "10.625rem", height: "10.625rem" }}
                >
                  <img
                    src={assetImages.judgeFeedbackImg}
                    alt="Judge Feedback"
                    className="w-100 h-100 object-center object-fit-cover"
                  />
                </div>
                <div className="flex-fill">
                  <p className="fw-semibold mb-3">
                    Aggregate judge feedback categorized
                  </p>
                  <ul className="d-flex gap-2">
                    <li className="d-flex flex-column gap-2 flex-fill body-bg rounded-8 p-3">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#21960F",
                        }}
                      ></span>
                      <p className="lh-1">Positive</p>
                      <h3 className="fw-semibold m-0">24K+</h3>
                    </li>
                    <li className="d-flex flex-column gap-2 flex-fill body-bg rounded-8 p-3">
                      <span
                        className="d-block rounded-circle"
                        style={{
                          width: "0.5rem",
                          height: "0.5rem",
                          backgroundColor: "#EE523C",
                        }}
                      ></span>
                      <p className="lh-1">Constructive</p>
                      <h3 className="fw-semibold m-0">16K+</h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    </section>
  );
};

export default ChallengeDashboardBody;
