/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import LeadCvBuilderBody from "components/CRMComponents/CRMLeadInformationComponents/CRMLeadCVBuilder/LeadCvBuilderBody";

const CRMLeadCVBuilder = () => {
  useEffect(() => {
    document.title = "CRM Lead | CV Builder";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <LeadCvBuilderBody />
    </main>
  );
};

export default CRMLeadCVBuilder;
