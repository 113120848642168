import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AllLogKanbanBody from "components/AllLogComponents/AllLogKanbanBody/AllLogKanbanBody";

const AllLogKanban = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "All Logs";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="alllogs" />
      <AllLogKanbanBody />
    </main>
  );
};

export default AllLogKanban;
