/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

//import images
import { assetImages } from "constants";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DirectAccessHeader from "../Header/DirectAccessHeader";
import DirectAccessFilterPopup from "../Popup/DirectAccessFilterPopup";
import SaveDirectAccessPopup from "../Popup/SaveDirectAccessPopup";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

const DirectAccessBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [accessList, setAccessList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedAccessIds, setSelectedAccessIds] = useState([]);

  const [selectedAccessId, setselectedAccessId] = useState(null);

  //filter requirements
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterTitle, setfilterTitle] = useState("");
  const [filterUrl, setfilterUrl] = useState("");
  const [filterUserIds, setfilterUserIds] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all direct access
  const getAllDirectAccess = async () => {
    setRowSelection({});
    setSelectedAccessIds([]);
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_DIRECT_ACCESS + `?token=${token}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle !== "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterUrl !== "") {
        requestUrl = requestUrl + `&filterurl=${filterUrl}`;
      }

      if (filterUserIds.length > 0) {
        requestUrl = requestUrl + `&filteruserids=${filterUserIds}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setAccessList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //function for massive delete direct access
  const bulkDeleteDirectAccess = async () => {
    if (selectedAccessIds.length > 0) {
      const accessListToBeDelete = accessList.filter((item) =>
        selectedAccessIds.includes(item._id)
      );

      try {
        const bookmarkData = {
          accesslist: accessListToBeDelete,
        };

        const requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_DIRECT_ACCESS +
          `?token=${token}`;

        const response = await putData(requestURL, bookmarkData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllDirectAccess();
          }, 1000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 70,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#saveDirectAccessPopup"
          aria-controls="saveDirectAccessPopup"
          onClick={() => {
            setselectedAccessId(row.original._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "title",
      header: t("Title"),
      size: 200,
      Cell: ({ row }) => (
        <Link
          to={row.original.accessurl}
          className="text-decoration-none"
          // target="_blank"
        >
          {row.original.title}
        </Link>
      ),
    },
    {
      accessorKey: "accessurl",
      header: t("Url for Access"),
      Cell: ({ row }) => (
        <Link
          to={row.original.accessurl}
          className="text-decoration-none"
          // target="_blank"
        >
          {row.original.accessurl}
        </Link>
      ),
    },
    {
      accessorKey: "moderatorname",
      header: t("Owner"),
    },
    {
      accessorKey: "createddate",
      header: t("Date"),
    },
    {
      accessorKey: "tagnames",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagnames != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagnames.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  /* Updates the column visibility state by toggling the visibility of the column with the given accessor key.*/
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /* Represents the state of column visibility in the table.*/
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  //function for column visibility
  const onColumnVisiblityHandler = (newColumnState) => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  const resetFilterData = () => {
    setfilterTitle("");
    setfilterUrl("");
    setfilterUserIds([]);
    setfilterTags([]);

    setReloadData(true);
  };

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedIdArr = selectedIdsArray.map((id) => id);

      setSelectedAccessIds(selectedIdArr);
    } else {
      setSelectedAccessIds([]);
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      filterTitle !== "" ||
      filterUrl !== "" ||
      filterUserIds.length > 0 ||
      filterTags.length > 0
    ) {
      getAllDirectAccess();
    }
  }, [filterTitle, filterUrl, filterUserIds, filterTags]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getAllDirectAccess();
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllDirectAccess();
      setReloadData(false);
    }
  }, [reloadData]);

  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <DirectAccessHeader reloadList={refreshRecords} />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              {/* ------ pagination section end ------ */}
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={accessList} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                  pagination: lastPagination,
                  columnVisibility: visibleColoumns,
                  columnOrder: columnOrder,
                }} //pass our managed row selection state to the table to use
                onPaginationChange={setPagination} // set pagination
                initialState={{
                  rowSelection,
                  pagination: lastPagination,
                  columnVisibility: visibleColoumns,
                  columnOrder: columnOrder,
                }}
                // enableRowActions // show the action column
                enableColumnOrdering={true}
                // renderRowActionMenuItems={({ row }) => {
                //   const [leadId, emailId, ownerId, leadName] =
                //     row.id.split("/"); // Extracts the _id part

                //   const menuItems = [
                //     <MenuItem key="details">
                //       <Link
                //         className="dropdown-item"
                //         to={`/admin/crm/lead/information/${leadId}`}
                //       >
                //         {t("View Details")}
                //       </Link>
                //     </MenuItem>,
                //   ];

                //   if (
                //     userInfo.role.slug === "ADMIN" ||
                //     userInfo.role.slug === "SUPER_ADMIN" ||
                //     userInfo._id.toString() === ownerId.toString()
                //   ) {
                //     menuItems.splice(
                //       2,
                //       0,
                //       <MenuItem key="leadtask">
                //         <Link
                //           className="dropdown-item"
                //           to={`/admin/crm/lead/addtask/${leadId}`}
                //         >
                //           {t("View Task")}
                //         </Link>
                //       </MenuItem>,
                //       <MenuItem key="leadmail">
                //         <Link
                //           className="dropdown-item"
                //           to={`/admin/crm/lead/communication/${leadId}`}
                //         >
                //           {t("View Communication")}
                //         </Link>
                //       </MenuItem>
                //     );
                //   }

                //   if (
                //     userInfo.role.slug === "ADMIN" ||
                //     userInfo.role.slug === "SUPER_ADMIN" ||
                //     (moduleAccess.includes("MOD_CRM") &&
                //       moduleAccess.includes("MOD_OPPORTUNITY"))
                //   ) {
                //     menuItems.splice(
                //       3,
                //       0,
                //       <MenuItem key="leadopportunity">
                //         <Link
                //           className="dropdown-item"
                //           to={`/admin/crm/lead/opportunities/${leadId}`}
                //         >
                //           {t("View Opportunities")}
                //         </Link>
                //       </MenuItem>
                //     );
                //   }

                //   if (
                //     userInfo.role.slug === "ADMIN" ||
                //     userInfo.role.slug === "SUPER_ADMIN" ||
                //     userInfo._id.toString() === ownerId.toString()
                //   ) {
                //     menuItems.push(
                //       <MenuItem key="update">
                //         <Link
                //           className="dropdown-item"
                //           to={`/admin/crm/lead/save/${leadId}`}
                //         >
                //           {t("Update Lead")}
                //         </Link>
                //       </MenuItem>
                //     );
                //   }

                //   return menuItems;
                // }} // action columns menu items
                onColumnVisibilityChange={onColumnVisiblityHandler}
                onColumnOrderChange={changeColumnOrderHandler}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      {/* update bookmarke popup  */}
      <SaveDirectAccessPopup
        selectedAccessId={selectedAccessId}
        setselectedAccessId={setselectedAccessId}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
        afterPopupClose={() => {
          setTimeout(() => {
            getAllDirectAccess();
          }, 2000);
        }}
      />

      <AddLabelModal
        moduleName="directaccess"
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllDirectAccess();
          }, 2000);
        }}
        selectedIds={selectedAccessIds}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
      />

      {/* filter popup */}
      <DirectAccessFilterPopup
        moduleName={"MOD_DIRECT_ACCESS"}
        visibleColoumns={visibleColoumns}
        setVisibleColoumns={setVisibleColoumns}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        setfilterTitle={setfilterTitle}
        setfilterUrl={setfilterUrl}
        setfilterUserIds={setfilterUserIds}
        setfilterTags={setfilterTags}
        setfilterStartDate={setfilterStartDate}
        setfilterEndDate={setfilterEndDate}
        isFilterReset={isFilterReset}
        setIsFilterReset={setIsFilterReset}
        setIsNoDefaultFilter={setIsNoDefaultFilter}
      />

      <DeleteWarningModal
        moduleName="Bookmark"
        onDelete={bulkDeleteDirectAccess}
        onCancelDelete={() => {
          setRowSelection({});
          setSelectedAccessIds([]);
        }}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default DirectAccessBody;
