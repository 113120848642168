/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BreadCrumb = ({
  breadCrumbText = [],
  bottom = false,
  top = true,
  displayName = "",
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <nav
      className={`breadcrumb-container d-none d-md-block  ${
        top ? "pt-3" : ""
      } ${bottom ? "pb-3" : ""}`}
      aria-label="breadcrumb"
    >
      <ol className="breadcrumb fs-sm mb-0">
        {/* --- fixed bread crumb text start --- */}
        <li className="breadcrumb-item">
          <Link to="/admin/dashboard">{t("Dashboard")}</Link>
        </li>
        {/* --- fixed bread crumb text end --- */}

        {/* --- dynamic bread crumb text start ---- */}
        {breadCrumbText.map((item, index) => {
          return (
            <li
              key={index}
              className={`breadcrumb-item ${
                index === breadCrumbText.length - 1 && "active"
              } `}
              aria-current="page"
            >
              {index === breadCrumbText.length - 1 ? (
                item.title
              ) : (
                <Link to={item.link}>{item.title}</Link>
              )}
            </li>
          );
        })}

        {/* --- dynamic bread crumb text end ---- */}
        {displayName && displayName !== "" ? (
          <li className={`breadcrumb-item active`} aria-current="page">
            {displayName}
          </li>
        ) : null}
      </ol>
    </nav>
  );
};
export default BreadCrumb;
