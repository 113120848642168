/* eslint-disable */
import { useState } from "react";
import { useParams } from "react-router-dom";

import CrmLeadInformationCommonHeader from "../../common/CrmLeadInformationCommonHeader";
import CrmLeadInformationLeftPanel from "../LefPanel/CrmLeadInformationLeftPanel";

import { assetImages } from "constants";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";

import NoteSection from "components/Common/NoteComponent/NoteSection";
import { useTranslation } from "react-i18next";

const CrmLeadInformationBody = () => {
  const params = useParams();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation(); //for translation

  const [leadName, setleadName] = useState("");
  const [leadImage, setleadImage] = useState("");
  const [isAuthenticatedUser, setisAuthenticatedUser] = useState(false);

  const [noteAccessUserList, setnoteAccessUserList] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white">
          {/* ------ lead information common header ------ */}
          <CrmLeadInformationCommonHeader
            moduleName="leadinformation"
            leadName={leadName}
            leadImage={leadImage}
            isAuthenticatedUser={isAuthenticatedUser}
          />
          {/* ------ lead information section start ------ */}
          <div className="crm-info-container py-4 py-md-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                {/* ------- lead information left panel start ------ */}
                <div className="col-lg-8 mb-4 mb-lg-0">
                  <CrmLeadInformationLeftPanel
                    setleadName={setleadName}
                    setleadImage={setleadImage}
                    setisValidUser={setisAuthenticatedUser}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                    setnoteAccessUserList={setnoteAccessUserList}
                  />
                </div>
                {/* ------- lead information left panel end ------ */}

                {/* ------- lead information right panel start ------ */}
                <div className="col-lg-4">
                  <NoteSection
                    noteAccessUserList={noteAccessUserList}
                    leadId={params.id}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                  />
                </div>
                {/* ------- lead information right panel end ------ */}
              </div>
            </div>
          </div>
          {/* ------ lead information section end ------ */}

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default CrmLeadInformationBody;
