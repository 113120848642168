/* eslint-disable */
import React from "react";
import { assetImages } from "constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteWarningModal = ({
  onDelete = () => {},
  onCancelDelete = () => {},
  moduleName = "",
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="deletewarning"
      tabIndex="-1"
      aria-labelledby="eventGallerydeleteModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2>
              {t(`Are you sure you want to delete this`)} {t(`${moduleName}`)}?
            </h2>
            {/* <h3>** Task with logs can't be deleted</h3> */}
            <div className="d-flex gap-3 justify-content-center">
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onDelete}
              >
                {t("Yes Delete")}
              </Link>

              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancelDelete}
              >
                {t("No, Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWarningModal;
