/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import VatOptions from "data/Invoice/VAT.json";

import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import { assetImages } from "constants";
import NoteSection from "../NoteComponent/NoteSection";

const SaveInvoicePopup = ({
  moduleName,
  leadId = null,
  contactId = null,
  contactPersonId = null,
  moduleSlug,
  selectedInvoiceId,
  setSelectedInvoiceId,
  reloadList,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  const ecosystemLogo = localStorage.getItem("ecosystemlogo");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const history = useHistory();
  const params = useParams();

  const [followersInput, setFollowersInput] = useState("");
  const [followersOptions, setFollowersOptions] = useState([]);

  const [invoiceModuleName, setInvoiceModuleName] = useState(moduleName || "");
  const [invoiceLeadId, setinvoiceLeadId] = useState(leadId);
  const [invoiceContactId, setinvoiceContactId] = useState(contactId);
  const [invoiceContactPersonId, setinvoiceContactPersonId] =
    useState(contactPersonId);
  const [ownerName, setownerName] = useState("");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [promisePercent, setpromisePercent] = useState("");
  const [followersValue, setFollowersValue] = useState(null);
  const [selctedFollowers, setSelectedFollowers] = useState([]);
  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [noteList, setNoteList] = useState([]);

  const [invoiceOwnerId, setinvoiceOwnerId] = useState(null);

  const invoiceItemTemplate = {
    _id: null,
    description: "",
    quantity: 0,
    price: 0,
    discountpercent: 0,
    discount: 0,
    taxpercent: 0,
    tax: 0,
    amount: 0,
    taxselectedvalue: null, // non-functional used for mapping react select
    descriptionwarning: false, // non-functional used for showing warning
    quantitywarning: false, // non-functional, used for showing warning
    pricewarning: false, // non-functional, used for showing warning
  };

  const [invoiceItemsList, setInvoiceItemsList] = useState([]);

  const [invoiceNote, setInvoiceNote] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  // validation of invoice
  const [validation, setValidation] = useState({
    titleWarning: false,
    priceWarning: false,
    percentWarning: false,
  });

  //requirements of leads/contacts
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [emailAddress, setemailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [company, setcompany] = useState("");
  const [position, setposition] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");

  const [noteAccessUserList, setnoteAccessUserList] = useState([]);

  const onFollowersInputChange = (val) => {
    setFollowersInput(val);
  };

  const getFollowersOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setFollowersOptions(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  const followersSelectionHandler = (val) => {
    setFollowersValue(val);
    setSelectedFollowers(val.map((item) => item.value));
  };

  // on click of Add item
  const addNewInvoiceItem = () => {
    setInvoiceItemsList([...invoiceItemsList, invoiceItemTemplate]);
  };

  // on click delete a invoice item
  const onDeleteInvoiceItem = (index) => {
    const deletedItemBlock = [...invoiceItemsList];
    deletedItemBlock.splice(index, 1);
    setInvoiceItemsList(deletedItemBlock);
  };

  //update invoice item block
  const updateInvoiceBlock = (invoiceItems) => {
    const invoiceItemsArr = invoiceItems.map((invoiceItem) => {
      let invoiceItemSavedData = {
        _id: invoiceItem._id ?? null,
        description: invoiceItem.description ?? "",
        quantity: invoiceItem.unit ?? "",
        price: invoiceItem.price ?? "",
        discountpercent: invoiceItem.discountpercent ?? 0,
        discount: invoiceItem.discountamount ?? 0,
        taxpercent: invoiceItem.taxpercent ?? 0,
        tax: invoiceItem.taxamount ?? 0,
        amount: invoiceItem.totalprice ?? 0,
        taxselectedvalue: getTaxSelectedSelectOptionValue(
          invoiceItem.taxpercent
        ), // non-functional used for mapping react select
        descriptionwarning: false, // non-functional used for showing warning
        quantitywarning: false, // non-functional, used for showing warning
        pricewarning: false, // non-functional, used for showing warning
      };

      return invoiceItemSavedData;
    });

    setInvoiceItemsList(invoiceItemsArr);
  };

  //change invoice item input value
  const changeInvoiceItemsInputs = (index, field, value) => {
    const updatedFormValues = invoiceItemsList.map((block, i) => {
      if (field === "description") {
        if (value !== "") {
          block.descriptionwarning = false;
        } else {
          block.descriptionwarning = true;
        }
      } else if (field === "quantity") {
        if (value !== "") {
          block.quantitywarning = false;
        } else {
          block.quantitywarning = true;
        }
      } else if (field === "price") {
        if (value !== "") {
          block.pricewarning = false;
        } else {
          block.pricewarning = true;
        }
      } else {
      }
      return index == i ? Object.assign(block, { [field]: value }) : block;
    });

    setInvoiceItemsList(updatedFormValues);

    updateItemGrossVlues(index);
  };

  //function for select tax
  const taxSelectionHandler = (index, field1, field2, val) => {
    const updatedFormValues = invoiceItemsList.map((block, i) =>
      index == i
        ? Object.assign(block, { [field1]: val, [field2]: val.value })
        : block
    );

    setInvoiceItemsList(updatedFormValues);

    updateItemGrossVlues(index);
  };

  // update invoice calculation
  const invoiceItemUpdateHandler = () => {
    const updatedItems = [...invoiceItemsList];

    const totalAmount = updatedItems.reduce(
      (amount, item) => amount + item.price * item.quantity,
      0
    );

    const totalTax = updatedItems.reduce((tax, item) => tax + item.tax, 0);

    const totalDiscount = updatedItems.reduce(
      (disount, item) => disount + item.discount,
      0
    );

    const payableAmount = updatedItems.reduce(
      (totalamount, item) => totalamount + item.amount,
      0
    );

    console.log("totalAmount", totalAmount);

    // setInvoice((prevInvoice) => ({
    //   ...prevInvoice,
    //   amount: totalAmount,
    //   totaltax: totalTax,
    //   totaldiscount: totalDiscount,
    //   payableamount: payableAmount,
    // }));

    setTotalAmount(totalAmount);
    setTotalTax(totalTax);
    setTotalDiscount(totalDiscount);
    setPayableAmount(payableAmount);
  };

  // on update or view, show the pre-selected value of Tax for each invoice items
  const getTaxSelectedSelectOptionValue = (val) => {
    let selectedVatOption = {};

    for (let vatOption of VatOptions) {
      if (vatOption.value === val.toString()) {
        selectedVatOption = vatOption;
      }
    }

    return selectedVatOption;
  };

  // set dependent item value on params change
  const updateItemGrossVlues = (index) => {
    const updatedItems = [...invoiceItemsList];
    const quantity = parseInt(updatedItems[index].quantity);
    const price = parseInt(updatedItems[index].price);
    const tax = parseInt(updatedItems[index].taxpercent);

    if (quantity > 0 && price > 0) {
      const taxToPay = parseInt((quantity * price * tax) / 100, 10);
      const totalPrice = parseInt(quantity * price, 10) + taxToPay;

      updatedItems[index].tax = taxToPay;
      updatedItems[index].amount = totalPrice;

      // setInvoice((prevInvoice) => ({
      //   ...prevInvoice,
      //   items: updatedItems,
      // }));
      setInvoiceItemsList(updatedItems);
    }
  };

  // function for validation
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (totalAmount === "" || totalAmount <= 0) {
      setValidation((prevState) => ({ ...prevState, priceWarning: true }));
      isValid = false;
    }

    if (promisePercent === "" || promisePercent <= 0) {
      setValidation((prevState) => ({
        ...prevState,
        percentWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //* save the invoice record */
  const saveInvoice = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      let invoiceData = {
        leadid: invoiceLeadId || leadId || null,
        contactid: invoiceContactId || contactId || null,
        clientid: invoiceContactPersonId || contactPersonId || null,
        title,
        description,
        amount: totalAmount,
        promisepercent: promisePercent,
        followers: selctedFollowers,
        invoicedate: getCurrentDateInString(),
        duedate: getCurrentDateInString(),
        totaltax: totalTax,
        totaldiscount: totalDiscount,
        payableamount: payableAmount,
        currency: "euro",
        items: invoiceItemsList,
        status: "1",
        notes: [],
        typeslug: moduleSlug,
        modulename: invoiceModuleName ?? "",
      };

      console.log("invoice ===>", invoiceData);

      let requestUrl = url.API_BASE_URL;

      let response = {};

      try {
        if (selectedInvoiceId) {
          requestUrl +=
            url.API_UPDATE_INVOICE + `/${selectedInvoiceId}?token=${token}`;

          response = await putData(requestUrl, invoiceData);
        } else {
          requestUrl += url.API_CREATE_INVOICE + `?token=${token}`;

          response = await postData(requestUrl, invoiceData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          let offCanvasPopup = document.querySelector("#saveInvoice");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          resetHandler();
          reloadList();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up all required fields");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //* get the invoice details */
  const getInvoiceDetails = async () => {
    let requestUrl =
      url.API_BASE_URL +
      url.API_INVOICE_DETAILS +
      `/${selectedInvoiceId}` +
      `?token=${token}`;

    try {
      const response = await getData(requestUrl);

      console.log("invoice details ----->", response);

      if (response.status) {
        const invoiceData = response.data;

        if (invoiceData.modulename) {
          setInvoiceModuleName(invoiceData.modulename);
        } else if (moduleName && moduleName !== "") {
          setInvoiceModuleName(moduleName);
        } else {
          setInvoiceModuleName("");
        }

        setinvoiceLeadId(invoiceData.leadid ? invoiceData.leadid._id : null);

        setinvoiceContactPersonId(
          invoiceData.client ? invoiceData.client._id : null
        );
        settitle(invoiceData.title);
        setdescription(invoiceData.description);
        setpromisePercent(invoiceData.promisepercent);
        followersSelectionHandler(invoiceData.followerslist);
        setinvoiceOwnerId(invoiceData.user._id);
        setownerName(invoiceData.ownername);

        if (invoiceData.invoiceitems && invoiceData.invoiceitems.length > 0) {
          updateInvoiceBlock(invoiceData.invoiceitems);
        } else {
          setTotalAmount(invoiceData.amount);
        }
        setNoteList(invoiceData.notes);

        if (invoiceData.leadid) {
          getLeadDetails(invoiceData.leadid._id);
        }

        if (invoiceData.client) {
          getContactUserDetails(invoiceData.client._id);
        }

        if (invoiceData.contactid) {
          setinvoiceContactId(invoiceData.contactid);
        }

        const allFollowerIds = response.data.followers
          ? response.data.followers.map((item) => item._id)
          : [];

        const appendedNoteFollowers = [
          ...allFollowerIds,
          response.data.user._id,
        ];

        const filterNoteFollowers = appendedNoteFollowers.filter(
          (item) => item.toString() !== userInfo._id.toString()
        );

        setnoteAccessUserList(filterNoteFollowers);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  const resetHandler = () => {
    setValidation({
      titleWarning: false,
      priceWarning: false,
      percentWarning: false,
    });

    const followersObj = [
      {
        label: `${userInfo.name ?? userInfo.email} ${
          userInfo.surname ? userInfo.surname : null
        }`,
        value: userInfo._id,
      },
    ];

    followersSelectionHandler(followersObj);

    settitle("");
    setdescription("");
    setpromisePercent("");
    setInvoiceItemsList([]);
    setTotalAmount("");
    setTotalTax("");
    setTotalTax("");
    setPayableAmount("");
    setFollowersInput("");
    setFollowersOptions([]);
    setInvoiceNote("");
    setNoteList([]);
    setSelectedInvoiceId(null);
    // addNewInvoiceItem();

    //reset information
    setfirstName("");
    setlastName("");
    setemailAddress("");
    setPhoneNumber("");
    setDateOfBirth("");
    setcompany("");
    setposition("");
    setcity("");
    setstate("");
    setcountry("");
    setzipcode("");
  };

  //function for get lead details
  const getLeadDetails = async (leadId) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${leadId}?token=${token}`;

      console.log("url of lead details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of lead details------>", response);

      if (response.status) {
        setfirstName(response.data.name);
        setlastName(response.data.surname);
        setemailAddress(response.data.email);
        setPhoneNumber(response.data.phone);

        if (response.data.dob) {
          if (response.data.dob.includes("-")) {
            let components = response.data.dob.split("-");
            let year = components[0];
            let month = components[1];
            let day = components[2];
            let newDate = day + "-" + month + "-" + year;
            setDateOfBirth(newDate);
          }
        } else {
          setDateOfBirth("");
        }

        setcompany(response.data?.company ?? "");
        setposition(response.data?.position ?? "");
        setcity(response.data?.city ?? "");
        setstate(response.data?.state ?? "");
        setcountry(response.data?.country ?? "");
        setzipcode(response.data?.zipcode ?? "");
        setaddress(response.data?.address ?? "");
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get contact user details
  const getContactUserDetails = async (userId) => {
    // console.log("userInfo", userInfo);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${userId}` +
        `?token=${token}`;

      // console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status && response.data) {
        setfirstName(response.data.name);
        setlastName(response.data.surname);
        setemailAddress(response.data.email);
        setPhoneNumber(response.data.phone);

        if (response.data.dob) {
          let components = response.data.dob.split("-");
          let year = components[0];
          let month = components[1];
          let day = components[2];
          let newDate = day + "-" + month + "-" + year;
          setDateOfBirth(newDate);
        } else {
          setDateOfBirth("");
        }

        setcompany(response.data?.company ?? "");
        setposition(response.data?.position ?? "");
        setcity(response.data?.city ?? "");
        setstate(response.data?.state ?? "");
        setcountry(response.data?.country ?? "");
        setzipcode(response.data?.zipcode ?? "");
        setaddress(response.data?.address ?? "");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // get client list and default UI
  useEffect(() => {
    const followersObj = [
      {
        label: `${userInfo.name ?? userInfo.email} ${
          userInfo.surname ? userInfo.surname : null
        }`,
        value: userInfo._id,
      },
    ];

    followersSelectionHandler(followersObj);

    if (invoiceItemsList.length === 0) {
      addNewInvoiceItem();
    }
  }, []);

  // update cost and other info when any invoiceitem data changes in invoice
  useEffect(() => {
    invoiceItemUpdateHandler();
  }, [invoiceItemsList]);

  // get saved invoice data to show and update
  useEffect(() => {
    if (selectedInvoiceId) {
      getInvoiceDetails();
    }
  }, [selectedInvoiceId]);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersOptions();
    }
  }, [followersInput]);

  //options for company list in select
  const salesPersonOptionsToShow =
    followersOptions.length > 0 ? followersOptions : [];

  return (
    <div
      className="offcanvas invoice_w offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveInvoice"
      aria-labelledby="saveInvoice"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          Save Opportunity
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="d-flex flex-column h-100"
              >
                <div className="fields-container flex-fill">
                  {/* <!-- ========== Start moderator Section ========== --> */}
                  {ownerName === "" ? null : (
                    <div className="form-group mb-4">
                      <label
                        htmlFor="labelName"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        Moderator
                      </label>
                      <p className="form-control fs-sm shadow-none">
                        {ownerName}
                      </p>
                    </div>
                  )}
                  {/* <!-- ========== End moderator Section ========== --> */}

                  {/* <!-- ========== Start title Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="labelName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="labelName"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter here"
                      value={title}
                      onChange={(e) => {
                        settitle(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          titleWarning: false,
                        }));
                      }}
                    />
                    {validation.titleWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>Please enter a title!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <!-- ========== End title Section ========== --> */}

                  {/* <!-- ========== Start description Section ========== --> */}
                  <div className="form-group mb-4">
                    <label
                      htmlFor="description"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Description
                    </label>
                    <textarea
                      name=""
                      id=""
                      rows="5"
                      className="form-control fs-sm shadow-none"
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  {/* <!-- ========== End description Section ========== --> */}

                  {/* <!-- ========== Start amount, percent and salesperson Section ========== --> */}
                  <div className="row">
                    {/* <!-- ========== Start amount Section ========== --> */}
                    <div className="col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="labelName"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Price
                        </label>
                        {/* <input
                          type="number"
                          id="labelName"
                          className="form-control fs-sm shadow-none"
                          placeholder="Enter here"
                          value={totalAmount}
                          onChange={(e) => {
                            setTotalAmount(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              priceWarning: false,
                            }));
                          }}
                        /> */}
                        <p className="form-control fs-sm shadow-none">
                          {totalAmount}
                        </p>
                        {validation.priceWarning && (
                          <div className="error-message mt-2">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                              <span>Please enter price!</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!-- ========== End amount Section ========== --> */}

                    {/* <!-- ========== Start percent Section ========== --> */}
                    <div className="col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="labelName"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Promise Percent
                        </label>
                        <input
                          type="number"
                          id="labelName"
                          className="form-control fs-sm shadow-none"
                          placeholder="Enter here"
                          value={promisePercent}
                          onChange={(e) => {
                            setpromisePercent(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              percentWarning: false,
                            }));
                          }}
                        />
                        {validation.percentWarning && (
                          <div className="error-message mt-2">
                            <p className="d-flex align-items-center gap-1 text-danger">
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                              <span>Please enter a promise percent!</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!-- ========== End percent Section ========== --> */}

                    {/* <!-- ========== Start followers Section ========== --> */}
                    <div className="col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="labelName"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Followers
                        </label>
                        <Select
                          isMulti
                          placeholder="Type person name or email"
                          options={salesPersonOptionsToShow}
                          value={followersValue}
                          onChange={(val) => {
                            followersSelectionHandler(val);
                          }}
                          onInputChange={(val) => {
                            onFollowersInputChange(val);
                          }}
                        />
                      </div>
                    </div>
                    {/* <!-- ========== End followers Section ========== --> */}
                  </div>
                  {/* <!-- ========== End amount and percent Section ========== --> */}

                  {/* Invoice Items */}
                  <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
                    {/* <!-- ========== Start invoice item table ========== --> */}
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <thead>
                          <tr>
                            <th
                              width="40%"
                              className="description bg-transparent fs-md fw-semibold border-0"
                            >
                              Description
                            </th>
                            <th className="quantity bg-transparent fs-md fw-semibold border-0">
                              Quantity
                            </th>
                            <th className="amt bg-transparent fs-md fw-semibold border-0">
                              Price
                            </th>
                            <th className="taxes bg-transparent fs-md fw-semibold border-0">
                              Tax (IVA)
                            </th>
                            <th className="pay-amt bg-transparent fs-md fw-semibold border-0">
                              Total Amount
                            </th>
                            <th className="bg-transparent fs-md fw-semibold border-0"></th>
                          </tr>
                        </thead>
                        <tbody style={{ height: "200px" }}>
                          {invoiceItemsList.map((invoiceItem, index) => {
                            return (
                              <tr key={index} style={{ verticalAlign: "top" }}>
                                <td className="description border-bottom-0 p-1">
                                  <input
                                    type="text"
                                    name="description"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.descriptionwarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    value={invoiceItem.description}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="quantity border-bottom-0 p-1">
                                  <input
                                    name="quantity"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.quantitywarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    type="number"
                                    value={invoiceItem.quantity}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="amt border-bottom-0 p-1">
                                  <input
                                    name="price"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.pricewarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    type="number"
                                    value={invoiceItem.price}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="taxes border-bottom-0 p-0">
                                  <Select
                                    placeholder="IVA %"
                                    options={VatOptions}
                                    className="form-control "
                                    value={invoiceItem.taxselectedvalue}
                                    onChange={(val) =>
                                      taxSelectionHandler(
                                        index,
                                        "taxselectedvalue",
                                        "taxpercent",
                                        val
                                      )
                                    }
                                  />
                                </td>
                                <td className="pay-amt border-bottom-0 p-1">
                                  <span
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.pricewarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                  >
                                    {invoiceItem.amount}
                                  </span>
                                </td>
                                <td className="action border-bottom-0">
                                  <Link
                                    className="text-black "
                                    to="#"
                                    onClick={() => onDeleteInvoiceItem(index)}
                                  >
                                    <span className="d-block material-symbols-outlined">
                                      delete
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <!-- ========== End invoice item table ========== --> */}

                    {/* <!-- ========== Start add new invoice item button ========== --> */}
                    {invoiceOwnerId &&
                    invoiceOwnerId.toString() !==
                      userInfo._id.toString() ? null : (
                      <button
                        className="btn btn-outline-primary"
                        onClick={addNewInvoiceItem}
                      >
                        + Add Item
                      </button>
                    )}
                    {/* <!-- ========== End add new invoice item button ========== --> */}

                    {/* <!-- ========== Start save invoice button ========== --> */}
                    {invoiceOwnerId &&
                    invoiceOwnerId.toString() !==
                      userInfo._id.toString() ? null : (
                      <button
                        className={`btn btn-primary ms-5`}
                        onClick={saveInvoice}
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                      >
                        Save Opportunity
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    )}
                    {/* <!-- ========== End save invoice button ========== --> */}
                  </div>

                  {/* Invoice final amount */}
                  <div className="table-wrapper pb-3 mb-3 border-bottom border-gray">
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <tbody>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              Total Discount
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalDiscount}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              Total Tax (VAT)
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalTax}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fs-lg fw-semibold bg-transparent border-0">
                              Total Payable Amount
                            </td>
                            <td className="p-0 fs-lg fw-semibold text-success bg-transparent text-end border-0">
                              {payableAmount}€
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            {selectedInvoiceId ? (
              <NoteSection
                noteAccessUserList={noteAccessUserList}
                invoiceId={selectedInvoiceId}
                setShowAlert={setShowAlert}
                setAlertMessage={setAlertMessage}
                setMessageType={setMessageType}
              />
            ) : null}

            <div className="mt-4  p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
              <h2>
                {invoiceLeadId && "Lead"} {invoiceContactPersonId && "Contact"}{" "}
                Information
              </h2>
              {/* <!-- ========== Start lead information Section ========== --> */}
              <h3>Name</h3>
              <p>
                <Link
                  to={
                    invoiceLeadId
                      ? `/admin/crm/lead/information/${invoiceLeadId}`
                      : invoiceContactId
                      ? `/admin/crm/contact/details/timeline/${invoiceContactId}`
                      : "#"
                  }
                >
                  {firstName} {lastName}
                </Link>
              </p>

              <h3>Email</h3>
              <p>{emailAddress}</p>

              <h3>Phone</h3>
              <p>{phoneNumber}</p>

              <h3>Date of birth</h3>
              <p>{dateOfBirth}</p>

              <h3>Position</h3>
              {position === "" ? null : (
                <p>
                  {position} {position !== "" && company !== "" ? "at" : ""}{" "}
                  {company === "" ? "" : company}
                </p>
              )}

              <h3>Location</h3>
              <p>{address}</p>

              <h3>City</h3>
              <p>{city}</p>

              <h3>State</h3>
              <p>{state}</p>

              <h3>Country</h3>
              <p>{country}</p>

              <h3>Zipcode</h3>
              <p>{zipcode}</p>

              {/* <!-- ========== End lead information Section ========== --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveInvoicePopup;
